import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button } from "react-bootstrap"
import { closeModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './ModalStyle.scss';
import { API, Storage } from 'aws-amplify';


class AddDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileInput: ''
    }
    this.handleModalClose.bind(this);
  }

  handleModalClose() {
    this.props.setModalName(null);
    this.props.closeModal();

    //Storage.get(this.state.newFileKey, {level: 'protected', expires: 60}).then(result => console.log(result)).catch(err => console.log(err));
  }

  uuid() { // Public Domain/MIT
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
      d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  uploadDocument = async () => {
    var storageResponse = '';
    //this next await IS necessary, allows uuid() to run before uploading file
    await this.setState({ newFileKey: this.uuid() });
    try {
      storageResponse = await Storage.put(this.state.newFileKey, this.state.fileInput, {
        contentType: 'application/pdf',
        level: 'public'
      })
      console.log(storageResponse);

      //Now, include the newly uploaded record in the API.
      API.post('1', `/documents`, { body: { ItemId: this.state.newFileKey, FileName: this.state.fileName, MemberId: 'PLACEHOLDER' } }).then(responseJson => {
        console.log(responseJson);
      });
    } catch (err) {
      console.log(err);
    }
  }
  onChange = e => {
    if (e.target.files.length > 0) {
      console.log(e.target.files[0])
      this.setState({ fileInput: e.target.files[0], fileName: e.target.files[0].name })
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Documents</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input id="file-upload" type="file" accept=".pdf" onChange={this.onChange} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
          </Button>
            <Button variant="primary" onClick={this.uploadDocument}>
              Upload
          </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )

  }
}
AddDocuments.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
  }
}
export default connect(mapStateToProps, { closeModal, setModalName })(AddDocuments);