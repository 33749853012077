import React, { } from 'react';
import { Form, Button } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.css';
import '../../../App.css';
import { Auth, API, Storage } from 'aws-amplify';
import { SignIn } from "aws-amplify-react";
import customStyle from './loginStyles';
import logo from '../../../assets/logo.png';
import { FontAwesomeIcon } from '../../../../node_modules/@fortawesome/react-fontawesome'
import { faFacebook, faLinkedin, faTwitter, faInstagram } from '../../../../node_modules/@fortawesome/free-brands-svg-icons'
import { setUserAttributes } from '../../../actions/adminActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';



class Login extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];

    this.usernameInput.bind(this);
    this.passwordInput.bind(this);
    this.handleLogin.bind(this);

  }

  componentDidMount() {
  }

  /**
   * 
   * @param {Input field value of User Name} event 
   * @description updates the state on behalf of user input
   */
  usernameInput(event) {
    this.state.username = event.currentTarget.value;
  }

  /**
 * 
 * @param {Input field value of password} event 
 * @description updates the state on behalf of user input
 */
  passwordInput(event) {
    this.state.password = event.currentTarget.value;
  }

  /**
   * 
   * @param {clicks login button} event 
   * @description it logs the user in via aws amplify 
   */
  handleLogin(event) {
    event.preventDefault();
    //Logs in upon user input
    Auth.signIn({
      username: this.state.username.toLowerCase(),
      password: this.state.password,
    }).then(() => {

      //set user to signed in
      this._validAuthStates = ['signedIn'];

      //upon authentication, retrieve the logged in user's name and profile picture
      Auth.currentAuthenticatedUser()
        .then(user => {
          let attributes = user.attributes;
          let accessId = attributes['custom:accessId'];
          localStorage.setItem('userType', attributes['custom:userType']);
          localStorage.setItem('name', attributes['custom:firstName']+' '+attributes['custom:lastName']);

          if (['admin', 'client'].includes(attributes['custom:userType'])) {
            API.get('1', '/clients?ItemId=' + accessId).then(responseJson => {
              let loggedInUser = responseJson.Output[0];
              console.log('loggedInUser : ', loggedInUser);
            });
          }
          
          window.location.reload();
        })
    }).catch(() => {
      alert("Incorrect Login Credentials");
    });
  }
  showComponent() {
    return (
      <React.Fragment>
        <div className="navbar navbar-default navbar-fixed-top" style={customStyle.topHeader}>
          <div className="container-fluid">
            <div className="navbar-header mx-auto" >
              <img src={logo} alt="" />
            </div>
          </div>
        </div>
        <div className="container-fluid" style={{ padding: "100px" }}></div>
        <div className="container" style={{ width: "40%" }}>
          <div className="row align-items-center">
            <div className="col mx-auto" style={{ width: "100%" }}>

              <Form onSubmit={e => this.handleLogin(e)}>
                <Form.Group style={{marginBottom:15}} controlId="formBasicEmail">
                  <Form.Control type="text"
                    placeholder="Username or Email" style={customStyle.inputForm} value={this.state.username} onChange={e => this.usernameInput(e)} required />
                </Form.Group>
                <Form.Group style={{marginBottom:15}} controlId="formBasicPassword">
                  <Form.Control type="password"
                    placeholder="Password" style={customStyle.inputForm} value={this.state.username} onChange={e => this.passwordInput(e)} required />
                </Form.Group>
                <Button type="submit" style={customStyle.mainButton}>Login</Button>
              </Form>
            </div>
          </div>
        </div>
        <footer className="container-fluid" style={{ position: 'relative', bottom: '0' }}></footer>
        <div className="row align-items-start"></div>
        <div className="row align-items-center"></div>
        <div className="row align-items-end">
        </div>
        <div className="container-fluid text-center" style={customStyle.footer}>
          <br />
          <div className="row align-items-start" style={customStyle.speakText}>
            <div className="col-4"></div>
            <div className="col-4">
              Need to speak with someone?
              </div>
            <div className="col-4"></div>
          </div>
          <div className="row align-items-center" style={customStyle.getTouchText}>
            <div className="col-4"></div>
            <div className="col-4">
              Get in touch with us
              </div>
            <div className="col-4"></div>
          </div>

          <div className="row align-items-end" style={customStyle.socialMediaLinks} sm={3}>
            <div className="container">
              <div className="col text-center" style={customStyle.socialMediaSpacing}>
                <a href="https://www.facebook.com/teckpert">
                  <FontAwesomeIcon icon={faFacebook} color="#6FBE47" size="2x" />
                </a>
              </div>
              <div className="col" style={customStyle.socialMediaSpacing}>
                <a href="https://twitter.com/teckpert">
                  <FontAwesomeIcon icon={faTwitter} color="#6FBE47" size="2x" />
                </a>
              </div>
              <div className="col" style={customStyle.socialMediaSpacing}>
                <a href="https://www.linkedin.com/company/teckpert">
                  <FontAwesomeIcon icon={faLinkedin} color="#6FBE47" size="2x" />
                </a>
              </div>
              <div className="col" style={customStyle.socialMediaSpacing}>
                <a href="https://www.instagram.com/teckpert/">
                  <FontAwesomeIcon icon={faInstagram} color="#6FBE47" size="2x" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Login.propTypes = {
  setUserAttributes: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    myState: state,
  }
}
export default connect(mapStateToProps, { setUserAttributes })(Login)