import {
  SET_CONTRACT,
  UPDATE_CONTRACT,
  ENABLE_CONTRACT_LOADER,
  DISABLE_CONTRACT_LOADER,
  SET_CONTRACT_FORM_DATA,
  CLEAR_CONTRACT_FORM_DATA,
  SET_CONTACTS,
  SET_CONTRACT_DETAILS,
  SET_WORKERS,
  SET_RESPONSE,
  SET_CONTRACT_CLIENT, SET_CONTRACT_CONTACT,
} from "../actions/types";

const initialState = {
  isLoading: false,
  contracts: [],
  filterContracts: [],
  addContractData: {},
  contacts: [],
  contractDetails: {},
  contractClient: {},
  contractContact: {},
  workers: [],
  addContractResponse: '',
};

export default function (state = initialState, action) {
  switch (action.type) {

    case SET_CONTRACT_CLIENT:
      return {
        ...state,
        contractClient: action.data,
      }

    case SET_CONTRACT_CONTACT:
      return {
        ...state,
        contractContact: action.data,
      }

    case SET_CONTRACT_DETAILS:
      return {
        ...state,
        contractDetails: action.data,
      }

    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.data,
      }
    case SET_WORKERS:
      console.log(action);
      return {
        ...state,
        workers: (typeof action.data !== "undefined") ? action.data : action.members,
      }
    case SET_RESPONSE:
      return {
        ...state,
        addContractResponse: action.data
      }
    case SET_CONTRACT_FORM_DATA:
      return {
        ...state,
        addContractData: {
          ...state.addContractData,
          [action.key]: action.value,
        },
      };
    case CLEAR_CONTRACT_FORM_DATA:
      return {
        ...state,
        contractList: {},
        addContractData: {},
      };

    case ENABLE_CONTRACT_LOADER:
      return {
        ...state,
        isLoading: true
      };

    case DISABLE_CONTRACT_LOADER:
      return {
        ...state,
        isLoading: false
      };

    case SET_CONTRACT:
      return {
        ...state,
        contracts: action.data,
        filterContracts: action.data
      };
    case UPDATE_CONTRACT:
      return {
        ...state,
        contracts: action.data.TotalData,
        filterContracts: action.data.filterData
      };
    default:
      return state;
  }
}
