import React, { Component } from 'react';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { API } from 'aws-amplify';

import { Link } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

import { Modal, Button } from "react-bootstrap"
import Input from "../../../components/ui/form/Input";
import Moment from 'react-moment';

class InvoicesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], page: 1, totalSize: 1, sizePerPage: 25,
            InvoiceModalData: { Workers: [], PM: {}, CTO: {} }
        }
    }
    componentDidMount() {
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }
        API.get('1', `/invoices?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}`).then(responseJson => {
            this.setState(() => ({
                page: page, data: responseJson.Output, sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder, totalSize: responseJson.RecordsTotal
            }));
        });
    }

    closeModal() {
        this.setState({ modal: null, ItemId: null });
    }

    render() {
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <hr className="line" />
            </div>
            <div className="container">
                <BootstrapTable
                    remote
                    wrapperClasses="table-responsive"
                    style={{ width: 2000, overflowX: 'auto' }}
                    keyField="ItemId"
                    filter={filterFactory()}
                    pagination={paginationFactory({ page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
                    classes='time_sheet_table'
                    bordered={false}
                    data={this.state.data}
                    page={this.state.page}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                    columns={[
                        {
                            dataField: 'QuickbooksId',
                            text: 'Invoice #',
                            sort: true,
                            formatter: (cell, row) => { return (<div><a target="_blank" href={`https://app.qbo.intuit.com/app/invoice?txnId=${row.QuickbooksLink}`}><b>{cell}</b></a></div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Client',
                            text: 'Client',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Contract',
                            text: 'Contract',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'DateCreated',
                            text: 'Date',
                            sort: true,
                            formatter: (cell, row) => { return (<Moment utc format="MM/DD/YYYY">{cell}</Moment>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Amount',
                            text: 'Amount',
                            sort: true,
                            formatter: (cell, row) => {
                                return (<div>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cell)}</div>);
                            },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Status',
                            text: 'Status',
                            sort: true,
                            formatter: (cell, row) => { return (<div style={{ color: cell.startsWith('Paid') ? '#6FBE47' : '#000000', fontWeight: cell.startsWith('Paid') ? 600 : 400 }}>{cell}</div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'ItemId',
                            text: '',
                            sort: false,
                            formatter: (cell, row) => {
                                return (<div>
                                    <Link onClick={() => {
                                        console.log("quick view!");
                                        let WorkersTotal = 0;
                                        for (let i = 0; i < row.Workers.length; i++) {
                                            WorkersTotal += row.Workers[i].Quantity * row.Workers[i].Rate;
                                        }
                                        this.setState({
                                            InvoiceModal: true,
                                            InvoiceModalData: {
                                                Workers: row.Workers,
                                                WorkersTotal: WorkersTotal,
                                                ContractId: row.ContractId,
                                                PM: {
                                                    Description: row.PMDescription,
                                                    Percentage: row.PMPercentage
                                                },
                                                CTO: {
                                                    Description: row.CTODescription,
                                                    Percentage: row.CTOPercentage
                                                }
                                            }
                                        });
                                    }}>Quick View</Link>
                                    {' - '}
                                    <a target="_blank" href={`https://app.qbo.intuit.com/app/invoice?txnId=${row.QuickbooksLink}`}>View on QB</a>
                                    {' - '}
                                    <Link onClick={() => { this.setState({ modal: 'delete', ItemId: row.ItemId }) }}>Delete</Link>
                                </div>);
                            },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }
                    ]}
                    defaultSorted={[{
                        dataField: 'DateCreated',
                        order: 'desc'
                    }]}
                />
            </div>
            <Modal animation={false} size="md" show={this.state.modal === 'delete'} onHide={() => { this.closeModal() }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete this invoice?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { this.closeModal() }}>Cancel</Button>
                    <Button onClick={() => {
                        API.del('1', `/invoices?ItemId=${this.state.ItemId}`).then(responseJson => {
                            this.closeModal();
                            this.handleTableChange(null, this.state);
                        });
                    }} variant="primary">Delete</Button>
                </Modal.Footer>
            </Modal>

            <Modal animation={false} size="xl" show={this.state.InvoiceModal} onHide={() => this.setState({ InvoiceModal: false })} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Quick View Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table mt-4 time_sheet_table">
                        <thead>
                            <tr>
                                <th style={{ width: '10%' }}>#</th>
                                <th style={{ width: '50%' }}>Description</th>
                                <th style={{ width: '10%' }}>Quantity</th>
                                <th style={{ width: '10%' }}>Rate</th>
                                <th style={{ width: '10%' }}>Percentage</th>
                                <th style={{ width: '10%' }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.InvoiceModalData.Workers.map((obj, index) =>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                        {obj.Description}
                                    </td>
                                    <td>
                                        {obj.Quantity}
                                    </td>
                                    <td>
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(obj.Rate)}
                                    </td>
                                    <td>
                                    </td>
                                    <td>
                                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(obj.Quantity * obj.Rate)}
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td>{this.state.InvoiceModalData.Workers.length + 1}</td>
                                <td>
                                    {this.state.InvoiceModalData.PM.Description}
                                </td>
                                <td>
                                    1
                                </td>
                                <td>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(((this.state.InvoiceModalData.PM.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)))}
                                </td>
                                <td>
                                    {this.state.InvoiceModalData.PM.Percentage}%
                                </td>
                                <td>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(((this.state.InvoiceModalData.PM.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)))}
                                </td>
                            </tr>
                            <tr>
                                <td>{this.state.InvoiceModalData.Workers.length + 2}</td>
                                <td>
                                    {this.state.InvoiceModalData.CTO.Description}
                                </td>
                                <td>
                                    1
                                </td>
                                <td>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(((this.state.InvoiceModalData.CTO.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)))}
                                </td>
                                <td>
                                    {this.state.InvoiceModalData.CTO.Percentage}%
                                </td>
                                <td>
                                    {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(((this.state.InvoiceModalData.CTO.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)))}
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total</td>
                                <td>{new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(((this.state.InvoiceModalData.WorkersTotal) + ((this.state.InvoiceModalData.PM.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)) + ((this.state.InvoiceModalData.CTO.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal))))} </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row" style={{ justifyContent: 'center', marginTop: 20 }}>
                        <a className="greenBtn" style={{ marginRight: 15, height: 55, backgroundColor: '#AAAAAA', paddingLeft: 25, paddingRight: 25 }} onClick={() => this.setState({ InvoiceModal: false, InvoiceModalData: { Workers: [], PM: {}, CTO: {} } })}>Close</a>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment >
        )
    }
}

export default InvoicesList;