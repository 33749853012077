import { 
    SET_CLIENT, UPDATE_CLIENT,
    ENABLE_CLIENT_LOADER, DISABLE_CLIENT_LOADER,
    ADD_CLIENT,CLEAR_RESPONSE,SET_RESPONSE,CLIENT_DETAIL,
    SET_CONTACT_FORM_DATA,SET_CLIENT_CONTACTS,
    SET_CONTACT_DETAIL,CLEAR_CONTACT_DETAIL
  } from '../actions/types';
  
  const initialState = {
    isLoading: false,
    clients: [],
    filterClients: [],
    addClientResponse : '',
    clientDetails:{},
    addContactData: {},
    clientContacts : [],
    contactDetail : {}
  };
  export default function(state = initialState, action) {
    switch (action.type) {
        case ENABLE_CLIENT_LOADER:
        return {
          ...state,
          isLoading: true
        }
      case SET_CONTACT_FORM_DATA:
      return {
        ...state,
        addContactData: {
          ...state.addContactData,
          [action.key]: action.value,
        },
      }
      case CLEAR_CONTACT_DETAIL:
      return {
        ...state,
        contactDetail : {},
        addClientResponse : ""
      }
      case SET_CONTACT_DETAIL:
      return {
        ...state,
        contactDetail : action.data
      }

      case SET_CLIENT_CONTACTS:
      return {
        ...state,
        clientContacts : action.data
      }
      
      case DISABLE_CLIENT_LOADER:
        return {
          ...state,
          isLoading: false
        }
      case SET_RESPONSE:
      return {
        ...state,
        addClientResponse: action.data
      }
      case CLEAR_RESPONSE:
      return {
        ...state,
        addClientResponse: ''
      }
      case CLIENT_DETAIL:
      return {
        ...state,
        clientDetails : action.data
      }
      case SET_CLIENT:
      
        return {
          ...state,
          clients: action.data,
          filterClients : action.data,
          addClientResponse: ''
        }
      case UPDATE_CLIENT: 
        return {
          ...state,
          clients: action.data.TotalData,
          filterClients : action.data.filterData,
          addClientResponse: ''
        }
      default:
        return state;
    }
  }