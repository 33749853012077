import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class SearchBar extends Component {
  static propTypes = {};

  onChange = (value) => {

  }

  render() {
    return (
      <Fragment>
        <div className="Search">
          <input
            ref="searchBox"
            type=""
            className="Search__SearchBar"
            placeholder={this.props.placeholder}
            onChange={(event) => this.onChange(event)}
          ></input>
          {this.props.Search === '' ?
            <div className="Search__iconSearch">
              <FontAwesomeIcon className="SearchIcon" icon={faSearch} />

            </div> : 
            <div className="Search__iconClose" onClick={() => this.onClearSearch()} >
              <FontAwesomeIcon className="closeIcon" icon={faTimes} />
            </div>
          }
          {/* <DropDowns {...this.props} /> */}
        </div>
      </Fragment>
    );
  }
}

SearchBar.propTypes = {
  placeholder: PropTypes.string,
  Search: PropTypes.string,
}

SearchBar.defaultProps = {
  placeholder: '',
  Search: '',
}

export default SearchBar;
