import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  FETCH_OPPORTUNITIES,
  UPDATE_OPPORTUNITY,
  FILTER_OPPORTUNITIES,
  SORT_OPPORTUNITIES,
  SUBMIT_OPPORTUNITY,
  GET_OPPORTUNITY
} from "../actions/types";
import * as opportunityActions from '../actions';

import * as opportunityApis from '../api';
import * as dataParser from '../../../helperFunctions/DataParser';

export default function* clientSagas() {
  yield takeEvery(FETCH_OPPORTUNITIES, fetchOpportunities);
  yield takeEvery(FILTER_OPPORTUNITIES, filterOpportunities);
  yield takeEvery(SORT_OPPORTUNITIES, sortOpportunities);
  yield takeEvery(SUBMIT_OPPORTUNITY, submitOpportunity);
  yield takeEvery(GET_OPPORTUNITY, getOpportunityDetails);

}

function* getOpportunityDetails(action) {
  console.log(action);
  yield put(opportunityActions.enableLoader());
  let response = yield call(opportunityApis.getOpportunity, action.data);
  yield put(opportunityActions.setOpportunityDetails(response.Output));
  yield put(opportunityActions.disableLoader());
}

function* sortOpportunities(action) {
  console.log(action);
  let parsedData = yield call(dataParser.sortData, action.data.sortby, action.data.sortDir, action.data.data);
  var filteredData = { "filterData": parsedData, "TotalData": action.data.data }
  yield put(opportunityActions.updateOpportunities(filteredData));
}

function* submitOpportunity(action) {
  yield put(opportunityActions.enableLoader());
  if (typeof action.data.Specializations !== "undefined") {
    action.data.Specializations = JSON.stringify(action.data.Specializations);
  }
  if (typeof action.data.Responsibilities !== "undefined") {
    action.data.Responsibilities = JSON.stringify(action.data.Responsibilities);
  }
  if (typeof action.data.Requirements !== "undefined") {
    action.data.Requirements = JSON.stringify(action.data.Requirements);
  }

  const dataParsed = action.data;
  let response = ""
  if (typeof action.data.ItemId !== "undefined") {
    response = yield call(opportunityApis.putOpportunity, dataParsed);
  } else {
    response = yield call(opportunityApis.postOpportunity, dataParsed);
  }

  if (typeof response.Message !== "undefined" && response.Message === "Success") {
    // console.log('response : ', response);
    if (typeof action.data.ItemId !== "undefined") {
      let responseDetail = yield call(opportunityApis.getOpportunity, action.data.ItemId);
      yield put(opportunityActions.setOpportunityDetails(responseDetail.Output));
    }
  }
  yield put(opportunityActions.clearOpportunityFormData());
  yield put(opportunityActions.setResponse(response.Message));
  yield put(opportunityActions.disableLoader());
}

function* fetchOpportunities() {
  yield put(opportunityActions.enableLoader());
  let data = yield call(opportunityApis.fetchOpportunities);
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    yield put(opportunityActions.setOpportunities(data.Output));
  }
  yield put(opportunityActions.disableLoader());
}
function* filterOpportunities(action) {
  //let data = yield call(timeSheetApis.fetchTimeSheets);
  yield put(opportunityActions.enableLoader());
  let parsedData = [];
  let filteredData = [];
  if (action.data.value.length === 0) {
    parsedData = action.data.data;
  } else {
    parsedData = yield call(dataParser.filterOpportunities, action.data.key, action.data.value, action.data.data);
  }
  filteredData = { "filterData": parsedData, "TotalData": action.data.data };
  yield put(opportunityActions.disableLoader());
  yield put(opportunityActions.updateOpportunities(filteredData));
}