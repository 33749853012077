import { call, put, takeEvery, takeLeading } from "redux-saga/effects";
import * as types from "../actions/types";
import * as messageActions from "../actions";
import * as messageApis from '../api';

export default function* messageSagas() {
  yield takeLeading(types.FETCH_MESSAGE_THREADS, fetchMessageThreads);
  yield takeLeading(types.FETCH_AVAILABLE_CONTACTS, fetchAvailableContacts);
  yield takeEvery(types.SEND_MESSAGE, sendMessage);
  yield takeEvery(types.SEND_MESSAGE_CONVERSATION, sendMessageConversation);

  yield takeEvery(types.SEND_BULK_MESSAGE, sendBulkMessage);
  yield takeLeading(types.FETCH_CONVERSATION, fetchConversation);
}

function* fetchConversation (action) {
  yield put(messageActions.enableLoader());
  const data = yield call (messageApis.fetchConversation, action.ItemId);
  // console.log('data : ', data );
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    const conversation = {
      ItemId: action.ItemId,
      conversation: data.Output.reverse(),
    }
    yield put (messageActions.setConversation(conversation));
  } else {
    yield put (messageActions.setConversation({
      ItemId: '',
      conversation: [],
    }));
  }
  yield put(messageActions.disbleLoader());
}

function* sendMessageConversation (action) {
  yield put(messageActions.enableLoader());
  const dataM = yield call(messageApis.sendMessage, action.data);
  yield put(messageActions.disbleLoader());
  
  const data = yield call (messageApis.fetchConversation, action.data.ReceiverId);
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    const conversation = {
      ItemId: action.data.ReceiverId,
      conversation: data.Output.reverse(),
    }
    yield put (messageActions.setConversation(conversation));
  }
  const data1 = yield call (messageApis.fetchMessageThreads);
  if (typeof data1.Message !== 'undefined' && data1.Message === "Success") {
    yield put (messageActions.setMessageThreads(data1.Output));
  }
}

function* sendMessage (action) {
  const data = yield call(messageApis.sendMessage, action.data);
  console.log('data : ', data);
  // NotificationManager.info();
}

function* sendBulkMessage (action) {
  yield put(messageActions.enableLoader());
  yield call(messageApis.sendBulkMessage, action.message, action.contacts);
  const data = yield call (messageApis.fetchMessageThreads);
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    yield put (messageActions.setMessageThreads(data.Output));
  } else {
    yield put (messageActions.setMessageThreads([]));
  }
  yield put(messageActions.disbleLoader());
}

function* fetchMessageThreads () {
  yield put(messageActions.enableLoader());
  const data = yield call (messageApis.fetchMessageThreads);
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    yield put (messageActions.setMessageThreads(data.Output));
  } else {
    yield put (messageActions.setMessageThreads([]));
  }
  yield put(messageActions.disbleLoader());
}

function* fetchAvailableContacts () {
  yield put(messageActions.enableLoader());
  const data = yield call (messageApis.fetchAvailableContacts);
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    yield put (messageActions.setAvailableContacts(data.Output));
  } else {
    yield put (messageActions.setAvailableContacts([]));
  }
  yield put(messageActions.disbleLoader());
}