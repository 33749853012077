import { 
  ENABLE_TIMESHEET_LOADER, DISABLE_TIMESHEET_LOADER,
  FETCH_TIMESHEETS, SET_TIMESHEET, UPDATE_TIMESHEET,
  FILTER_TIMESHEET,SORT_TIMESHEET,SET_SELECTED_TIMESHEET,
  GET_DETAILED_TIMESHEET,SET_DETAILED_TIMESHEET,SELECTED_TIMESHEET,
  DELETE_DETAILED_TIMESHEET,DELETE_SELECTED_TIMESHEET,
  SET_TIMESHEET_FORM_DATA,SEND_TO_CLIENT,
  SET_SEND_TO_CLIENT_RESPONSE,SET_ALL_CONTACTS,FETCH_ALL_CONTACTS,
  SET_PAYROLL_FORM_DATA,SET_SEND_TO_PAYROLL_RESPONSE,SEND_TO_PAYROLL,
  FETCH_PAYROLLS,SET_PAYROLLS,APPROVE_RESPONSE,APPROVE_TIMESHEETS,
  TIMESHEET_FOR_APPROVAL,SET_TIMESHEET_FOR_APPROVAL,EDIT_PAYROLL_DATE, SELECTED_PAYROLL
} from './types';

export const enableLoader = () => ({
  type: ENABLE_TIMESHEET_LOADER,
});

export const deleteSelectedTimeSheet = () => ({
  type: DELETE_SELECTED_TIMESHEET,
})

export const disableLoader = () => ({
  type: DISABLE_TIMESHEET_LOADER,
});

export const fetchTimesheets = () => ({
  type: FETCH_TIMESHEETS
});

export const setTimesheet = (data) => ({
  type: SET_TIMESHEET,
  data,
});

export const filterTimesheets = (data) => ({
  type: FILTER_TIMESHEET,
  data,
});

export const setSelectedTimeSheet = (data) => ({
  type: SELECTED_TIMESHEET,
  data,
});

export const setSelectedPayroll = (data) => ({
  type: SELECTED_PAYROLL,
  data,
});

export const getDetailedTimeSheet = (data) => ({
  type: GET_DETAILED_TIMESHEET,
  data,
});
export const deleteDetailedTimeSheet = (data) => ({
  type: DELETE_DETAILED_TIMESHEET,
  data,
});
export const setDetailedTimeSheet = (data) => ({
  type: SET_DETAILED_TIMESHEET,
  data,
});


export const sortTimesheets = (data) => ({
  type: SORT_TIMESHEET,
  data,
});

export const updateTimesheet = (data) => ({
  type: UPDATE_TIMESHEET,
  data,
});
export const setTimesheetFormData = (key, value) => ({
  type: SET_TIMESHEET_FORM_DATA,
  key,
  value
});
export const setPayrollFormData = (key, value) => ({
  type: SET_PAYROLL_FORM_DATA,
  key,
  value
});
export const sendToClient = (data) => ({
  type: SEND_TO_CLIENT,
  data,
});
export const sendToPayroll = (data) => ({
  type: SEND_TO_PAYROLL,
  data,
});
export const editPayrollDate = (data) => ({
  type: EDIT_PAYROLL_DATE,
  data,
});
export const setResponse = (data) => ({
  type: SET_SEND_TO_CLIENT_RESPONSE,
  data,
});
export const setPayrollResponse = (data) => ({
  type: SET_SEND_TO_PAYROLL_RESPONSE,
  data,
});

export const fetchAllContacts = (data) => ({
  type: FETCH_ALL_CONTACTS,
  data,
});
export const setAllContacts = (data) => ({
  type: SET_ALL_CONTACTS,
  data,
});
export const fetchPayrolls = (data) => ({
  type: FETCH_PAYROLLS,
  data,
});
export const setPayrolls = (data) => ({
  type: SET_PAYROLLS,
  data,
});
export const approveTimesheet = (data) => ({
  type: APPROVE_TIMESHEETS,
  data,
});
export const setApproveResponse = (data) => ({
  type: APPROVE_RESPONSE,
  data,
});
export const fetchTimesheetsApproval = (data) => ({
  type: TIMESHEET_FOR_APPROVAL,
  data,
});
export const setTimeSheetforApprove = (data) => ({
  type: SET_TIMESHEET_FOR_APPROVAL,
  data,
});
