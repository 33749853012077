import { all } from 'redux-saga/effects';
// import authSagas from './auth';
import authSagas from '../modules/Login/sagas';
import memberSagas from '../modules/Members/sagas';

import timesheetSagas from '../modules/Timesheet/sagas';
import contractSagas from '../modules/Contracts/sagas';
import clientSagas from '../modules/Clients/sagas';
import OpportunitySagas from '../modules/Opportunities/sagas';
import MessagingSagas from '../modules/Messaging/sagas';
    
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    memberSagas(),
    timesheetSagas(),
    contractSagas(),
    clientSagas(),
    OpportunitySagas(),
    MessagingSagas(),
  ]);
}