import React, { Component } from "react";
import InputTextArea from "../../../../components/ui/form/InputTextArea";
import Input from "../../../../components/ui/form/Input";

export class MessageForm extends Component {

  constructor () {
    super();
    this.state = {
      Body: '',
    }
  }

  onChange = (key, value) => {
    this.props.onMessageChange(key, value);
  }

  render() {
    return (
      <div>
        {/* <Input
          label="Subject"
          placeholder="Enter Subject"
          onChange={value => this.onChange("Subject", value)}
          value={this.state.title}
          invalidFeedback=''
        /> */}

        <InputTextArea
          label="Message"
          placeholder="Enter Message"
          onChange={value => this.onChange("Message", value)}
          value={this.state.message}
          invalidFeedback=''
          rows="6"
        />
      </div>
    );
  }
}

export default MessageForm;