import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import EditWork from '../Modals/EditWork';
import EditEducation from '../Modals/EditEducation';
import EditSpecializations from '../Modals/EditSpecializations';
import EditCertifications from '../Modals/EditCertifications';
import AddDocuments from '../Modals/AddDocuments';
import AddContracts from '../Modals/AddContracts';
import SendToClient from '../Modals/SendToClient';
import SendToPayroll from '../Modals/SendToPayroll';
import DetailedTimeSheet from '../Modals/DetailedTimeSheet';
import ContactAdd from '../Modals/ContactAdd';
import ClientEdit from '../Modals/ClientEdit';
import AddContract from '../Modals/AddContract';
import OpportunityEdit from '../Modals/OpportunityEdit';
import AddPeopleContract from '../Modals/AddPeopleContract';
import OpportunitySetRates from '../Modals/OpportunitySetRates';
import EditPayrollDate from '../Modals/EditPayrollDate';



import { openModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class CustomModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "Work"                      : <EditWork/>,
            "Education"                 : <EditEducation/>,
            "Specializations"           : <EditSpecializations/>, 
            "Certifications"            : <EditCertifications/>,
            "Documents"                 : <AddDocuments/>,
            "Contracts"                 : <AddContracts/>,
            "SendToClient"              : <SendToClient />,
            "SendToPayroll"              : <SendToPayroll />,
            "DetailedTimeSheet"         : <DetailedTimeSheet />,
            "ContactAdd"                : <ContactAdd />,
            "ClientEdit"                : <ClientEdit />,
            "AddContract"               : <AddContract />,
            "OpportunityEdit"           : <OpportunityEdit />,
            "AddPeopleContract"         : <AddPeopleContract />,
            "OpportunitySetRates"       : <OpportunitySetRates />,
            "EditPayrollDate"           : <EditPayrollDate />
        }
    }
    render() {
        console.log(this.props.modalState);
        console.log(this.props.modalName);
        return (<React.Fragment >{this.props.modalState ? this.state[this.props.modalName] : <div></div>}</React.Fragment>)      
    }
}
// checks for function type
CustomModal.propTypes = {
    openModal: PropTypes.func.isRequired,
    setModalName: PropTypes.func.isRequired,
};
//maps from redux to component
function mapStateToProps(state) {
    return {
        modalState: state.uiState.modalOpen,
        modalName: state.uiState.modalName,
    }
}
export default connect(mapStateToProps, { openModal, setModalName })(CustomModal);