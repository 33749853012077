import React, { Component } from "react";
import PropTypes from "prop-types";

export class AccordionCard extends Component {
  static propTypes = {
    title: PropTypes.string,
  };

  static defaltProps = {
    title: '',
  }


  render() {

    const identifier = this.props.title.replace(/[^a-zA-Z0-9]/g, '');

    return (
      <div className="card">
        <div className="card-header" id="headingOne">
          <h5 className="mb-0">
            <button
              className="btn btn-link"
              data-toggle="collapse"
              data-target={`#collapse-${identifier}`}
              aria-expanded="true"
              aria-controls={`collapse-${identifier}`}
            >
              {this.props.title}
            </button>
            {this.props.actionhtml}
          </h5>
        </div>

        <div
          id={`collapse-${identifier}`}
          className="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div className="card-body">
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

AccordionCard.propTypes = {
  title: PropTypes.string,
  actionhtml: PropTypes.any,
};

AccordionCard.defaultProps = {
  title: '',
  actionhtml: '',
}

export default AccordionCard;
