import React, { Component } from "react";
import PropTypes from 'prop-types';

import moment from 'moment'
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import enGb from 'date-fns/locale/en-GB';
registerLocale('en-gb', enGb);
setDefaultLocale('en-gb');

export class InputDateOpportunity extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: (typeof props.value !== "undefined" && props.value !== '0000-00-00') ? new Date(props.value).setDate(new Date(props.value).getDate() + 1) : '', //quick fix for UTC issue
    };
    console.log(props.value);
  }

  onChange(date) {
    const dateFormat = moment(date).format('YYYY-MM-DD');
    console.log(dateFormat);
    this.setState({ value: date }, () => {
      this.props.onChange(dateFormat);
    });
  }

  render() {
    let inputClass = 'form-control';
    if (this.props.validFeedback !== '') inputClass += ' is-valid';
    if (this.props.invalidFeedback !== '') inputClass += ' is-invalid';

    return (
      <div className="form-group">
        {this.props.label !== "" && (
          <label className="form-label">{this.props.label}</label>
        )}
        {!['','0000-00-00'].includes(this.state.value) ? (
          <div>
            <DatePicker
              selected={this.state.value}
              onChange={date => this.onChange(date)}
              className={inputClass}
              showPopperArrow={false}
            />
            <button style={{marginTop:5,width:'100%'}} onClick={() => {
              this.onChange('');
            }}>Clear Start Date</button>
          </div>
        ) : (
            <div>
              <input
                type="text"
                className='form-control'
                value='Open'
                readonly
              />
              <button style={{marginTop:5,width:'100%'}} onClick={() => {
                this.onChange(new Date());
              }}>Set Start Date</button>
            </div>
          )}
        {this.props.invalidFeedback !== '' && <div class="invalid-feedback">{this.props.invalidFeedback}</div>}
        {this.props.validFeedback !== '' && <div class="valid-feedback">{this.props.validFeedback}</div>}
      </div>
    );
  }
}

InputDateOpportunity.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  validFeedback: PropTypes.string,
  invalidFeedback: PropTypes.string,
  value: PropTypes.string,
};

InputDateOpportunity.defaultProps = {
  label: '',
  placeholder: '',
  validFeedback: '',
  invalidFeedback: '',
  value: '',
}

export default InputDateOpportunity;
