import {
  DELETE_NOTE,
  ADD_NOTE,
  GET_NOTE,
} from "../actions/actionTypes";

const initialState = {
  noteInfo: {},
  noteState: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTE:
      return {
        ...state,
        noteInfo: action.payload
      };
    case GET_NOTE:
      return {
        ...state,
        noteState: action.payload,
      };
    
    case DELETE_NOTE:
      if(action.id)
      return initialState;
      break;
    default:
      return state;
  }
}


