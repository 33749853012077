import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal,openModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Input from "../../components/ui/form/Input";
import InputDropdown from "../../components/ui/form/InputDropdown";
import InputDate from "../../components/ui/form/InputDate";
import InputHours from "../../components/ui/form/InputHours";
import InputTextArea from "../../components/ui/form/InputTextArea";

import { availableStatus } from "../../modules/Opportunities/config/Constants";
import { availableType } from "../../modules/Opportunities/config/Constants";
import { availableLocation } from "../../modules/Opportunities/config/Constants";
import { availableCompensation } from "../../modules/Opportunities/config/Constants";
import ReactTags from 'react-tag-autocomplete';
import * as opportunityActions from '../../modules/Opportunities/actions/index';
import {
    faSpinner,
    faPhoneAlt,
    faEnvelope,
    faClock,
    faMapMarkerAlt,
    faMoneyBillAlt,
    faPlaneDeparture,
    faHourglassStart

} from "@fortawesome/free-solid-svg-icons";

class OpportunitySetRates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {
            Compensation: '',
            },
            isValid: false,
           
        }
        
        this.onSubmit = this.onSubmit.bind(this);
        this.validateData = this.validateData.bind(this);
        
    } 
    onChange(key, value) {
        this.props.setOpportunityFormData(key, value);
    }

    componentDidMount() {
        this.props.setOpportunityFormData("ItemId", this.props.opportunityList.opportunityDetails[0].ItemId);
        this.props.setOpportunityFormData("Compensation", this.props.opportunityList.opportunityDetails[0].Compensation); 
        setTimeout(() => {
          var isModalFound = document.getElementsByClassName('modal show');
          if(isModalFound.length > 1){
            document.getElementsByClassName('modal show')[0].style.display="none";
          }
        }, 1000);
    }
    setError = (key, message) => {
      let formErrors = this.state.formErrors;
      formErrors[key] = message; 
      this.setState({
        formErrors: formErrors,
        isValid: false,
      });
    }
    clearFormErrors =  async () => {
      const emptyErrors = {
        Compensation: '',
      };
      await this.setState({
        formErrors: emptyErrors,
      });
    }
     
    validateData = () => {
      const data = this.props.addOpportunityData;
      return this.clearFormErrors().then(() => {
        let hasErrors = false;        
        if (typeof data.Compensation === 'undefined' || data.Compensation === '') {
          this.setError('Compensation', 'Please provide Rates');
          hasErrors = true;
        }
        return hasErrors;
      });
    }
    onSubmit(){
      
      this.validateData().then(hasErrors => {
          if (!hasErrors) {
              this.props.addOpportunity(this.props.addOpportunityData);
          }
        });
    }
    handleModalClose() {
      this.props.setModalName(null);
      this.props.closeModal();
    }
    closeModal(){
        console.log("Modal Closed")
        this.props.setModalName(null);
        this.props.closeModal();
    }
    closeModalCallback(){
      this.props.RemoveOpportunityResponse();
        this.props.setModalName(null);
        this.props.closeModal();
    }
  //
  render() {
    const {formErrors} = this.props;
    return (
      <div>
        <Modal className="opportunity_modal" size="lg" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Set Rates</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="container add_opportunity_container">
              <div className="add_opportunity">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-12">
                            <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faMoneyBillAlt}/> Set Rates</label>
                            <Input
                            onChange={value => this.onChange("Compensation", value)}
                            invalidFeedback={this.state.formErrors.Compensation}
                            value={this.props.addOpportunityData.Compensation}
                            />
                            </div>
                        </div>
                    </div> 
                  </div>
                </div>
            </div>
            {this.props.opportunityList.isLoading && 
                  <div className="loader"><FontAwesomeIcon icon={faSpinner} spin/></div>
              }
             {this.props.opportunityList.addOpportunityResponse === "Success" ?  
                this.closeModalCallback()
              : this.props.opportunityList.addOpportunityResponse !== "" ? <div className="status error">Error in creating opportunity!</div> : ''  
              }
          </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
          </Button>
            <Button onClick={this.onSubmit} variant="primary">
              Save Changes
          </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )

  }
}
OpportunitySetRates.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
  getContracts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    opportunities: state.opportunityList.opportunities,
    opportunityList: state.opportunityList,
    addOpportunityData: state.opportunityList.addOpportunityData
  }
}
const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: (data) => dispatch(closeModal()),
        openModal: () => dispatch(openModal()),
        setModalName: (data) => dispatch(setModalName(data)),
        setOpportunityFormData: (key, value) => dispatch(opportunityActions.setOpportunityFormData(key, value)),
        addOpportunity: (data) => dispatch(opportunityActions.addOpportunity(data)),
        RemoveOpportunityResponse : () => dispatch(opportunityActions.RemoveOpportunityResponse())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(OpportunitySetRates);