import React, { Component } from "react";
import { connect } from "react-redux";
import "../styles/ContractStyles.scss";
import Nav from "../../../components/Navigation/Nav";
import TopNav from "../../../components/TopNav/TopNav";
// import { Table, Modal, Button, ButtonToolbar } from "react-bootstrap";
import * as contractActions from "../actions";
import * as clientActions from "../../Clients/actions";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { ContractConstants } from "../config/Constants";
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import AddContractForm from "../components/addContractForm";

export class ContractAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        Name: '',
        StartDate: '',
        EndDate: '',
        Hours: '',
        Status: '',
        ClientId: '',
        MemberId: '',
      },
      isValid: false,
      clients: [],
    }
  }

  componentDidMount() {
    this.props.fetchClients();
    this.props.fetchWorkers();
  }

  parseClientData = () => {
    let data = [];
    if(this.props.clients.length > 0){
      this.props.clients.map((item) => {
        data.push({
          title: item.Name,
          value: item.ItemId,
        });
      });
    }
    return data;
  }
  parseWorkersData = () => {
    let data = [];
    console.log(this.props.workers);
    if(this.props.workers.length > 0){
      this.props.workers.map((item) => {
        data.push({
          title: item.Name,
          value: item.ItemId,
        });
      });
    }
    return data;
  }

  parseContactsData = () => {
    let data = [];
    if (this.props.contacts.length > 0) {
      this.props.contacts.map(item => {
        data.push({
          title: `${item.FirstName} ${item.LastName}`,
          value: item.ItemId,
        });
      })
    }
    return data;
  }
  
  fetchContacts = (ItemId) => {
    console.log(ItemId);
    this.props.fetchContacts(ItemId);
  }

  onSubmit = () => {
    this.validateData().then(hasErrors => {
      if (!hasErrors) {
        this.props.addContract(this.props.addContractData);
      }
    });
  }

  setError = (key, message) => {
    let formErrors = this.state.formErrors;
    formErrors[key] = message; 
    this.setState({
      formErrors: formErrors,
      isValid: false,
    });
  }

  clearFormErrors =  async () => {
    const emptyErrors = {
      Name: '',
      StartDate: '',
      EndDate: '',
      Hours: '',
      Status: '',
      ClientId: '',
      MemberId: '',
    };
    await this.setState({
      formErrors: emptyErrors,
    });
  }

  validateData = () => {
    const data = this.props.addContractData;
    return this.clearFormErrors().then(() => {
      let hasErrors = false;

      if (typeof data.Name === 'undefined' || data.Name === '') {
        this.setError('Name', 'Please provide Contract Name');
        hasErrors = true;
      }
      if (typeof data.StartDate === 'undefined' || data.StartDate === '') {
        this.setError('StartDate', 'Please provide Start Date');
        hasErrors = true;
      }
      
      if (typeof data.Status === 'undefined' || data.Status === '') {
        this.setError('Status', 'Please select Status');
        hasErrors = true;
      }
     
      if (typeof data.Details === 'undefined' || data.Details === '') {
        this.setError('Details', 'Please insert Details');
        hasErrors = true;
      }
      
      if (typeof data.ClientId === 'undefined' || data.ClientId === '' || data.ClientId <= 0 ) {
        this.setError('ClientId', 'Please select Client');
        hasErrors = true;
      }
      return hasErrors;
    });
  }

  onCancel = () => {
    console.log("onCancel Called");
    // this.context.router.goBack();
  }

  onInput = (key, value) => {
    this.props.setContractFormData(key, value);
  }

  render() {
    console.log(this.props.contractList);
    return (
      <React.Fragment>
        <TopNav title="Add Contract" />
        <Nav />
        <div class="container add_contract_container">
          <AddContractForm
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            onInput={this.onInput}
            formErrors={this.state.formErrors}
            clients={this.parseClientData()}
            contacts={this.parseContactsData()}
            workers={this.parseWorkersData()}
            fetchContacts={this.fetchContacts}
          />
          {this.props.contractList.isLoading && 
              <div className="loader"><FontAwesomeIcon icon={faSpinner} spin/></div>
          }
          {this.props.contractList.addContractResponse === "Success" ?  
            <Redirect to="/contracts" />
          : this.props.contractList.addContractResponse !== "" ? <div className="status error">Error in creating contract!</div> : ''  
          }
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  
  addContractData: state.contract.addContractData,
  clients: state.clientList.clients,
  contacts: state.contract.contacts,
  workers: state.contract.workers,
  contractList : state.contractList
});

const mapDispatchToProps = dispatch => {
  return {
    setContractFormData: (key, value) => dispatch(contractActions.setContractFormData(key, value)),
    fetchClients: () => dispatch(clientActions.fetchClients()),
    fetchWorkers: () => dispatch(contractActions.fetchWorkers()),
    fetchContacts: (ItemId) => dispatch(contractActions.fetchContacts(ItemId)),
    addContract: (data) => dispatch(contractActions.addContract(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractAdd);
