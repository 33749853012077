import {
  ENABLE_OPPORTUNITY_LOADER, DISABLE_OPPORTUNITY_LOADER,
  SET_OPPORTUNITY, UPDATE_OPPORTUNITIES, SET_OPPORTUNITY_FORM_DATA, CLEAR_OPPORTUNITY_FORM_DATA,
  SET_RESPONSE, OPPORTUNITY_DETAIL, REMOVE_RESPONSE
} from '../actions/types';

const initialState = {
  isLoading: false,
  opportunities: [],
  filterOpportunities: [],
  addOpportunityResponse: '',
  opportunityDetails: {},
  addOpportunityData: {},
  opportunityDetails: {},
};
export default function (state = initialState, action) {
  switch (action.type) {
    case ENABLE_OPPORTUNITY_LOADER:
      return {
        ...state,
        isLoading: true
      }
    case REMOVE_RESPONSE:
      return {
        ...state,
        addOpportunityResponse: ''
      }
    case DISABLE_OPPORTUNITY_LOADER:
      return {
        ...state,
        isLoading: false
      }
    case SET_OPPORTUNITY:

      return {
        ...state,
        opportunities: action.data,
        filterOpportunities: action.data,
        addOpportunityResponse: ''
      }
    case SET_OPPORTUNITY_FORM_DATA:
      return {
        ...state,
        addOpportunityData: {
          ...state.addOpportunityData,
          [action.key]: action.value,
        },
      };
    case CLEAR_OPPORTUNITY_FORM_DATA:
      return {
        ...state,
        addOpportunityData: {},
      };
    case OPPORTUNITY_DETAIL:
      return {
        ...state,
        opportunityDetails: action.data,
        addOpportunityResponse: ''
      }
    case SET_RESPONSE:
      return {
        ...state,
        addOpportunityResponse: action.data
      }
    case UPDATE_OPPORTUNITIES:
      return {
        ...state,
        opportunities: action.data.TotalData,
        filterOpportunities: action.data.filterData,
        addOpportunityResponse: ''
      }
    default:
      return state;
  }
}