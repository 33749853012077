import React, { Component } from "react";
import PropTypes from "prop-types";
import { getStorageFile } from '../../../../helperFunctions/storageUtils';
export class ContactImg extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            "profilePic"       : "",
        }
    }
  static propTypes = {
    contact: PropTypes.object
  };
  componentDidMount() {
      if(this.props.contact.ProfilePicture !== null && this.props.contact.ProfilePicture !== ""){
        getStorageFile(this.props.contact.ProfilePicture).then((url) => {
            if(url && url !== ''){this.setState({profilePic:url});
            }
        });
      }
  }
  render() {
    const { contact } = this.props;
    
     return(
         <div className="contactImgContainer">
            {(this.state.profilePic === "") ? "" :
                <img className="contactImg" src={this.state.profilePic} />
            }
        </div>
    );
}
}

export default ContactImg;