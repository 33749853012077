import React, { Component } from "react";
import PropTypes from "prop-types";
import NotesView from "./Tabs/NotesView";

export class TabsNotes extends Component {
  static propTypes = {};
  state = {
    newNotes: 0
  };
  componentDidMount() {
  }
  componentWillReceiveProps() {
    if (this.props.member.Notes) {
      const checkDate = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000);
      let newNotes = this.props.member.Notes.filter(obj => new Date(obj.DateCreated) >= checkDate);
      this.setState({ newNotes: newNotes.length });
    }
  }
  render() {
    const { member } = this.props;

    return (
      <section>
        <div className="profileTabs main-header">
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <NotesView member={member} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default TabsNotes;