import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import '../styles/TimeSheetStyles.scss';
import { API } from 'aws-amplify';
import TopNav from '../../../components/TopNavClient/TopNav';
import { Table, Modal, Button, ButtonToolbar, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import queryString from 'query-string'
import * as timesheetActions from '../actions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFile,
    faAngleUp,
    faAngleDown,
    faSpinner

} from "@fortawesome/free-solid-svg-icons";
import Moment from 'react-moment';
import { getStorageFile } from '../../../helperFunctions/storageUtils';
import Input from "../../../components/ui/form/Input";
import InputTextArea from "../../../components/ui/form/InputTextArea";
import { PieChart } from 'react-minimal-pie-chart';

class ApproveTimeSheets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            response: "",
            clientSort: faAngleUp,
            workerSort: faAngleUp,
            periodSort: faAngleUp,
            hoursSort: faAngleUp,
            statusSort: faAngleUp,
            payrollSort: faAngleUp,
            contractSort: faAngleUp,
            modal: {
                PeriodStart: '',
                PeriodEnd: '',
                ProfilePicture: '',
                Member: '',
                PrimaryTitle: '',
                Status: '',
                TimesheetDays: [],
                HourlyRate: ''
            },
            modalState: false,
            issuesModalState: false,
            issuesModalSubmitted: false,
            pieChartData: [],
            pieChartPeriods: [],
            pieChartDataMonths: [],
            pieChartMonths: []
        }
        this.setcurrentTimeSheet = this.setcurrentTimeSheet.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.clearAll = this.clearAll.bind(this);
    }
    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        const timesheets = values.Timesheets;
        this.props.fetchTimesheetsApproval(timesheets);

        //every 250 ms, check and see if the data is loaded- if it is, load the pie chart
        let pieChartData = [];
        let pieChartPeriods = [];
        let pieChartDataMonths = [];
        let pieChartMonths = [];
        let colorArray = ['#F47A1F', '#FDBB2F', '#377B2B', '#7AC142', '#007CC3', '#00529B', '#003F5C', '#58508D', '#BC5090', '#FF6361', '#FFA600'];
        let colorIndex = 0;
        for (let i = 0; i < 10; i++) {
            setTimeout(() => {
                if (pieChartData.length === 0) {
                    this.props.timesheetList.setTimesheetForApproval.forEach(obj => {
                        obj.TimesheetDays.forEach(innerObj => {
                            let parsedHours = Number(innerObj.Hours.split(':')[0]) + Number(innerObj.Hours.split(':')[1] / 60);
                            if (pieChartData.filter(obj2 => obj2.title === innerObj.Category).length === 0) {
                                pieChartData.push({ title: innerObj.Category, value: parsedHours, color: colorArray[colorIndex] });
                                colorIndex < 11 ? colorIndex += 1 : colorIndex = 0;
                            }
                            else {
                                pieChartData[pieChartData.findIndex(obj2 => obj2.title === innerObj.Category)].value += parsedHours;
                            }
                        });

                        if (pieChartPeriods.filter(obj2 => obj2 === obj.Period).length === 0) {
                            pieChartPeriods.push(obj.Period);
                        }
                    });
                    this.setState({ pieChartData: pieChartData, pieChartPeriods: pieChartPeriods, pieChartDataMonths: pieChartDataMonths, pieChartMonths: pieChartMonths });
                }
            }, 250 * i);
        }
    }
    selectAll() {
        var checkboxes = document.getElementsByClassName('timesheet_check');
        var selectedTimeSheets = [];
        // loop over them all
        for (var i = 0; i < checkboxes.length; i++) {
            // And stick the checked ones onto an array...
            checkboxes[i].checked = true;
            var selectedTimeSheetId = checkboxes[i].getAttribute('data-timesheet-id');
            var selectedTimeSheet = this.props.timesheetList.setTimesheetForApproval.filter(function (timesheet) {
                return timesheet.ItemId === selectedTimeSheetId;
            });
            selectedTimeSheets.push(selectedTimeSheet[0]);
        }
        this.props.setSelectedTimeSheet({ "selectedTimeSheet": selectedTimeSheets });
    }
    clearAll() {
        var checkboxes = document.getElementsByClassName('timesheet_check');
        for (var i = 0; i < checkboxes.length; i++) {
            // And stick the checked ones onto an array...
            checkboxes[i].checked = false;
        }
        this.props.setSelectedTimeSheet({ "selectedTimeSheet": [] });
    }
    setcurrentTimeSheet(event) {
        var checkboxes = document.getElementsByClassName('timesheet_check');
        var selectedTimeSheets = this.props.selectedTimeSheet;
        // loop over them all

        let selectedTimeSheetIds = [];
        if (this.props.selectedTimeSheet.length > 0) {
            this.props.selectedTimeSheet.map(item => {
                selectedTimeSheetIds.push(item.ItemId);
            })
        }
        let selectedTimeSheetId = event.currentTarget.getAttribute('data-timesheet-id');
        let currentTimeSheetIndex = this.props.selectedTimeSheet.findIndex(item => item.ItemId === selectedTimeSheetId);
        if (currentTimeSheetIndex !== -1) {
            selectedTimeSheets.splice(currentTimeSheetIndex, 1);
        }


        for (var i = 0; i < checkboxes.length; i++) {
            // And stick the checked ones onto an array...

            if (checkboxes[i].checked) {
                selectedTimeSheetId = checkboxes[i].getAttribute('data-timesheet-id');
                var selectedTimeSheet = this.props.timesheetList.setTimesheetForApproval.filter(function (timesheet) {
                    return timesheet.ItemId === selectedTimeSheetId;
                });

                // console.log('SEE THIS : ',selectedTimeSheetIds);
                if (selectedTimeSheetIds.indexOf(selectedTimeSheet[0].ItemId) === -1) {
                    // console.log(selectedTimeSheet[0].ItemId);
                    selectedTimeSheets.push(selectedTimeSheet[0]);
                }
            }
        }
        this.props.setSelectedTimeSheet({ "selectedTimeSheet": selectedTimeSheets });
    }
    sortData(event) {

        var sortKey = event.currentTarget.getAttribute('data-sort-key');
        var sortBy = event.currentTarget.getAttribute('data-sort');
        var currentSortDir = this.state[sortKey];
        let sortDir = "desc";
        if (currentSortDir.iconName === "angle-up") {
            this.setState({ [sortKey]: faAngleDown });
        } else {
            this.setState({ [sortKey]: faAngleUp });
            sortDir = "asc";
        }
        var sortParams = { "sortby": sortBy, "data": this.props.filteredTimeSheets, "sortDir": sortDir }
        //this.setState({currentStatus : currentStatus});
        //this.props.filterTimeSheet.sort(this.compareValues(sortby));
        // var sortParams = {"sortby": sortBy,"data" : this.props.filteredTimeSheets,"sortDir":sortDir}
        this.props.sortTimesheets(sortParams);
    }
    handleModalClose() {
        this.setState({
            modalState: false,
            modal: {
                PeriodStart: '',
                PeriodEnd: '',
                ProfilePicture: '',
                Member: '',
                PrimaryTitle: '',
                Status: '',
                TimesheetDays: [],
                HourlyRate: ''
            }
        });
    }
    render() {
        let currentTimesheets = [];

        if (typeof this.props.timesheetList.setTimesheetForApproval !== "undefined" && this.props.timesheetList.setTimesheetForApproval.length > 0) {
            currentTimesheets = this.props.timesheetList.setTimesheetForApproval;
        }
        let selectedTimeSheetIds = [];
        if (this.props.selectedTimeSheet.length > 0) {
            this.props.selectedTimeSheet.map(item => {
                selectedTimeSheetIds.push(item.ItemId);
            })
        }
        return (
            <React.Fragment>
                <TopNav />
                <div className="container">
                    <div className="container">
                        <div className="row" style={{ marginTop: 40 }}>
                            <div className="col-md-12">
                                <Table className="time_sheet_table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '15%' }}> <a className="selectAll" onClick={this.selectAll}>Select All</a><br />
                                                <a className="clearAll" onClick={this.clearAll}> Clear  </a>
                                            </th>
                                            <th style={{ width: '15%' }}>Client</th>
                                            <th style={{ width: '15%' }}>Worker</th>
                                            <th style={{ width: '15%' }}>Period</th>
                                            <th style={{ width: '15%' }}>Hours</th>
                                            <th style={{ width: '15%' }}>Status</th>
                                            <th style={{ width: '15%' }}>Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentTimesheets.map((timesheet) => {
                                            let checked = false;
                                            if (selectedTimeSheetIds.indexOf(timesheet.ItemId) !== -1) {
                                                checked = true;
                                            }
                                            return (
                                                <tr>
                                                    <td>
                                                        <label className="container1">
                                                            <input type="checkbox" checked={checked} className="timesheet_check" data-timesheet-id={timesheet.ItemId} onClick={this.setcurrentTimeSheet} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </td>
                                                    <td> {timesheet.Client} </td>
                                                    <td> {timesheet.Member} </td>
                                                    <td> {timesheet.Period} </td>
                                                    <td> {timesheet.Hours} </td>
                                                    <td> {timesheet.Status} </td>
                                                    <td> <FontAwesomeIcon className="icon2 timesheetDetail" icon={faFile} data-timesheet-id={timesheet.ItemId} onClick={() => {
                                                        this.setState({ modalState: true, modal: timesheet });
                                                        if (timesheet.ProfilePicture) {
                                                            getStorageFile(timesheet.ProfilePicture).then((url) => {
                                                                this.state.modal.ProfilePictureUrl = url;
                                                                console.log('set profile picture!');
                                                                this.setState({ render: true });
                                                            });
                                                        }
                                                    }} /> </td>
                                                </tr>
                                            )
                                        })}
                                        {this.props.timesheetList.isLoading &&
                                            <div className="loader"><FontAwesomeIcon icon={faSpinner} spin /></div>
                                        }
                                    </tbody>
                                </Table>


                            </div>
                        </div>
                    </div>
                </div>
                <div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10, fontSize: 14, marginLeft: 'auto', marginRight: 'auto', maxWidth: 1500 }}>
                    <a href="javascript:void(0)" onClick={() => { this.setState({ issuesModalState: true }) }}>Issues with these timesheets?</a>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10, fontSize: 14, marginLeft: 'auto', marginRight: 'auto', maxWidth: 1500 }}>
                    {this.state.pieChartData.length > 0 ?
                        <div>
                            <div style={{ fontWeight: '800' }}>Periods in this timesheet approval</div>
                            <div style={{ fontWeight: '600', fontStyle: 'italic' }}>{this.state.pieChartPeriods.join(", ")}</div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: -100, marginRight: 100 }}>
                                <PieChart
                                    style={{ width: 500, height: 400 }}
                                    label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
                                    labelStyle={(index) => ({ fill: '#000000', fontSize: '4px' })}
                                    radius={40}
                                    labelPosition={120}
                                    animate={true}
                                    data={this.state.pieChartData}
                                />
                                <Table className="time_sheet_table" style={{ width: 'unset', marginLeft: 40 }}>
                                    <thead>
                                        <tr>
                                            <th>Category</th>
                                            <th>Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.pieChartData.map((obj, index) => <tr><td style={{ display: 'flex', flexDirection: 'row' }}><div style={{ width: 20, height: 20, backgroundColor: obj.color, marginRight: 10 }} /> {['undefined', 'NULL'].includes(obj.title) || !obj.title ? 'Uncategorized' : obj.title}</td><td>{obj.value}</td></tr>)}
                                    </tbody>
                                </Table>
                            </div>
                        </div> : <div />}
                    {this.state.pieChartData.length > 0 ?
                        <div>
                            <div style={{ fontWeight: '800' }}>Previous 3 months</div>
                            <div style={{ fontWeight: '600', fontStyle: 'italic' }}>{this.state.pieChartMonths.join(", ")}</div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: -100 }}>
                                <PieChart
                                    style={{ width: 500, height: 400 }}
                                    label={({ dataEntry }) => `${Math.round(dataEntry.percentage)}%`}
                                    labelStyle={(index) => ({ fill: '#000000', fontSize: '4px' })}
                                    radius={40}
                                    labelPosition={120}
                                    animate={true}
                                    data={this.state.pieChartData}
                                />
                                <Table className="time_sheet_table" style={{ width: 'unset', marginLeft: 40 }}>
                                    <thead>
                                        <tr>
                                            <th>Category</th>
                                            <th>Hours</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.pieChartData.map((obj, index) => <tr><td style={{ display: 'flex', flexDirection: 'row' }}><div style={{ width: 20, height: 20, backgroundColor: obj.color, marginRight: 10 }} /> {['undefined', 'NULL'].includes(obj.title) || !obj.title ? 'Uncategorized' : obj.title}</td><td>{obj.value}</td></tr>)}
                                    </tbody>
                                </Table>
                            </div>
                        </div> : <div />}
                </div>

                <Modal animation={false} size="lg" className="detailed_time_sheet_modal" show={this.state.modalState} onHide={() => this.handleModalClose()} centered>
                    <Modal.Header closeButton>
                        {(this.state.modal.PeriodStart !== "" && this.state.modal.PeriodEnd !== "") ?
                            <Modal.Title> Timesheet Period: From <Moment format="MM/DD/YYYY">{this.state.modal.PeriodStart}</Moment> to <Moment format="MM/DD/YYYY">{this.state.modal.PeriodEnd}</Moment> </Modal.Title>
                            :
                            <Modal.Title> Timesheet Period: </Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">
                            <ul className="profildetail">
                                <li>
                                    <div className="userprofil">
                                        {this.state.modal.ProfilePictureUrl &&
                                            <img className="contactImg" src={this.state.modal.ProfilePictureUrl} />
                                        }
                                    </div>
                                </li>
                                <li className="username">
                                    <h3> {this.state.modal.Member} </h3>
                                    <h4> {this.state.modal.PrimaryTitle} </h4>
                                    <h5> Status: {this.state.modal.Status} </h5>
                                </li>
                            </ul>
                            <table className="table mt-4 time_sheet_table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Hours</th>
                                        <th>Category</th>
                                        <th>Description</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.modal.TimesheetDays.map((timesheetday) => {
                                        return (
                                            <tr>
                                                <td> <Moment format="MM/DD/YYYY">{timesheetday.Date}</Moment> </td>
                                                <td> {timesheetday.Hours} </td>
                                                <td> {timesheetday.Category === 'undefined' ? 'Uncategorized' : timesheetday.Category} </td>
                                                <td> {timesheetday.Details} </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>


                        </div>
                    </Modal.Body>
                </Modal>

                <Modal animation={false} size="lg" className="detailed_time_sheet_modal" show={this.state.issuesModalState} onHide={() => { this.setState({ issuesModalState: false, issuesModalSubject: '', issuesModalDetails: '' }) }} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Report Timesheets Issue</Modal.Title>
                    </Modal.Header>
                    {!this.state.issuesModalSubmitted ?
                        <Modal.Body>
                            <div style={{ marginBottom: 20 }}>Fill out the following fields to notify the admins of an issue with these timesheets.</div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <Input
                                            label="Subject"
                                            placeholder="Enter the Subject"
                                            onChange={value => this.setState({ issuesModalSubject: value })}
                                            validFeedback=""
                                            value={this.state.issuesModalSubject}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label class="form-label">Message</label>
                                        <InputTextArea
                                            placeholder="Enter the Message"
                                            onChange={value => this.setState({ issuesModalDetails: value })}
                                            validFeedback=""
                                            rows="8"
                                            value={this.state.issuesModalDetails}
                                        />
                                    </div>
                                </div>

                            </div>
                        </Modal.Body>
                        :
                        <Modal.Body>
                            Your issue submitted successfully.
                        </Modal.Body>
                    }
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.setState({ issuesModalState: false, issuesModalSubject: '', issuesModalDetails: '' }) }}>
                            Close
                    </Button>
                        {!this.state.issuesModalSubmitted &&
                            <Button variant="primary" onClick={() => {
                                //let data = ''; this.props.selectedTimeSheet.forEach(obj => {data += obj.ItemId + ',';}); data = data.slice(0, -1);
                                let data = queryString.parse(this.props.location.search);
                                data.Subject = this.state.issuesModalSubject;
                                data.Details = this.state.issuesModalDetails;
                                data.Issue = true;
                                API.put('2', '/approve', { body: data }).then(responseJson => {
                                    console.log(responseJson);
                                    this.setState({ issuesModalSubmitted: true, issuesModalSubject: '', issuesModalDetails: '' });
                                });
                            }}>
                                Submit
                    </Button>
                        }
                    </Modal.Footer>
                </Modal>

            </React.Fragment>
        )
    }
}


function mapStateToProps(state) {

    return {
        myState: state,
        timeSheets: state.timesheetList.timeSheets,
        timesheetList: state.timesheetList,
        selectedTimeSheet: state.timesheetList.selectedTimeSheet,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchTimesheets: () => dispatch(timesheetActions.fetchTimesheets()),
        approveTimesheet: (data) => dispatch(timesheetActions.approveTimesheet(data)),
        fetchTimesheetsApproval: (data) => dispatch(timesheetActions.fetchTimesheetsApproval(data)),
        setSelectedTimeSheet: (data) => dispatch(timesheetActions.setSelectedTimeSheet(data)),
        getDetailedTimeSheet: (data) => dispatch(timesheetActions.getDetailedTimeSheet(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproveTimeSheets);