import React from "react";

export default function Footer() {
  return (
    <footer class="navbar footer footer-light footer-shadow content container-fluid teckpertFooter">
      <div style={{margin:'auto'}}>
        Copyright © 2021 TECKpert LLC. All rights reserved.
      </div>
    </footer>
  );
}
