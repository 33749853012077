import React from "react";
import Amplify from "aws-amplify";
import { Auth } from "aws-amplify";
import { ConnectedRouter } from "connected-react-router";
import { Authenticator, SignIn, Greetings } from "aws-amplify-react";
import Login from "./components/Pages/Login/Login";
import App from "./App";
import { Provider } from "react-redux";
import store, { history } from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import { Environment } from './Environment';
let configuration = Amplify.configure({
  Auth: {
    identityPoolId: Environment.identityPoolId,
    region: Environment.region,
    identityPoolRegion: Environment.region,
    userPoolId: Environment.userPoolId,
    userPoolWebClientId: Environment.userPoolWebClientId,
    mandatorySignIn: false,
    authenticationFlowType: "USER_PASSWORD_AUTH"
  },
  API: {
    endpoints: [
      {
        name: "1",
        endpoint: Environment.endpoint,
        region: Environment.region,
        custom_header: async () => ({
          Authorization: (await Auth.currentSession()).idToken.jwtToken,
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
        })
      },
      {
        name: "2",
        endpoint: Environment.endpoint,
        region: Environment.region
      }
    ]
  },
  Storage: {
    AWSS3: {
      bucket: Environment.bucket,
      region: Environment.region
    }
  },
  Analytics: {
    disabled: true
  }
});

class AuthenticateApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bypassUrl: ["/clientDashboard", "/timesheets/approve"]
    };
  }
  render() {
    var currentUrl = window.location.href;
    let checkIfBypass = 0;
    this.state.bypassUrl.forEach(function (word) {
      checkIfBypass = checkIfBypass + currentUrl.includes(word);
    });
    return (
      <div>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            {checkIfBypass > 0 ? (
              <App />
            ) : (
                <Authenticator
                  hide={[SignIn, Greetings]}
                  amplifyConfig={configuration}
                >
                  <Login />
                  <App />
                </Authenticator>
              )}
          </ConnectedRouter>
        </Provider>
      </div>
    );
  }
}

export default AuthenticateApp;
