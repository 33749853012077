import React, { Component } from 'react';
import '../styles/TimeSheetStyles.scss';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as timesheetActions from '../actions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from '../../../components/Modals/Modal';
import { openModal, setModalName } from '../../../actions/uiActions';
import Moment from 'react-moment';
import { faFile } from "@fortawesome/free-solid-svg-icons";

import { API } from 'aws-amplify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

class TimesheetMembersList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], page: 1, totalSize: 1, sizePerPage: 25,
            ContractsOrWorkers: ' ',
            Status: ' ',
            Contract: ' ',
            FromDate: ' ',
            ToDate: ' ',
            Duration: ' ',
            Contracts: [],
            ContractName: '',
        }
    }

    componentDidMount() {
        API.get('1', `/contracts`).then(responseJson => {
            this.setState({ Contracts: responseJson.Output });
        });
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, ContractsOrWorkers, Status, FromDate, ToDate, Contract }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }

        let filterString = '';
        if (!ContractsOrWorkers) { ContractsOrWorkers = this.state.ContractsOrWorkers } if (ContractsOrWorkers !== ' ') { filterString += `&ContractsOrWorkers=${ContractsOrWorkers}` }
        if (!Status) { Status = this.state.Status } if (Status !== ' ') { filterString += `&Status=${Status}`; }
        if (!FromDate) { FromDate = this.state.FromDate } if (FromDate !== ' ') { filterString += `&fromDate=${FromDate}`; }
        if (!ToDate) { ToDate = this.state.ToDate } if (ToDate !== ' ') { filterString += `&toDate=${ToDate}`; }
        if (!Contract) { Contract = this.state.Contract } if (Contract !== ' ') { filterString += `&ContractId=${Contract}`; }

        API.get('1', `/timesheets?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}${filterString}`).then(responseJson => {
            console.log(responseJson);
            this.setState(() => ({
                page: page, data: responseJson.Output, sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder, totalSize: responseJson.RecordsTotal,
                ContractsOrWorkers: ContractsOrWorkers,
                Status: Status,
                FromDate: FromDate,
                ToDate: ToDate,
                Contract: Contract
            }));
        });
    }

    render() {
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="search_boxnew">
                            <li className="search-box">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search Contract" onChange={(e) => { this.handleTableChange(null, { ContractsOrWorkers: e.currentTarget.value ? e.currentTarget.value : ' ' }) }} />
                                    <div className="input-group-append">
                                        <button className="btn" type="submit">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 SearchIcon" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <Dropdown className="filterToggle">
                                    <Dropdown.Toggle>{this.state.Status !== ' ' ? this.state.Status : 'Select Status'}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => { this.handleTableChange(null, { Status: ' ' }) }}>Select Status</Dropdown.Item>
                                        {['Pending', 'Sent for Approval', 'Approved', 'Payroll in Progress', 'Paid'].map(obj => {
                                            return (<Dropdown.Item onClick={() => { this.handleTableChange(null, { Status: obj }); }}>{obj}</Dropdown.Item>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li className="contractFilter">
                                <Dropdown className="filterToggle">
                                    <Dropdown.Toggle>{this.state.Contract !== ' ' ? this.state.ContractName : 'Select Contract'}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => { this.handleTableChange(null, { Contract: ' ' }) }}>Select Contract</Dropdown.Item>
                                        {this.state.Contracts.map(obj => {
                                            return (<Dropdown.Item onClick={() => { this.handleTableChange(null, { Contract: obj.ContractId }); this.setState({ ContractName: obj.Name }); }}>{obj.Name}</Dropdown.Item>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li>
                                <Dropdown className="filterToggle">
                                    <Dropdown.Toggle>{this.state.Duration !== ' ' ? this.state.Duration : 'Select Duration'}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {
                                            this.handleTableChange(null, { FromDate: ' ', ToDate: ' ' });
                                            this.setState({ Duration: ' ' });
                                        }}>Select Duration</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let ToDate = this.lastWeekformatDate(today);
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last Week' });
                                        }}>Last Week</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let ToDate = this.lastWeekformatDate(today);
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 14));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last 2 Weeks' });
                                        }}>Last 2 Weeks</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let ToDate = this.lastWeekformatDate(today);
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth() - 1, today.getDate()));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last Month' });
                                        }}>Last Month</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let ToDate = this.lastWeekformatDate(today);
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth() - 2, today.getDate()));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last 2 Months' });
                                        }}>Last 2 Months</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container">
                <hr className="line" />
                <BootstrapTable
                    remote
                    keyField="ItemId"
                    filter={filterFactory()}
                    pagination={paginationFactory({ page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
                    classes='time_sheet_table'
                    bordered={false}
                    data={this.state.data}
                    page={this.state.page}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                    columns={[
                        {
                            dataField: 'Client',
                            text: 'Client',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'ContractName',
                            text: 'Contract',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'PeriodStart',
                            text: 'Period',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{row.Period}</div>); },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Hours',
                            text: 'Hours',
                            sort: false,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '8%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Status',
                            text: 'Status',
                            sort: true,
                            formatter: (cell, row) => {
                                return (<div>{cell === 'Paid' ? new Date() < new Date(row.PayrollDate) ? 'Payroll In Progress' : 'Paid' : cell}</div>);
                            },
                            headerStyle: { width: '8%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'PayrollDate',
                            text: 'Payroll',
                            sort: true,
                            formatter: (cell, row) => {
                                return (<div>{cell !== null && <Moment utc format="MM/DD/YYYY">{cell}</Moment>}</div>);
                            },
                            headerStyle: { width: '8%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'ItemId',
                            text: 'Details',
                            sort: false,
                            formatter: (cell, row) => {
                                return (<div><a href="javascript:void(0)" onClick={() => {
                                    this.props.setSelectedTimeSheet({ "selectedTimeSheet": [row] });
                                    this.props.getDetailedTimeSheet({ "itemId": cell });
                                    this.props.openModal();
                                    this.props.setModalName('DetailedTimeSheet');
                                }}><FontAwesomeIcon className="icon2 timesheetDetail" icon={faFile} /></a></div>);
                            },
                            headerFormatter: (column) => { return (<div>{column.text}</div>) },
                            headerStyle: { width: '8%' }
                        }]}
                    defaultSorted={[{
                        dataField: 'PeriodStart',
                        order: 'desc'
                    }]}
                />
            </div>
            <CustomModal />
        </React.Fragment>
        )
    }

    lastWeekformatDate(dateObj) {
        var dateObjMonth = dateObj.getMonth() + 1;
        var dateObjDay = dateObj.getDate();
        var dateObjYear = dateObj.getFullYear();
        var dateFormatted = ("0000" + dateObjYear.toString()).slice(-4) + "-" + ("00" + dateObjMonth.toString()).slice(-2) + "-" + ("00" + dateObjDay.toString()).slice(-2);
        return dateFormatted;
    }
}

function mapStateToProps(state) {
    return {
        myState: state,
        timeSheets: state.timesheetList.timeSheets,
        modalState: state.uiState.modalOpen,
        modalName: state.uiState.modalName,
        selectedTimeSheet: state.timesheetList.selectedTimeSheet,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedTimeSheet: (data) => dispatch(timesheetActions.setSelectedTimeSheet(data)),
        getDetailedTimeSheet: (data) => dispatch(timesheetActions.getDetailedTimeSheet(data)),
        setSelectedPayroll: (data) => dispatch(timesheetActions.setSelectedPayroll(data)),

        openModal: () => dispatch(openModal()),
        setModalName: (data) => dispatch(setModalName(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimesheetMembersList);