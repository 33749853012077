import React from "react";
import { FontAwesomeIcon } from "../../../node_modules/@fortawesome/react-fontawesome";
import { Auth } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap.js';
import icon from '../../assets/Picture1.png';
import 'bootstrap/dist/js/bootstrap.bundle';
import './TopNavStyle.scss';
import {
    faBell,
    faComments,
} from "../../../node_modules/@fortawesome/free-solid-svg-icons";
import * as timesheetActions from '../../modules/Timesheet/actions';
import { setUserAttributes } from '../../actions/adminActions';
import CustomModal from '../Modals/Modal';
import { openModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";

let name = '';
let profilePicture = '';

class TopNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NavbarText: '',
            SendToClient: false,
            AddClient: false,
            displayModal: true,
            addContract: false,
            AddOpportunity: false,
            AddCourse: false,
            sendNewMsgBtn: false,
        };
        this.handleSignOut.bind(this);
        this.sendToClient = this.sendToClient.bind(this);
        this.sendToPayroll = this.sendToPayroll.bind(this);
    }
    sendToClient() {
        if (this.props.timesheetList.selectedTimeSheet.length > 0) {
            this.props.openModal();
            this.props.setModalName('SendToClient');
        }
    }
    sendToPayroll() {
        if (this.props.timesheetList.selectedTimeSheet.length > 0) {
            this.props.openModal();
            this.props.setModalName('SendToPayroll');
        }
    }
    handleSignOut(event) {
        event.preventDefault();
        Auth.signOut()
            .then(() => { console.log("Signed Out") })
            .catch(err => console.log(err));
    }

    componentDidMount() {
        let currentRoute = window.location.pathname;
        if (['admin', 'finance', 'sales', 'recruiter', 'marketing'].includes(localStorage.getItem('userType'))) {
            switch (currentRoute) {
                case '/':
                    this.setState({ NavbarText: 'Search People' });
                    break;
                case '/clients':
                    this.setState({ NavbarText: 'Search Clients', AddClient: true });
                    break;
                case '/opportunities':
                    this.setState({ NavbarText: 'Search Opportunities', AddOpportunity: true });
                    break;
                case '/contracts':
                    this.setState({ NavbarText: 'Search Contracts', addContract: true });
                    break;
                case '/timesheets':
                    this.setState({ NavbarText: 'Search Timesheets', SendToClient: true });
                    break;
                case '/invoices':
                    this.setState({ NavbarText: 'Invoices', AddInvoice: true });
                    break;
                case '/invoices/add':
                    this.setState({ NavbarText: 'Invoices', AddInvoice2: true });
                    break;
                case '/tools':
                    this.setState({ NavbarText: 'Tools' });
                    break;
                case '/tools/payrolls':
                    this.setState({ NavbarText: 'Tools' });
                    break;
                case '/tools/emails':
                    this.setState({ NavbarText: 'Tools' });
                    break;
                case '/messaging':
                    this.setState({ NavbarText: 'Messaging' });
                    break;
                case '/analytics':
                    this.setState({ NavbarText: 'Analytics' });
                    break;
                case '/admins':
                    this.setState({ NavbarText: 'Users' });
                    break;
                case '/courses':
                    this.setState({ NavbarText: 'Courses', AddCourse: true });
                    break;
                default:
                    this.setState({ NavbarText: '' });
            }
        }
        else {
            switch (currentRoute) {
                case '/':
                    this.setState({ NavbarText: 'Time Entry' });
                    break;
                case '/timesheets':
                    this.setState({ NavbarText: 'Timesheets' });
                    break;
                default:
                    this.setState({ NavbarText: '' });
            }
        }
        if (currentRoute.indexOf('/messaging/') !== -1) {
            this.setState({ sendNewMsgBtn: true });
        }
        profilePicture = localStorage.getItem("profilePicture");
        name = localStorage.getItem("name");
        window.scrollTo(0, 0);
    }


    render() {
        return (
            <React.Fragment >
                <nav className="navbar navbar-expand-lg top_nav navbar-light bg-light">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item navbar-brand top-nav-name">{(this.props.title !== '' ? this.props.title : this.state.NavbarText)}</li>
                    </ul>
                    {this.state.AddClient &&
                        <div className="sendPayrollBtn">
                            <Link className="btn btn-success sendPayroll" to="/clients/add">Add Client</Link>
                        </div>
                    }
                    {this.state.AddOpportunity &&
                        <div className="sendPayrollBtn">
                            <Link className="btn btn-success sendPayroll" to="/opportunities/add">Create New</Link>
                        </div>
                    }
                    {this.state.addContract &&
                        <div className="sendPayrollBtn">
                            <Link className="btn btn-success sendPayroll" to="/contracts/add">Add New</Link>
                        </div>
                    }
                    {this.state.AddCourse &&
                        <div className="sendPayrollBtn">
                            <Link className="btn btn-success sendPayroll" to="/courses/add">Create New</Link>
                        </div>
                    }
                    {this.state.AddInvoice &&
                        <div className="sendPayrollBtn">
                            <Link className="btn btn-success sendPayroll" to="/invoices/add">Create New</Link>
                        </div>
                    }
                    {this.state.sendNewMsgBtn && <div className="sendPayrollBtn">
                        <Link className="btn btn-success sendPayroll" to="/Messaging">New Message</Link>
                    </div>}
                    {this.state.SendToClient &&
                        <div className="sendPayrollBtn" style={{ marginRight: 20 }}>
                            {(this.props.timesheetList.selectedTimeSheet.length > 0) ?
                                <div>
                                    <a className="btn btn-success sendPayroll" href="#" onClick={this.sendToPayroll}>Send to Payroll</a>
                                    {(this.props.timesheetList.selectedTimeSheet.every((value) => value.ContractId === this.props.timesheetList.selectedTimeSheet[0].ContractId)) ?
                                        <a className="btn btn-success sendToClient" href="#" onClick={this.sendToClient} >Send for Approval</a>
                                        :
                                        <a className="btn btn-success sendToClient greyed" href="#">Send for Approval</a>
                                    }
                                </div>
                                :
                                <div>
                                    <a className="btn btn-success sendPayroll greyed" href="#">Send to Payroll</a>
                                    <a className="btn btn-success sendToClient greyed" href="#">Send for Approval</a>
                                </div>
                            }
                        </div>
                    }
                    {/*<img src={require("../../assets/icon.png")} className="nav-item profile-picture" alt="" />*/}
                    <div className="nav-item dropdown profile-name-dropdown">
                        <Dropdown color="#ffffff" navbar={true} className="userMenuToggle" >
                            <Dropdown.Toggle>{name}</Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => {
                                    this.handleSignOut(e);
                                }} >Sign Out</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    {['admin', 'client'].includes(localStorage.getItem('userType')) &&
                        <div style={{ paddingRight: '1.5rem' }}>
                            <Link onClick={() => { console.log("notification modal should pop up") }}>
                                <svg className="nav-item bell-icon">
                                    <FontAwesomeIcon icon={faBell} />
                                </svg>
                            </Link>
                        </div>}

                    {/* {['admin', 'client'].includes(localStorage.getItem('userType')) &&
                        <div style={{ paddingRight: '1.5rem' }}>
                            <Link to="/messaging">
                                <svg className="nav-item message-icon">
                                    <FontAwesomeIcon icon={faComments} />
                                </svg>
                            </Link>
                        </div>} */}

                </nav>
                <CustomModal />
            </React.Fragment>
        )
    }
}
TopNav.propTypes = {
    setUserAttributes: PropTypes.func.isRequired,
    title: PropTypes.string,
};

TopNav.defaultProps = {
    title: '',
}

function mapStateToProps(state) {
    return {
        userState: state.adminState.userState,
        myState: state,
        timesheetList: state.timesheetList,
        modalState: state.uiState.modalOpen,
        modalName: state.uiState.modalName,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        setUserAttributes: () => dispatch(setUserAttributes()),
        openModal: () => dispatch(openModal()),
        setModalName: (data) => dispatch(setModalName(data)),
        getDetailedTimeSheet: (data) => dispatch(timesheetActions.getDetailedTimeSheet(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(TopNav)