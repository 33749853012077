import React, { Component } from "react";
import moment from "moment";
import InputTextArea from "../../../components/ui/form/InputTextArea";

export class ContactList extends Component {

  constructor () {
    super();
    this.state = {
      message: '',
      formError: '',
    }
  }

  scrollToBottom() {
    const scrollHeight = this.messageList.scrollHeight;
    const height = this.messageList.clientHeight;
    const maxScrollTop = scrollHeight - height;
    this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  onMessageChange = (text) => {
    this.setState({
      message: text
    });
  }

  onMessageSend = () => {
    this.setState({formError: ''});
    if (this.state.message !== '') {
      this.props.onMessageSend(this.state.message);
      this.setState({ message: '' });
    } else {
      this.setState({formError: 'Please type a message to send.'})
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="Chat_section">
          <div className="Chat__List" ref={(div) => {
            this.messageList = div;
          }}>
            {this.props.data.map((item, index) => {
              let currentMessageDate = moment(item.createdAt).format("DD MMMM");
              let PreviousMessageDate = currentMessageDate;
              let dateDivider = "";
              if (index > 0) {
                PreviousMessageDate = moment(
                  this.props.data[index - 1].createdAt
                ).format("DD MMMM");
                if (PreviousMessageDate !== currentMessageDate) {
                  dateDivider = currentMessageDate;
                }
              } else {
                dateDivider = currentMessageDate;
              }
              return (
                <div key={item._id}>
                  {dateDivider !== "" && (
                    <div className="divider">{dateDivider}</div>
                  )}
                  <div
                    key={`messagingLiost-${index}`}
                    className="Chat__List__Item"
                  >
                    <div className="Chat__List__Item__Picture">
                      <div className="Chat__List__Item__Picture__Image"></div>
                    </div>
                    <div className="Chat__List__Item__Content">
                      <span className="Chat__List__Item__Content__Name">
                        {/* {item.name} -{" "} */}
                        <small>{moment(item.createdAt).format("LT")}</small>
                      </span>
                      <span className="Chat__List__Item__Content__Msg">
                        {item.text}
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <InputTextArea 
            rows="3"
            onChange={this.onMessageChange}
            value={this.state.message}
            invalidFeedback={this.state.formError}
          />
          <ul className="buttonBlock">
            <li>
              <a className="greenBtn" onClick={this.onMessageSend}>Send</a>
            </li>
            {/* <li>
              <a className="uploadFile">Upload File</a>
            </li> */}
          </ul>
        </div>
      </React.Fragment>
    );
  }
}

export default ContactList;
