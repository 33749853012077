import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


class EditSpecializations extends Component {
  constructor(props) {
    super(props);
    this.handleModalClose.bind(this);
    this.state = {
      selectedSpecialization: '',
    }
    this.handleSelectSpecialization.bind(this);
  }

  handleModalClose() {
    this.props.setModalName(null);
    this.props.closeModal();
  }

  handleSelectSpecialization(event) {
    this.setState({selectedSpecialization: event.currentTarget.value});
  }

  //
  render() {
    return (
      <React.Fragment>
        <Modal show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Specializations</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Select a specialization</Form.Label>
                    <Form.Control as="select">
                      <option>.NET</option>
                      <option>Angular JS</option>
                      <option>ASP</option>
                      <option>C#</option>
                      <option>Java</option>
                      <option>HTML</option>
                      <option>CSS</option>
                      <option>JavaScript</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Member Specializations</Form.Label>
                    <Form.Control as="select" multiple>
                      {this.props.memberInfo.Specializations.map((specialization) => {
                        return (
                          <option onClick={e => this.handleSelectSpecialization(e)}>{specialization}</option>
                        )
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
          </Button>
            <Button variant="primary">
              Save Changes
          </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )

  }
}
EditSpecializations.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    memberInfo: state.member.memberInfo[0],
  }
}
export default connect(mapStateToProps, { closeModal, setModalName })(EditSpecializations);