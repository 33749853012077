import { API, Storage } from 'aws-amplify';

/* CONTRACT : MANAGE DOCUMENTS API CALLS : START */
export function addContractDocument(ItemId, data) {
    return API.post('1',
        `/documents`,
        {
            body: {
                Key: data.addDocumentFile,
                Type: data.addDocumentType,
                Folder: 'Contract',
                Name: data.addDocument,
                ContractId: ItemId
            }
        }
    ).then(responseJson => {
        return responseJson;
    });
}
export function editContractDocument(ItemId, data) {
    return API.put('1',
        `/documents`, {
        body: {
            ItemId: data.editDocument,
            Key: data.editDocumentFile,
            Type: data.editDocumentType
        }
    }
    ).then(responseJson => {
        return responseJson;
    });
}
export function deleteContractDocument(ItemId, data) {
    const deleteDocument = data.deleteDocument;
    return API.del('1',
        `/documents?ItemId=${deleteDocument}`
    ).then(responseJson => {
        return responseJson;
    });
}
/* CONTRACT : MANAGE DOCUMENTS API CALLS : END */

/* CONTRACT : MANAGE JOB ORDER API CALLS : START */
export function addContractJobOrder(ItemId, data) {
    return API.post(
        '1',
        `/joborders`, {
        body: {
            ContractId: ItemId,
            MemberId: data.Worker,
            Title: data.Title,
            StartDate: data.StartDate,
            EndDate: data.EndDate,
            Hours: data.Hours,
            BillableRate: data.BillableRate,
            Rate: data.Rate,
            TimeApproval: data.TimeApproval,
            Remote: data.Remote,
            Description: data.Description,
            LocationId: data.LocationId,
            City: data.City,
            State: data.State,
            Zip: data.Zip,
            Type: data.Type,
            AutoSend: data.AutoSend
        }
    }
    ).then(responseJson => {
        return responseJson;
    });
}
export function editContractJobOrder(ItemId, data) {
    return API.put(
        '1',
        `/joborders`, {
        body: {
            ItemId: data.JobOrderId,
            ContractId: ItemId,
            MemberId: data.Worker,
            Title: data.Title,
            StartDate: data.StartDate,
            EndDate: data.EndDate,
            Hours: data.Hours,
            BillableRate: data.BillableRate,
            Rate: data.Rate,
            TimeApproval: data.TimeApproval,
            Remote: data.Remote,
            Description: data.Description,
            LocationId: data.LocationId,
            City: data.City,
            State: data.State,
            Zip: data.Zip,
            Type: data.Type,
            AutoSend: data.AutoSend
        }
    }
    ).then(responseJson => {
        return responseJson;
    });
}
export function deleteContractJobOrder(ItemId, deleteJobOrder) {
    return API.put('1', `/contracts`, { body: { ItemId: ItemId, deleteJobOrder: deleteJobOrder } }).then(responseJson => {
        return responseJson;
    });
}
/* CONTRACT : MANAGE JOB ORDER API CALLS : START */

/* CONTRACT : MANAGE NOTES API CALLS : START */
export function addContractNote(ItemId, addNote) {
    return API.put('1', `/contracts`, { body: { ItemId: ItemId, addNote: addNote } }).then(responseJson => {
        return responseJson;
    });
}
export function editContractNote(ItemId, editNoteId, editNote) {
    return API.put('1', `/contracts`, { body: { ItemId, ItemId, editNote, editNote, editNoteId, editNoteId } }).then(responseJson => {
        return responseJson;
    });
}
export function deleteContractNote(ItemId, deleteNote) {
    return API.put('1', `/contracts`, { body: { ItemId, ItemId, deleteNote, deleteNote } }).then(responseJson => {
        return responseJson;
    });
}
/* CONTRACT : MANAGE NOTES API CALLS : END */
export function updateCategories(ItemId, data) {
    return API.put('1', `/contracts`, { body: { ItemId: ItemId, Categories: data } }).then(responseJson => {
        return responseJson;
    });
}

export function fetchContracts() {
    return API.get('1', '/contracts').then(responseJson => {
        return responseJson;
    });
}

export function fetchContractDetails(ItemId) {
    return API.get('1', `/contracts?ItemId=${ItemId}`).then(responseJson => {
        return responseJson;
    });
}

export function fetchContacts(ItemId) {
    return API.get('1', `/contacts?ItemId=${ItemId}`).then(responseJson => {
        return responseJson;
    });
}

export function fetchContactsList() {
    return API.get('1', `/contacts`).then(responseJson => {
        return responseJson;
    });
}
export function fetchWorkersList() {
    return API.get('1', `/members/dropdown`).then(responseJson => {
        return responseJson;
    });
}

export function postContracts(data) {
    return API.post('1', '/contracts', { body: data }).then(responseJson => {
        return responseJson;
    });
}

export function editContract(ItemId, data) {
    data.ItemId = ItemId;
    return API.put('1', '/contracts', { body: data }).then(responseJson => {
        return responseJson;
    });
}