/**
 * 
 * @param {*} keyword 
 * @param {*} data 
 * @description parses information to be more readable and pushes it to an array. 
 * @if statements check if the values are null.
 * Parse the response from API.get from members
 * traverse the array of members profiles 
 */
// 
export function filterData(key,value,data) {
    if(key === "keyword"){
       return data.filter(function(timesheet) {
            if(timesheet.Member.toLowerCase().includes(value.toLowerCase()) || timesheet.Client.toLowerCase().includes(value.toLowerCase())){
                return true;
            }else{
                return false;
            }
        });
    }
    if(key === "Status"){
        return data.filter(function(timesheet) {
            return timesheet.Status === value;
        });
    }
    if(key === "ContractName"){
        return data.filter(function(timesheet) {
            return timesheet.ContractName === value;
        });
    }
}

export function filterOpportunities(key,value,data) {
    
    if(key === "keyword"){
       return data.filter(function(opportunity) {
            if(opportunity.Title.toLowerCase().includes(value.toLowerCase()) ){
                return true;
            }else{
                return false;
            }
        });
    }
    if(key === "status"){
        return data.filter(function(opportunity) {
            if(value.includes(opportunity.Status)){
                return true;    
            }
        });
    }
}
export function filterClients(key,value,data) {
    if(key === "keyword"){
        return data.filter(function(client) {
            
            if(client.Name !== null && client.Name.toLowerCase().includes(value.toLowerCase())){
                return true;
            }else{
                return false;
            }
        });
    }
}
export function filterContracts(key,value,data) {
    
    if(key === "keyword"){
       return data.filter(function(contract) {
            if(contract.Name.toLowerCase().includes(value.toLowerCase()) ){
                return true;
            }else{
                return false;
            }
        });
    }
    if(key === "status"){
        return data.filter(function(contract) {
            if(value.includes(contract.Status)){
                return true;    
            }
        });
    }
}

export function filterContactByClientId(data, ClientId) {
    const filteredData = data.filter(item => {
        return item.ClientId === ClientId;
    });
    return filteredData;
}
export function filterContactByItemId(ItemId,data) {
    let filteredData = data.filter(item => {
        return item.ItemId === ItemId;
    });
    if(filteredData.length > 0){
        filteredData = filteredData[0];
    }
    return filteredData;
}
export function sortData(sortBy,sortDir,data) {
   data.sort(compareValues(sortBy,sortDir));
    return data
}
export function compareValues(key, order='asc') {
    return function(a, b) {
      if(!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        // property doesn't exist on either object
        return 0;
      }
  
      const varA = (typeof a[key] === 'string') ?
        a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string') ?
        b[key].toUpperCase() : b[key];
  
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
}
export const parseObjectToQueryStringData = (data) => {
    return Object.keys(data).map(key => key + '=' + data[key]).join('&');
  }
  