import { API, Storage } from "aws-amplify";

export function fetchConversation (messageId) {
  // and you can call GET /messages?MessengerId=c02847b8-c1d7-4cc7-b185-b7ba38c4a794 to retrieve all messages with that individual
  return API.get("1", `/messages?MessengerId=${messageId}&ItemsPerPage=50`).then(responseJson => {
    return responseJson;
  });
}

export function sendMessage(data) {
  const postdata = {
    body: data
  };
  return API.post("1", `/messages`, postdata).then(responseJson => {
    return responseJson;
  });
}

export function sendBulkMessage (message, contacts) {
  if (contacts.length > 0) {
    contacts.map(item => {
      let data = {
        Message: message,
        ReceiverId: item.id
      };
      let postdata = {
        body: data
      };
      console.log('postdata : ', postdata);
      return API.post("1", `/messages`, postdata).then(responseJson => {
        return responseJson;
      }); 
    });
  }
}

export function fetchMessageThreads() {
  return API.get("1", `/messagethreads`).then(responseJson => {
    return responseJson;
  });
}

export function fetchAvailableContacts() {
  return API.get("1", `/userslistSQL`).then(responseJson => {
    return responseJson;
  });
}
