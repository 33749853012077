import React from "react";
import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { PDFDownloadLink, Image } from "@react-pdf/renderer";
import logo from "./Logo_TECKpert.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

// Create Document Component
const MyDocument = ({ member, profilePic }) => (
  <Document style={styles.parent}>
    <Page size='A4' style={{ margin: 10 }}>
      <Image style={styles.imageContainer} source={logo} alt='TECKpert Logo' />
      <View style={styles.container}>
        <Image
          style={styles.profilePic}
          source={{
            uri: profilePic,
            headers: { Pragma: "no-cache", "Cache-Control": "no-cache" },
          }}
        />
        <View style={{ width: "75%", marginTop: 10, lineHeight: 1.25 }}>
          <View
            style={{
              color: "#6fbe47",
              flexDirection: "row",
              justifyContent: "space-between",
              fontSize: 20,
            }}>
            <Text>
              {member.FirstName} {member.LastName[0]}
            </Text>
            <Text>{member.Meta.PrimaryRole.label}</Text>
          </View>
          <View>
            {member.Meta.Education
              ? member.Meta.Education.map((item, i) => (
                  <Text key={i}>
                    {item.Degree} at {item.School}{" "}
                    {item.End.substring(0, 2) + item.End.split("/")[2]}
                  </Text>
                ))
              : null}
          </View>
          <Text style={{ color: "#4d4d4d", fontSize: 14 }}>{member.Meta.Description}</Text>
          <View style={{ flexDirection: "row" }}>
            <Text style={{ fontSize: 14, marginTop: 2, color: "#4d4d4d" }}>Skills: </Text>
            {member.Meta.Specializations
              ? member.Meta.Specializations.map((item, i) => (
                  <Text
                    key={i}
                    style={{
                      flexDirection: "row",
                      alignSelf: "center",
                      backgroundColor: "#6fbe47",
                      borderRadius: 5,
                      color: "white",
                      padding: 3,
                      fontSize: 10,
                      marginRight: 3,
                    }}>
                    {item}
                  </Text>
                ))
              : null}
          </View>
        </View>
      </View>
      <Text style={{ color: "#6fbe47", fontWeight: "extrabold" }}>Work</Text>
      {member.Meta.Work
        ? member.Meta.Work.map((item, i) => (
            <View key={i} style={{ marginLeft: 10, paddingBottom: 5, width: "95%" }}>
              <Text>
                {item.Company} | {item.Role} |{" "}
                {item.Start.substring(5, 7) + "-" + item.Start.substring(0, 4)} -{" "}
                {item.End.substring(5, 7) + "-" + item.End.substring(0, 4)}
              </Text>
              <Text style={{ color: "#4d4d4d", fontSize: 14 }}>{item.Detail}</Text>
            </View>
          ))
        : null}
      <Text style={{ color: "#6fbe47", fontWeight: "bold" }}>Certifications</Text>
      {member.Meta.VerifiedCertifications
        ? member.Meta.VerifiedCertifications.map((item, i) => (
            <View key={i} style={{ marginLeft: 10, width: "95%" }}>
              <Text>{item}</Text>
            </View>
          ))
        : null}
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  parent: { padding: 20, width: "80%" },
  imageContainer: { display: "block", margin: "auto", marginTop: 0, marginBottom: 0, width: "50%" },
  logoContainer: { width: 12 },
  icon: { fontSize: 12, color: "#6fbe47" },
  profilePic: { width: "20%", borderRadius: 200, marginRight: 10 },
  container: { flexDirection: "row" },
});

const People = ({ member, profilePic }) => {
  const [delay, setDelay] = useState(false);

  setTimeout(function () {
    setDelay(true);
  }, 3000);
  return (
    <>
      {delay ? (
        <PDFDownloadLink
          document={<MyDocument member={member} profilePic={profilePic} />}
          fileName={"TECKpert_" + member.FirstName + "_" + member.LastName[0] + ".pdf"}>
          {({ blob, url, loading, error }) =>
            loading ? (
              "Loading document..."
            ) : error ? (
              "Error"
            ) : (
              <FontAwesomeIcon className='icon3' icon={faFilePdf} />
            )
          }
        </PDFDownloadLink>
      ) : (
        "not loaded"
      )}
    </>
  );
};

export default People;
