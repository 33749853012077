import React, { Component } from "react";
import PropTypes from 'prop-types';

import moment from 'moment'
import DatePicker, { registerLocale, setDefaultLocale} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import enGb from 'date-fns/locale/en-GB';
registerLocale('en-gb', enGb);
setDefaultLocale('en-gb');

export class InputDate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      value: (typeof props.value !== "undefined" && props.value !== '') ? new Date(props.value).setDate(new Date(props.value).getDate() + 1) : '', //quick fix for UTC issue
    };
  }

  onChange(date) {
    const dateFormat = moment(date).format('YYYY-MM-DD');
    console.log(dateFormat);
    this.setState({ value: date }, () => {
      this.props.onChange(dateFormat);
    });
  }

  render() {
    let inputClass = 'form-control';
    if (this.props.validFeedback !== '') inputClass += ' is-valid';
    if (this.props.invalidFeedback !== '') inputClass += ' is-invalid';

    return (
      <div className="form-group">
        {this.props.label !== "" && (
          <label className="form-label">{this.props.label}</label>
        )}
        <div>
          <DatePicker
            selected={this.state.value}
            onChange={date => this.onChange(date)}
            className={inputClass}
            showPopperArrow={false}
          />
        </div>
        {this.props.invalidFeedback !== '' && <div class="invalid-feedback">{this.props.invalidFeedback}</div>}
        {this.props.validFeedback !== '' && <div class="valid-feedback">{this.props.validFeedback}</div>}
      </div>
    );
  }
}

InputDate.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  validFeedback: PropTypes.string,
  invalidFeedback: PropTypes.string,
  value: PropTypes.string,
};

InputDate.defaultProps = {
  label: '',
  placeholder: '',
  validFeedback: '',
  invalidFeedback: '',
  value: '',
}

export default InputDate;
