import React, { Component } from "react";
import PropTypes from "prop-types";
import EmptyData from './EmptyData';
import { ContractConstants } from '../../config/Constants';
import AccordionCard from "../../../../components/ui/AccordionCard";
import * as storageUtils from '../../../../helperFunctions/storageUtils'
import Moment from 'react-moment';
import Empty from '../../../../components/ui/Empty';
import {
  Link
} from "react-router-dom";

export class Workers extends Component {

  static propTypes = {
    worker: PropTypes.array
  };

  render() {
    const { worker } = this.props;
    return (
      <AccordionCard title="Workers"  actionhtml={
        <span className="add-sign" onClick={() => {
          this.showAddJobOrderModal();
        }} >+</span>
      }>
      {(typeof worker !== 'undefined' && Array.isArray(worker) && worker.length > 0) ?
        <table class="table mt-4 contractWorkers">
          <thead>
            <tr>
              <th>Worker Name</th>
              <th>Title</th>
              <th>Hours</th>
              <th>Start Date</th>
              <th>Term</th>
              <th>Rate</th>
              <th>Remote</th>
              <th>Timesheet</th>
            </tr>
          </thead>
          <tbody>
          
              {worker.map( (item, index) => {
                var hours = item.Hours.split(':');
                return (
                  <tr key={`worker-${item.ItemId}-${index}`}>
                    <td><Link to={"/workers/"+item.ItemId}>{item.Name}</Link></td>
                    <td>{item.Title}</td>
                    <td>{hours[0]}</td>
                    <td><Moment format="MM/DD/YYYY">{item.StartDate}</Moment></td>
                    <td>{storageUtils.getDateDifference(item.StartDate, item.EndDate)}</td>
                    <td>${item.HourlyRate}</td>
                    <td>{item.Remote}</td>
                    <td>{item.Timesheet}</td>
                  </tr>
                )
              })}
           
          </tbody>
        </table>
         : <Empty message="No workers found" />
        }
      </AccordionCard>
    );
  }
}

export default Workers;
