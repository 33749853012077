import { 
    ENABLE_CLIENT_LOADER, DISABLE_CLIENT_LOADER,
    FETCH_CLIENTS, SET_CLIENT, UPDATE_CLIENT,EDIT_CLIENT,
    FILTER_CLIENT,SORT_CLIENT,ADD_CLIENT,
    CLEAR_RESPONSE, SET_RESPONSE,GET_CLIENT,CLIENT_DETAIL,
    SET_CONTACT_FORM_DATA,ADD_CONTACT,DELETE_CONTACT,GET_CLIENT_CONTACTS,
    SET_CLIENT_CONTACTS,SET_CONTACT_DETAIL,CLEAR_CONTACT_DETAIL,
    GET_CONTACT_DETAIL,SUBMIT_CLIENT_CONTRACTS,
    ADD_CLIENT_NOTE, EDIT_CLIENT_NOTE, DELETE_CLIENT_NOTE,
    ADD_CLIENT_DOCUMENT,EDIT_CLIENT_DOCUMENT,DELETE_CLIENT_DOCUMENT
  } from './types';
  
  export const enableLoader = () => ({
    type: ENABLE_CLIENT_LOADER,
  });
  
  export const setContactFormData = (key, value) => ({
    type: SET_CONTACT_FORM_DATA,
    key,
    value,
  })
  export const clearContactDetail = () => ({
    type: CLEAR_CONTACT_DETAIL,
  });
  export const disableLoader = () => ({
    type: DISABLE_CLIENT_LOADER,
  });
  
  export const fetchClients = () => ({
    type: FETCH_CLIENTS
  });

  export const addContact = (data) => ({
    type: ADD_CONTACT,
    data
  })

  export const deleteContact = (data) => ({
    type: DELETE_CONTACT,
    data
  })
  
  export const setClients = (data) => ({
    type: SET_CLIENT,
    data,
  });
  
  export const addClient = (data) => ({
    type: ADD_CLIENT,
    data,
  });
  export const updateClient = (data) => ({
    type: EDIT_CLIENT,
    data,
  }); 
  export const clearResponse = (data) => ({
    type: CLEAR_RESPONSE,
    data,
  });

  export const setResponse = (data) => ({
    type : SET_RESPONSE,
    data
  });

  export const filterClients = (data) => ({
    type: FILTER_CLIENT,
    data,
  });
  
  export const sortClients = (data) => ({
    type: SORT_CLIENT,
    data,
  });

  export const getClientDetails = (data) => ({
    type: GET_CLIENT,
    data,
  });
  export const getClientContacts = (data) => ({
    type: GET_CLIENT_CONTACTS,
    data,
  });
  export const setClientContacts = (data) => ({
    type: SET_CLIENT_CONTACTS,
    data,
  });
  export const setContactDetail = (data)  => ({
    type: SET_CONTACT_DETAIL,
    data,
  });
  export const getContactDetail = (data)  => ({
    type: GET_CONTACT_DETAIL,
    data,
  });
  
  export const setClientDetails = (data) => ({
    type: CLIENT_DETAIL,
    data,
  });
  
  export const updateClients = (data) => ({
    type: UPDATE_CLIENT,
    data,
  });

  export const addClientNote = (ItemId, addNote) => ({
    type: ADD_CLIENT_NOTE,
    ItemId,
    addNote,
  });
  export const editClientNote = (ItemId, editNoteId, editNote) => ({
    type: EDIT_CLIENT_NOTE,
    ItemId,
    editNoteId,
    editNote,
  });
  export const deleteClientNote = (ItemId, deleteNote) => ({
    type: DELETE_CLIENT_NOTE,
    ItemId,
    deleteNote,
  });
  export const addClientDocument = (ItemId, data) => ({
    type: ADD_CLIENT_DOCUMENT,
    ItemId,
    data,
  });
  export const editClientDocument = (ItemId, data) => ({
    type: EDIT_CLIENT_DOCUMENT,
    ItemId,
    data,
  });
  export const deleteClientDocument = (ItemId, data) => ({
    type: DELETE_CLIENT_DOCUMENT,
    ItemId,
    data,
  });
  // ADD CONTRACT - ACTION
export const addClientContract = (data) => ({
  type: SUBMIT_CLIENT_CONTRACTS,
  data,
});