import React, { Component } from "react";
import Empty from "../../../../../components/ui/Empty";

export class Requests extends Component {
  getUnverifiedCertifications = () => {
    const { member } = this.props;
    return typeof member.Meta !== "undefined" &&
      typeof member.Meta.UnverifiedCertifications !== "undefined" &&
      member.Meta.UnverifiedCertifications !== ""
      ? member.Meta.UnverifiedCertifications
      : [];
  };

  getVerifiedCertifications = () => {
    const { member } = this.props;
    return typeof member.Meta !== "undefined" &&
      typeof member.Meta.VerifiedCertifications !== "undefined" &&
      member.Meta.VerifiedCertifications !== ""
      ? member.Meta.VerifiedCertifications
      : [];
  };

  onToggle = (type, index) => {
    let verified = this.getVerifiedCertifications();
    let unverified = this.getUnverifiedCertifications();
    if (type === "Verified") {
      verified = [].concat(verified, unverified[index]);
      unverified.splice(index, 1);
    } else {
      unverified = [].concat(unverified, verified[index]);
      verified.splice(index, 1);
    }
    this.props.updateCertifications(verified, unverified);
  };

  render() {
    const verified = this.getVerifiedCertifications();
    const unverified = this.getUnverifiedCertifications();

    return (
      <div className="notesBlock">
        <div className="row">
          <div className="col-md-12 requests">
            <div className="requests__container">
              <h4>Unverified Accomplishments</h4>
              {unverified.length > 0 ? (
                <div className="requests__container__items">
                  {unverified.map((item, index) => {
                    return (
                      <div
                        className="requests__container__items__item"
                        key={`requests-list-item-uv-${index}`}
                      >
                        <span>{item}</span>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => this.onToggle("Verified", index)}
                        >
                          Verify
                        </button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div style={{width: 400, marginTop: 20}}>
                  <Empty message="No Unverified Accomplishment" />
                </div>
              )}
            </div>

            <div className="requests__container">
              <h4>Verified Accomplishments</h4>
              {verified.length > 0 ? (
                <div className="requests__container__items">
                  {verified.map((item, index) => {
                    return (
                      <div
                        className="requests__container__items__item"
                        key={`requests-list-item-uv-${index}`}
                      >
                        <span>{item}</span>
                        <button
                          className="btn btn-primary btn-sm"
                          onClick={() => this.onToggle("Unverified", index)}
                        >
                          Unverify
                        </button>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div style={{width: 400, marginTop: 20}}>
                  <Empty message="No Verified Accomplishment" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Requests;
