

let customStyle = {

    topHeader: {
        backgroundColor: '#373737',
    },

    loginError: {
        height: "25%",
    },

    centerImage: {
        paddingBottom: '20px'
    },

    copyright: {
        backgroundColor: 'black',
    },

    paddingSpace: {

    },

    logoImage: {
        textAlign: 'center'
    },

    footerFixed: {
        position:"fixed",
        bottom:"0px",
    },
    footer: {
        backgroundColor: '#373737',
        position:"absolute",
        bottom: 0,

    },

    mainContainer: {
        minHeight: '100vh', /* will cover the 100% of viewport */
        overflow: 'hidden',
        display: 'block',
        position: 'relative',
    },

    footerPadding: {
        paddingTop: '50px'
    },

    speakText: {
        fontFamily: 'Lato',
        color: '#ffffff',
        fontSize: 22,

    },

    navBoldText: {
        fontFamily: 'Lato-Bold'
    },

    getTouchText: {
        fontFamily: 'Lato',
        color: '#6FBE47',
        fontSize: 22,

    },

    socialMediaLinks: {
        display: 'inline',
        marginRight: "6%"
    },

    socialMediaSpacing: {
        display: 'inline',

    },

    mainButton: {
        backgroundColor: '#6FBE47',
        borderColor: '#6FBE47',
        height: 60,
        width: '100%',
        fontSize: 20,
        marginBottom: 30,
        marginLeft: '0%',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 0,
    },

    inputForm: {
        borderRadius: 0,
        alignItems: 'center',
        justifyContent: 'center',
        outlineColor: '#6FBE47',
        boxShadow: ' 1px #6FBE47',
    },

    loginContainer: {
        width: '30%',
        position: 'absolute',
        textAlign: 'center',
        marginTop: '10%',
        marginLeft: '30%'
    }
}

export default customStyle;