import React, { Component } from "react";
import PropTypes from "prop-types";

export class InputAutocomplete extends Component {
    onChange() {
        var val = document.getElementById("autocompleteInput"+this.props.placeholder).value;
        var opts = document.getElementById('autocompleteList'+this.props.placeholder).childNodes;
        for (var i = 0; i < opts.length; i++) {
            if (opts[i].value === val) {
                this.props.onChange(opts[i].getAttribute('data-value'));
                break;
            }
        }
    }

    render() {

        let inputClass = 'form-control';
        if (this.props.validFeedback !== '') inputClass += ' is-valid';
        if (this.props.invalidFeedback !== '') inputClass += ' is-invalid';
        return (
            <div className="form-group">
                {this.props.label !== "" && (
                    <label className="form-label" style={this.props.labelStyle}>{this.props.label}</label>
                )}
                <input id={"autocompleteInput"+this.props.placeholder} list={"autocompleteList"+this.props.placeholder}
                    style={this.props.style}
                    className={inputClass}
                    onChange={(event) => { this.onChange() }}
                    defaultValue={this.props.selected} />
                <datalist id={"autocompleteList"+this.props.placeholder}>
                    {this.props.placeholder &&
                        <option value='' >{this.props.placeholder}</option>
                    }
                    {this.props.options.map((item, index) => {
                        return (
                            <option key={`${item.value}-${index}`} data-value={item.value ? item.value : item.title} >{item.title}</option>
                        )
                    })}
                </datalist>
                {/* <select
            className={inputClass}
            onChange={(event) => this.onChange(event)}
            defaultValue={this.props.selected}
          >
          {this.props.placeholder && 
            <option value='' >{this.props.placeholder}</option>
          }
          {this.props.options.map((item,index) => {
            return (
              <option key={`${item.value}-${index}`} value={item.value ? item.value: item.title } >{item.title}</option>
            )
          })}
          </select> */}
                {this.props.invalidFeedback !== '' && <div class="invalid-feedback">{this.props.invalidFeedback}</div>}
                {this.props.validFeedback !== '' && <div class="valid-feedback">{this.props.validFeedback}</div>}
            </div>
        );
    }
}

InputAutocomplete.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    selected: PropTypes.string,
    labelStyle: PropTypes.object,
    style: PropTypes.object
};

InputAutocomplete.defaultProps = {
    label: '',
    placeholder: '',
    options: [],
    validFeedback: '',
    invalidFeedback: '',
    selected: '',
}

export default InputAutocomplete;
