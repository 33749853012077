import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import '../styles/clientDetailStyles.scss'; 
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { Table,Modal,Button,ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as clientActions from '../actions/index';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientConstants } from '../config/Constants';
import { Link } from "react-router-dom";
import CustomModal from '../../../components/Modals/Modal';
import * as uiActions from '../../../actions/uiActions';
import {Info} from '../components/view/Info';
import {Notes} from '../components/view/Notes';
import {Documents} from '../components/view/Documents';
import {Contracts} from '../components/view/Contracts';
import {History} from '../components/view/History';
import { Redirect } from 'react-router-dom'
import {
    faList,
    faTh,
    faClock,
    faBook,
    faAngleLeft,
    faAngleRight,
    faSpinner,
    faPhoneAlt,
    faEnvelope

} from "@fortawesome/free-solid-svg-icons";
class ClientView extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            "Company"       : "",
            "Location"      : "",
            "IndustryType"  : "",
            "Website"       : "",
            "Phone"         : "",
            "Fax"           : "",
            "Email"         : ""
        }
        this.openAddContact = this.openAddContact.bind(this);
        this.openEditClient = this.openEditClient.bind(this);
    }
    componentDidMount() {
      const ClientId = this.props.match.params.ItemId;
      this.props.getClientDetails(ClientId);
      this.props.getClientContacts(ClientId);
      
    }

    /* MANAGE CONTRACT NOTES : START */
    onAddNote = (note) => {
        let ClientId = this.props.match.params.ItemId;
        this.props.addClientNote(ClientId, note);
    }
    onEditNote = (noteId, note) => {
        let ClientId = this.props.match.params.ItemId;
        this.props.editClientNote(ClientId, noteId, note);
    }
    onDeleteNote = (noteId) => {
        let ClientId = this.props.match.params.ItemId;
        this.props.deleteClientNote(ClientId, noteId);
    }
    /* MANAGE CONTRACT NOTES : END */
    openAddContact = (contact) =>{
        let currentContact = contact;
        if(currentContact !== ""){
            var data= {"contact":currentContact,"contacts":this.props.clientList.clientContacts};
            this.props.getContactDetail(data);
        }
        else {
            this.props.clientList.contactDetail.ItemId = undefined;
        }
        this.props.openModal();
        this.props.setModalName('ContactAdd'); 
    }

    /* MANAGE CONTRACT DOCUMENTS : START */
    onDocumentAdd = (data) => {
        let ItemId = this.props.match.params.ItemId;
        this.props.addClientDocument(ItemId, data);
    }
    onDocumentEdit = (data) => {
        let ItemId = this.props.match.params.ItemId;
        this.props.editClientDocument(ItemId, data);
    }
    openEditClient = () => {
        console.log(1111);
        this.props.openModal();
        this.props.setModalName('ClientEdit'); 
    }
    onDocumentDelete = (data) => {
        let ItemId = this.props.match.params.ItemId;
        this.props.deleteClientDocument(ItemId, data);
    }
    onContractAdd = (data)  => {
        this.props.openModal();
        this.props.setModalName('AddContract'); 
    }
    render(){
        return(
            <React.Fragment>
            <TopNav title="Client Details" />
            <Nav />
            <div class="container client_detail_screen">
                <div class="row">  
                    <div className="col-md-9 col-lg-10 text-right detail_top_button">
                        <Link className="btn btn-success email">Email</Link>
                        <Link className="btn btn-success message">Message</Link>
                    </div> 
                    <div class="col-md-3 col-lg-2"> 
                        <ul class="editinglist"> 
                            <li> 
                                <a onClick={this.openEditClient} href="#" class="active"> 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"/><path d="M0 0h24v24H0z" fill="none"/></svg>
                                </a>
                            </li> 
                           
                        </ul>
                    </div> 
                </div>

                <Info client={this.props.clientList.clientDetails} openContact={this.openAddContact}   contacts={this.props.clientList.clientContacts} />
                <div class="report_issues client_details_block">
                    <Notes 
                    client={this.props.clientList.clientDetails} 
                    onAddNote={this.onAddNote} 
                    onEditNote={this.onEditNote}
                    onDeleteNote={this.onDeleteNote}/>
                    <div id="accordion" class="accordion_block">
                        <Documents 
                        client={this.props.clientList.clientDetails} 
                        onDocumentAdd={this.onDocumentAdd}
                        onDocumentEdit={this.onDocumentEdit}
                        onDocumentDelete={this.onDocumentDelete}/>
                        <Contracts 
                        client={this.props.clientList.clientDetails} onAddContract={this.onContractAdd} />
                        <History client={this.props.clientList.clientDetails} />
                    </div>
                    {this.props.clientList.isLoading && 
                        <div className="loader"><FontAwesomeIcon icon={faSpinner} spin/></div>
                    }
                </div>
            </div>
           
           
            <CustomModal/>
            </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        myState: state,
        clientList: state.clientList,
        //modalState: state.uiState.modalOpen,
        //modalName: state.uiState.modalName,
    } 
}

const mapDispatchToProps = (dispatch) => {
    
    return {
       getClientDetails: (data) => dispatch(clientActions.getClientDetails(data)),
       getClientContacts: (data) => dispatch(clientActions.getClientContacts(data)),
       getContactDetail: (data) => dispatch(clientActions.getContactDetail(data)),
       openModal: () => dispatch(uiActions.openModal()),
       setModalName: (data) => dispatch(uiActions.setModalName(data)),

       addClientNote: (ItemId, addNote) => dispatch(clientActions.addClientNote(ItemId, addNote)),
       editClientNote: (ItemId, editNoteId, editNote) => dispatch(clientActions.editClientNote(ItemId, editNoteId, editNote)),
       deleteClientNote: (ItemId, deleteNote) => dispatch(clientActions.deleteClientNote(ItemId, deleteNote)), 

       addClientDocument: (ItemId, data) => dispatch(clientActions.addClientDocument(ItemId, data)), 
        editClientDocument: (ItemId, data) => dispatch(clientActions.editClientDocument(ItemId, data)), 
        deleteClientDocument: (ItemId, data) => dispatch(clientActions.deleteClientDocument(ItemId, data)), 
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientView);