import { Storage } from "aws-amplify";
import moment from "moment";

export const getStorageFile = async (key, redirect = false) => {
  const url = await Storage.get(
    key, {
      level: 'public', 
      expires: 60
    }
  );
  if (redirect === true && url !== '') {
    window.open(url, "_blank");
  }
  return url;
}

export const getDateDifference = (start, end, formatAs = 'auto') => {
  const startDate = moment(start, "YYYY-MM-DD");
  const endDate = moment(end, "YYYY-MM-DD");
  switch (formatAs) {
    case 'hours': 
      return moment.duration(startDate.diff(endDate)).asHours();
    case 'days': 
      return moment.duration(startDate.diff(endDate)).asDays();
    case 'weeks':
      return moment.duration(startDate.diff(endDate)).asWeeks();
    case 'months':
        return moment.duration(startDate.diff(endDate)).asMonths();
    case 'auto':
    default: {
      let humanizeString = moment.duration(endDate.diff(startDate)).humanize();
      return humanizeString.charAt(0).toUpperCase() + humanizeString.slice(1);
    }
  }
}