import React, { Component } from 'react';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { Link } from "react-router-dom";
import Input from "../../../components/ui/form/Input";
import InputDropdown from "../../../components/ui/form/InputDropdown";
import InputAutocomplete from "../../../components/ui/form/InputAutocomplete";
import InputDateOpportunity from "../../../components/ui/form/InputDateOpportunity";
import { API } from 'aws-amplify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

class CoursesAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {},
            courseData: {
                SendPush: false
            },
            InstitutionsDropdown: []
        }
    }

    componentDidMount() {
        API.get('1', `/institutions/dropdown`,
        ).then(responseJson => {
            let parsedData = [];
            responseJson.Output.map(obj => {
                parsedData.push({ title: `${obj.Name}`, value: obj.Name })
            })
            this.setState({ InstitutionsDropdown: parsedData });
        });
    }

    onSubmit() {
        API.post('1', '/courses', { body: this.state.courseData }).then(responseJson => {
            console.log(responseJson);
            if (responseJson.Message === "Success") {
                this.props.history.push('/courses');
            }
            else {
                alert("There was an error adding this course");
            }
        });
    }

    render() {
        return (<React.Fragment>
            <TopNav title="Add Course" />
            <Nav />
            <section className="lighgray">
                <div className="container">
                    <div class="container add_opportunity_container">
                        <div className="add_opportunity">
                            <div style={{ height: 30 }} />
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input
                                                        label="Course Title"
                                                        placeholder="Course Title"
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, Title: value } })}
                                                        validFeedback=""
                                                        invalidFeedback={this.state.formErrors.Title}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row test">
                                                <div className="col-md">
                                                    <InputDropdown
                                                        label="Category"
                                                        placeholder="Select Category"
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, Category: value, Track: '' } })}
                                                        options={[{ title: "Computer Science", value: "Computer Science" }, { title: "Data Analysis", value: "Data Analysis" }, { title: "Design", value: "Design" }, { title: "Digital Transformation", value: "Digital Transformation" }, { title: "IT & Cloud", value: "IT & Cloud" }, { title: "Marketing", value: "Marketing" }]}
                                                        invalidFeedback={this.state.formErrors.Category}
                                                    />
                                                </div>
                                                <div className="col-md">
                                                    <InputDropdown
                                                        label="Track"
                                                        placeholder="Select Track"
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, Track: value } })}
                                                        options={this.state.courseData.Category === 'Computer Science' ? [{ title: "Web", value: "Web" }, { title: "General", value: "General" }, { title: "Databases", value: "Databases" }, { title: "Mobile Apps", value: "Mobile Apps" }, { title: "Game Development", value: "Game Development" }, { title: "Wearable", value: "Wearable" }] : this.state.courseData.Category === 'Data Analysis' ? [{ title: "AI", value: "AI" }, { title: "Data Science", value: "Data Science" }, { title: "Machine Learning", value: "Machine Learning" }] : this.state.courseData.Category === 'Design' ? [{ title: "Branding", value: "Branding" }, { title: "User Experience", value: "User Experience" }, { title: "Design Thinking", value: "Design Thinking" }] : this.state.courseData.Category === 'IT & Cloud' ? [{ title: "Cybersecurity", value: "Cybersecurity" }, { title: "Systems", value: "Systems" }, { title: "Architecture", value: "Architecture" }] : this.state.courseData.Category === 'Marketing' ? [{ title: "Automation", value: "Automation" }, { title: "Analytics", value: "Analytics" }, { title: "Strategy", value: "Strategy" }, { title: "Social Media", value: "Social Media" }, { title: "Software", value: "Software" }, { title: "Software", value: "Software" }, { title: "Email", value: "Email" }, { title: "Content", value: "Content" }] : []}
                                                        invalidFeedback={this.state.formErrors.Track}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row test">
                                                <div className="col-md">
                                                    <InputAutocomplete
                                                        label="Institution"
                                                        placeholder="Institution"
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, Institution: value } })}
                                                        invalidFeedback={this.state.formErrors.Institution}
                                                        options={this.state.InstitutionsDropdown}
                                                    />
                                                </div>
                                                <div className="col-md">
                                                    <Input
                                                        label="Length"
                                                        placeholder="Length"
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, Length: value } })}
                                                        invalidFeedback={this.state.formErrors.Length}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input
                                                        label="Link"
                                                        placeholder="Link"
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, Link: value } })}
                                                        validFeedback=""
                                                        invalidFeedback={this.state.formErrors.Link}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <ReactQuill
                                                        style={{ height: 250, marginBottom: 50, paddingBottom: 43, backgroundColor: '#ffffff' }}
                                                        modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['link'], ['clean']] }}
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, Description: value } })}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label className="form-label">Hours Per Week</label>
                                                    <Input
                                                        placeholder="Hours Per Week"
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, HoursPerWeek: value } })}
                                                        validFeedback=""
                                                        invalidFeedback={this.state.formErrors.HoursPerWeek}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label className="form-label">Cost</label>
                                                    <Input
                                                        placeholder="Cost"
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, Cost: value } })}
                                                        validFeedback=""
                                                        invalidFeedback={this.state.formErrors.Cost}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label className="form-label">Verified Cost</label>
                                                    <Input
                                                        placeholder="Verified Cost"
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, VerifiedCost: value } })}
                                                        validFeedback=""
                                                        invalidFeedback={this.state.formErrors.VerifiedCost}
                                                    />
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <label className="form-label">Start Date</label>
                                                    <InputDateOpportunity
                                                        onChange={value => this.setState({ courseData: { ...this.state.courseData, StartDate: value } })}
                                                        invalidFeedback={this.state.formErrors.StartDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <ul className="buttonBlock">
                                                <li>
                                                    <a href="#" className="greenBtn" onClick={() => { this.onSubmit() }}>Save</a>
                                                </li>
                                                <li>
                                                    <Link to="/courses" className="greenBtn">Cancel</Link>
                                                </li>
                                                <ul className="checkoptionBox" style={{ marginTop: 10 }}>
                                                    <li>
                                                        <label className="container1">
                                                            <input type="checkbox" onChange={() => { this.setState({ courseData: { ...this.state.courseData, SendPush: !this.state.courseData.SendPush } }); }} data-status="Open" />
                                                            <span className="checkmark"></span> Send Push Notification</label>
                                                    </li>
                                                </ul>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
        )
    }
}

export default CoursesAdd;