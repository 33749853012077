import React, { Component } from "react";
import PropTypes from "prop-types";

import { Modal, Button } from "react-bootstrap";

export class AppModal extends Component {
  render() {
    return (
      <Modal
        size="lg"
        className={`modal ${this.props.class}`}
        show={this.props.isVisible}
        onHide={() => this.props.onClose()}
        centered={this.props.centered}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            {this.props.children}
          </div>
        </Modal.Body>
        {this.props.showButtons && 
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.props.onClose()}>
              {this.props.cancelTitle}
            </Button>
            <Button onClick={this.props.onSubmit} variant="primary">
              {this.props.buttonTitle}
            </Button>
          </Modal.Footer>
        }
      </Modal>
    );
  }
}

AppModal.propTypes = {
  isVisible: PropTypes.bool,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  class: PropTypes.string,
  buttonTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  showButtons: PropTypes.bool,
  centered: PropTypes.bool,
};

AppModal.defaultProps = {
  isVisible: false,
  title: "",
  onSubmit: () => {},
  onClose: () => {},
  class: "",
  buttonTitle: 'Submit',
  cancelTitle: 'Cancel',
  showButtons: true,
  centered: false,
};

export default AppModal;
