import React, { Component } from "react";
import PropTypes from "prop-types";
import { getStorageFile } from '../../../../helperFunctions/storageUtils';
import ContactImg from "./ContactImg";
export class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "profilePics": "",
        }
    }
    static propTypes = {
        contacts: PropTypes.object
    };
    componentDidMount() {
    }
    setPic() {
        if (this.props.contacts.length > 0) {
            for (var i = 0; i < this.props.contacts.length; i++) {
                if (typeof this.props.contacts[i].ProfilePicture !== "undefined" || typeof this.props.contacts[i].ProfilePicture !== "") {
                    var currentProfilePic = this.props.contacts[i].ProfilePicture;
                    getStorageFile(currentProfilePic).then((url, currentProfilePic) => {
                        if (url && url !== '') {
                            var profilepics = this.state.profilePics;
                            //profilepics[i] = url;
                            // this.setState({profilePics:profilepics});
                        }
                    });
                }
            }
        }
    }
    openContact = (event) => {
        var contactid = event.currentTarget.getAttribute('data-client');
        this.props.openContact(contactid);
    }
    render() {
        const { contacts } = this.props;
        console.log(contacts);
        return (
            <div class="row">
                {(contacts.length > 0) &&
                    this.setPic()
                }
                {contacts.map((contact, index) => {
                    let isPrimary = "";
                    if (contact.PrimaryContact === "true") {
                        isPrimary = "primary";
                    }
                    return (
                        <div className={"profile-block " + isPrimary} data-client={contact.ItemId} onClick={this.openContact} >
                            <div class="userprofil"><ContactImg contact={contact} /></div>
                            <h4>{contact.FirstName} {contact.LastName} </h4>
                        </div>
                    )
                })}
                <div class="profile-block" data-client="" onClick={() => { this.props.openContact('') }}>
                    <a class="userprofil addbtn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" /><path d="M0 0h24v24H0z" fill="none" /></svg>
                    </a>
                    <h4 class="greentxt">Add New</h4>
                </div>
            </div>
        );
    }
}

export default Contacts;