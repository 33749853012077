import React, { Component } from "react";

import { Link } from "react-router-dom";

import Input from "../../../components/ui/form/Input";
import InputDropdown from "../../../components/ui/form/InputDropdown";
import InputDate from "../../../components/ui/form/InputDate";
import InputDateOpportunity from "../../../components/ui/form/InputDateOpportunity";
import InputTextArea from "../../../components/ui/form/InputTextArea";

import { availableStatus } from "../config/Constants";
import { availableType } from "../config/Constants";
import { availableLocation } from "../config/Constants";
import ReactTags from 'react-tag-autocomplete';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock, faMapMarkerAlt, faMoneyBillAlt, faHourglassStart } from "@fortawesome/free-solid-svg-icons";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class addOpportunityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalEnabled: false,
      skills: [],
      responsibilities: [],
      requirements: [],
      SendPush: false
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleAddSkill = this.handleAddSkill.bind(this);
    this.handleRemoveSkill = this.handleRemoveSkill.bind(this);
    this.handleAddResponsibilities = this.handleAddResponsibilities.bind(this);
    this.handleRemoveResponsibilities = this.handleRemoveResponsibilities.bind(this);
    this.handleAddRequirements = this.handleAddRequirements.bind(this);
    this.handleRemoveRequirements = this.handleRemoveRequirements.bind(this);
  }
  onChange(key, value) {
    this.props.onInput(key, value);
  }
  handleAddSkill = (tag) => {
    const skills = [].concat(this.state.skills, tag.name);
    this.setState({ skills });
  }

  handleRemoveSkill = (i) => {
    const skills = this.state.skills.slice(0);
    skills.splice(i, 1);
    this.setState({ skills });
  }
  handleAddResponsibilities = (tag) => {
    const responsibilities = [].concat(this.state.responsibilities, tag.name);
    this.setState({ responsibilities });
  }

  handleRemoveResponsibilities = (i) => {
    const responsibilities = this.state.responsibilities.slice(0);
    responsibilities.splice(i, 1);
    this.setState({ responsibilities });
  }
  handleAddRequirements = (tag) => {
    const requirements = [].concat(this.state.requirements, tag.name);
    this.setState({ requirements });
  }

  handleRemoveRequirements = (i) => {
    const requirements = this.state.requirements.slice(0);
    requirements.splice(i, 1);
    this.setState({ requirements });
  }

  onSubmit() {
    this.props.onInput("Specializations", this.state.skills);
    this.props.onInput("Responsibilities", this.state.responsibilities);
    this.props.onInput("Requirements", this.state.requirements);
    this.props.onSubmit();
  }

  render() {
    const { formErrors } = this.props;
    const skills = [];
    const responsibilities = [];
    const requirements = [];
    this.state.skills.map((item, index) => {
      skills.push({
        id: index,
        name: item,
      });
      return null;
    });
    this.state.responsibilities.map((item, index) => {
      responsibilities.push({
        id: index,
        name: item,
      });
      return null;
    });
    this.state.requirements.map((item, index) => {
      requirements.push({
        id: index,
        name: item,
      });
      return null;
    });
    return (
      <div className="add_opportunity">
        <div style={{ height: 30 }} />
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <Input
                      label="Job Title"
                      placeholder="Job Title"
                      onChange={value => this.onChange("Title", value)}
                      validFeedback=""
                      invalidFeedback={formErrors.Title}
                    />
                  </div>
                </div>
                <div className="row test">
                  <div className="col-md">
                    <InputDropdown
                      label="Type"
                      placeholder="Select Type"
                      onChange={value => this.onChange("Type", value)}
                      options={availableType}
                      invalidFeedback={formErrors.Type}
                    />
                  </div>
                  <div className="col-md">
                    <InputDropdown
                      label="Status"
                      placeholder="Select Status"
                      onChange={value => this.onChange("Status", value)}
                      options={availableStatus}
                      invalidFeedback={formErrors.Status}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <label class="form-label">Detail</label>
                    <ReactQuill
                      style={{ height: 200, marginBottom: 20, paddingBottom: 42, backgroundColor: '#ffffff' }}
                      modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['link'], ['clean']] }}
                      onChange={value => this.onChange("Description", value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label">Skills/Specializations</label>
                    <ReactTags
                      tags={skills}
                      className="form-control"
                      allowNew={true}
                      handleDelete={this.handleRemoveSkill}
                      handleAddition={this.handleAddSkill}
                      placeholder='Skills/Specialization'
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label">Responsibilities</label>
                    <ReactTags
                      tags={responsibilities}
                      className="form-control"
                      allowNew={true}
                      handleDelete={this.handleRemoveResponsibilities}
                      handleAddition={this.handleAddResponsibilities}
                      placeholder='Responsibilities'
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label">Requirements</label>

                    <ReactTags
                      tags={requirements}
                      className="form-control"
                      allowNew={true}
                      handleDelete={this.handleRemoveRequirements}
                      handleAddition={this.handleAddRequirements}
                      placeholder='Requirements'
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faMapMarkerAlt} /> Location</label>
                    <InputDropdown
                      placeholder="Select Location"
                      onChange={value => this.onChange("Location", value)}
                      options={availableLocation}
                      invalidFeedback={formErrors.Location}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faMoneyBillAlt} /> Compensation</label>
                    <Input
                      onChange={value => this.onChange("Compensation", value)}
                      invalidFeedback={formErrors.Compensation}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faClock} /> Term</label>
                    <InputDropdown
                      onChange={value => this.onChange("HoursType", value)}
                      options={[{ title: 'Hours per week', value: 'Hours per week' }, { title: 'Hours per month', value: 'Hours per month' }, { title: 'Fixed delvierable', value: 'Fixed delvierable' }]}
                    />
                    <Input
                      onChange={value => this.onChange("Hours", value)}
                      validFeedback=""
                      invalidFeedback={formErrors.Hours}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faHourglassStart} /> Start Date</label>
                    <InputDateOpportunity
                      onChange={value => this.onChange("StartDate", value)}
                      invalidFeedback={formErrors.StartDate}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faMoneyBillAlt} /> Length</label>
                    <Input
                      onChange={value => this.onChange("Length", value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul className="buttonBlock">
                  <li>
                    <a href="#" className="greenBtn" onClick={this.onSubmit}>
                      Save
                    </a>
                  </li>
                  <li>
                    <Link to="/opportunities" className="greenBtn">Cancel</Link>
                  </li>
                  <ul className="checkoptionBox" style={{ marginTop: 10 }}>
                    <li>
                      <label className="container1">
                        <input type="checkbox" onChange={() => { this.onChange("SendPush", !this.state.SendPush); this.setState({ SendPush: !this.state.SendPush }); }} data-status="Open" />
                        <span className="checkmark"></span> Send Push Notification</label>
                    </li>
                    <li>
                      <label className="container1">
                        <input type="checkbox" onChange={() => { this.onChange("SendEmail", !this.state.SendEmail); this.setState({ SendEmail: !this.state.SendEmail }); }} data-status="Open" />
                        <span className="checkmark"></span> Send Email Notification</label>
                    </li>
                    <li>
                      <label className="container1">
                        <input type="checkbox" onChange={() => { this.onChange("SendMatch", !this.state.SendMatch); this.setState({ SendMatch: !this.state.SendMatch }); }} data-status="Open" />
                        <span className="checkmark"></span> Only Send To People With Matching Specializations</label>
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default addOpportunityForm;
