const CreatePDF = (opportunity) => {
  let responsibilitiesResult = ``;
  if (opportunity.Responsibilities)
    opportunity.Responsibilities.map((item) => (responsibilitiesResult += `<li>${item}</li>`));

  let requirementsResults = ``;
  if (opportunity.Requirements)
    opportunity.Requirements.map((item) => (requirementsResults += `<li>${item}</li>`));

  let specializationsResults = ``;
  if (opportunity.Specializations)
    opportunity.Specializations.map(
      (item) =>
        (specializationsResults += `<li style="    border: 1px solid #333;
  padding: 2px 16px;
  color: #333;
  border-radius: 8px;
  margin-right:10px;">${item}</li>`)
    );
  let link = "./envelope-solid.png";
  var yourDOCTYPE = "<!DOCTYPE html>";
  var printPreview = window.open("", "print_preview");
  var printDocument = printPreview.document;
  printDocument.open();
  var head = `<head>
  <link href="https://fonts.googleapis.com/css2?family=Lato&display=swap" rel="stylesheet" type='text/css'>
            <style> 
            .to-print{height:279mm; width:80mm; } 
            body {
                font-family: 'Lato', Arial
                background: url(${link});
            }
            </style></head>`;
  printDocument.write(
    yourDOCTYPE +
      "<html>" +
      head +
      `
<body>
      <h1 style="color: #69ce36; 
      font-weight: bold;">${opportunity.Title}</h1>
      <div class='to-print' style='width:100vw'>
      ${opportunity.Description}
      <h2>Skills/Specializations</h2>
      <ul style="margin: 25px 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;">${specializationsResults}</ul>
      <h2>Responsibilities</h2>
        <ul>${responsibilitiesResult}</ul>
    <h2>Requirements</h2>
        <ul>${requirementsResults}</ul>
        <div style="display:flex";>
        <div style="margin-right:100px;"><div style="font-weight:bold">Location:</div> ${opportunity.Location}</div>
        <div style="margin-right:100px;"><div style="font-weight:bold">Compensation:</div> ${opportunity.Compensation}</div>
        <div style="margin-right:100px;"><div style="font-weight:bold">Hours/Month:</div> ${opportunity.Hours}</div>
        <div><div style="font-weight:bold"> Position: </div><div>${opportunity.Length}</div></div>
        </div>
      ` +
      "</div>" +
      "</body>" +
      "</html>"
  );
  printPreview.print();
  printPreview.close();
};

export default CreatePDF;
