import React, { Component } from "react";
import AccordionCard from '../../../../components/ui/AccordionCard';
import { Modal } from "react-bootstrap";
import Input from "../../../../components/ui/form/Input";
import InputDate from "../../../../components/ui/form/InputDate";
import InputTextArea from "../../../../components/ui/form/InputTextArea";
import Moment from 'react-moment';
import Empty from '../../../../components/ui/Empty';

export class Education extends Component {

  constructor () {
    super();
    this.state = {
      modalEnabled: false,
      isEdit: false,
      educationDetail: {
        End: '',
        Role: '',
        Start: '',
        Detail: '',
        Company: '',
      },
      educationIndex: 0,
      formErrors: {
        End: '',
        Role: '',
        Start: '',
        Detail: '',
        Company: '',
      }
    }
  }

  getEducations = () => {
    const { member } = this.props;
    return (typeof member.Meta !== 'undefined' && typeof member.Meta.Education !== 'undefined' && member.Meta.Education !== '') ? member.Meta.Education: [];
  }
  
  handleModalClose = () => {
    this.setState({
      modalEnabled: false,
      isEdit: false,
      educationDetail: {
        School: '',
        Degree: '',
        End: '',
        Detail: '',
      },
      educationIndex: 0,
      formErrors: {
        School: '',
        Degree: '',
        End: '',
        Detail: '',
      }
    })
  }

  getModalTitle = () => {
    return 'Add Education';
  }

  onChange = (key, value) => {
    this.setState({
      educationDetail: {
        ...this.state.educationDetail,
        [key]: value
      }
    });
  }

  onSubmit = () => {
    let educations = [];
    if(!this.state.isEdit) {
      educations = [].concat(this.getEducations(), this.state.educationDetail);
    } else {
      educations = this.getEducations();
      educations[this.state.educationIndex] = this.state.educationDetail;
    }
    this.props.onSubmit(educations).then(resposne => {
      this.handleModalClose();
    });
  }

  onEditEducation = (item, index) => {
    this.setState({
      isEdit: true,
      educationDetail: item,
      educationIndex: index,
      modalEnabled: true,
    })
  }
  
  onDeleteEducation = (index) => {
    if (window.confirm('Are you sure you wish to delete this Education?')) {
      let educations = this.getEducations();
      educations.splice(index, 1);
      this.props.onSubmit(educations);
    }
  }

  render() {
    const Education = this.getEducations();

    return (
      <div>
        <AccordionCard title="Education" actionhtml={
          <span className="add-sign" onClick={() => {
            this.setState({modalEnabled: true});
          }} >+</span>
        } >
          {Education.length > 0 ? (
            <div className="Education__list">
              {Education.map((item, index) => {
                return (
                  <div className="Education__list__item" key={`profile-education-${index}`} >
                    <span className="Education__list__item__year">
                      <Moment format="MM/DD/YYYY">{item.End}</Moment>
                      <div className="Education__list__item__actions">
                        <a onClick={() => this.onEditEducation(item, index)} >Edit</a>
                        <a onClick={() => this.onDeleteEducation(index)}>Delete</a>
                      </div>
                    </span> | 
                    <div className="Education__list__item__school">
                      {(item.Degree !== '' ? `${item.Degree} at ` : '-')}
                      {(item.School !== '' ? item.School : '-')}
                      <p>{item.Detail}</p>
                    </div>
                  </div>
                )
              })}
            </div>
          ) : (
            <Empty message="No education found" />
          )}
        </AccordionCard>

        <Modal animation={false} size="lg" className="detailed_time_sheet_modal" show={this.state.modalEnabled} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>{this.getModalTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              
              <Input
                label="School"
                placeholder="Enter School"
                onChange={value => this.onChange("School", value)}
                value={this.state.educationDetail.School}
                invalidFeedback={''}
              />
              <Input
                label="Degree"
                placeholder="Enter Degree"
                onChange={value => this.onChange("Degree", value)}
                value={this.state.educationDetail.Degree}
                invalidFeedback={''}
              />
              <InputDate
                label="Start Date"
                placeholder="Select Start date"
                onChange={value => this.onChange("Start", value)}
                value={this.state.educationDetail.Start}
                invalidFeedback={''}
              />
              <InputDate
                label="End Date"
                placeholder="Select End date"
                onChange={value => this.onChange("End", value)}
                value={this.state.educationDetail.End}
                invalidFeedback={''}
              />
              <InputTextArea
                label="Details"
                placeholder="Enter Details"
                onChange={value => this.onChange("Detail", value)}
                value={this.state.educationDetail.Detail}
                invalidFeedback={''}
                rows="3"
              />
              <a className="greenBtn modalButton" onClick={() => this.onSubmit()} >
                Save
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Education;
