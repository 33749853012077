import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { API } from "aws-amplify";

export class OnboardingForm extends Component {
  render() {
    return (
    <Modal animation={false} size='lg' show={this.props.onboardingModal} centered>
        <Modal.Header
          closeButton
          onHide={() =>
            this.props.updateState({
              onboardingModal: false,
            })
          }>
          <Modal.Title>Onboarding</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className='table mt-4 time_sheet_table'>
            <thead>
              <tr>
                <th style={{ width: "40%", textAlign: "left" }}>Description</th>
                <th style={{ width: "20%" }}>Value</th>
                <th style={{ width: "40%" }}>Link</th>
              </tr>
            </thead>
            <tbody>
              <tr> {console.log(this.props.onboardingData)}
                <td style={{ textAlign: "left" }}>Driver's License uploaded</td>
                <td>
                  <select
                    style={{ padding: 10 }}
                    value={this.props.onboardingData.DriversLicense}
                    onChange={(event) => {
                      this.props.updateState({
                        onboardingData: {
                          ...this.props.onboardingData,
                          DriversLicense: event.currentTarget.value,
                        },
                      });
                    }}>
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>3 references</td>
                <td>
                <select
                    style={{ padding: 10 }}
                    value={this.props.onboardingData.ThreeReferences}
                    onChange={(event) => {
                      this.props.updateState({
                        onboardingData: {
                          ...this.props.onboardingData,
                          ThreeReferences: event.currentTarget.value,
                        },
                      });
                    }}>
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Agree to a background check</td>
                <td>
                <select
                    style={{ padding: 10 }}
                    value={this.props.onboardingData.BackgroundCheck}
                    onChange={(event) => {
                      this.props.updateState({
                        onboardingData: {
                          ...this.props.onboardingData,
                          BackgroundCheck: event.currentTarget.value,
                        },
                      });
                    }}>
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Provide Social Security Number</td>
                <td>
                  <input
                    style={{ padding: 10, width: "100%" }}
                    value={this.props.onboardingData.SSN}
                    onChange={(event) => {
                      this.props.updateState({
                        onboardingData: {
                          ...this.props.onboardingData,
                          SSN: event.currentTarget.value,
                        },
                      });
                    }}
                  />
                </td>
                <td></td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Sign NDA</td>
                <td>
                  <select
                    style={{ padding: 10 }}
                    value={this.props.onboardingData.SignNDA}
                    onChange={(event) => {
                      this.props.updateState({
                        onboardingData: {
                          ...this.props.onboardingData,
                          SignNDA: event.currentTarget.value,
                        },
                      });
                    }}>
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </td>
                <td>
                  <input
                    style={{ padding: 10, width: "100%" }}
                    value={this.props.onboardingData.NDALink}
                    onChange={(event) => {
                      this.props.updateState({
                        onboardingData: {
                          ...this.props.onboardingData,
                          NDALink: "event.currentTarget.value",
                        },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Sign Contractor Services Agreement</td>
                <td>
                  <select
                    style={{ padding: 10 }}
                    value={this.props.onboardingData.SignCSA}
                    onChange={(event) => {
                      this.props.updateState({
                        onboardingData: {
                          ...this.props.onboardingData,
                          SignCSA: event.currentTarget.value,
                        },
                      });
                    }}>
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </td>
                <td>
                  <input
                    style={{ padding: 10, width: "100%" }}
                    value={this.props.onboardingData.CSALink}
                    onChange={(event) => {
                      this.props.updateState({
                        onboardingData: {
                          ...this.props.onboardingData,
                          CSALink: event.currentTarget.value,
                        },
                      });
                    }}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ textAlign: "left" }}>Enroll in Payroll</td>
                <td>
                  <select
                    style={{ padding: 10 }}
                    value={this.props.onboardingData.Enroll}
                    onChange={(event) => {
                      this.props.updateState({
                        onboardingData: {
                          ...this.props.onboardingData,
                          Enroll: event.currentTarget.value,
                        },
                      });
                    }}>
                    <option value={0}>No</option>
                    <option value={1}>Yes</option>
                  </select>
                </td>
                <td>
                  <input
                    style={{ padding: 10, width: "100%" }}
                    value={this.props.onboardingData.EnrollLink}
                    onChange={(event) => {
                      this.props.updateState({
                        onboardingData: {
                          ...this.props.onboardingData,
                          EnrollLink: event.currentTarget.value,
                        },
                      });
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant='secondary'
            onClick={() => {
              this.props.updateState({ onboardingModal: false, onboardingData: {} });
            }}>
            Close
          </Button>
          <Button
            variant='primary'
            onClick={async () => {
              delete this.props.onboardingData.ThreeReferences;
              delete this.props.onboardingData.DriversLicense;
              let responseMembers = await API.put("1", `/members`, {
                body: this.props.onboardingData,
              });
              console.log(responseMembers, "grewgews");
              this.props.updateState({
                onboardingModal: false,
                onboardingData: this.props.onboardingData,
              });
            }}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default OnboardingForm;
