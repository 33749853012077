import React, { Component } from "react";
import PropTypes from "prop-types";
import EmptyData from "./EmptyData";
import { ContractConstants } from "../../config/Constants";
import AccordionCard from "../../../../components/ui/AccordionCard";
import * as storageUtils from "../../../../helperFunctions/storageUtils";
import Empty from "../../../../components/ui/Empty";
import { Modal, Button } from "react-bootstrap";
import InputTextArea from "../../../../components/ui/form/InputTextArea";
import InputFile from "../../../../components/ui/form/InputFile";
import { Link } from "react-router-dom";

import Input from "../../../../components/ui/form/Input";
import InputDropdown from "../../../../components/ui/form/InputDropdown";
import InputAutocomplete from "../../../../components/ui/form/InputAutocomplete";
import InputDate from "../../../../components/ui/form/InputDate";
import InputHours from "../../../../components/ui/form/InputHours";

import Moment from "react-moment";
import moment from "moment";

import { API, Storage } from "aws-amplify";
import listOfStates from "../../../../helperFunctions/listOfStates";

export class JobOrders extends Component {
  static propTypes = { workers: PropTypes.array };

  constructor() {
    super();
    this.state = {
      modalEnabled: false,
      ItemId: 0,
      file: {},
      error: "",
      AddJobOrder: {},
      formErrors: {},
      ViewArchived: false,
      onboardingModal: false,
      onboardingData: {},
    };
    API.get("1", `/members/dropdown`).then((responseJson) => {
      let parsedData = [];
      responseJson.Output.map((obj) => {
        parsedData.push({
          title: `${obj.Name} ${obj.Email ? "(" + obj.Email + ")" : ""}`,
          value: obj.ItemId,
        });
      });
      this.setState({ MembersDropdown: parsedData });
    });

    API.get("1", `/locations/dropdown`).then((responseJson) => {
      let parsedData = [];
      responseJson.Output.map((obj) => {
        parsedData.push({ title: obj.Name, value: obj.ItemId });
      });
      this.setState({ LocationsDropdown: parsedData });
    });
  }

  showAddJobOrderModal = () => {
    this.setState({
      modalEnabled: true,
      AddJobOrder: {
        StartDate: moment(
          new Date(this.props.contract.StartDate).setDate(
            new Date(this.props.contract.StartDate).getDate() - 1
          )
        ).format("YYYY-MM-DD"),
        EndDate: moment(
          new Date(this.props.contract.EndDate).setDate(
            new Date(this.props.contract.EndDate).getDate() - 1
          )
        ).format("YYYY-MM-DD"),
        TimeApproval: "0",
        Remote: "Remote",
      },
    });
  };

  handleModalClose = () => {
    this.setState({
      modalEnabled: false,
      file: {},
      error: "",
      AddJobOrder: {},
      addLocation: false,
    });
  };

  onSubmit = () => {
    console.log("checking for errors");
    this.validateData().then((hasErrors) => {
      console.log(hasErrors);
      console.log("errors!");
      if (!hasErrors) {
        console.log("no errors");

        //manually add a location and then assign it to the job order if that's the case
        if (this.state.addLocation) {
          API.post("1", `/locations`, {
            body: {
              Address: this.state.Address,
              City: this.state.City,
              State: this.state.State,
              Zip: this.state.Zip,
            },
          }).then((responseJson) => {
            this.onInput("LocationId", responseJson.Output.ItemId);

            if (!this.state.AddJobOrder.JobOrderId) {
              this.props.onJobOrderAdd(this.state.AddJobOrder);
            } else {
              this.props.onJobOrderEdit(this.state.AddJobOrder);
            }

            this.handleModalClose();
          });
        } else {
          if (!this.state.AddJobOrder.JobOrderId) {
            this.props.onJobOrderAdd(this.state.AddJobOrder);
          } else {
            this.props.onJobOrderEdit(this.state.AddJobOrder);
          }
          this.handleModalClose();
        }
      }
    });
  };

  getModalTitle = () => {
    if (this.state.AddJobOrder.ItemId) {
      return "Edit Contract's Worker";
    } else {
      return "Add Worker to Contract";
    }
  };

  onFileUpload = (file) => {
    this.setState({
      file: file,
    });
    if (typeof this.state.file.FileID !== "undefined" && this.state.file.FileID !== "") {
      API.post("1", `/documents`, {
        body: {
          Name: this.state.file.FileInput.name,
          Key: this.state.file.FileID,
          Type: "Job Order",
          Folder: "JobOrder",
          JobOrderId: this.state.file.JobOrderId,
        },
      }).then((responseJson) => {
        return responseJson;
      });
    }
    this.handleModalClose();
  };

  setError = (key, message) => {
    let formErrors = this.state.formErrors;
    formErrors[key] = message;
    this.setState({
      formErrors: formErrors,
      isValid: false,
    });
  };

  clearFormErrors = async () => {
    const emptyErrors = {
      Worker: "",
    };
    await this.setState({
      formErrors: emptyErrors,
    });
  };

  validateData = () => {
    const data = this.state.AddJobOrder;
    return this.clearFormErrors().then(() => {
      let hasErrors = false;
      let errorMessages = [
        "Worker",
        "Start Date",
        "End Date",
        "Hours",
        "Title",
        "Rate",
        "Time Approval",
        "Remote",
        "Description",
      ];
      [
        "Worker",
        "StartDate",
        "EndDate",
        "Hours",
        "Title",
        "Rate",
        "TimeApproval",
        "Remote",
      ].forEach((obj, index) => {
        if (typeof data[obj] === "undefined" || data[obj] === "") {
          this.setError(obj, "Please provide the " + errorMessages[index]);
          hasErrors = true;
        }
      });
      return hasErrors;
    });
  };

  onInput = (key, value) => {
    this.state.AddJobOrder[key] = value;
  };

  changeWorker = (value) => {
    API.get("1", `/members?ItemId=${value}`).then((responseJson) => {
      if (responseJson.Output[0].ItemId !== null) {
        this.setState({
          AddJobOrder: {
            ...this.state.AddJobOrder,
            Worker: value,
            Rate: responseJson.Output[0].Meta.HourlyRate,
            Title: responseJson.Output[0].PrimaryTitle,
          },
        });
      } else {
        this.setState({
          AddJobOrder: { ...this.state.AddJobOrder, Worker: "", Rate: "", Title: "" },
        });
      }
    });
  };

  onEditPress = (item) => {
    API.get("1", `/joborders?ItemId=${item.JobOrderId}`).then((responseJson) => {
      this.setState({
        ItemId: this.state.ItemId,
        modalEnabled: true,
        AddJobOrder: {
          JobOrderId: responseJson.Output[0].ItemId,
          WorkerName: responseJson.Output[0].MemberName,
          Worker: responseJson.Output[0].MemberId,
          Title: responseJson.Output[0].Title,
          StartDate: responseJson.Output[0].StartDate,
          EndDate: responseJson.Output[0].EndDate,
          Hours: responseJson.Output[0].Hours,
          BillableRate: responseJson.Output[0].BillableRate,
          Rate: responseJson.Output[0].Rate,
          TimeApproval: responseJson.Output[0].TimeApproval,
          Remote: responseJson.Output[0].Remote,
          Description: responseJson.Output[0].Description,
          Documents: responseJson.Output[0].Documents,
          LocationId: responseJson.Output[0].LocationId,
          Type: responseJson.Output[0].Type,
          AutoSend: responseJson.Output[0].AutoSend,
        },
      });
    });
  };

  onDelete = (item) => {
    if (window.confirm("Are you sure you wish to archive this worker?")) {
      API.put("1", `/joborders?ItemId=${item.JobOrderId}&Archived=1`).then((responseJson) => {
        this.props.worker.filter((obj) => obj.JobOrderId === item.JobOrderId)[0].Archived = "1";
        this.setState({});
      });
    }
  };

  onRestore = (item) => {
    if (window.confirm("Are you sure you wish to restore this worker?")) {
      API.put("1", `/joborders?ItemId=${item.JobOrderId}&Archived=0`).then((responseJson) => {
        this.props.worker.filter((obj) => obj.JobOrderId === item.JobOrderId)[0].Archived = "0";
        this.setState({});
      });
    }
  };

  render() {
    const { worker } = this.props;
    const updateState = (obj) => {
      this.setState(obj);
    };
    return (
      <div>
        <AccordionCard
          title='Workers'
          actionhtml={
            <div>
              <div className='col-md-7' style={{ marginLeft: 120, marginBottom: -60 }}>
                <ul className='checkoptionBox'>
                  <li style={{ marginRight: -20, marginTop: -127 }}>
                    <label className='container1'>
                      <input
                        type='checkbox'
                        className='contract_status'
                        onChange={() => {
                          this.setState({ ViewArchived: !this.state.ViewArchived });
                        }}
                        data-status='ViewArchived'
                      />
                      <span className='checkmark' style={{ marginTop: -3 }}></span>View Archived
                    </label>
                  </li>
                </ul>
              </div>
              <span className='add-sign' onClick={this.addContract}>
                +
              </span>
              <span
                className='add-sign'
                onClick={() => {
                  this.showAddJobOrderModal();
                }}>
                +
              </span>
            </div>
          }>
          {typeof worker !== "undefined" && Array.isArray(worker) && worker.length > 0 ? (
            <table class='table mt-4 contractWorkers'>
              <thead>
                <tr>
                  <th>Worker Name</th>
                  <th>Title</th>
                  <th>Hours</th>
                  <th>Start Date</th>
                  <th>Term</th>
                  <th>Billable Rate</th>
                  <th>Worker Rate</th>
                  <th>Remote</th>
                  <th>Time Approval</th>
                  <th>Job Order</th>
                  <th>Auto Send</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {worker.map((item, index) => {
                  if (
                    (!this.state.ViewArchived && item.Archived === "1") ||
                    (this.state.ViewArchived && item.Archived === "0")
                  ) {
                    return null;
                  } else {
                    var hours = item.Hours.split(":");
                    return (
                      <tr key={`worker-${item.ItemId}-${index}`}>
                        <td>
                          <Link to={"/workers/" + item.ItemId}>{item.Name}</Link>
                        </td>
                        <td>{item.Title}</td>
                        <td>{hours[0]}</td>
                        <td>
                          <Moment format='MM/DD/YYYY'>{item.StartDate}</Moment>
                        </td>
                        <td>{storageUtils.getDateDifference(item.StartDate, item.EndDate)}</td>
                        <td>{item.BillableRate && "$" + item.BillableRate}</td>
                        <td>{item.Rate && "$" + item.Rate}</td>
                        <td>{item.Remote}</td>
                        <td>{item.Timesheet === "1" ? "✓" : ""}</td>
                        <td>
                          {item.Documents.length > 0 ? (
                            <a
                              href='javascript:void(0)'
                              onClick={() =>
                                storageUtils.getStorageFile(item.Documents[0].Key, true)
                              }>
                              {item.Documents[0].Name}
                            </a>
                          ) : (
                            <InputFile
                              label=''
                              onChange={(event) => {
                                event.JobOrderId = item.JobOrderId;
                                this.onFileUpload(event);
                              }}
                              invalidFeedback={this.state.error}
                            />
                          )}
                        </td>
                        <td>
                          <span
                            style={{ cursor: "pointer" }}
                            onClick={async () => {
                              //let responseJobOrders = await API.get('1', `/joborders?ItemId=${item.ItemId}`);
                              let responseDocuments = await API.get(
                                "1",
                                `/documents?Type=Drivers License&MemberId=${item.ItemId}`
                              );
                              let responseMembers = await API.get(
                                "1",
                                `/members?ItemId=${item.ItemId}`
                              );
                              console.log(responseMembers);
                              let ThreeReferences = false;
                              if (responseMembers.Output[0].Meta.References) {
                                if (responseMembers.Output[0].Meta.References.length >= 3) {
                                  ThreeReferences = true;
                                }
                              }
                              this.setState({
                                onboardingModal: true,
                                onboardingData: {
                                  ItemId: item.ItemId,
                                  DriversLicense:
                                    responseDocuments.Output.length > 0 ? true : false,
                                  ThreeReferences: ThreeReferences,
                                  BackgroundCheck: responseMembers.Output[0].BackgroundCheck,
                                  SSN: responseMembers.Output[0].SSN,
                                  SignNDA: responseMembers.Output[0].SignNDA,
                                  NDALink: "https://app.hellosign.com/s/Am8LEDKf",
                                  SignCSA: responseMembers.Output[0].SignCSA,
                                  CSALink: "https://app.hellosign.com/s/W2gPVOPJ",
                                  SignJobOrder: responseMembers.Output[0].SignJobOrder,
                                  JobOrderLink: responseMembers.Output[0].JobOrderLink,
                                  Enroll: responseMembers.Output[0].Enroll,
                                  EnrollLink: responseMembers.Output[0].EnrollLink,
                                },
                              });
                            }}></span>
                        </td>
                        <td>{item.AutoSend === "1" ? "Yes" : "No"}</td>
                        <td className='actionsCell'>
                          <div className='actionsWrapper'>
                            <span onClick={() => this.onEditPress(item)}>Edit</span>
                            {item.Archived === "1" ? (
                              <span onClick={() => this.onRestore(item)}>Restore</span>
                            ) : (
                              <span onClick={() => this.onDelete(item)}>Archive</span>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                })}
              </tbody>
            </table>
          ) : (
            <Empty message='No workers found' />
          )}
        </AccordionCard>

        <Modal
          animation={false}
          size='xl'
          className='detailed_time_sheet_modal'
          show={this.state.modalEnabled}
          onHide={() => this.handleModalClose()}
          animation={false}
          centered>
          <Modal.Header closeButton>
            <Modal.Title>{this.getModalTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='add_contact'>
              <div className='row'>
                <div className='col-md'>
                  <InputAutocomplete
                    label='Worker Name'
                    placeholder='Please select Worker'
                    selected={this.state.AddJobOrder.WorkerName}
                    onChange={(value) => this.changeWorker(value)}
                    options={this.state.MembersDropdown}
                    invalidFeedback={this.state.formErrors.Worker}
                  />
                </div>

                <div className='col-md'>
                  <Input
                    label='Title'
                    placeholder='Title'
                    value={this.state.AddJobOrder.Title}
                    onChange={(value) => this.onInput("Title", value)}
                    invalidFeedback={this.state.formErrors.Title}
                    listenToChangedValue={true}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md'>
                  <InputDate
                    label='Start Date'
                    value={this.state.AddJobOrder.StartDate}
                    onChange={(value) => this.onInput("StartDate", value)}
                    invalidFeedback={this.state.formErrors.StartDate}
                  />
                </div>
                <div className='col-md'>
                  <InputDate
                    label='End Date'
                    value={this.state.AddJobOrder.EndDate}
                    onChange={(value) => this.onInput("EndDate", value)}
                    invalidFeedback={this.state.formErrors.EndDate}
                  />
                </div>
                <div className='col-md'>
                  <InputDropdown
                    labelStyle={{ paddingLeft: 4, paddingRight: 4 }}
                    label='Type'
                    placeholder='Select Type'
                    selected={this.state.AddJobOrder.Type}
                    onChange={(value) => {
                      this.onInput("Type", value);
                      this.setState({ AddJobOrder: { ...this.state.AddJobOrder, Type: value } });
                    }}
                    options={[
                      { title: "Hours per month", value: "Hours per month" },
                      { title: "Fixed project", value: "Fixed project" },
                    ]}
                  />
                </div>
                <div className='col-md'>
                  <Input
                    label={
                      this.state.AddJobOrder.Type ? this.state.AddJobOrder.Type : "Hours per month"
                    }
                    placeholder='Hours'
                    value={this.state.AddJobOrder.Hours}
                    onChange={(value) => this.onInput("Hours", value)}
                    invalidFeedback={this.state.formErrors.Hours}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md'>
                  <Input
                    labelStyle={{ paddingLeft: 10, paddingRight: 10 }}
                    label='Billable Rate'
                    placeholder='Rate'
                    value={this.state.AddJobOrder.BillableRate}
                    onChange={(value) => this.onInput("BillableRate", value)}
                    invalidFeedback={this.state.formErrors.Rate}
                  />
                </div>
                <div className='col-md'>
                  <Input
                    labelStyle={{ paddingLeft: 10, paddingRight: 10 }}
                    label='Worker Rate'
                    placeholder='Rate'
                    value={this.state.AddJobOrder.Rate}
                    onChange={(value) => this.onInput("Rate", value)}
                    invalidFeedback={this.state.formErrors.Rate}
                    listenToChangedValue={true}
                  />
                </div>
                <div className='col-md'>
                  <InputDropdown
                    labelStyle={{ paddingLeft: 4, paddingRight: 4 }}
                    label='Time Approval'
                    placeholder='Choose'
                    selected={this.state.AddJobOrder.TimeApproval}
                    onChange={(value) => this.onInput("TimeApproval", value)}
                    options={[
                      { title: "Yes", value: "1" },
                      { title: "No", value: "0" },
                    ]}
                    invalidFeedback={this.state.formErrors.TimeApproval}
                  />
                </div>
                <div className='col-md'>
                  <InputDropdown
                    labelStyle={{ paddingLeft: 10, paddingRight: 10 }}
                    label='Remote'
                    placeholder='Choose'
                    selected={this.state.AddJobOrder.Remote}
                    onChange={(value) => {
                      this.onInput("Remote", value);
                      this.setState({ render: true });
                    }}
                    options={[
                      { title: "Remote", value: "Remote" },
                      { title: "On Site", value: "On Site" },
                      { title: "Remote/On Site", value: "Remote/On Site" },
                    ]}
                    invalidFeedback={this.state.formErrors.Remote}
                  />
                </div>
                <div className='col-md'>
                  <InputDropdown
                    labelStyle={{ paddingLeft: 10, paddingRight: 10 }}
                    label='Auto Send'
                    placeholder='Choose'
                    selected={this.state.AddJobOrder.AutoSend}
                    onChange={(value) => {
                      this.onInput("AutoSend", value);
                      this.setState({ render: true });
                    }}
                    options={[
                      { title: "No", value: "0" },
                      { title: "Yes", value: "1" },
                    ]}
                    invalidFeedback={this.state.formErrors.AutoSend}
                  />
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <InputTextArea
                    label='Role Description'
                    placeholder='Description'
                    value={this.state.AddJobOrder.Description}
                    onChange={(value) => this.onInput("Description", value)}
                    invalidFeedback={this.state.formErrors.Description}
                  />
                </div>
              </div>
              {["On Site", "Remote/On Site"].includes(this.state.AddJobOrder.Remote) ? (
                !this.state.addLocation ? (
                  <div className='row'>
                    <div className='col-md-9'>
                      <InputDropdown
                        label='Location'
                        placeholder='Choose'
                        selected={this.state.AddJobOrder.LocationId}
                        onChange={(value) => {
                          this.onInput("LocationId", value);
                          this.setState({ render: true });
                        }}
                        options={this.state.LocationsDropdown}
                        invalidFeedback={this.state.formErrors.LocationId}
                      />
                    </div>
                    <div
                      className='col-md-3'
                      style={{
                        marginTop: "auto",
                        marginBottom: 30,
                        textAlign: "center",
                        paddingRight: 30,
                      }}>
                      <a
                        href='javascript:void(0)'
                        onClick={() => {
                          this.setState({ addLocation: true });
                        }}>
                        Manually Add Location
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className='row'>
                    <div className='col-md-4'>
                      <Input
                        label='Address'
                        placeholder='Address'
                        value={this.state.Address}
                        onChange={(value) => this.setState({ Address: value })}
                        invalidFeedback={this.state.formErrors.Address}
                      />
                    </div>
                    <div className='col-md-2'>
                      <Input
                        label='City'
                        placeholder='City'
                        value={this.state.City}
                        onChange={(value) => this.setState({ City: value })}
                        invalidFeedback={this.state.formErrors.City}
                      />
                    </div>
                    <div className='col-md-2'>
                      <InputDropdown
                        label='State'
                        placeholder='Choose'
                        selected={this.state.State}
                        onChange={(value) => this.setState({ State: value })}
                        options={listOfStates()}
                        invalidFeedback={this.state.formErrors.State}
                      />
                    </div>
                    <div className='col-md-2'>
                      <Input
                        label='Zip Code'
                        placeholder='Zip Code'
                        value={this.state.Zip}
                        onChange={(value) => this.setState({ Zip: value })}
                        invalidFeedback={this.state.formErrors.Zip}
                      />
                    </div>
                    <div
                      className='col-md-2'
                      style={{
                        marginTop: "auto",
                        marginBottom: 20,
                        paddingLeft: 10,
                        paddingRight: 40,
                        textAlign: "center",
                      }}>
                      <a
                        href='javascript:void(0)'
                        onClick={() => {
                          this.setState({ addLocation: false });
                        }}>
                        Select Location from List
                      </a>
                    </div>
                  </div>
                )
              ) : null}

              <div className='row'>
                <div className='col-md-12' style={{ margin: 15 }}>
                  <div style={{ fontSize: 22 }}>Documents</div>
                  <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-end" }}>
                    {this.state.AddJobOrder.Documents
                      ? this.state.AddJobOrder.Documents.map((obj, index) => {
                          return (
                            <div
                              style={{
                                width: 200,
                                height: 100,
                                borderWidth: 1,
                                borderColor: "#cccccc",
                                borderStyle: "solid",
                                borderRadius: 15,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 10,
                                marginRight: 10,
                                flexDirection: "column",
                              }}>
                              <a
                                href='javascript:void(0)'
                                onClick={async () => {
                                  if (
                                    window.confirm("Are you sure you wish to delete this document?")
                                  ) {
                                    await API.del("1", `/documents?ItemId=${obj.ItemId}`);
                                    await Storage.remove(obj.ItemId, { level: "public" });
                                    delete this.state.AddJobOrder.Documents[index];
                                    this.setState({ render: true });
                                  }
                                }}
                                style={{
                                  width: 25,
                                  height: 25,
                                  borderWidth: 1,
                                  borderColor: "#cccccc",
                                  borderStyle: "solid",
                                  borderRadius: 13,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  position: "absolute",
                                  backgroundColor: "#ffffff",
                                  marginTop: -45,
                                  marginLeft: 99,
                                }}>
                                ×
                              </a>
                              <a
                                href='javascript:void(0)'
                                onClick={() => {
                                  storageUtils.getStorageFile(obj.Key, true);
                                }}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}>
                                <div
                                  style={{
                                    wordBreak: "break-all",
                                    fontSize: 12,
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    textAlign: "center",
                                  }}>
                                  {obj.Name}
                                </div>
                                <div style={{ color: "#000000", fontSize: 12 }}>{obj.Type}</div>
                                <div style={{ color: "#000000", fontSize: 12 }}>
                                  {new Date(obj.DateCreated).toLocaleString("en-US", {
                                    month: "numeric",
                                    day: "numeric",
                                    year: "numeric",
                                  })}
                                </div>
                              </a>
                            </div>
                          );
                        })
                      : null}
                    <InputFile
                      label=''
                      onChange={(event) => {
                        API.put("1", `/joborders`, {
                          body: {
                            ItemId: this.state.AddJobOrder.JobOrderId,
                            addDocument: event.FileInput.name,
                            addDocumentFile: event.FileID,
                            addDocumentCreator: "",
                            addDocumentType: "Job Order",
                          },
                        }).then((responseJson) => {
                          console.log(responseJson);
                          API.get(
                            "1",
                            `/joborders?ItemId=${this.state.AddJobOrder.JobOrderId}`
                          ).then((responseJson) => {
                            console.log(responseJson);
                            this.setState({
                              AddJobOrder: {
                                JobOrderId: responseJson.Output[0].ItemId,
                                Worker: responseJson.Output[0].MemberId,
                                Title: responseJson.Output[0].Title,
                                StartDate: responseJson.Output[0].StartDate,
                                EndDate: responseJson.Output[0].EndDate,
                                Hours: responseJson.Output[0].Hours,
                                BillableRate: responseJson.Output[0].BillableRate,
                                Rate: responseJson.Output[0].Rate,
                                TimeApproval: responseJson.Output[0].TimeApproval,
                                Remote: responseJson.Output[0].Remote,
                                Description: responseJson.Output[0].Description,
                                LocationId: responseJson.Output[0].LocationId,
                                City: responseJson.Output[0].City,
                                State: responseJson.Output[0].State,
                                Zip: responseJson.Output[0].Zip,
                                Documents: responseJson.Output[0].Documents,
                                Type: responseJson.Output[0].Type,
                              },
                            });
                          });
                        });
                      }}
                      invalidFeedback={this.state.error}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' onClick={() => this.handleModalClose()}>
              Close
            </Button>
            <Button variant='primary' onClick={() => this.onSubmit()}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default JobOrders;
