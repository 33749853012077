import { API, Storage } from 'aws-amplify';

export function fetchClients() {
    return API.get('1', '/clients?OrderBy=Name&SortOrder=asc').then(responseJson => {
        return responseJson;
    });
}
export function addClient(data) {
    return API.post('1', '/clients', { body: data }).then(responseJson => {
        return responseJson;
    });
}
export function editClient(data) {
    return API.put('1', '/clients', { body: data }).then(responseJson => {
        return responseJson;
    });
}
export function getClient(data) {
    return API.get('1', '/clients?ItemId=' + data).then(responseJson => {
        return responseJson;
    });
}
export function getContacts(data) {
    return API.get('1', '/contacts?ItemsPerPage=100&ClientId=' + data).then(responseJson => {
        return responseJson;
    });
}
export function updateContacts(data) {
    return API.put('1', '/contacts', { body: data }).then(responseJson => {
        return responseJson;
    });
}

export function addClientNote(ItemId, addNote) {
    return API.put('1', `/clients`, { body: { ItemId: ItemId, addNote: addNote } }).then(responseJson => {
        return responseJson;
    });
}
export function editClientNote(ItemId, editNoteId, editNote) {
    return API.put('1', `/clients`, { body: { ItemId: ItemId, editNote: editNote, editNoteId: editNoteId } }).then(responseJson => {
        return responseJson;
    });
}
export function deleteClientNote(ItemId, deleteNote) {
    return API.put('1', `/clients`, { body: { ItemId: ItemId, deleteNote: deleteNote } }).then(responseJson => {
        return responseJson;
    });
}

export function addClientDocument(ItemId, data) {
    return API.post('1',
        `/documents`,
        {
            body: {
                Key: data.addDocumentFile,
                Type: data.addDocumentType,
                Folder: 'Client',
                Name: data.addDocument,
                ClientId: ItemId
            }
        }
    ).then(responseJson => {
        return responseJson;
    });
}
export function editClientDocument(ItemId, data) {
    return API.put('1',
        `/documents`, {
        body: {
            ItemId: data.editDocument,
            Key: data.editDocumentFile,
            Type: data.editDocumentType
        }
    }
    ).then(responseJson => {
        return responseJson;
    });
}
export function deleteClientDocument(ItemId, data) {
    const deleteDocument = data.deleteDocument;
    return API.del('1',
        `/documents?ItemId=${deleteDocument}`
    ).then(responseJson => {
        return responseJson;
    });
}


export function addContact(data) {
    return API.post('1', '/contacts', { body: data }).then(responseJson => {
        return responseJson;
    });
}

export function deleteContact(data) {
    return API.del('1', `/contacts?${data}`).then(responseJson => {
        return responseJson;
    });
}

