import React, { Component } from 'react';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { API } from 'aws-amplify';

import { Link } from "react-router-dom";
// import { connect } from 'react-redux';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

import { Modal, Button } from "react-bootstrap"
import Input from "../../../components/ui/form/Input";
import { Dropdown } from 'react-bootstrap';
import { Label } from 'aws-amplify-react';

import "../styles/AdminsList.styles.css";

class AdminsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], page: 1, totalSize: 1, sizePerPage: 25,
            Type: ' ', UserType: ' ', WorkerName: ' ', LastName: ' ', Name: ' '
        }
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, UserType, LastName, WorkerName }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }
        let filterString = "";

        if (UserType === undefined) {
            UserType = this.state.UserType;
          }
          if (UserType !== " ") {
            filterString += `&UserType=${UserType}`;
          }

          if (LastName === undefined) {
            LastName = this.state.LastName;
          }
          if (LastName !== " ") {
            filterString += `&LastName=${LastName}`;
          }


        API.get('1', `/admins?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}${filterString}`).then(responseJson => {
            this.setState(() => ({
                page: page, data: responseJson.Output, sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder, totalSize: responseJson.RecordsTotal,
                UserType: UserType, LastName: LastName, WorkerName: WorkerName
            }));
        });
    }

    closeModal() {
        this.setState({ modal: null, ItemId: null, FirstName: null, LastName: null, UserType: null, Email: null, OldEmail: null });
    }

    render() {
        return (
        <React.Fragment>
            <div className="sendPayrollBtn">
                <Link style={{ position: 'absolute', top: 20, right: 300, zIndex: 1 }} className="btn btn-success sendPayroll" onClick={() => { this.setState({ modal: 'add' }) }}>Create New</Link>
            </div>
            <TopNav />
            <Nav />
            <div className="container">
                <hr className="line" />
                <div className='row'>
                    <div className='col-md-12'>
                        {/* <ul className='search_boxnew'>
                            <li className='search-box'>
                                <div className='input-group'>
                                    <input
                                    type='text'
                                    className='form-control'
                                    placeholder='Search by Name'
                                    onChange={ e => this.setState({ Name: e.target.value }) }
                                    />
                                    <div className='input-group-append'>
                                        <button className='btn' type='submit'>
                                            <svg
                                            aria-hidden='true'
                                            focusable='false'
                                            data-prefix='fas'
                                            data-icon='search'
                                            className='svg-inline--fa fa-search fa-w-16 SearchIcon'
                                            role='img'
                                            xmlns='http://www.w3.org/2000/svg'
                                            viewBox='0 0 512 512'>
                                            <path
                                                fill='currentColor'
                                                d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul> */}
                        {/* <ul id="ul-custom-filter" className='search_boxnew search_filters'>
                            <li style={{ flex: "0 0 100%" }}>
                            <Dropdown className='filterToggle'>
                                <Dropdown.Toggle style={{textTransform: 'capitalize'}}>
                                {this.state.UserType !== " " ? this.state.UserType : "Type"}
                                </Dropdown.Toggle>
                                <Dropdown.Menu style={{textTransform: 'capitalize'}}>
                                    <Dropdown.Item
                                        onClick={() => {
                                        this.handleTableChange(null, { UserType: " " });
                                        }}>
                                        Select Type
                                    </Dropdown.Item>
                                    {["admin", "member", "finance", "marketing", "sales", "recruiter"].map((obj) => {
                                        return (
                                        <Dropdown.Item
                                            onClick={() => {
                                            this.handleTableChange(null, { UserType: obj });
                                            }}
                                            // onClick={ e => this.setState({ UserType: e.target.value }) }
                                            >
                                            {obj}
                                        </Dropdown.Item>
                                        );
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                            </li>
                        </ul> */}
                    </div>
                </div>
            </div>
            <div className="container">
                <BootstrapTable
                    remote
                    wrapperClasses="table-responsive"
                    style={{ width: 2000, overflowX: 'auto' }}
                    keyField="ItemId"
                    filter={filterFactory()}
                    pagination={paginationFactory({ page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
                    classes='time_sheet_table capitalize'
                    bordered={false}
                    data={this.state.data}
                    page={this.state.page}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                    columns={[
                        {
                            dataField: 'LastName',
                            text: 'Name',
                            sort: true,
                            formatter: (cell, row) => { return (<div><b>{row.FirstName + ' ' + row.LastName}</b></div>); },
                            headerStyle: { width: '30%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'UserType',
                            text: 'Type',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '25%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Email',
                            text: 'Email',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '30%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'ItemId',
                            text: '',
                            sort: false,
                            formatter: (cell, row) => {
                                return (<div>
                                    <Link onClick={() => { this.setState({ modal: 'edit', ItemId: row.ItemId, FirstName: row.FirstName, LastName: row.LastName, UserType: row.UserType, Email: row.Email, OldEmail: row.Email }) }}>Edit</Link>
                                    {' - '}
                                    <Link onClick={() => { this.setState({ modal: 'delete', ItemId: row.ItemId, FirstName: row.FirstName, LastName: row.LastName }) }}>Delete</Link>
                                </div>);
                            },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }
                    ]}
                    defaultSorted={[{
                        dataField: 'LastName',
                        order: 'asc'
                    }]}
                />
            </div>
            <Modal animation={false} size="md" show={this.state.modal === 'add'} onHide={() => { this.closeModal() }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        label="First Name"
                        onChange={value => { this.setState({ FirstName: value }) }}
                    />
                    <Input
                        label="Last Name"
                        onChange={value => { this.setState({ LastName: value }) }}
                    />
                    <Label>Type</Label>
                    <Dropdown className="form-group">
                        <Dropdown.Toggle style={{textTransform: 'capitalize'}} id="custom-drop">
                            {this.state.UserType !== " " ? this.state.UserType : "admin"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{textTransform: 'capitalize'}} required>
                            <Dropdown.Item disabled
                                onClick={() => {
                                    this.setState({ UserType: " " });
                                }}
                                >
                                Select Job Type
                            </Dropdown.Item>
                            {["admin", "member", "finance", "marketing", "sales", "recruiter"].map((value) => {
                                return (
                                    <Dropdown.Item
                                        onClick={() => {
                                            this.setState({ UserType: value });
                                        }}>
                                        {value}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Input
                        label="Email"
                        onChange={value => { this.setState({ Email: value }) }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { this.closeModal() }}>Cancel</Button>
                    <Button onClick={() => {
                        API.post('1', `/usersSQL`, { body: { FirstName: this.state.FirstName, LastName: this.state.LastName, Type: this.state.UserType, Email: this.state.Email } }).then(responseJson => {
                            this.closeModal();
                            this.handleTableChange(null, this.state);
                        });
                    }} variant="primary">Add</Button>
                </Modal.Footer>
            </Modal>
            <Modal animation={false} size="md" show={this.state.modal === 'edit'} onHide={() => { this.closeModal() }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        label="First Name"
                        value={this.state.FirstName}
                        onChange={value => { this.setState({ FirstName: value }) }}
                    />
                    <Input
                        label="Last Name"
                        value={this.state.LastName}
                        onChange={value => { this.setState({ LastName: value }) }}
                    />
                    <Label>Type</Label>
                    <Dropdown className="form-group">
                        <Dropdown.Toggle style={{textTransform: 'capitalize'}} id="custom-drop">
                            {this.state.UserType !== " " ? this.state.UserType : " "}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{textTransform: 'capitalize'}}>
                            <Dropdown.Item disabled
                                onClick={() => {
                                    this.setState({ UserType: " " });
                                }}>
                                Select Job Type
                            </Dropdown.Item>
                            {["admin", "member", "finance", "marketing", "sales", "recruiter"].map((value) => {
                                return (
                                    <Dropdown.Item
                                        onClick={() => {
                                            this.setState({ UserType: value });
                                        }}>
                                        {value}
                                    </Dropdown.Item>
                                );
                            })}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Input
                        label="Email"
                        value={this.state.Email}
                        onChange={value => { this.setState({ Email: value }) }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { this.closeModal() }}>Cancel</Button>
                    <Button onClick={() => {
                        API.put('1', `/usersSQL`, { body: { ItemId: this.state.ItemId, FirstName: this.state.FirstName, LastName: this.state.LastName, UserType: this.state.UserType, Email: this.state.Email, OldEmail: this.state.OldEmail } }).then(responseJson => {
                            this.closeModal();
                            this.handleTableChange(null, this.state);
                        });
                    }} variant="primary">Save</Button>
                </Modal.Footer>
            </Modal>
            <Modal animation={false} size="md" show={this.state.modal === 'delete'} onHide={() => { this.closeModal() }} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Admin</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete {this.state.FirstName} {this.state.LastName}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { this.closeModal() }}>Cancel</Button>
                    <Button onClick={() => {
                        API.del('1', `/usersSQL?ItemId=${this.state.ItemId}`).then(responseJson => {
                            this.closeModal();
                            this.handleTableChange(null, this.state);
                        });
                    }} variant="primary">Delete</Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
        )
    }
}

export default AdminsList;