import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './ModalStyle.scss';
import {parseEducation} from '../../helperFunctions/parseStringHelper';


class EditEducation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tempYear: '',
      tempDegree: '',
      tempInstitution: ''
    }

    this.handleModalClose.bind(this);
    this.handleYearChange.bind(this);
    this.handleDegreeChange.bind(this);
    this.handleInstitutionChange.bind(this);
    this.handleSelectEducation.bind(this);
  }

  handleModalClose() {
    this.props.setModalName(null);
    this.props.closeModal();
  }

  /**
   * 
   * @param {change value via input} event 
   * @description change year field
   */
  handleYearChange(event) {
    this.setState({tempYear: event.currentTarget.value})
  }

  /**
   * 
   * @param {change value via input} event 
   * @description change degree field
   */
  handleDegreeChange(event) {
    this.setState({tempDegree: event.currentTarget.value})
  }
/**
   * 
   * @param {change value via input} event 
   * @description change school field
   */
  handleInstitutionChange(event) {
    this.setState({tempInstitution: event.currentTarget.value})
  }

/**
   * 
   * @param {change value via input} event 
   * @description change degree (bachelors, masters) field
   */
  handleSelectEducation(event) {
    let currentSelection = parseEducation(event.currentTarget.value);
    this.setState({tempYear: currentSelection.Year})
    this.setState({tempDegree: currentSelection.Degree})
    this.setState({tempInstitution: currentSelection.Institution})
  }

  //
  render() {
    return (
      <React.Fragment>
        <Modal animation={false} size="lg" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Education</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Year</Form.Label>
                    <Form.Control type="text" placeholder="" value={this.state.tempYear} onChange={e => this.handleYearChange(e)}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Degree</Form.Label>
                    <Form.Control type="text" placeholder="" value={this.state.tempDegree} onChange={e => this.handleDegreeChange(e)}/>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Institution</Form.Label>
                    <Form.Control type="text" placeholder="" value={this.state.tempInstitution} onChange={e => this.handleInstitutionChange(e)}/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="exampleForm.ControlSelect2">
                    <Form.Label>Select Education</Form.Label>
                    <Form.Control as="select" multiple>
                      {this.props.memberInfo.AllEducation.map((education) => {
                        return (
                          <option onClick={e => this.handleSelectEducation(e)}>{education}</option>
                        )
                      })}
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
          </Button>
            <Button variant="primary">
              Save Changes
          </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )

  }
}
EditEducation.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    memberInfo: state.member.memberInfo[0]
  }
}
export default connect(mapStateToProps, { closeModal, setModalName })(EditEducation);