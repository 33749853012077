import { 
  FETCH_TIMESHEETS, SET_TIMESHEET, UPDATE_TIMESHEET,
  ENABLE_TIMESHEET_LOADER, DISABLE_TIMESHEET_LOADER,
  GET_DETAILED_TIMESHEET,SET_DETAILED_TIMESHEET,SELECTED_TIMESHEET,
  DELETE_DETAILED_TIMESHEET,DELETE_SELECTED_TIMESHEET,
  SET_TIMESHEET_FORM_DATA,SET_SEND_TO_CLIENT_RESPONSE,SET_ALL_CONTACTS,
  SET_PAYROLL_FORM_DATA,SET_SEND_TO_PAYROLL_RESPONSE,
  SET_PAYROLLS,APPROVE_RESPONSE,SET_TIMESHEET_FOR_APPROVAL,SELECTED_PAYROLL
} from '../actions/types';

const initialState = {
  isLoading: false,
  timeSheets: [],
  filterTimeSheets: [],
  selectedTimeSheet:[],
  detailedTimeSheet:{},
  addTimesheetData : {},
  addPayrollData : {},
  sendToClientResponse : '',
  sendToPayrollResponse: '',
  allContacts : [],
  payrolls: [],
  approveResponse: '',
  setTimesheetForApproval: [],
  selectedPayroll:{}
};

export default function(state = initialState, action) {
  switch (action.type) {

    case ENABLE_TIMESHEET_LOADER:
      return {
        ...state,
        isLoading: true
      }
    
    case DISABLE_TIMESHEET_LOADER:
      return {
        ...state,
        isLoading: false
      }
    case SET_PAYROLLS:
    return {
      ...state,
      payrolls: action.data,
    }
    case SET_PAYROLL_FORM_DATA:
    return {
      ...state,
      addPayrollData: {
        ...state.addPayrollData,
        [action.key]: action.value,
      },
    };  
    case SET_TIMESHEET_FORM_DATA:
      return {
        ...state,
        addTimesheetData: {
          ...state.addTimesheetData,
          [action.key]: action.value,
        },
      };
    case SET_TIMESHEET:
    
      return {
        ...state,
        timeSheets: action.data,
        filterTimeSheets : action.data,
        sendToClientResponse: '',
        sendToPayrollResponse: '',
        approveResponse: '',
      }
    case SET_TIMESHEET_FOR_APPROVAL:
    return {
      ...state,
      setTimesheetForApproval:action.data
    }
    case DELETE_DETAILED_TIMESHEET:
    return {
      ...state,
      detailedTimeSheet: {},
      sendToClientResponse: '',
        sendToPayrollResponse: '',
    }
    case UPDATE_TIMESHEET: 
      return {
        ...state,
        timeSheets: action.data.TotalData,
        filterTimeSheets : action.data.filterData,
        sendToClientResponse : '',
        sendToPayrollResponse :'',
      }

    case SET_DETAILED_TIMESHEET:
    return {
      ...state,
      detailedTimeSheet: action.data,
      sendToClientResponse: '',
        sendToPayrollResponse: '',
        approveResponse:'',
    }
    case SET_ALL_CONTACTS:
    return {
      ...state,
      allContacts: action.data,

    }
    case DELETE_SELECTED_TIMESHEET:
    return{
      ...state,
      selectedTimeSheet: {},
      sendToClientResponse: '',
        sendToPayrollResponse: '',
        approveResponse:'',
    }
    case SELECTED_TIMESHEET:
    return{
      ...state,
      selectedTimeSheet: action.data.selectedTimeSheet,
      sendToClientResponse: '',
        sendToPayrollResponse: '',
        approveResponse:'',
        payrolls : [],
    }
    case SELECTED_PAYROLL:
      return{
        ...state,
        selectedPayroll: action.data.selectedPayroll,
        sendToClientResponse: '',
          sendToPayrollResponse: '',
          approveResponse:'',
          payrolls : [],
      }
    case SET_SEND_TO_CLIENT_RESPONSE:
    return {
      ...state,
      sendToClientResponse: action.data,
      payrolls : [],
    }
    case SET_SEND_TO_PAYROLL_RESPONSE:
    return {
      ...state,
      sendToPayrollResponse: action.data,
      payrolls : [],
    }
    case APPROVE_RESPONSE:
    return {
      ...state,
      approveResponse:action.data,
    }
    
    default:
      return state;
  }
}
