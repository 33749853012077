// LOADER ACTIONS
export const ENABLE_TIMESHEET_LOADER = "ENABLE_TIMESHEET_LOADER";
export const DISABLE_TIMESHEET_LOADER = "DISABLE_TIMESHEET_LOADER";

export const FETCH_TIMESHEETS = 'FETCH_TIMESHEETS';
export const SET_TIMESHEET = 'SET_TIMESHEET';
export const UPDATE_TIMESHEET = 'UPDATE_TIMESHEET';
export const FILTER_TIMESHEET = 'FILTER_TIMESHEET';
export const SORT_TIMESHEET = 'SORT_TIMESHEET';
export const SET_SELECTED_TIMESHEET = 'SET_SELECTED_TIMESHEET';
export const SELECTED_TIMESHEET = 'SELECTED_TIMESHEET';
export const GET_DETAILED_TIMESHEET = 'GET_DETAILED_TIMESHEET';
export const SET_DETAILED_TIMESHEET = 'SET_DETAILED_TIMESHEET';
export const DELETE_DETAILED_TIMESHEET = 'DELETE_DETAILED_TIMESHEET';
export const DELETE_SELECTED_TIMESHEET = 'DELETE_SELECTED_TIMESHEET';
export const SET_TIMESHEET_FORM_DATA = 'SET_TIMESHEET_FORM_DATA';
export const SEND_TO_CLIENT = 'SEND_TO_CLIENT';
export const SET_SEND_TO_CLIENT_RESPONSE = 'SET_SEND_TO_CLIENT_RESPONSE';
export const FETCH_ALL_CONTACTS = 'FETCH_ALL_CONTACTS';
export const SET_ALL_CONTACTS = 'SET_ALL_CONTACTS';
export const SET_PAYROLL_FORM_DATA = 'SET_PAYROLL_FORM_DATA';
export const SEND_TO_PAYROLL = 'SEND_TO_PAYROLL';
export const SET_SEND_TO_PAYROLL_RESPONSE = 'SET_SEND_TO_PAYROLL_RESPONSE';
export const FETCH_PAYROLLS = 'FETCH_PAYROLLS';
export const SET_PAYROLLS = 'SET_PAYROLLS';
export const APPROVE_TIMESHEETS = 'APPROVE_TIMESHEETS';
export const APPROVE_RESPONSE = 'APPROVE_RESPONSE';
export const TIMESHEET_FOR_APPROVAL = 'TIMESHEET_FOR_APPROVAL';
export const SET_TIMESHEET_FOR_APPROVAL = 'SET_TIMESHEET_FOR_APPROVAL';
export const EDIT_PAYROLL_DATE = 'EDIT_PAYROLL_DATE';
export const SELECTED_PAYROLL = 'SELECTED_PAYROLL';
