import React, { Component } from "react";
import { connect } from "react-redux";
import "../styles/MemberStyles.scss";
import * as memberActions from "../actions";
import Nav from "../../../components/Navigation/Nav";
import TopNav from "../../../components/TopNav/TopNav";
import TabsNotes from "../components/profile/TabsNotes";

import { openModal } from "../../../actions/uiActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";

export class MemberNotes extends Component {
  componentDidMount() {
    const ItemId = this.props.match.params.ItemId;
    this.props.fetchMember(ItemId);
    console.log(`Mount Item Id: ${ItemId}`);
  }

  render() {
    return (
      <React.Fragment>
        <TopNav />
        <Nav />
        <br />
        <div className='member_details_block'>
          <div className='container'>
            <TabsNotes member={this.props.member} />
          </div>
          {this.props.isLoading && (
            <div className='loader'>
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  member: state.members.member,
  isLoading: state.members.isLoading,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMember: (ItemId) => dispatch(memberActions.fetchMember(ItemId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberNotes);
