import React, { Component } from "react";
import PropTypes from "prop-types";
import Preferences from './Tabs/Preferences';
import Notes from './Tabs/Notes';
import Requests from './Tabs/Requests';
import { faComment } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export class ProfileTabs extends Component {
  static propTypes = {};
  state = {
    newNotes: 0
  };
  componentDidMount() {
  }
  componentWillReceiveProps() {
    if (this.props.member.Notes) {
      const checkDate = new Date(Date.now() - 10 * 24 * 60 * 60 * 1000);
      let newNotes = this.props.member.Notes.filter(obj => new Date(obj.DateCreated) >= checkDate);
      this.setState({ newNotes: newNotes.length });
    }
  }
  render() {
    const { member } = this.props;
    const systemStatus = (typeof member.AccountStatus !== 'undefined' && member.AccountStatus !== '') ? member.AccountStatus : '';
    const WeeklyAvailability = (typeof member.Meta !== 'undefined' && member.Meta.WeeklyAvailability !== null && typeof member.Meta.WeeklyAvailability !== 'undefined' && member.Meta.WeeklyAvailability !== '') ? member.Meta.WeeklyAvailability : '';
    const EmploymentStatus = (typeof member.Meta !== 'undefined' && member.Meta.EmploymentStatus !== null && typeof member.Meta.EmploymentStatus !== 'undefined' && member.Meta.EmploymentStatus !== '') ? member.Meta.EmploymentStatus : '';
    const LocationAvailability = (typeof member.Meta !== 'undefined' && member.Meta.LocationAvailability !== null && typeof member.Meta.LocationAvailability !== 'undefined' && member.Meta.LocationAvailability !== '') ? member.Meta.LocationAvailability : '';
    return (
      <section>
        <div className="profileTabs main-header">
          <ul className="nav " id="pills-tab" role="tablist">
            <li className="nav-item active">
              <a
                className="nav-link active"
                id="pills-home-tab"
                data-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                <span className="categories">Notes {this.state.newNotes > 0 && <div style={{ display:'inline-block'}}>
                      <FontAwesomeIcon style={{ color:'#6fbe47', fontSize:35 }} icon={faComment} /><span style={{ fontSize:20, marginLeft: '-24px', color:'white', position: 'absolute' }}>{this.state.newNotes}</span></div>}</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="pills-contact-tab"
                data-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                <span className="categories"> Preferences</span>
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="pills-profile-tab"
                data-toggle="pill"
                href="#pills-requests"
                role="tab"
                aria-controls="pills-requests"
                aria-selected="false"
              >
                <span className="categories">Requests</span>
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
              <Notes
                onAddNote={this.props.onAddNote}
                onEditNote={this.props.onEditNote}
                onDeleteNote={this.props.onDeleteNote}
                member={member} />
            </div>
            <div className="tab-pane fade" id="pills-requests" role="tabpanel" aria-labelledby="pills-requests-tab">
              <Requests member={member} updateCertifications={this.props.updateCertifications} />
            </div>
            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
              <Preferences
                systemStatus={systemStatus}
                WeeklyAvailability={WeeklyAvailability}
                EmploymentStatus={EmploymentStatus}
                LocationAvailability={LocationAvailability}
              />
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ProfileTabs;