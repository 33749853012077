// import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { takeEvery } from 'redux-saga/effects'

function* mySaga() {
    yield takeEvery("USER_FETCH_REQUESTED", fetchUser);
}

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchUser(action) {
   
}

export default mySaga;