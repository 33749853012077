import React, { Component } from 'react';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import Moment from 'react-moment';
import { API } from 'aws-amplify';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import Modal from '../../../components/ui/Modal';
import InputDropdown from "../../../components/ui/form/InputDropdown";
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";

class ToolsEmailsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            data: [],
            totalSize: 1,
            sizePerPage: 25,
            Worker: ' ',
            Type: ' ',
            Date: ' ',
            interviewModal: false,
            Subject: '',
            Message: '',
            Types: [
                { title: 'Pre-Screen', value: 'preScreen' },
                { title: 'Screening Interview for Position', value: 'interviewForPosition' },
                { title: 'Interview with Client', value: 'interviewWithClient' },
                { title: 'Offer for Contract', value: 'offerForContract' }
            ]
        }
        this.handleTableChange = this.handleTableChange.bind(this);
    }
    componentDidMount() {
        console.log("tools emails did mount");
        API.get('1', `/admins?OrderBy=LastName&SortOrder=ASC&ItemsPerPage=100&Page=0`).then(responseJson => {
            let data = [];
            let adminsData = {};
            responseJson.Output?.map(item => {
                data.push({
                    title: `${item.FirstName} ${item.LastName} (${item.Email})`,
                    value: item.ItemId,
                    firstName: item.FirstName
                });
                adminsData[item.ItemId] = item.FirstName + ' ' + item.LastName;
            });
            this.setState(() => ({ admins: data, adminsData: adminsData }));
        });
    }
    RemoteTable = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
        <BootstrapTable
            remote
            keyField="ItemId"
            data={data}
            columns={[{
                dataField: 'DateCreated',
                text: 'Date Sent',
                sort: true,
                formatter: (cell) => { return (<div className="tableCenter"><Moment utc format="MM/DD/YYYY">{cell}</Moment></div>); },
                headerFormatter: (column, colIndex, { sortElement }) => {
                    return (<div className="tableCenter"><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>)
                },
                headerStyle: { width: '10%' }
            }, {
                dataField: 'To',
                text: 'To',
                sort: true,
                formatter: (cell) => { return (<div className="tableCenter">{cell}</div>); },
                headerFormatter: (column, colIndex, { sortElement }) => {
                    return (<div className="tableCenter"><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>)
                },
                headerStyle: { width: '10%' }
            }, {
                dataField: 'From',
                text: 'From',
                sort: true,
                formatter: (cell) => { return (<div className="tableCenter">{cell}</div>); },
                headerFormatter: (column, colIndex, { sortElement }) => {
                    return (<div className="tableCenter"><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>)
                },
                headerStyle: { width: '10%' }
            }, {
                dataField: 'Type',
                text: 'Type',
                sort: true,
                formatter: (cell) => { return (<div className="tableCenter">{this.state.Types.filter(obj => obj.value === cell).map((obj, id) => obj.title)}</div>); },
                headerFormatter: (column, colIndex, { sortElement }) => {
                    return (<div className="tableCenter"><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>)
                },
                headerStyle: { width: '10%' }
            }, {
                dataField: 'Body',
                text: 'Message',
                sort: true,
                formatter: (cell, row) => { return (<div><b>Subject: </b>{row.Subject}<p /><b>Body: </b>{cell}</div>); },
                headerFormatter: (column, colIndex, { sortElement }) => {
                    return (<div className="tableCenter"><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>)
                },
                headerStyle: { width: '20%' }
            }, {
                dataField: 'ItemId',
                text: '',
                sort: false,
                formatter: (cell, row) => { return (<div className="tableCenter"><a onClick={() => { this.setState({ interviewModal: true, MemberIds: JSON.parse(row.MemberIds), Admin: row.Admin, Type: row.Type, Subject: row.Subject, Body: row.Body }); }} href="#">Resend</a></div>); },
                headerFormatter: (column, colIndex, { sortElement }) => {
                    return (<div className="tableCenter"><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>)
                },
                headerStyle: { width: '5%' }
            }]}
            defaultSorted={[{
                dataField: 'DateCreated',
                order: 'desc'
            }]}
            filter={filterFactory()}
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
            onTableChange={onTableChange}
            classes='time_sheet_table'
            bordered={false}
        />
    );
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, Worker, Type, Date }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }

        let filterString = '';
        if (!Worker) { Worker = this.state.Worker } if (Worker !== ' ') { filterString += `&Worker=${Worker}` }
        if (!Type) { Type = this.state.Type } if (Type !== ' ') { filterString += `&Type=${Type}`; }
        if (!Date) { Date = this.state.Date } if (Date !== ' ') { filterString += `&Date=${Date}`; }

        API.get('1', `/emails?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}${filterString}`).then(responseJson => {
            let result = responseJson.Output;
            this.setState(() => ({
                page: page,
                data: result,
                sizePerPage: sizePerPage,
                sortField: sortField,
                sortOrder: sortOrder,
                totalSize: responseJson.RecordsTotal,
                Worker: Worker,
                Type: Type,
                Date: Date
            }));
            window.scrollTo(0, 0);
        });
    }

    onSetupInterview = () => {
        API.post('1', `/interview`, {
            body: {
                Admin: this.state.Admin,
                MemberIds: this.state.MemberIds,
                Type: this.state.Type,
                Subject: this.state.Subject,
                Body: this.state.Body
            }
        }).then(responseJson => {
            this.setState({ interviewModal: false, Admin: null, Subject: null, Body: null, Type: ' ', MemberIds: null });
            this.handleTableChange(null, this.state);
        });
    }

    formatMessage = () => {
        let subject = '';
        let message = '';
        if (this.state.Type === 'preScreen') {
            subject = `We'd like to get to know you`;
            message = `Welcome to TECKpert.\n\nMy name is ${this.state.adminsData[this.state.Admin]} and I'd like to setup a Zoom meeting to get to know you better.\n\nPlease click on the link below to find a time that works for you.\n\nhttps://calendly.com/tckage/pre-screen-platform?name=[NAME]&email=[EMAIL]\n\nI look forward to meeting you.\n\n- ${this.state.adminsData[this.state.Admin]}`;
        }
        else if (this.state.Type === 'interviewForPosition') {
            subject = `We'd like to speak with you about the [Opportunity Name] opportunity`;
            message = `Thank you for your interest in our [Opportunity Name].\n\nWe are holding pre-screening interviews for this opportunity over the coming days.\n\nClick on the link below to select a time slot.\n\nhttps://calendly.com/tckage/pre-screen-position?name=[NAME]&email=[EMAIL]&a1=opportunity\n\nPlease take some time to complete your profile on our app prior to the interview. I look forward to meeting with you.\n\n- ${this.state.adminsData[this.state.Admin]}`;
        }
        else if (this.state.Type === 'interviewWithClient') {
            subject = `Interview Request by our client for the [Opportunity Name] opportunity`;
            message = `Our client has reviewed your profile and would like to setup an interview.\n\nClick on the link below to select a time slot.\n\nhttps://calendly.com/tckage/interview-client?name=[NAME]&email=[EMAIL]&a1=position\n\nWe look forward to meeting with you.\n\n- ${this.state.adminsData[this.state.Admin]}`;
        }
        else if (this.state.Type === 'offerForContract') {
            subject = `Contract Offer for [Opportunity Name]`;
            message = `Congratulations!\n\nOur client is ready for you to join the team!\n\nI have some details below for you to review:\n\n• Desired start date: mm/dd/yy\n•Rate of Pay: $xy\n• Hours per week: NN\n• Length of contract: NN\n\nPlease contact me at 555-555-5555 to review the next steps.\n\nBest,\n- ${this.state.adminsData[this.state.Admin]}`;
        }
        this.setState({ Subject: subject, Body: message });
    }

    render() {
        const { data, sizePerPage, page } = this.state;
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <div className="row" style={{ paddingLeft: 40, paddingTop: 25, paddingBottom: 0, fontSize: 24 }}>
                    <Link style={{ marginRight: 10, fontWeight: '800', textDecoration: 'none' }} to="/tools">{'←'}</Link>Messages Log
                </div>
                <div className="col-md-12">
                    <ul className="search_boxnew">
                        <li className="search-box">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Search Worker" onChange={(e) => { this.handleTableChange(null, { Worker: e.currentTarget.value ? e.currentTarget.value : ' ' }) }} />
                                <div className="input-group-append">
                                    <button className="btn" type="submit">
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 SearchIcon" role="img"
                                            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <ul className="search_boxnew search_filters">
                        <li>
                            <Dropdown className="filterToggle">
                                <Dropdown.Toggle>{![' ', ''].includes(this.state.Type) ? this.state.Types.filter(obj => obj.value === this.state.Type)[0].title : 'Select Type'}</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => { this.handleTableChange(null, { Type: ' ' }) }}>Select Type</Dropdown.Item>
                                    {this.state.Types.map(obj => {
                                        return (<Dropdown.Item onClick={() => { this.handleTableChange(null, { Type: obj.value }); }}>{obj.title}</Dropdown.Item>)
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                        <li style={{ padding: 20 }}>
                            <span style={{ marginRight: 15 }}>Date</span>
                            <input id="Date" type="date" onChange={() => { this.handleTableChange(null, { Date: document.getElementById('Date').value }) }} className="form-control" />
                        </li>
                        <li>
                        </li>
                    </ul>
                </div>
                <hr className="line" />
            </div>
            <div className="container">
                <this.RemoteTable
                    data={data}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                />
            </div>

            <Modal
                isVisible={this.state.interviewModal}
                title={`Resend Message ${this.state.MemberIds ? `to ${this.state.MemberIds.length} workers` : ''}`}
                onSubmit={this.onSetupInterview}
                onClose={() => {
                    this.setState({ interviewModal: false });
                }}
            >
                <div className="container send_to_client_container">
                    <div className="clients_appBox from-for">
                        <div className="row">
                            <div className="col-md">
                                <div className="form-group">
                                    <label class="form-label">From</label>
                                    <InputDropdown
                                        placeholder="Select Admin"
                                        onChange={value => {
                                            setTimeout(() => {
                                                this.formatMessage();
                                            }, 0);
                                            this.setState({ Admin: value });
                                        }}
                                        options={this.state.admins}
                                        selected={this.state.Admin}
                                    />
                                </div>
                            </div>
                            <div className="col-md">
                                <div className="form-group">
                                    <label class="form-label">Type</label>
                                    <InputDropdown
                                        placeholder="Select Type"
                                        onChange={value => {
                                            setTimeout(() => {
                                                this.formatMessage();
                                            }, 0);
                                            this.setState({ Type: value });
                                        }}
                                        options={this.state.Types}
                                        selected={this.state.Type}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Subject</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(event) => this.setState({ Subject: event.currentTarget.value })}
                                        value={this.state.Subject}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Body</label>
                                    <textarea
                                        type="text"
                                        className="form-control"
                                        onChange={(event) => this.setState({ Body: event.currentTarget.value })}
                                        value={this.state.Body}
                                        rows={15}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment >
        )
    }
}

export default ToolsEmailsList;