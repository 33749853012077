import React, { Component } from "react";
import PropTypes from "prop-types";
export class EmptyData extends Component {
  static propTypes = {
    msg: PropTypes.object
  };
  render() {
    const { msg } = this.props;
    return (
      <div>
        <p>{msg}</p>
      </div>
    );
  }
}

export default EmptyData;
