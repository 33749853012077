import React, { Component } from "react";
import PropTypes from 'prop-types';

export class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: (typeof props.value !== "undefined") ? props.value : ""
    };
  }
  onChange(event) {
    this.props.onChange(event.currentTarget.value);
    this.setState({ value: event.currentTarget.value });
  }

  componentWillReceiveProps(event) {
    if (this.props.listenToChangedValue) {
      this.props.onChange(event.value);
      this.setState({ value: event.value });
    }
  }

  render() {
    let value = "";

    let inputClass = 'form-control';
    if (this.props.validFeedback !== '') inputClass += ' is-valid';
    if (this.props.invalidFeedback !== '') inputClass += ' is-invalid';
    if (typeof this.props.className !== "undefined") {
      inputClass += ' ' + this.props.className;
    }
    if (typeof this.props.value !== "undefined") {
      value = this.props.value;
    }
    return (
      <div className="form-group">
        {this.props.label !== "" && (
          <label className="form-label" style={this.props.labelStyle}>{this.props.label}</label>
        )}

        <input
          type={this.props.number ? "number" : "text"}
          min="0"
          className={inputClass}
          onChange={(event) => this.onChange(event)}
          placeholder={this.props.placeholder}
          value={this.state.value}
          disabled={this.props.disabled ? 'disabled' : null}
        />
        {this.props.invalidFeedback !== '' && <div class="invalid-feedback">{this.props.invalidFeedback}</div>}
        {this.props.validFeedback !== '' && <div class="valid-feedback">{this.props.validFeedback}</div>}
      </div>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  validFeedback: PropTypes.string,
  invalidFeedback: PropTypes.string,
  labelStyle: PropTypes.object,
  value: PropTypes.string,
  listenToChangedValue: PropTypes.bool,
  number: PropTypes.bool,
  disabled: PropTypes.bool
};

Input.defaultProps = {
  label: '',
  placeholder: '',
  validFeedback: '',
  invalidFeedback: '',
  value: '',
  number: false,
  disabled: false
}

export default Input;
