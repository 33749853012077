import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  FETCH_CLIENTS,
  UPDATE_CLIENT,
  EDIT_CLIENT,
  FILTER_CLIENT,
  SORT_CLIENT,
  ADD_CLIENT,
  GET_CLIENT,
  ADD_CONTACT,
  DELETE_CONTACT,
  GET_CLIENT_CONTACTS,
  GET_CONTACT_DETAIL, SUBMIT_CLIENT_CONTRACTS,
  ADD_CLIENT_NOTE, EDIT_CLIENT_NOTE, DELETE_CLIENT_NOTE,
  ADD_CLIENT_DOCUMENT, EDIT_CLIENT_DOCUMENT, DELETE_CLIENT_DOCUMENT
} from "../actions/types";
import * as clientActions from '../actions';

import * as clientApis from '../api';
import * as contractUtils from '../../Contracts/utils/helper'
import * as contractApis from "../../Contracts/api";
import * as dataParser from '../../../helperFunctions/DataParser';

export default function* clientSagas() {
  yield takeEvery(FETCH_CLIENTS, fetchClients);
  yield takeEvery(FILTER_CLIENT, filterClients);
  yield takeEvery(ADD_CLIENT, addClient);
  yield takeEvery(EDIT_CLIENT, editClient);

  yield takeEvery(SORT_CLIENT, sortClients);
  yield takeEvery(GET_CLIENT, getClientDetail);
  yield takeEvery(ADD_CONTACT, addContact);
  yield takeEvery(DELETE_CONTACT, deleteContact);
  yield takeEvery(GET_CLIENT_CONTACTS, getContacts);
  yield takeEvery(GET_CONTACT_DETAIL, filterContact);

  yield takeEvery(ADD_CLIENT_NOTE, addClientNote);
  yield takeEvery(EDIT_CLIENT_NOTE, editClientNote);
  yield takeEvery(DELETE_CLIENT_NOTE, deleteClientNOte);

  yield takeEvery(ADD_CLIENT_DOCUMENT, addClientDocument);
  yield takeEvery(EDIT_CLIENT_DOCUMENT, editClientDocument);
  yield takeEvery(DELETE_CLIENT_DOCUMENT, deleteClientDocument);

  yield takeEvery(SUBMIT_CLIENT_CONTRACTS, submitClientContract);
}


function* addClientDocument(action) {
  yield put(clientActions.enableLoader());
  yield call(clientApis.addClientDocument, action.ItemId, action.data);
  let data = yield call(clientApis.getClient, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(clientActions.setClientDetails(data.Output));
  }
  yield put(clientActions.disableLoader());
}
function* submitClientContract(action) {
  yield put(clientActions.enableLoader());
  //const dataParsed = yield call(contractUtils.parseObjectToQueryStringData, action.data);
  const response = yield call(contractApis.postContracts, action.data);
  let data = yield call(clientApis.getClient, action.data.ClientId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(clientActions.setClientDetails(data.Output));
  }
  yield put(clientActions.disableLoader());
}
function* editClientDocument(action) {
  yield put(clientActions.enableLoader());
  yield call(clientApis.editClientDocument, action.ItemId, action.data);
  let data = yield call(clientApis.getClient, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(clientActions.setClientDetails(data.Output));
  }
  yield put(clientActions.disableLoader());
}
function* deleteClientDocument(action) {
  yield put(clientActions.enableLoader());
  yield call(clientApis.deleteClientDocument, action.ItemId, action.data);
  let data = yield call(clientApis.getClient, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(clientActions.setClientDetails(data.Output));
  }
  yield put(clientActions.disableLoader());
}

function* addClientNote(action) {
  yield put(clientActions.enableLoader());
  yield call(clientApis.addClientNote, action.ItemId, action.addNote);
  let data = yield call(clientApis.getClient, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(clientActions.setClientDetails(data.Output));
  }
  yield put(clientActions.disableLoader());
}
function* editClientNote(action) {
  yield put(clientActions.enableLoader());
  yield call(clientApis.editClientNote, action.ItemId, action.editNoteId, action.editNote);
  let data = yield call(clientApis.getClient, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(clientActions.setClientDetails(data.Output));
  }
  yield put(clientActions.disableLoader());
}
function* deleteClientNOte(action) {
  yield put(clientActions.enableLoader());
  yield call(clientApis.deleteClientNote, action.ItemId, action.deleteNote);
  let data = yield call(clientApis.getClient, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(clientActions.setClientDetails(data.Output));
  }
  yield put(clientActions.disableLoader());
}

function* filterContact(action) {
  let filtereddata = yield call(dataParser.filterContactByItemId, action.data.contact, action.data.contacts);
  yield put(clientActions.setContactDetail(filtereddata));
}

function* getContacts(action) {
  yield put(clientActions.enableLoader());
  let response = yield call(clientApis.getContacts, action.data);
  if (typeof response.Message !== "undefined" && response.Message === "Success") {
    //let filtereddata = yield call(dataParser.filterContactByClientId, response.Output, action.data);
    yield put(clientActions.setClientContacts(response.Output));
    //yield put(clientActions.setContactResponse(response.Message));
  }
  yield put(clientActions.disableLoader());
}
function* getClientDetail(action) {
  console.log(action);
  yield put(clientActions.enableLoader());
  yield put(clientActions.clearResponse());
  let response = yield call(clientApis.getClient, action.data);
  yield put(clientActions.setClientDetails(response.Output));
  yield put(clientActions.disableLoader());
}

function* addContact(action) {
  console.log(action.data);
  let data = {
    ClientId: action.data.ClientId,
    FirstName: action.data.FirstName,
    LastName: action.data.LastName,
    Email: action.data.Email,
    Title: action.data.Title,
    Phone: action.data.Phone,
    Fax: action.data.Fax,
    PrimaryContact: action.data.primarycontact
  };
  if (typeof action.data.ItemId !== "undefined") {
    data.ItemId = action.data.ItemId;
  }
  if (typeof action.data.ProfilePicture !== "undefined" && action.data.ProfilePicture !== "") {
    data.ProfilePicture = action.data.ProfilePicture;
  }
  yield put(clientActions.enableLoader());
  let response = "";
  if (typeof action.data.ItemId !== "undefined") {
    response = yield call(clientApis.updateContacts, data);
  } else {
    response = yield call(clientApis.addContact, data);
  }
  yield put(clientActions.setResponse(response.Message));

  yield put(clientActions.disableLoader());
}

function* deleteContact(action) {
  console.log(action.data);
  let data = 'ItemId=' + action.data.ItemId;
  yield put(clientActions.enableLoader());
  let response = yield call(clientApis.deleteContact, data);
  yield put(clientActions.setResponse(response.Message));
  yield put(clientActions.disableLoader());
}

function* editClient(action) {
  let data = {
    Name: action.data.Name,
    AccountStatus: 'active',
    PrimaryContact: null,
    Address: action.data.Address,
    City: action.data.City,
    State: action.data.State,
    Zip: action.data.Zip,
    IndustryType: action.data.IndustryType,
    Website: action.data.Website,
    Phone: action.data.Phone,
    Fax: action.data.Fax,
    Email: action.data.Email
  };
  if (typeof action.data.ItemId !== "undefined") {
    data.ItemId = action.data.ItemId;
  }
  yield put(clientActions.enableLoader());
  yield put(clientActions.clearResponse());
  let response = yield call(clientApis.editClient, data);
  yield put(clientActions.setResponse(response.Message));
  let responseDetail = yield call(clientApis.getClient, action.data.ItemId);
  yield put(clientActions.setClientDetails(responseDetail.Output));
  yield put(clientActions.clearResponse());
  yield put(clientActions.disableLoader());
}

function* addClient(action) {
  let data = {
    Name: action.data.Company,
    AccountStatus: 'active',
    Address: action.data.Address,
    City: action.data.City,
    State: action.data.State,
    Zip: action.data.Zip,
    IndustryType: action.data.IndustryType,
    Website: action.data.Website,
    Phone: action.data.Phone,
    Fax: action.data.Fax,
    Email: action.data.Email
  };
  yield put(clientActions.enableLoader());
  yield put(clientActions.clearResponse());
  let response = yield call(clientApis.addClient, data);
  yield put(clientActions.setResponse(response.Message));

  yield put(clientActions.disableLoader());
}

function* sortClients(action) {
  console.log(action);
  let parsedData = yield call(dataParser.sortData, action.data.sortby, action.data.sortDir, action.data.data);
  var filteredData = { "filterData": parsedData, "TotalData": action.data.data }
  yield put(clientActions.updateClients(filteredData));
}

function* fetchClients() {
  yield put(clientActions.enableLoader());
  let data = yield call(clientApis.fetchClients);
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    yield put(clientActions.setClients(data.Output));
  }
  yield put(clientActions.disableLoader());
}
function* filterClients(action) {
  //let data = yield call(timeSheetApis.fetchTimeSheets);
  yield put(clientActions.enableLoader());
  let parsedData = [];
  let filteredData = [];
  if (action.data.value.length === 0) {
    parsedData = action.data.data;
  } else {
    parsedData = yield call(dataParser.filterClients, action.data.key, action.data.value, action.data.data);
  }
  filteredData = { "filterData": parsedData, "TotalData": action.data.data };
  yield put(clientActions.disableLoader());
  yield put(clientActions.updateClients(filteredData));
}