// LOADER ACTIONS
export const ENABLE_CLIENT_LOADER = "ENABLE_CLIENT_LOADER";
export const DISABLE_CLIENT_LOADER = "DISABLE_CLIENT_LOADER";

export const FETCH_CLIENTS = 'FETCH_CLIENTS';
export const SET_CLIENT = 'SET_CLIENT';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const EDIT_CLIENT = 'EDIT_CLIENT';
export const FILTER_CLIENT = 'FILTER_CLIENT';
export const SORT_CLIENT = 'SORT_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE';
export const SET_RESPONSE = 'SET_RESPONSE';
export const GET_CLIENT = 'GET_CLIENT';
export const CLIENT_DETAIL = 'CLIENT_DETAIL';
export const SET_CONTACT_FORM_DATA = 'SET_CONTACT_FORM_DATA';
export const ADD_CONTACT = 'ADD_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const GET_CLIENT_CONTACTS = 'GET_CLIENT_CONTACTS';
export const SET_CLIENT_CONTACTS = 'SET_CLIENT_CONTACTS';
export const SET_CONTACT_DETAIL = 'SET_CONTACT_DETAIL';
export const CLEAR_CONTACT_DETAIL = 'CLEAR_CONTACT_DETAIL';
export const GET_CONTACT_DETAIL = 'GET_CONTACT_DETAIL';
export const ADD_CLIENT_NOTE = 'ADD_CLIENT_NOTE';
export const EDIT_CLIENT_NOTE = 'EDIT_CLIENT_NOTE';
export const DELETE_CLIENT_NOTE = 'DELETE_CLIENT_NOTE';
export const ADD_CLIENT_DOCUMENT = 'ADD_CLIENT_DOCUMENT';
export const EDIT_CLIENT_DOCUMENT = 'EDIT_CLIENT_DOCUMENT';
export const DELETE_CLIENT_DOCUMENT = 'DELETE_CLIENT_DOCUMENT';
export const SUBMIT_CLIENT_CONTRACTS = 'SUBMIT_CLIENT_CONTRACTS';
