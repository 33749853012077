import React, { Component } from 'react';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import CustomModal from '../../../components/Modals/Modal';
import { API } from 'aws-amplify';

import { Link } from "react-router-dom";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

class AnalyticsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], page: 1, totalSize: 1, sizePerPage: 25,
            Draft: false,
            Building: false,
            InProgress: true,
            Paused: false,
            Ended: false,
        }
    }
    componentDidMount() {

        //Get dates for the last 3 months
        let month1 = new Date();
        let month2 = new Date();
        let month3 = new Date();
        month1.setMonth(month1.getMonth() - 0);
        month2.setMonth(month2.getMonth() - 1);
        month3.setMonth(month3.getMonth() - 2);
        this.setState({
            month1: month1.toLocaleString('default', { month: 'long' }),
            month2: month2.toLocaleString('default', { month: 'long' }),
            month3: month3.toLocaleString('default', { month: 'long' }),
        })
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, Draft, Building, InProgress, Paused, Ended }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }

        let filterString = '';
        if (Draft === undefined) { Draft = this.state.Draft } if (Draft) { filterString += `&Draft=${Draft}`; }
        if (Building === undefined) { Building = this.state.Building } if (Building) { filterString += `&Building=${Building}`; }
        if (InProgress === undefined) { InProgress = this.state.InProgress } if (InProgress) { filterString += `&InProgress=${InProgress}`; }
        if (Paused === undefined) { Paused = this.state.Paused } if (Paused) { filterString += `&Paused=${Paused}`; }
        if (Ended === undefined) { Ended = this.state.Ended } if (Ended) { filterString += `&Ended=${Ended}`; }

        API.get('1', `/analytics?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}${filterString}`).then(responseJson => {
            this.setState(() => ({
                page: page, data: responseJson.Output, sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder, totalSize: responseJson.RecordsTotal,
                Draft: Draft,
                Building: Building,
                InProgress: InProgress,
                Paused: Paused,
                Ended: Ended
            }));
        });
    }

    render() {
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <ul className="checkoptionBox">
                    <li style={{ marginRight: -20 }}> <label className="container1">
                        <input type="checkbox" className="contract_status" onChange={() => { this.handleTableChange(null, { Draft: !this.state.Draft }); }} data-status="Draft" />
                        <span className="checkmark"></span> Draft</label></li>
                    <li> <label className="container1"> <input type="checkbox" className="contract_status" data-status="Building" onChange={() => { this.handleTableChange(null, { Building: !this.state.Building }); }} />
                        <span className="checkmark"></span> Building </label></li>
                    <li style={{ marginRight: 20 }}> <label className="container1"> <input type="checkbox" className="contract_status" data-status="In Progress" onChange={() => { this.handleTableChange(null, { InProgress: !this.state.InProgress }); }} defaultChecked />
                        <span className="checkmark"></span> In Progress </label></li>
                    <li style={{ marginRight: -10 }}> <label className="container1">	<input type="checkbox" className="contract_status" data-status="Paused" onChange={() => { this.handleTableChange(null, { Paused: !this.state.Paused }); }} /> <span className="checkmark">
                    </span> Paused </label></li>
                    <li> <label className="container1"> <input type="checkbox" className="contract_status" data-status="Ended" onChange={() => { this.handleTableChange(null, { Ended: !this.state.Ended }); }} />
                        <span className="checkmark"></span> Ended </label></li>
                </ul>
                <hr className="line" />
            </div>
            <div className="container">
                <BootstrapTable
                    remote
                    wrapperClasses="table-responsive"
                    style={{ width: 2000, overflowX: 'auto' }}
                    keyField="ItemId"
                    filter={filterFactory()}
                    pagination={paginationFactory({ page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
                    classes='time_sheet_table'
                    bordered={false}
                    data={this.state.data}
                    page={this.state.page}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                    columns={[
                        {
                            dataField: 'Name',
                            text: 'Contract Name',
                            sort: true,
                            formatter: (cell, row) => { return (<div><b><Link title={`Client Name: ${row.ClientName} \rClient Supervisor: ${row.SupervisorName ? row.SupervisorName : 'N/A'}\rContract Type: ${row.Status} \rHours: ${row.Hours} \rPM: ${row.PMName ? row.PMName : 'N/A'} ${row.PMFee ? '(' + row.PMFee + '%)' : ''} \rCTO: ${row.CTOName ? row.CTOName : 'N/A'} ${row.CTOFee ? '(' + row.CTOFee + '%)' : ''}`} className="contractLink" to={"contracts/" + row.ItemId}>{cell}</Link></b></div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'ClientName',
                            text: 'Client',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Status',
                            text: 'Status',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '5%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Hours',
                            text: 'Hours Contracted',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{cell}/{row.Type === 'Fixed Project' ? 'total' : 'monthly'}{row.Type === 'Fixed Project' && <div style={cell < row.TotalHours ? { color: '#ff0000', fontWeight: '600' } : {}}>To Date: {row.TotalHours}</div>}</div>); },
                            headerStyle: { width: '5%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Month1Hours',
                            text: `${this.state.month1} Total Hours`,
                            sort: true,
                            formatter: (cell, row) => { return (<div style={cell > row.Hours ? { color: '#ff0000', fontWeight: '600' } : {}}>{cell}</div>); },
                            headerStyle: { width: '5%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Month1Breakdown',
                            text: 'Breakdown Actual/Contracted',
                            sort: true,
                            formatter: (cell, row) => { return (<div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: cell }}></div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Month2Hours',
                            text: `${this.state.month2} Total Hours`,
                            sort: true,
                            formatter: (cell, row) => { return (<div style={cell > row.Hours ? { color: '#ff0000', fontWeight: '600' } : {}}>{cell}</div>); },
                            headerStyle: { width: '5%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Month2Breakdown',
                            text: 'Breakdown Actual/Contracted',
                            sort: true,
                            formatter: (cell, row) => { return (<div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: cell }}></div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Month3Hours',
                            text: `${this.state.month3} Total Hours`,
                            sort: true,
                            formatter: (cell, row) => { return (<div style={cell > row.Hours ? { color: '#ff0000', fontWeight: '600' } : {}}>{cell}</div>); },
                            headerStyle: { width: '5%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Month3Breakdown',
                            text: 'Breakdown Actual/Contracted',
                            sort: true,
                            formatter: (cell, row) => { return (<div style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={{ __html: cell }}></div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }
                    ]}
                    defaultSorted={[{
                        dataField: 'Month1Hours',
                        order: 'desc'
                    }]}
                />
            </div>
            <CustomModal />
        </React.Fragment >
        )
    }
}

export default AnalyticsList;