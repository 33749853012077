import { Auth } from 'aws-amplify';

export function refreshToken({ dispatch, getState }) {
    return (next) => (action) => {
        Auth.currentSession()
            .then()
            .catch(err => console.log(err));
        return next(action);
    }

}
