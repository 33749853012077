import React from "react";
import { connect } from "react-redux";
import Nav from "../../../components/Navigation/Nav";
import TopNav from "../../../components/TopNav/TopNav";
import "../styles/SearchPageStyle.scss";
import "../styles/GridStyle.scss";
import "../../../styles/style.scss";
import "../styles/QuickStats.styles.css"

import { Link } from "react-router-dom";
import Moment from "react-moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faFlag, faStickyNote } from "@fortawesome/free-solid-svg-icons";

import { API } from "aws-amplify";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import { Dropdown, Card } from "react-bootstrap";
import { Alert } from "bootstrap";
import Modal from "../../../components/ui/Modal";
import InputDropdown from "../../../components/ui/form/InputDropdown";
import * as storageUtils from "../../../helperFunctions/storageUtils";

import TabsNotes from "../../Members/components/profile/TabsNotes";
import Empty from "../../../components/ui/Empty";

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      page: 1,
      totalSize: 1,
      sizePerPage: 25,
      WorkerName: " ",
      Status: " ",
      JobTitle: " ",
      JobTitleName: " ",
      Location: " ",
      Opportunity: " ",
      OpportunityName: " ",
      ScreeningStatus: " ",
      HasPhoto: false,
      HasResume: false,
      HasLinkedIn: false,
      HasPortfolio: false,
      IsStarred: false,
      JobTitles: [],
      Opportunities: [],
      SelectedRows: [],
      interviewModal: false,
      Subject: "",
      Body: "",
      Types: [
        { title: "Interview with Client", value: "interviewWithClient" },
        { title: "Invite to Skills Test", value: "InviteToSkillsTest" },
        { title: "Offer for Contract", value: "offerForContract" },
        { title: "Pre-Screen", value: "preScreen" },
        { title: "Screening Interview for Position", value: "interviewForPosition" },
      ],
      notesModal: false, 
      notesCount: 0,
      peopleTotal: 0,
      onContractTotal: 0, 
      vettedTotal: 0, 
      Notes: [],
      notesData: []
    };
  }

  componentDidMount() {
    API.get("1", `/members`).then(
      (responseJson) => {
        let data = [];
        responseJson.Output?.map((item) => {
          data.push({
            key: item.ItemId,
            Notes: item.Notes
          });
        });
        this.setState(() => ({ notesData: data }));
      }
    ); 
    API.get("1",`/quickstats`)
    .then((responseJson) => {
      this.setState({ QuickStats: responseJson.Output }, () => {
        this.setState({ peopleTotal: this.state.QuickStats[0].People, 
          onContractTotal: this.state.QuickStats[0].OnContract,
          vettedTotal: this.state.QuickStats[0].Vetted} );
      });
    })
   API.get("1", `/jobtitlesfiltered/dropdown`).then((responseJson) => {
      this.setState({ JobTitles: responseJson.Output });
    });
   API.get("1", `/opportunities?Open=true`).then((responseJson) => {
      this.setState({ Opportunities: responseJson?.Output ?? [] }, () => {
        console.log(this.state.Opportunities);
      });
    });
    API.get("1", `/admins?OrderBy=LastName&SortOrder=ASC&ItemsPerPage=100&Page=0`).then(
      (responseJson) => {
        let data = [];
        let adminsData = {};
        responseJson.Output?.map((item) => {
          data.push({
            title: `${item.FirstName} ${item.LastName} (${item.Email})`,
            value: item.ItemId,
            firstName: item.FirstName,
          });
          adminsData[item.ItemId] = item.FirstName + " " + item.LastName;
        });
        this.setState(() => ({ admins: data, adminsData: adminsData }));
      }
    );
    API.get(
      "1",
      `/opportunities?OrderBy=Title&SortOrder=ASC&ItemsPerPage=100&Page=0&Open=true`
    ).then((responseJson) => {
      let data = [];
      responseJson.Output?.map((item) => {
        data.push({
          title: item.Title,
          value: item.Title,
        });
      });
      this.setState(() => ({ positions: data }));
    });
  }

  handleTableChange = (
    type,
    {
      page,
      sizePerPage,
      sortField,
      sortOrder,
      WorkerName,
      Status,
      JobTitle,
      Location,
      Opportunity,
      ScreeningStatus,
      Notes,
      HasPhoto,
      HasResume,
      HasLinkedIn,
      HasPortfolio,
      IsStarred,
    }
  ) => {
    if (!page && !sizePerPage && !sortField && !sortOrder) {
      page = this.state.page;
      sizePerPage = this.state.sizePerPage;
      sortField = this.state.sortField;
      sortOrder = this.state.sortOrder;
    }

    let filterString = "";
    if (WorkerName === undefined) {
      WorkerName = this.state.WorkerName;
    }
    if (WorkerName !== " ") {
      filterString += `&WorkerName=${WorkerName}`;
    }
    if (Status === undefined) {
      Status = this.state.Status;
    }
    if (Status !== " ") {
      filterString += `&Status=${Status}`;
    }
    if (JobTitle === undefined) {
      JobTitle = this.state.JobTitle;
    }
    if (JobTitle !== " ") {
      filterString += `&JobTitle=${JobTitle}`;
    }
    if (Location === undefined) {
      Location = this.state.Location;
    }
    if (Location !== " ") {
      filterString += `&Location=${Location}`;
    }
    if (Opportunity === undefined) {
      Opportunity = this.state.Opportunity;
    }
    if (Opportunity !== " ") {
      filterString += `&Opportunity=${Opportunity}`;
    }
    if (ScreeningStatus === undefined) {
      ScreeningStatus = this.state.ScreeningStatus;
    }
    if (ScreeningStatus !== " ") {
      filterString += `&ScreeningStatus=${ScreeningStatus.toLowerCase()}`;
    }
    if (HasPhoto === undefined) {
      HasPhoto = this.state.HasPhoto;
    }
    if (HasPhoto) {
      filterString += `&HasPhoto=${HasPhoto}`;
    }
    if (HasResume === undefined) {
      HasResume = this.state.HasResume;
    }
    if (HasResume) {
      filterString += `&HasResume=${HasResume}`;
    }
    if (HasLinkedIn === undefined) {
      HasLinkedIn = this.state.HasLinkedIn;
    }
    if (HasLinkedIn) {
      filterString += `&HasLinkedIn=${HasLinkedIn}`;
    }
    if (HasPortfolio === undefined) {
      HasPortfolio = this.state.HasPortfolio;
    }
    if (HasPortfolio) {
      filterString += `&HasPortfolio=${HasPortfolio}`;
    }
    if (IsStarred === undefined) {
      IsStarred = this.state.IsStarred;
    }
    if (IsStarred) {
      filterString += `&IsStarred=${IsStarred}`;
    }
    
     API.get(
      "1",
      `/members?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${
        page - 1
      }${filterString}`
    ).then((responseJson) => {
      this.setState(() => ({
        page: page,
        data: responseJson.Output,
        sizePerPage: sizePerPage,
        sortField: sortField,
        sortOrder: sortOrder,
        totalSize: responseJson.RecordsTotal,
        WorkerName: WorkerName,
        Status: Status,
        JobTitle: JobTitle,
        Location: Location,
        Opportunity: Opportunity,
        ScreeningStatus: ScreeningStatus,
        Notes: Notes,
        HasPhoto: HasPhoto,
        HasResume: HasResume,
        HasLinkedIn: HasLinkedIn,
        HasPortfolio: HasPortfolio,
        IsStarred: IsStarred
      }));
      window.scrollTo(0, 0);
    });
  };

  toggleStar = async (data, index) => {
    data.Starred = !data.Starred;
    API.put("1", `/members`, { body: { ItemId: data.ItemId, Starred: data.Starred ? 1 : 0 } }).then(
      (responseJson) => {
        this.state.data[index] = data;
        
        this.setState({ render: true });
      }
    );
  };  

  formatMessage = () => {
    let subject = "";
    let message = "";
    if (this.state.Type === "preScreen") {
      subject = `We'd like to get to know you`;
      message = `Welcome to TECKpert.\n\nMy name is ${
        this.state.adminsData[this.state.Admin]
      } and I'd like to setup a Zoom meeting to get to know you better.\n\nPlease click on the link below to find a time that works for you.\n\nhttps://calendly.com/tckage/pre-screen-platform?name=[NAME]&email=[EMAIL]\n\nI look forward to meeting you.\n\n- ${
        this.state.adminsData[this.state.Admin]
      }`;
    } else if (this.state.Type === "interviewForPosition") {
      subject = `We'd like to speak with you about the [OPPORTUNITY NAME] opportunity`;
      message = `Thank you for your interest in our [OPPORTUNITY NAME].\n\nWe are holding pre-screening interviews for this opportunity over the coming days.\n\nClick on the link below to select a time slot.\n\nhttps://calendly.com/tckage/pre-screen-position?name=[NAME]&email=[EMAIL]&a1=[OPPORTUNITY]\n\nPlease take some time to complete your profile on our app prior to the interview. I look forward to meeting with you.\n\n- ${
        this.state.adminsData[this.state.Admin]
      }`;
    } else if (this.state.Type === "interviewWithClient") {
      subject = `Interview Request by our client for the [OPPORTUNITY NAME] opportunity`;
      message = `Our client has reviewed your profile and would like to setup an interview.\n\nClick on the link below to select a time slot.\n\nhttps://calendly.com/tckage/interview-client?name=[NAME]&email=[EMAIL]&a1=[OPPORTUNITY]\n\nWe look forward to meeting with you.\n\n- ${
        this.state.adminsData[this.state.Admin]
      }`;
    } else if (this.state.Type === "offerForContract") {
      subject = `Contract Offer for [OPPORTUNITY NAME]`;
      message = `Congratulations!\n\nOur client is ready for you to join the team!\n\nI have some details below for you to review:\n\n• Desired start date: mm/dd/yy\n•Rate of Pay: $xy\n• Hours per week: NN\n• Length of contract: NN\n\nPlease contact me at 555-555-5555 to review the next steps.\n\nBest,\n- ${
        this.state.adminsData[this.state.Admin]
      }`;
    } else if (this.state.Type === "InviteToSkillsTest") {
      subject = `Thank you for your interest in our [OPPORTUNITY NAME] position.`;
      message = `We are sending you an invite for a skills test from a service called TestDome.\n\nPlease be on the lookout for the invite and respond to the test invite.\n\nThe test must be completed within 3 days to be considered for the position. \n\nBest,\n- ${
        this.state.adminsData[this.state.Admin]
      }`;
    }
    this.setState({ Subject: subject, Body: message });
  };

  onSetupInterview = () => {
    let Subject = this.state.Subject;
    let Body = this.state.Body;
    if (!["preScreen", "", undefined].includes(this.state.Type)) {
      Subject = Subject.replace(/\[OPPORTUNITY NAME\]/g, this.state.Position);
      Body = Body.replace(/\[OPPORTUNITY NAME\]/g, this.state.Position).replace(
        /\[OPPORTUNITY\]/g,
        encodeURI(this.state.Position)
      );
    }
      API.post("1", `/interview`, {
      body: {
        Admin: this.state.Admin,
        MemberIds: this.state.SelectedRows,
        Type: this.state.Type,
        Subject: Subject,
        Body: Body,
      },
    }).then((responseJson) => {
      this.setState({
        interviewModal: false,
        Admin: null,
        Subject: null,
        Body: null,
        Type: null,
        MemberIds: null,
        Position: null,
      });
    });
  };

  render() {
    return (
      <React.Fragment>
        <TopNav />
        <Nav />
        <div className='container'>
          <hr></hr>
          <div style={{ textAlign: "center" }}>
            <b><h4>QuickStats</h4></b>
          </div>
          <div className="main-container">
            <div className="people">
              <Card>
                <h6>Total of People</h6>
                <h4 className="value">{this.state.peopleTotal}</h4>
              </Card>
            </div>
            <div className="on-contract">
              <Card>
                <h6>People On Contract</h6>
                <h4 className="value">{this.state.onContractTotal}</h4>
              </Card>
            </div>
            <div className="vetted">
              <Card>
                <h6>People Vetted</h6>
                <h4 className="value">{this.state.vettedTotal}</h4>
              </Card>
            </div>
          </div>
          <hr></hr>
          <div className='row'>
            <div className='col-md-12'>
              <ul className='search_boxnew'>
                <li className='search-box'>
                  <div className='input-group'>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Search by Name, Title, or Skill'
                      onChange={(e) => {
                        this.handleTableChange(null, {
                          WorkerName: e.currentTarget.value ? e.currentTarget.value : " ",
                        });
                      }}
                    />
                    <div className='input-group-append'>
                      <button className='btn' type='submit'>
                        <svg
                          aria-hidden='true'
                          focusable='false'
                          data-prefix='fas'
                          data-icon='search'
                          className='svg-inline--fa fa-search fa-w-16 SearchIcon'
                          role='img'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 512 512'>
                          <path
                            fill='currentColor'
                            d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'></path>
                        </svg>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
              <ul className='search_boxnew search_filters'>
                <li style={{ flex: "0 0 25%" }}>
                  <Dropdown className='filterToggle'>
                    <Dropdown.Toggle>
                      {this.state.ScreeningStatus !== " " ? this.state.ScreeningStatus : "Status"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          this.handleTableChange(null, { ScreeningStatus: " " });
                        }}>
                        Select Status
                      </Dropdown.Item>
                      {["Approved", "Pending", "Vetted", "On Contract"].map((obj) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              this.handleTableChange(null, { ScreeningStatus: obj });
                            }}>
                            {obj}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li style={{ flex: "0 0 25%" }}>
                  <Dropdown className='filterToggle'>
                    <Dropdown.Toggle>
                      {this.state.JobTitleName !== " " ? this.state.JobTitleName : "Job Title"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{ maxHeight: 400, overflowY: "auto" }}>
                      <Dropdown.Item
                        onClick={() => {
                          this.handleTableChange(null, { JobTitle: " " });
                          this.setState({ JobTitleName: " " });
                        }}>
                        Select Job Title
                      </Dropdown.Item>
                      {/* {this.state.JobTitles?.map((obj) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              this.handleTableChange(null, { JobTitle: obj.ItemId });
                              this.setState({ JobTitleName: obj.Name });
                            }}>
                            {obj.Name}
                          </Dropdown.Item>
                        );
                      })} */}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li style={{ flex: "0 0 25%" }}>
                  <Dropdown className='filterToggle'>
                    <Dropdown.Toggle>
                      {this.state.Location !== " " ? this.state.Location : "Location"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          this.handleTableChange(null, { Location: " " });
                        }}>
                        Select Location
                      </Dropdown.Item>
                      {["Remote", "On Site"].map((obj) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              this.handleTableChange(null, { Location: obj });
                            }}>
                            {obj}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li style={{ flex: "0 0 25%" }}>
                  <Dropdown className='filterToggle'>
                    <Dropdown.Toggle>
                      {this.state.OpportunityName !== " "
                        ? this.state.OpportunityName
                        : "Opportunity"}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          this.handleTableChange(null, { Opportunity: " " });
                          this.setState({ OpportunityName: " " });
                        }}>
                        Select Opportunity
                      </Dropdown.Item>
                      {/* {this.state.Opportunities?.map((obj) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              this.handleTableChange(null, { Opportunity: obj.ItemId });
                              this.setState({ OpportunityName: obj.Name });
                            }}>
                            {obj.Title}
                          </Dropdown.Item>
                        );
                      })} */}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </ul>
            </div>
          </div>
          <div
            className='row'
            style={{ justifyContent: "space-between", marginRight: 20, marginLeft: 20 }}>
            <div className='row' style={{ justifyContent: "space-between" }}>
              <ul className='checkoptionBox' style={{ margin: 0 }}>
                <li style={{ marginRight: 40 }}>
                  {" "}
                  <label className='container1' style={{ marginBottom: 0 }}>
                    <input
                      type='checkbox'
                      className='opportunity_status'
                      onChange={() => {
                        this.handleTableChange(null, { HasResume: !this.state.HasResume });
                      }}
                      data-status='HasResume'
                    />
                    <span className='checkmark'></span> Has Resume
                  </label>
                </li>
              </ul>
              <ul className='checkoptionBox' style={{ margin: 0 }}>
                <li style={{ marginRight: 40 }}>
                  {" "}
                  <label className='container1' style={{ marginBottom: 0 }}>
                    <input
                      type='checkbox'
                      className='opportunity_status'
                      onChange={() => {
                        this.handleTableChange(null, { HasLinkedIn: !this.state.HasLinkedIn });
                      }}
                      data-status='HasLinkedIn'
                    />
                    <span className='checkmark'></span> Has LinkedIn
                  </label>
                </li>
              </ul>
              <ul className='checkoptionBox' style={{ margin: 0 }}>
                <li style={{ marginRight: 40 }}>
                  {" "}
                  <label className='container1' style={{ marginBottom: 0 }}>
                    <input
                      type='checkbox'
                      className='opportunity_status'
                      onChange={() => {
                        this.handleTableChange(null, { HasPortfolio: !this.state.HasPortfolio });
                      }}
                      data-status='HasPortfolio'
                    />
                    <span className='checkmark'></span> Has Portfolio
                  </label>
                </li>
              </ul>
              <ul className='checkoptionBox' style={{ margin: 0 }}>
                <li style={{ marginRight: 40 }}>
                  {" "}
                  <label className='container1' style={{ marginBottom: 0 }}>
                    <input
                      type='checkbox'
                      className='opportunity_status'
                      onChange={() => {
                        this.handleTableChange(null, { HasPhoto: !this.state.HasPhoto });
                      }}
                      data-status='HasPhoto'
                    />
                    <span className='checkmark'></span> Has Photo
                  </label>
                </li>
              </ul>
              <ul className='checkoptionBox' style={{ margin: 0 }}>
                <li style={{ marginRight: 40 }}>
                  {" "}
                  <label className='container1' style={{ marginBottom: 0 }}>
                    <input
                      type='checkbox'
                      className='opportunity_status'
                      onChange={() => {
                        this.handleTableChange(null, { IsStarred: !this.state.IsStarred });
                      }}
                      data-status='IsStarred'
                    />
                    <span className='checkmark'></span>Is Starred
                  </label>
                </li>
              </ul>
            </div>
            {this.state.SelectedRows.length > 0 ? (
              <a
                href='javascript:void(0)'
                onClick={() => {
                  this.setState({ interviewModal: !this.state.interviewModal });
                }}>
                Setup Interviews
              </a>
            ) : (
              <span style={{ color: "#cccccc" }}>Setup Interviews</span>
            )}
          </div>
        </div>
        <div className='container'>
          <hr className='line' />
          <BootstrapTable
            remote
            keyField='ItemId'
            filter={filterFactory()}
            pagination={paginationFactory({
              page: this.state.page,
              sizePerPage: this.state.sizePerPage,
              totalSize: this.state.totalSize,
              hideSizePerPage: true,
              alwaysShowAllBtns: true,
              withFirstAndLast: false,
            })}
            classes='time_sheet_table'
            bordered={false}
            data={this.state.data}
            page={this.state.page}
            sizePerPage={this.state.sizePerPage}
            totalSize={this.state.totalSize}
            onTableChange={this.handleTableChange}
            columns={[
              {
                dataField: "Starred",
                text: <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faStar} />,
                sort: true,
                formatter: (cell, row, index) => {
                  return (
                    <FontAwesomeIcon
                      style={{ cursor: "pointer", color: cell ? "#6FBE47" : "#cccccc" }}
                      onClick={() => {
                        this.toggleStar(row, index);
                      }}
                      icon={faStar}
                    />
                  );
                },
                headerStyle: { width: "5%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn' href='#'>
                        {column.text}
                      </a>
                      {sortElement}
                    </div>
                  );
                },
              },
              {
                dataField: "LastName",
                text: "Name",
                sort: true,
                formatter: (cell, row) => {
                  return (
                    <div>
                      <b>
                        <Link className='contractLink' to={"workers/" + row.ItemId}>
                          {row.FirstName} {row.LastName}
                        </Link>
                      </b>
                    </div>
                  );
                },
                headerStyle: { width: "15%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn' href='#'>
                        {column.text}
                      </a>
                      {sortElement}
                    </div>
                  );
                },
              }, 
              {
                dataField: "PrimaryTitle",
                text: "Role",
                sort: true,
                formatter: (cell) => {
                  return <div>{cell}</div>;
                },
                headerStyle: { width: "12%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn' href='#'>
                        {column.text}
                      </a>
                      {sortElement}
                    </div>
                  );
                },
              },
              {
                dataField: "ScreeningStatus",
                text: "Status",
                sort: true,
                formatter: (cell, row) => {
                  return (
                    <div style={{ textTransform: "capitalize" }}>
                      {cell === "pending" ? (
                        <a
                          onClick={() => {
                            let response = window.confirm(
                              "Are you sure you want to approve this user?"
                            );
                            if (response) {
                              API.get("1", `/users/confirm?Members_ItemId=${row.ItemId}`).then(
                                (success) => {
                                  this.handleTableChange(null, {});
                                },
                                (error) => {
                                  this.handleTableChange(null, {});
                                }
                              );
                            }
                          }}
                          href='#'>
                          {cell}
                        </a>
                      ) : (
                        cell
                      )}
                    </div>
                  );
                },
                headerStyle: { width: "5%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn' href='#'>
                        {column.text}
                      </a>
                      {sortElement}
                    </div>
                  );
                },
              },
              {
                dataField: "Meta.LocationAvailability",
                text: "Availability",
                sort: false,
                formatter: (cell, row) => {
                  return <div style={{ textTransform: "capitalize" }}>{cell}</div>;
                },
                headerStyle: { width: "10%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn'>{column.text}</a>
                      {sortElement}
                    </div>
                  );
                },
              },
              {
                dataField: "Meta.Location",
                text: "Location",
                sort: false,
                formatter: (cell, row) => {
                  return <div>{cell}</div>;
                },
                headerStyle: { width: "10%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn'>{column.text}</a>
                      {sortElement}
                    </div>
                  );
                },
              },
              {
                dataField: "Meta.Education",
                text: "Recent Education",
                sort: false,
                formatter: (cell, row) => {
                  return (
                    <div>{cell && cell[0] && `${cell[0]?.End} | ${cell[0]?.Degree} at ${cell[0]?.School}`}</div>
                  );
                },
                headerStyle: { width: "20%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn'>{column.text}</a>
                      {sortElement}
                    </div>
                  );
                },
              },
              {
                dataField: "DateCreated",
                text: "Date Registered",
                sort: true,
                formatter: (cell, row) => {
                  return (
                    <div>
                      <Moment format='MM/DD/YYYY'>{cell}</Moment>
                    </div>
                  );
                },
                headerStyle: { width: "10%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn' href='#'>
                        {column.text}
                      </a>
                      {sortElement}
                    </div>
                  );
                },
              },
              {
                dataField: "Vetted",
                text: "Vetted",
                sort: true,
                formatter: (cell, row) => {
                  return (
                    <div>
                      {cell === "0000-00-00" ? "" : "✓"}{" "}
                      {row.Flagged ? <FontAwesomeIcon icon={faFlag} /> : ""}
                    </div>
                  );
                },
                headerStyle: { width: "5%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn' href='#'>
                        {column.text}
                      </a>
                      {sortElement}
                    </div>
                  );
                },
              },                        
              {
                dataField: "Notes",
                text: "Notes",
                sort: true,
                formatter: (cell, row) => {
                  return (
                    <div>
                      <b>
                        <a href='javascript:void(0)'
                          onClick={() => {
                            this.setState({ notesModal: !this.state.notesModal, ItemId: row.ItemId });
                          }}>
                          {row.Notes && row.Notes.length === 0 ? 
                          <FontAwesomeIcon style={{ cursor:"pointer", color: "gray" }} icon={faStickyNote} /> 
                          : <FontAwesomeIcon icon={faStickyNote} />}
                        </a>
                      </b>
                    </div>
                  );
                },
                headerStyle: { width: "15%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn' href='#'>
                        {column.text}
                      </a>
                      {sortElement}
                    </div>
                  );
                },
              }, 
              {
                dataField: "Meta.Portfolio",
                text: "Links",
                sort: true,
                formatter: (cell, row) => {
                  return (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <a
                        target='_blank'
                        href={
                          row.Meta.LinkedIn !== undefined &&
                          (row.Meta.LinkedIn.startsWith("http")
                            ? row.Meta.LinkedIn
                            : `https://${row.Meta.LinkedIn}`)
                        }>
                        LinkedIn
                      </a>
                      <a
                        target='_blank'
                        href={
                          cell !== undefined && (cell.startsWith("http") ? cell : `https://${cell}`)
                        }>
                        Portfolio
                      </a>
                      {row.Resume ? (
                        <a
                          target='_blank'
                          href={"javascript:void(0)"}
                          onClick={() => storageUtils.getStorageFile(row.Resume, true)}>
                          Resume
                        </a>
                      ) : (
                        <a>Resume</a>
                      )}
                    </div>
                  );
                },
                headerStyle: { width: "10%" },
                headerFormatter: (column, colIndex, { sortElement }) => {
                  return (
                    <div>
                      <a className='sortcolumn' href='#'>
                        {column.text}
                      </a>
                      {sortElement}
                    </div>
                  );
                },
              },
            ]}
            selectRow={{
              mode: "checkbox",
              clickToSelect: true,
              onSelect: (row, isSelect) => {
                if (isSelect) {
                  this.state.SelectedRows.push(row.ItemId);
                } else {
                  this.state.SelectedRows.splice(
                    this.state.SelectedRows.findIndex((item) => item === row.ItemId),
                    1
                  );
                }
                this.setState({});
              },
              onSelectAll: (isSelect, rows) => {
                this.setState({ SelectedRows: isSelect ? rows?.map((obj) => obj.ItemId) : [] });
              },
            }}
            defaultSorted={[
              {
                dataField: "DateCreated",
                order: "desc",
              },
            ]}
          />
        </div>

        <Modal
          isVisible={this.state.interviewModal}
          title={`Set up interviews with ${this.state.SelectedRows.length} workers`}
          onSubmit={this.onSetupInterview}
          onClose={() => {
            this.setState({ interviewModal: false });
          }}>
          <div className='container send_to_client_container'>
            <div className='clients_appBox from-for'>
              <div className='row'>
                <div className='col-md'>
                  <div className='form-group'>
                    <label class='form-label'>From</label>
                    <InputDropdown
                      placeholder='Select Admin'
                      onChange={(value) => {
                        setTimeout(() => {
                          this.formatMessage();
                        }, 0);
                        this.setState({ Admin: value });
                      }}
                      options={this.state.admins}
                    />
                  </div>
                </div>
                <div className='col-md'>
                  <div className='form-group'>
                    <label class='form-label'>Type</label>
                    <InputDropdown
                      placeholder='Select Type'
                      onChange={(value) => {
                        setTimeout(() => {
                          this.formatMessage();
                        }, 0);
                        this.setState({ Type: value });
                      }}
                      options={this.state.Types}
                    />
                  </div>
                </div>
                {!["preScreen", "", undefined].includes(this.state.Type) && (
                  <div className='col-md'>
                    <div className='form-group'>
                      <label class='form-label'>Position</label>
                      <InputDropdown
                        placeholder='Select Position'
                        onChange={(value) => {
                          setTimeout(() => {
                            this.formatMessage();
                          }, 0);
                          this.setState({ Position: value });
                        }}
                        options={this.state.positions}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label className='form-label'>Subject</label>
                    <input
                      type='text'
                      className='form-control'
                      onChange={(event) => this.setState({ Subject: event.currentTarget.value })}
                      value={this.state.Subject}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label className='form-label'>Body</label>
                    <textarea
                      type='text'
                      className='form-control'
                      onChange={(event) => this.setState({ Body: event.currentTarget.value })}
                      value={this.state.Body}
                      rows={15}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isVisible={this.state.notesModal}
          onClose={() => {
            this.setState({ notesModal: false });
          }}>
            <div>
              <div style={{ marginTop: "38px" }}></div>
              {
                this.state.notesData?.map((item, id) => (
                  item.key === this.state.ItemId ? 
                     (item.Notes && item.Notes.length !== 0 ? 
                      <TabsNotes member={this.state.notesData[id]}></TabsNotes>
                      : <div className="col-md-12"><div style={{ height: 40 }} /><Empty message="No notes found !" /></div>)
                  : " "                                            
                 ))
              }
              <div style={{height: "42px", backgroundColor: "white", position: "relative", top: "72px", left: "15px"}}></div>
            </div>
           
        </Modal>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);
