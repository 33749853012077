import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


class AddContracts extends Component {
  constructor(props) {
    super(props);
    this.handleModalClose.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      var isModalFound = document.getElementsByClassName('modal show');
      if(isModalFound.length > 1){
        document.getElementsByClassName('modal show')[0].style.display="none";
      }
    }, 1000);
  }

  handleModalClose() {
    this.props.setModalName(null);
    this.props.closeModal();
  }

  //
  render() {
    return (
      <React.Fragment>
        <Modal animation={false} size="lg" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Contracts</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="exampleForm.ControlSelect1" inline>
                    <Form.Label>Status</Form.Label>
                    <Form.Control as="select">
                      <option>Active</option>
                      <option>Completed</option>
                      <option>On Hold</option>
                    </Form.Control>
                  </Form.Group>

                </Col>

              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Start Date</Form.Label>
                    <Form.Control type="date" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>End Date</Form.Label>
                    <Form.Control type="date" />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Upload Documents</Form.Label>
                    <br />
                    <input id="file-upload" type="file" accept=".pdf" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Check
                      type={`checkbox`}
                      id={`checkbox`}
                      label={'MSA?'}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Check
                      type={`checkbox`}
                      id={`checkbox`}
                      label={'Job Order(s)?'}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
          </Button>
            <Button variant="primary">
              Save Changes
          </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )

  }
}
AddContracts.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
  getContracts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    memberContracts: state.contract.memberContracts,
  }
}
export default connect(mapStateToProps, { closeModal, setModalName })(AddContracts);