import React, { Component } from "react";
import PropTypes from "prop-types";

export class InputDropdown extends Component {

  onChange(event) {
    this.props.onChange(event.currentTarget.value);
  }

  render() {
    
    let inputClass = 'form-control';
    if(this.props.validFeedback !== '') inputClass += ' is-valid';
    if(this.props.invalidFeedback !== '') inputClass += ' is-invalid';
    return (
      <div className="form-group">
        {this.props.label !== "" && (
          <label className="form-label" style={this.props.labelStyle}>{this.props.label}</label>
        )}

          <select
            className={inputClass}
            onChange={(event) => this.onChange(event)}
            defaultValue={this.props.selected}
          >
          {this.props.placeholder && 
            <option value='' >{this.props.placeholder}</option>
          }
          {this.props.options.map((item,index) => {
            return (
              <option key={`${item.value}-${index}`} value={item.value ? item.value: item.title } >{item.title}</option>
            )
          })}
          </select>
          {this.props.invalidFeedback !== '' && <div class="invalid-feedback">{this.props.invalidFeedback}</div>}
          {this.props.validFeedback !== '' && <div class="valid-feedback">{this.props.validFeedback}</div>}
        
      </div>
    );
  }
}

InputDropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.string,
  labelStyle: PropTypes.object
};

InputDropdown.defaultProps = {
  label: '',
  placeholder: '',
  options: [],
  validFeedback: '',
  invalidFeedback: '',
  selected: '',
}

export default InputDropdown;
