import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, setModalName } from '../../actions/uiActions';
import { deleteSelectedTimeSheet } from '../../modules/Timesheet/actions'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './ModalStyle.scss';
import Moment from 'react-moment';
import { getStorageFile } from '../../helperFunctions/storageUtils';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner

} from "@fortawesome/free-solid-svg-icons";

class DetailedTimeSheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      "profilePic": "",
    }
    this.handleModalClose.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      var isModalFound = document.getElementsByClassName('modal show');
      if (isModalFound.length > 1) {
        document.getElementsByClassName('modal show')[0].style.display = "none";
      }

    }, 1000);
    if (this.props.timesheetList.selectedTimeSheet[0].ProfilePicture !== "undefined" && this.props.timesheetList.selectedTimeSheet[0].ProfilePicture !== null) {
      getStorageFile(this.props.timesheetList.selectedTimeSheet[0].ProfilePicture).then((url) => {
        if (url && url !== '') {
          this.setState({ profilePic: url });
        }
      });
    }
  }

  handleModalClose() {
    this.props.setModalName(null);
    this.props.closeModal();
    //this.props.deleteSelectedTimeSheet();
  }

  //
  render() {
    let PeriodFrom = '';
    let PeriodTo = '';
    let TimeSheetDays = [];
    let PrimaryTitle = "";
    let HourlyRate = "";
    if (this.props.timesheetList.detailedTimeSheet.length > 0) {
      PrimaryTitle = this.props.timesheetList.detailedTimeSheet[0].PrimaryTitle;
      HourlyRate = this.props.timesheetList.detailedTimeSheet[0].HourlyRate;
      PeriodFrom = this.props.timesheetList.detailedTimeSheet[0].PeriodStart.split('T');
      PeriodTo = this.props.timesheetList.detailedTimeSheet[0].PeriodEnd.split('T');
      PeriodFrom = PeriodFrom[0];
      PeriodTo = PeriodTo[0];
      TimeSheetDays = this.props.timesheetList.detailedTimeSheet[0].TimesheetDays;


    }
    return (
      <React.Fragment>
        <Modal animation={false} size="xl" className="detailed_time_sheet_modal" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            {(PeriodFrom !== "" && PeriodTo !== "") ?
              <Modal.Title> Timesheet Period: From <Moment format="MM/DD/YYYY">{PeriodFrom}</Moment> to <Moment format="MM/DD/YYYY">{PeriodTo}</Moment> </Modal.Title>
              :
              <Modal.Title> Timesheet Period: </Modal.Title>
            }
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <ul className="profildetail">
                <li>
                  <div className="userprofil">
                    {(this.state.profilePic === "") ? "" :
                      <img className="contactImg" src={this.state.profilePic} />
                    }
                  </div>
                </li>
                <li className="username">
                  <h3> {this.props.timesheetList.selectedTimeSheet[0].Member} </h3>
                  <h4> {PrimaryTitle} </h4>
                  <h5> Status: {this.props.timesheetList.selectedTimeSheet[0].Status} </h5>
                </li>
              </ul>
              <table className="table mt-4 time_sheet_table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Hours</th>
                    <th>Rate</th>
                    <th>Category</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {TimeSheetDays.map((timesheetday) => {
                    return (
                      <tr>
                        <td> <Moment format="MM/DD/YYYY">{timesheetday.Date}</Moment> </td>
                        <td> {timesheetday.Hours} </td>
                        <td> ${HourlyRate} </td>
                        <td> {timesheetday.Category==='undefined' ? 'Uncategorized' : timesheetday.Category} </td>
                        <td> {timesheetday.Details} </td>
                      </tr>
                    )
                  })}
                  {this.props.timesheetList.isLoading ?
                    <div className="loader"><FontAwesomeIcon icon={faSpinner} spin /></div>
                    : ''}
                </tbody>
              </table>


            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    )

  }
}
DetailedTimeSheet.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    memberInfo: state.member.memberInfo[0],
    timesheetList: state.timesheetList,
  }
}
export default connect(mapStateToProps, { closeModal, setModalName, deleteSelectedTimeSheet })(DetailedTimeSheet);