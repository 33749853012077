import React, { Component } from 'react';
import '../styles/ClientStyles.scss';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { connect } from 'react-redux';
import * as clientActions from '../actions/index';
import { Link } from "react-router-dom";

import { faFile } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { API } from 'aws-amplify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

class ClientList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], page: 1, totalSize: 1, sizePerPage: 25,
            ClientName: ' '
        }
    }

    componentDidMount() {
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, ClientName, Open, Closed }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }

        let filterString = '';
        if (ClientName === undefined) { ClientName = this.state.ClientName } if (ClientName !== ' ') { filterString += `&ClientName=${ClientName}` }

        API.get('1', `/clients?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}${filterString}`).then(responseJson => {
            this.setState(() => ({
                page: page, data: responseJson.Output, sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder, totalSize: responseJson.RecordsTotal,
                ClientName: ClientName
            }));
            window.scrollTo(0, 0);
        });
    }

    render() {
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <ul className="search_boxnew single-search">
                            <li className="search-box ">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search by Client or Contact Name" onChange={(e) => { this.handleTableChange(null, { ClientName: e.currentTarget.value ? e.currentTarget.value : ' ' }) }} />
                                    <div className="input-group-append">
                                        <button className="btn" type="submit">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 SearchIcon" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container">
                <hr className="line" />
                <BootstrapTable
                    remote
                    keyField="ItemId"
                    filter={filterFactory()}
                    pagination={paginationFactory({ page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
                    classes='time_sheet_table'
                    bordered={false}
                    data={this.state.data}
                    page={this.state.page}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                    columns={[
                        {
                            dataField: 'Name',
                            text: 'Company',
                            sort: true,
                            formatter: (cell, row) => { return (<div><b><Link className="contractLink" to={"clients/" + row.ItemId}>{cell}</Link></b></div>); },
                            headerStyle: { width: '25%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Location',
                            text: 'Location',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '25%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Name',
                            text: 'Contact',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '20%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'ActiveContracts',
                            text: 'Active Contracts',
                            sort: false,
                            formatter: (cell, row) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '20%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'ItemId',
                            text: 'Details',
                            sort: false,
                            formatter: (cell) => { return (<div><Link className="clientDetailBtn" to={"clients/" + cell}><FontAwesomeIcon className="icon2 timesheetDetail" icon={faFile} /></Link></div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn">{column.text}</a>{sortElement}</div>) },
                        },
                    ]}
                    defaultSorted={[{
                        dataField: 'Name',
                        order: 'asc'
                    }]}
                />
            </div>
        </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        myState: state,
        clients: state.clientList.clients,
        filteredClients: state.clientList.filterClients,
        clientList: state.clientList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchClients: () => dispatch(clientActions.fetchClients()),
        filterClients: (data) => dispatch(clientActions.filterClients(data)),
        sortClients: (data) => dispatch(clientActions.sortClients(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);