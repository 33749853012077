import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux';
import './ModalStyle.scss';
import Input from "../../components/ui/form/Input";
import InputDropdown from "../../components/ui/form/InputDropdown";
import InputMultiselect from "../../components/ui/form/InputMultiselect";
import InputTextArea from "../../components/ui/form/InputTextArea";
import * as timesheetActions from '../../modules/Timesheet/actions/index';
import {
  faSpinner,

} from "@fortawesome/free-solid-svg-icons";
import { API } from 'aws-amplify';
class SendToClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        Client: '',
        Contact: [],
        Subject: '',
        Details: '',
      },
      isValid: false,
      clients: [],
      admins: []
    }
    this.handleModalClose.bind(this);
  }
  componentDidMount() {
    setTimeout(() => {
      var isModalFound = document.getElementsByClassName('modal show');
      if (isModalFound.length > 1) {
        document.getElementsByClassName('modal show')[0].style.display = "none";
      }
    }, 1000);

    let Timesheets = [];
    for (var i = 0; i < this.props.timesheetList.selectedTimeSheet.length; i++) {
      Timesheets.push(this.props.timesheetList.selectedTimeSheet[i].ItemId);
    }
    let TimesheetIds = Timesheets.toString();
    this.props.setTimesheetFormData("Timesheets", TimesheetIds);
    this.props.setTimesheetFormData("Client", this.props.timesheetList.selectedTimeSheet[0].Client);
    if (typeof this.props.timesheetList.selectedTimeSheet[0].Contacts !== "undefined" && this.props.timesheetList.selectedTimeSheet[0].Contacts.length > 0 && this.props.timesheetList.selectedTimeSheet[0].Contacts !== null) {
      this.props.setTimesheetFormData("Contact", this.props.timesheetList.selectedTimeSheet[0].Contacts[0].ItemId);
    }

    let MessageDetails = 'Hi [FIRST NAME],\n\nPlease click on this link to approve the following Timesheets: [LINK]\n\nThanks,\nTECKpert';
    this.props.setTimesheetFormData('Details', MessageDetails);
    this.props.timesheetList.selectedTimeSheet[0].Details = MessageDetails;
    let MessageSubject = "Timesheet Approval Required for Period(s): ";
    let periodArray = [];
    this.props.timesheetList.selectedTimeSheet.forEach(obj => {
      if (!periodArray.includes(obj.Period)) {
        periodArray.push(obj.Period);
        MessageSubject += obj.Period + ', ';
      }
    });
    MessageSubject = MessageSubject.slice(0, -2);
    this.props.setTimesheetFormData('Subject', MessageSubject);
    this.props.timesheetList.selectedTimeSheet[0].Subject = MessageSubject;

    this.props.fetchAllContacts(this.props.timesheetList.selectedTimeSheet[0].ClientId);

    API.get('1', `/admins?OrderBy=LastName&SortOrder=ASC&ItemsPerPage=100&Page=0`).then(responseJson => {
      let data = [];
      responseJson.Output.map(item => {
        data.push({
          title: `${item.FirstName} ${item.LastName} (${item.Email})`,
          value: item.ItemId,
          firstName: item.FirstName
        });
      });
      this.setState(() => ({ admins: data }));
    });
  }
  parseAllContactsData = () => {
    let data = [];
    if (typeof this.props.timesheetList.allContacts !== "undefined" && this.props.timesheetList.allContacts.length > 0 && this.props.timesheetList.allContacts !== null) {
      this.props.timesheetList.allContacts.map(item => {
        data.push({
          title: `${item.FirstName} ${item.LastName}`,
          value: item.ItemId,
          firstName: item.FirstName
        });
      });

    }
    return data;
  }
  onSubmit = () => {
    this.validateData().then(hasErrors => {
      if (!hasErrors) {
        this.props.addTimesheetData.Contact = this.props.addTimesheetData.Contact.map(obj => obj.value).join(',');
        this.props.sendToClient(this.props.addTimesheetData);
      }
    });
  }

  setError = (key, message) => {
    let formErrors = this.state.formErrors;
    formErrors[key] = message;
    this.setState({
      formErrors: formErrors,
      isValid: false,
    });
  }
  clearFormErrors = async () => {
    const emptyErrors = {
      Client: '',
      Contact: [],
      Subject: '',
      Details: '',
    };
    await this.setState({
      formErrors: emptyErrors,
    });
  }
  validateData = () => {
    const data = this.props.addTimesheetData;
    console.log(data);
    return this.clearFormErrors().then(() => {
      let hasErrors = false;

      if (typeof data.Client === 'undefined' || data.Client === '') {

        this.setError('Client', 'Please provide Client Name');
        hasErrors = true;
      }
      if (typeof data.Contact === 'undefined' || data.Contact === '') {

        this.setError('Contact', 'Please select contact');
        hasErrors = true;
      }
      return hasErrors;
    });
  }
  closeModalCallback() {
    this.props.setModalName(null);
    this.props.closeModal();
  }
  handleModalClose() {
    document.getElementsByClassName('selection-cell-header')[0].getElementsByTagName('input')[0].click();
    this.props.setModalName(null);
    this.props.closeModal();
  }
  onChange(key, value) {
    this.props.setTimesheetFormData(key, value);
  }
  onInput = (key, value) => {
    this.props.setTimesheetFormData(key, value);
  }
  //
  render() {
    let allContacts = this.parseAllContactsData();
    let Timesheets = [];
    for (var i = 0; i < this.props.timesheetList.selectedTimeSheet.length; i++) {
      Timesheets.push(this.props.timesheetList.selectedTimeSheet[i].ItemId);
    }

    return (
      <React.Fragment>
        <Modal animation={false} size="lg" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Send Links to Clients for Approval</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container send_to_client_container">
              <div className="clients_appBox from-for">
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <label class="form-label">From</label>
                      <InputDropdown
                        placeholder="Select Admin"
                        onChange={value => this.onChange("Admin", value)}
                        options={this.state.admins}
                        invalidFeedback={this.state.formErrors.Admin}
                      />
                    </div>
                  </div>
                  <div className="col-md">
                    <div className="form-group">
                      <label class="form-label">To</label>
                      {(allContacts.length > 0) &&
                        <InputMultiselect
                          placeholder="Select Contact"
                          onChange={value => this.onChange("Contact", value)}
                          options={this.parseAllContactsData()}
                          invalidFeedback={this.state.formErrors.Contact}
                        />
                      }
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <Input
                        label="Subject"
                        placeholder="Enter the Subject"
                        onChange={value => this.onInput("Subject", value)}
                        validFeedback=""
                        value={this.props.timesheetList.selectedTimeSheet[0].Subject}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label class="form-label">Message</label>
                      {(allContacts.length > 0) &&
                        <InputTextArea
                          placeholder="Enter the Message"
                          onChange={value => this.onInput("Details", value)}
                          validFeedback=""
                          rows="15"
                          value={this.props.timesheetList.selectedTimeSheet[0].Details}
                        />
                      }
                    </div>
                  </div>

                </div>
              </div>
              {this.props.timesheetList.isLoading &&
                <div className="loader"><FontAwesomeIcon icon={faSpinner} spin /></div>
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
          </Button>
            <Button variant="primary" onClick={() => this.onSubmit()}>
              Send
          </Button>
          </Modal.Footer>

          {this.props.timesheetList.sendToClientResponse === "Success" ?
            this.closeModalCallback()
            : this.props.timesheetList.sendToClientResponse !== "" ? <div className="status error">Error in sending timesheet to client!</div> : ''
          }
        </Modal>
      </React.Fragment>
    )

  }
}
SendToClient.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    memberInfo: state.member.memberInfo[0],
    timesheetList: state.timesheetList,
    addTimesheetData: state.timesheetList.addTimesheetData,
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (data) => dispatch(closeModal()),
    setModalName: (data) => dispatch(setModalName(data)),
    setTimesheetFormData: (key, value) => dispatch(timesheetActions.setTimesheetFormData(key, value)),
    sendToClient: (data) => dispatch(timesheetActions.sendToClient(data)),
    fetchAllContacts: (data) => dispatch(timesheetActions.fetchAllContacts(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SendToClient);