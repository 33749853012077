import React, { Component } from "react";
import AccordionCard from "../../../../components/ui/AccordionCard";
import { certificationsOptions } from "../../config";
import ReactTags from 'react-tag-autocomplete';

import { Modal } from "react-bootstrap";
import Empty from '../../../../components/ui/Empty';

export class Certifications extends Component {
  static propTypes = {};

  constructor() {
    super();
    this.state = {
      VerifiedCertifications: [],
      UnverifiedCertifications: [],
      modalEnabled: false
    };
  }

  componentWillReceiveProps(newprops) {
    this.setState({
      VerifiedCertifications: this.getVerifiedCertifications(newprops),
      UnverifiedCertifications: this.getUnverifiedCertifications(newprops)
    });
  }

  getVerifiedCertifications = props => {
    const { member } = props;
    return typeof member.Meta !== "undefined" &&
      typeof member.Meta.VerifiedCertifications !== "undefined" &&
      member.Meta.VerifiedCertifications !== ""
      ? member.Meta.VerifiedCertifications
      : [];
  };

  getUnverifiedCertifications = props => {
    const { member } = props;
    return typeof member.Meta !== "undefined" &&
      typeof member.Meta.UnverifiedCertifications !== "undefined" &&
      member.Meta.UnverifiedCertifications !== ""
      ? member.Meta.UnverifiedCertifications
      : [];
  };

  handleRemove = (type, i) => {
    if (type === 'v') {
      const VerifiedCertifications = this.state.VerifiedCertifications.slice(0);
      VerifiedCertifications.splice(i, 1);
      this.setState({ VerifiedCertifications });
    } else {
      const UnverifiedCertifications = this.state.UnverifiedCertifications.slice(0);
      UnverifiedCertifications.splice(i, 1);
      this.setState({ UnverifiedCertifications });
    }
  }

  handleAdd = (type, tag) => {
    if (type === 'v') {
      const VerifiedCertifications = [].concat(this.state.VerifiedCertifications, tag.name);
      this.setState({VerifiedCertifications});
    } else {
      const UnverifiedCertifications = [].concat(this.state.UnverifiedCertifications, tag.name);
      this.setState({UnverifiedCertifications});
    }
  }

  handleModalClose = () => {
    this.setState({modalEnabled: false});
  }

  handleSubmit = () => {
    this.props.onSubmit(this.state.VerifiedCertifications, this.state.UnverifiedCertifications).then(resposne => {
      this.setState({modalEnabled: false});
    });
  }

  render() {
    const VerifiedCertifications = this.getVerifiedCertifications(this.props);
    const UnverifiedCertifications = this.getUnverifiedCertifications(this.props);
    
    const ModalVerifiedCertifications = [];
    this.state.VerifiedCertifications.map((item, index) => {
      ModalVerifiedCertifications.push({
        id: index,
        name: item,
      });
    });

    const ModalUnverifiedCertifications = [];
    this.state.UnverifiedCertifications.map((item, index) => {
      ModalUnverifiedCertifications.push({
        id: index,
        name: item,
      });
    });

    return (
      <div>
        <AccordionCard
          title="Certifications"
          actionhtml={
            <span
              className="add-sign"
              onClick={() => {
                this.setState({ modalEnabled: true });
              }}
            >
              +
            </span>
          }
        >
          {VerifiedCertifications.length > 0 ? (
            <div className="Specializations__list">
              <h5>Verified Certifications</h5>
              {VerifiedCertifications.map((item, index) => {
                return (
                  <span
                    key={`profile-verified-certifications-${index}`}
                    className="Specializations__list__item"
                  >
                    {item}
                    <i className="Specializations__list__item__saperator">,</i>
                  </span>
                );
              })}
            </div>
          ):
          <Empty message="No verified certifications found" />
          }
          <div style={{ height: 20 }} />
          {UnverifiedCertifications.length > 0 ? (
            <div className="Specializations__list">
              <h5>Unverified Certifications</h5>
              {UnverifiedCertifications.map((item, index) => {
                return (
                  <span
                    key={`profile-unverified-certifications-${index}`}
                    className="Specializations__list__item"
                  >
                    {item}
                    <i className="Specializations__list__item__saperator">,</i>
                  </span>
                );
              })}
            </div>
          ):
          <Empty message="No unverified certifications found" />
          }
        </AccordionCard>

        <Modal animation={false} size="lg" className="specialization_modal" show={this.state.modalEnabled} onHide={() => this.handleModalClose()} centered >
          <Modal.Header closeButton><Modal.Title>Manage Certificates</Modal.Title></Modal.Header>
          <Modal.Body>
            <div className="container">
              <h5>Verified Certifications</h5>
              <ReactTags
                tags={ModalVerifiedCertifications}
                allowNew={true}
                suggestions={certificationsOptions}
                handleDelete={(i) => this.handleRemove('v', i)}
                handleAddition={(tags) => this.handleAdd('v', tags)} 
                placeholder='Add Verified Certifications'
              />

              <h5>Unverified Certifications</h5>
              <ReactTags
                tags={ModalUnverifiedCertifications}
                suggestions={certificationsOptions}
                allowNew={true}
                handleDelete={(i) => this.handleRemove('u', i)}
                handleAddition={(tags) => this.handleAdd('u', tags)}
                placeholder='Add Unverified Certifications'
              />

              <a
                className="greenBtn modalButton"
                onClick={() => this.handleSubmit()}
              >
                Save
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Certifications;
