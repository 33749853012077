import React, { Component } from "react";
import PropTypes from "prop-types";
import { API, Storage } from "aws-amplify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import InputDropdown from "../../../components/ui/form/InputDropdown";

export class InputFile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileInput: '',
      fileName: '',
      fileType: '',
      newFileKey: '',
      DocType: '',
      loading: false,
    };
  }

  onChange = (event) => {
    this.setState({
      fileInput: event.target.files[0],
      fileName: event.target.files[0].name,
      fileType: event.target.files[0].type
    }, () => {
      this.uploadDocument().then(response => {
        let data = {
          FileID: response.key,
          FileInput: this.state.fileInput
        }
        this.props.onChange(data);
        this.setState({ data: data });
        return response;
      });
    });
  }

  onChangeType = (value) => {
    this.props.onChangeType(value);
  }

  uuid() {
    // Public Domain/MIT
    var d = new Date().getTime();
    if (
      typeof performance !== "undefined" &&
      typeof performance.now === "function"
    ) {
      d += performance.now(); //use high-precision timer if available
    }
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  uploadDocument = async () => {
    let storageResponse = "";
    await this.setState({
      newFileKey: this.uuid(),
      loading: true
    });
    try {
      storageResponse = await Storage.put(
        this.state.newFileKey,
        this.state.fileInput,
        {
          contentType: this.state.fileType,
          level: "public"
        }
      );
      this.setState({
        loading: false,
      });
      return storageResponse;
    } catch (err) {
      return err;
    }
  };

  render() {
    let inputClass = "form-control-file";
    if (this.props.validFeedback !== "") inputClass += " is-valid";
    if (this.props.invalidFeedback !== "") inputClass += " is-invalid";

    return (
      <div className="form-group form-fileupload-group">
        {this.props.label !== '' && <label className="form-label">{this.props.label}</label>}
        {(this.state.loading) && <div className="fileLoader"><FontAwesomeIcon icon={faSpinner} spin /></div>}


        <div className="row">

          <div className="col-md">
            <input
              type="file"
              disabled={this.state.loading}
              className={inputClass}
              onChange={event => this.onChange(event)}
            />
          </div>

          {this.props.options ? (
            <div className="col-md" style={{ marginTop: -35, marginLeft: -90 }}><InputDropdown
              label="Type"
              placeholder="Choose"
              onChange={value => this.onChangeType(value)}
              options={this.props.options}
            />
            </div>) : (<div />)}


        </div>

        {this.props.invalidFeedback !== "" && (
          <div className="invalid-feedback">{this.props.invalidFeedback}</div>
        )}
        {this.props.validFeedback !== "" && (
          <div className="valid-feedback">{this.props.validFeedback}</div>
        )}
      </div>
    );
  }
}

InputFile.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onChangeType: PropTypes.func,
  validFeedback: PropTypes.string,
  invalidFeedback: PropTypes.string
};

InputFile.defaultProps = {
  label: "",
  placeholder: "",
  validFeedback: "",
  invalidFeedback: ""
};

export default InputFile;
