import { API, Storage } from 'aws-amplify';
export function fetchOpportunities() {
    return API.get('1', '/opportunities').then(responseJson => {
        return responseJson;
    });
}
export function postOpportunity(data) {
    data.Applicants='[]';
    data.NonApplicants='[]';
    return API.post('1', `/opportunities`, { body: data }).then(responseJson => {
        return responseJson;
    });
}
export function getOpportunity(data) {
    return API.get('1', '/opportunities?ItemId=' + data).then(responseJson => {
        return responseJson;
    });
}
export function putOpportunity(data) {
    return API.put('1', `/opportunities`, { body: data }).then(responseJson => {
        return responseJson;
    });
}