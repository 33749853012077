import React, { Component } from 'react';
import '../styles/TimeSheetStyles.scss';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { Modal, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as timesheetActions from '../actions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from '../../../components/Modals/Modal';
import { openModal, setModalName } from '../../../actions/uiActions';
import Moment from 'react-moment';
import { faFile, faTimes, faCalendar } from "@fortawesome/free-solid-svg-icons";

import { API } from 'aws-amplify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "../styles/DateRangeCustom.css"

import InputAutocomplete from "../../../components/ui/form/InputAutocomplete";

import { Link } from "react-router-dom";

class TimeSheetList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], page: 1, totalSize: 1, sizePerPage: 25,
            MemberId: ' ',
            Status: ' ',
            Contract: ' ',
            FromDate: ' ',
            ToDate: ' ',
            Duration: ' ',
            Contracts: [],
            ContractName: '',
            TotalHours: 0,
            MembersDropdown: [],
            InvoiceModalData: { Worker: {}, PM: {}, CTO: {} }, 
            periodTo: 0, periodFrom: 0, 
        }
    }

    componentDidMount() {
        API.get('1', `/contracts/dropdown`).then(responseJson => {
            this.setState({ Contracts: responseJson.Output });
        });
        API.get('1', `/members/dropdown?Timesheets=1`,
        ).then(responseJson => {
            let parsedData = [];
            responseJson.Output?.map(obj => {
                parsedData.push({ title: `${obj.Name} ${obj.Email ? '(' + obj.Email + ')' : ''}`, value: obj.ItemId })
            })
            this.setState({ MembersDropdown: parsedData });
        });
    }
    async handleTimeSheet() {
        let timesheetDates = await API.get('1', `/datesfordownloadtimesheets?`) 
        this.state.periodFrom = timesheetDates.periodStart;
        this.state.periodTo = timesheetDates.periodEnd;
        return
    }
    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, MemberId, Status, FromDate, ToDate, Contract }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }

        let filterString = '';
        if (!MemberId) { MemberId = this.state.MemberId } if (MemberId !== ' ') { filterString += `&MemberId=${MemberId}` }
        if (!Status) { Status = this.state.Status } if (Status !== ' ') { filterString += `&Status=${Status}`; }
        if (!FromDate) { FromDate = this.state.FromDate } if (FromDate !== ' ') { filterString += `&FromDate=${FromDate}`; }
        if (!ToDate) { ToDate = this.state.ToDate } if (ToDate !== ' ') { filterString += `&ToDate=${ToDate}`; }
        if (!Contract) { Contract = this.state.Contract } if (Contract !== ' ') { filterString += `&ContractId=${Contract}`; }

        API.get('1', `/timesheets?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}${filterString}`).then(responseJson => {
            this.setState(() => ({
                page: page, data: responseJson.Output, sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder, totalSize: responseJson.RecordsTotal,
                MemberId: MemberId,
                Status: Status,
                FromDate: FromDate,
                ToDate: ToDate,
                Contract: Contract,
                TotalHours: responseJson.Output.length > 0 ? responseJson.Output[0].TotalHours : 0
            }));
            window.scrollTo(0, 0);
        });
    }

    render() {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        const myTimes = this.handleTimeSheet();
        console.log(myTimes)

        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <ul className="search_boxnew">
                            <li className="search-box">
                                {/* <input type="text" className="form-control" placeholder="Search Contract or Worker Name" onChange={(e) => { this.handleTableChange(null, { MemberId: e.currentTarget.value ? e.currentTarget.value : ' ' }) }} /> */}
                                <InputAutocomplete
                                    style={{ width: 450, marginTop: 15 }}
                                    placeholder="Worker"
                                    onChange={(value) => { this.handleTableChange(null, { MemberId: value ? value : ' ' }) }}
                                    options={this.state.MembersDropdown}
                                />
                            </li>
                        </ul>
                        <ul className="search_boxnew search_filters">
                            <li style={{ flex: '0 0 25%' }}>
                                <Dropdown className="filterToggle">
                                    <Dropdown.Toggle>{this.state.Status !== ' ' ? this.state.Status : 'Select Status'}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => { this.handleTableChange(null, { Status: ' ' }) }}>Select Status</Dropdown.Item>
                                        {['Pending', 'Sent for Approval', 'Approved', 'Payroll in Progress', 'Paid'].map(obj => {
                                            return (<Dropdown.Item onClick={() => { this.handleTableChange(null, { Status: obj }); }}>{obj}</Dropdown.Item>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li style={{ flex: '0 0 25%' }}>
                                <Dropdown className="filterToggle">
                                    <Dropdown.Toggle>{this.state.Contract !== ' ' ? this.state.ContractName : 'Select Contract'}</Dropdown.Toggle>
                                    <Dropdown.Menu style={{ maxHeight: 400, overflowY: 'auto' }}>
                                        <Dropdown.Item onClick={() => { this.handleTableChange(null, { Contract: ' ' }) }}>Select Contract</Dropdown.Item>
                                        {this.state.Contracts?.map(obj => {
                                            return (<Dropdown.Item onClick={() => { this.handleTableChange(null, { Contract: obj.ItemId }); this.setState({ ContractName: obj.Name }); }}>{obj.Name}</Dropdown.Item>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li style={{ flex: '0 0 25%' }}>
                                <Dropdown className="filterToggle">
                                    <Dropdown.Toggle>{this.state.Duration !== ' ' ? this.state.Duration : 'Select Duration'}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {
                                            this.handleTableChange(null, { FromDate: ' ', ToDate: ' ' });
                                            this.setState({ Duration: ' ' });
                                        }}>Select Duration</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let ToDate = this.lastWeekformatDate(today);
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 13));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last Week' });
                                        }}>Last Week</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let ToDate = this.lastWeekformatDate(today);
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 20));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last 2 Weeks' });
                                        }}>Last 2 Weeks</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), 1));
                                            let ToDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'This Month' });
                                        }}>This Month</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth() - 1, 1));
                                            let ToDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), 0));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last Month' });
                                        }}>Last Month</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth() - 2, 1));
                                            let ToDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), 0));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last 2 Months' });
                                        }}>Last 2 Months</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li style={{ flex: '0 0 25%' }}>
                                {/* {this.state.FromDate !== ' ' && this.state.ToDate !== ' ' ? (
                                    <DateRangePicker onApply={this.onDaterange} >
                                        <button className="rangeButton"><Moment utc format="MM/DD/YYYY">{this.state.FromDate}</Moment><div style={{ marginLeft: 10, marginRight: 10 }}>-</div><Moment utc format="MM/DD/YYYY">{this.state.ToDate}</Moment></button>
                                        <div className="Search__iconCloseDatePicker" onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ FromDate: ' ', ToDate: ' ' });
                                            this.handleTableChange(null, { FromDate: ' ', ToDate: ' ' });
                                            return false;
                                        }}
                                        ><FontAwesomeIcon className="closeIcon" icon={faTimes} /></div>
                                    </DateRangePicker>
                                ) : (
                                        <DateRangePicker onApply={(event, picker) => {
                                            this.setState({ FromDate: picker.startDate.format("YYYY-MM-DD"), ToDate: picker.endDate.format("YYYY-MM-DD") });
                                            this.handleTableChange(null, { FromDate: picker.startDate.format("YYYY-MM-DD"), ToDate: picker.endDate.format("YYYY-MM-DD") });
                                        }}>
                                            <button id="dateRangePickerStyle" className="rangeButton">Select Date Range <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faCalendar} /></button>
                                        </DateRangePicker>
                                    )} */}
                            </li>
                        </ul>
                    </div>
                </div>           
                <div className="row" style={{ justifyContent: 'space-between', marginRight: 20 }}>
                    <span className="row" style={{ justifyContent: 'start', margin: "0 10px" }}> 
                        <p>Quick DL (Period &nbsp;
                        <b><Moment format='MM/DD'>{this.state.periodFrom}</Moment> - <Moment format='MM/DD'>{this.state.periodTo}</Moment></b> ):        
                        <a style={{ marginRight: 5 }} href="javascript:void(0)" onClick={async() => {                        
                        let a = document.createElement("a");                        
                        let url = window.URL.createObjectURL(await API.get('1', `/downloadtimesheets?EmploymentStatus=W2`, {responseType: 'blob' }));                        
                        a.href = url;                        
                        a.download = 'timesheets_W2.csv';                        
                        a.click();                        
                        window.URL.revokeObjectURL(url);                    
                        }}>W2</a> 
                        |
                        <a style={{ marginRight: 5 }} href="javascript:void(0)" onClick={async() => {                        
                        let a = document.createElement("a");                        
                        let url = window.URL.createObjectURL(await API.get('1', `/downloadtimesheets?EmploymentStatus=1099`, {responseType: 'blob' }));                        
                        a.href = url;                        
                        a.download = 'timesheets_1099.csv';                        
                        a.click();                        
                        window.URL.revokeObjectURL(url);                    
                        }}>&nbsp;&nbsp;1099</a>
                        </p>                
                    </span>
                    <div>
                        <a style={{ marginRight: 20 }} href="javascript:void(0)" onClick={() => {
                            let dataToParse = [];
                            let parsedData = [['Client', 'Contract', 'Worker', 'Period', 'Hours', 'Status', 'Payroll']];
                            if (this.props.selectedTimeSheet.length === 0) {
                                dataToParse = this.state.data;
                            }
                            else {
                                dataToParse = this.props.selectedTimeSheet;
                            }
                            dataToParse.forEach((obj) => {
                                parsedData.push([obj.Client, obj.ContractName, obj.Member, obj.Period, obj.Hours, obj.Status, obj.PayrollId]);
                            });
                            let csvContent = "data:text/csv;charset=utf-8,"
                                + parsedData.map(e => e.join(",")).join("\n");
                            let encodedUri = encodeURI(csvContent);
                            let link = document.createElement("a");
                            link.setAttribute("href", encodedUri);
                            link.setAttribute("download", "timesheet_data.csv");
                            document.body.appendChild(link);
                            link.click();
                        }}>Download to Excel</a>
                        <span style={{ marginRight: 20 }}>-</span>
                        Total Hours: {this.state.TotalHours}
                    </div>
                </div>
            </div>
            <div className="container">
                <hr className="line" />
                <BootstrapTable
                    remote
                    keyField="ItemId"
                    filter={filterFactory()}
                    pagination={paginationFactory({ page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
                    classes='time_sheet_table'
                    bordered={false}
                    data={this.state.data}
                    page={this.state.page}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                    columns={[
                        {
                            dataField: 'Client',
                            text: 'Client',
                            sort: true,
                            formatter: (cell, row) => { return (<div title={`Client Name: ${row.ClientName} \rClient Supervisor: ${row.SupervisorName ? row.SupervisorName : 'N/A'}\rContract Type: ${row.ContractStatus} \rHours: ${row.ContractHours} \rPM: ${row.PMName ? row.PMName : 'N/A'} ${row.PMFee ? '(' + row.PMFee + '%)' : ''} \rCTO: ${row.CTOName ? row.CTOName : 'N/A'} ${row.CTOFee ? '(' + row.CTOFee + '%)' : ''}`}>{cell}</div>); },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'ContractName',
                            text: 'Contract',
                            sort: true,
                            formatter: (cell, row) => { return (<div title={`Client Name: ${row.ClientName} \rClient Supervisor: ${row.SupervisorName ? row.SupervisorName : 'N/A'}\rContract Type: ${row.ContractStatus} \rHours: ${row.ContractHours} \rPM: ${row.PMName ? row.PMName : 'N/A'} ${row.PMFee ? '(' + row.PMFee + '%)' : ''} \rCTO: ${row.CTOName ? row.CTOName : 'N/A'} ${row.CTOFee ? '(' + row.CTOFee + '%)' : ''}`}>{cell}</div>); },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Member',
                            text: 'Worker',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'PeriodStart',
                            text: 'Period',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{row.Period}</div>); },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Hours',
                            text: 'Hours',
                            sort: false,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '8%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Status',
                            text: 'Status',
                            sort: true,
                            formatter: (cell, row) => {
                                return (<div>{cell === 'Paid' ? new Date() < new Date(row.PayrollDate) ? 'Payroll In Progress' : 'Paid' : cell}</div>);
                            },
                            headerStyle: { width: '8%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'PayrollDate',
                            text: 'Payroll',
                            sort: true,
                            formatter: (cell, row) => {
                                return (<div>{(cell !== null) &&
                                    <a href="javascript:void(0)" onClick={() => {
                                        this.props.setSelectedPayroll({ selectedPayroll: { ItemId: row.PayrollId, PayrollDate: cell } });
                                        this.props.openModal();
                                        this.props.setModalName('EditPayrollDate');
                                    }
                                    }>
                                        <Moment utc format="MM/DD/YYYY">{cell}</Moment></a>}</div>);
                            },
                            headerStyle: { width: '4%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'ItemId',
                            text: 'Details',
                            sort: false,
                            formatter: (cell, row) => {
                                return (<div><a href="javascript:void(0)" onClick={() => {
                                    setTimeout(() => {
                                        this.props.setSelectedTimeSheet({ "selectedTimeSheet": this.props.selectedTimeSheet });
                                        this.props.getDetailedTimeSheet({ "itemId": cell });
                                        this.props.openModal();
                                        this.props.setModalName('DetailedTimeSheet');
                                        this.props.selectedTimeSheet.unshift(row);
                                    }, 1);
                                }}><FontAwesomeIcon className="icon2 timesheetDetail" icon={faFile} /></a></div>);
                            },
                            headerFormatter: (column) => { return (<div>{column.text}</div>) },
                            headerStyle: { width: '6%' }
                        }]}
                    selectRow={{
                        mode: 'checkbox',
                        clickToSelect: true,
                        onSelect: (row, isSelect) => {
                            if (isSelect) { this.props.selectedTimeSheet.unshift(row); }
                            else { this.props.selectedTimeSheet.splice(this.props.selectedTimeSheet.findIndex(item => item.ItemId === row.ItemId), 1); }
                            this.props.setSelectedTimeSheet({ "selectedTimeSheet": this.props.selectedTimeSheet });
                        },
                        onSelectAll: (isSelect, rows) => {
                            if (isSelect) { rows.forEach(obj => { this.props.selectedTimeSheet.unshift(obj); }); }
                            else { rows.forEach(obj => { this.props.selectedTimeSheet.splice(this.props.selectedTimeSheet.findIndex(item => item.ItemId === obj.ItemId), 1); }); }
                            this.props.setSelectedTimeSheet({ "selectedTimeSheet": this.props.selectedTimeSheet });
                        },
                    }}
                    defaultSorted={[{
                        dataField: 'PeriodStart',
                        order: 'desc'
                    }]}
                />
            </div>
            <CustomModal />
        </React.Fragment>
        )
    }

    lastWeekformatDate(dateObj) {
        var dateObjMonth = dateObj.getMonth() + 1;
        var dateObjDay = dateObj.getDate();
        var dateObjYear = dateObj.getFullYear();
        var dateFormatted = ("0000" + dateObjYear.toString()).slice(-4) + "-" + ("00" + dateObjMonth.toString()).slice(-2) + "-" + ("00" + dateObjDay.toString()).slice(-2);
        return dateFormatted;
    }
}

function mapStateToProps(state) {
    return {
        myState: state,
        timeSheets: state.timesheetList.timeSheets,
        modalState: state.uiState.modalOpen,
        modalName: state.uiState.modalName,
        selectedTimeSheet: state.timesheetList.selectedTimeSheet,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedTimeSheet: (data) => dispatch(timesheetActions.setSelectedTimeSheet(data)),
        getDetailedTimeSheet: (data) => dispatch(timesheetActions.getDetailedTimeSheet(data)),
        setSelectedPayroll: (data) => dispatch(timesheetActions.setSelectedPayroll(data)),

        openModal: () => dispatch(openModal()),
        setModalName: (data) => dispatch(setModalName(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TimeSheetList);