import React, { Component } from "react";
import { Link } from "react-router-dom";
// import ProfilePicture from '../../../components/Member/ProfilePicture';
export class ContactList extends Component {
  static propTypes = {};

  render() {
    return (
      <React.Fragment>
        {this.props.threads.length > 0 && (
          <div>
            {this.props.threads.map((item, index) => {
              let itemClass = "Messaging__List__Item";
              if (item.ItemId === this.props.ItemId) {
                itemClass += " active";
              }
              console.log(item);
              return (
                <Link
                  to={`/messaging/${item.ItemId}`}
                  key={`messagingLiost-${index}`}
                >
                  <div className={itemClass}>
                    <div className="Messaging__List__Item__Picture">
                      <div className="Messaging__List__Item__Picture__Image">
                      {/* {(item.ProfilePicture !== null) && <ProfilePicture source={item.ProfilePicture} />} */}
                      </div>
                    </div>
                    <div className="Messaging__List__Item__Content">
                      <span className="Messaging__List__Item__Content__Name">
                        {item.Name}
                      </span>
                      <span className="Messaging__List__Item__Content__Msg">
                        {item.Message.Text}
                      </span>
                    </div>
                    <div className="Messaging__List__Item__Time">
                      <span>{item.Message.DateCreated}</span>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default ContactList;
