// LOADER ACTIONS
export const ENABLE_CONTRACT_LOADER = "ENABLE_CONTRACT_LOADER";
export const DISABLE_CONTRACT_LOADER = "DISABLE_CONTRACT_LOADER";

export const FETCH_CONTRACTS = 'FETCH_CONTRACTS';
export const SET_CONTRACT = 'SET_CONTRACT';
export const UPDATE_CONTRACT = 'UPDATE_CONTRACT';
export const FILTER_CONTRACT = 'FILTER_CONTRACT';
export const SORT_CONTRACT = 'SORT_CONTRACT';

export const FETCH_CONTACTS = 'FETCH_CONTACTS';
export const FETCH_WORKERS = 'FETCH_WORKERS';
export const SET_WORKERS = 'SET_WORKERS';
export const SET_RESPONSE = 'SET_RESPONSE';

export const SET_CONTACTS = 'SET_CONTACTS';

export const SUBMIT_CONTRACTS = 'SUBMIT_CONTRACTS';
export const EDIT_CONTRACT = 'EDIT_CONTRACT';

export const FETCH_CONTRACTS_DETAILS = 'FETCH_CONTRACTS_DETAILS';
export const SET_CONTRACT_DETAILS = 'SET_CONTRACT_DETAILS';
export const SET_CONTRACT_CONTACT = 'SET_CONTRACT_CONTACT';
export const SET_CONTRACT_CLIENT = 'SET_CONTRACT_CLIENT';

/* NOTES ACTIONS */
export const ADD_CONTRACT_NOTE = "ADD_CONTRACT_NOTE";
export const EDIT_CONTRACT_NOTE = "EDIT_CONTRACT_NOTE";
export const DELETE_CONTRACT_NOTE = "DELETE_CONTRACT_NOTE";
export const UPDATE_CATEGORIES = "UPDATE_CATEGORIES";

/* JOB ORDER ACTIONS */
export const ADD_CONTRACT_JOBORDER = "ADD_CONTRACT_JOBORDER";
export const EDIT_CONTRACT_JOBORDER = "EDIT_CONTRACT_NO_JOBORDER";
export const DELETE_CONTRACT_JOBORDER = "DELETE_CONTRACT_JOBORDER";

/* DOCUMENTS ACTIONS */
export const ADD_CONTRACT_DOCUMENT = "ADD_CONTRACT_DOCUMENT";
export const EDIT_CONTRACT_DOCUMENT = "EDIT_TRACT_DOCUMENT";
export const DELETE_CONTRACT_DOCUMENT = "DELETE_TRACT_DOCUMENT";

export const SET_CONTRACT_FORM_DATA = "SET_CONTRACT_FORM_DATA";
export const CLEAR_CONTRACT_FORM_DATA = "CLEAR_CONTRACT_FORM_DATA";