import * as types from "../actions/types";

const initialState = {
  isLoading: false,
  threads: [],
  availableContacts: [],
  ItemId: '',
  conversation: [],
};

export default (state = initialState, action) => {
  switch (action.type) {

    case types.CLEAR_CONVERSATION:
      return {
        ...state,
        ItemId: '',
        conversation: [],
      }

    case types.SET_CONVERSATION:
      return {
        ...state,
        ItemId: action.data.ItemId,
        conversation: action.data.conversation,
      }

    case types.SET_MESSAGE_THREADS:
      return {
        ...state,
        threads: action.data
      };
    
    case types.SET_AVAILABLE_CONTACTS:
      return {
        ...state,
        availableContacts: action.data,
      }

    case types.ENABLE_LOADER:
      return { ...state, isLoading: true };

    case types.DISABLE_LOADER:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};
