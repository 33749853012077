import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputDate from "../../components/ui/form/InputDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as timesheetActions from '../../modules/Timesheet/actions/index';
import './ModalStyle.scss';
import moment from "moment";
import Moment from 'react-moment';
import {
  faSpinner

} from "@fortawesome/free-solid-svg-icons";

class SendToPayroll extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        PayrollDate: '',
      },
      isValid: false,
      clients: [],
      pendingConfirmation: false
    }
    this.handleModalClose.bind(this);
  }
  componentDidMount() {
    console.log()
    setTimeout(() => {
      var isModalFound = document.getElementsByClassName('modal show');
      if (isModalFound.length > 1) {
        document.getElementsByClassName('modal show')[0].style.display = "none";
      }
    }, 1000);
    let Timesheets = [];
    for (var i = 0; i < this.props.timesheetList.selectedTimeSheet.length; i++) {
      Timesheets.push(this.props.timesheetList.selectedTimeSheet[i].ItemId);
    }
    let TimesheetIds = Timesheets.toString();
    this.props.setPayrollFormData("Timesheets", TimesheetIds);
    this.props.setPayrollFormData("PayrollDate", "");
    let today = moment().format("YYYY-MM-DD");
    let data = "PayrollDate=" + today + "&Timesheets=" + TimesheetIds;
    console.log(47);
    console.log(data);
    this.props.fetchPayrolls(data);

    if (!this.props.timesheetList.selectedTimeSheet.every((value) => value.Status === "Approved")) {
      this.setState({ pendingConfirmation: true });
    }
  }
  setError = (key, message) => {
    let formErrors = this.state.formErrors;
    formErrors[key] = message;
    this.setState({
      formErrors: formErrors,
      isValid: false,
    });
  }
  clearFormErrors = async () => {
    const emptyErrors = {
      PayrollDate: '',
    };
    await this.setState({
      formErrors: emptyErrors,
    });
  }
  validateData = () => {
    const data = this.props.addPayrollData;
    console.log(data);
    return this.clearFormErrors().then(() => {
      let hasErrors = false;

      if (typeof data.PayrollDate === 'undefined' || data.PayrollDate === '') {

        this.setError('PayrollDate', 'Please provide Payroll Date');
        hasErrors = true;
      }
      return hasErrors;
    });
  }
  closeModalCallback() {
    this.props.setModalName(null);
    this.props.closeModal();
  }
  handleModalClose() {
    this.props.setModalName(null);
    this.props.closeModal();
  }
  onChange(key, value) {
    this.props.setPayrollFormData(key, value);
  }
  onInput = (key, value) => {
    this.props.setPayrollFormData(key, value);
  }

  handleModalClose() {
    document.getElementsByClassName('selection-cell-header')[0].getElementsByTagName('input')[0].click();
    this.props.setModalName(null);
    this.props.closeModal();
  }
  onSubmit = () => {
    this.validateData().then(hasErrors => {
      if (!hasErrors) {
        this.props.sendToPayroll(this.props.addPayrollData);
      }
    });
  }

  //
  render() {

    var payrolls = Object.entries(this.props.timesheetList.payrolls);

    return (
      <React.Fragment>
        <Modal animation={false} size="xl" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Process Payroll for the period from {this.props.timesheetList.payrolls.Info && moment(this.props.timesheetList.payrolls.Info.PeriodStart).add(1, 'days').format("MM/DD")} to {this.props.timesheetList.payrolls.Info && moment(this.props.timesheetList.payrolls.Info.PeriodEnd).add(1, 'days').format("MM/DD")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.pendingConfirmation ? (
              <div className="container send_to_client_container">
                <div class="row" style={{ justifyContent: 'center' }}>
                  {!this.props.timesheetList.selectedTimeSheet.some((value) => value.Status === "Paid") ? 'You have timesheets you are including in this payroll that are not approved' : 'You have timesheets you are including in this payroll that are already paid'}</div>
                <div class="row" style={{ justifyContent: 'center', marginTop: 20 }}>
                  <a className="greenBtn" style={{ marginRight: 15, height: 55, backgroundColor: '#AAAAAA', paddingLeft: 25, paddingRight: 25 }} onClick={() => this.handleModalClose()}>Cancel</a>
                  <a className="greenBtn" style={{ marginRight: 15, height: 55 }} onClick={() => { this.setState({ pendingConfirmation: false }) }}>Continue</a>
                </div>
              </div>
            ) : (
                <div className="container send_to_client_container">
                  <div className="process_payroll ">
                    <div className="modelBox">
                      <table className="table time_sheet_table">
                        <thead>
                          <tr>

                            <th>Worker</th>
                            <th>Period</th>
                            <th>Hours</th>
                            <th>Rate</th>
                            <th>Contract</th>
                            <th>Email Address</th>
                          </tr>
                        </thead>
                        <tbody>
                          {payrolls.map((payroll) => {
                            return (
                              <tr>
                                <td>{payroll[0] === 'Info' ? (<b><div style={{ marginBottom: 10 }}>Total Hours</div><div>Cash Required</div></b>) : payroll[0]}</td>
                                <td>{payroll[1].Period && payroll[1].Period.split(', ').map(obj => { return (<div>{obj}</div>) })}
                                  {payroll[0] !== 'Info' && <div>Total Hours: {payroll[1].Hours}</div>}
                                  {payroll[0] !== 'Info' && <div>Total Payment: ${payroll[1].Paid.toFixed(2)}</div>}
                                </td>
                                <td>{payroll[0] === 'Info' ? (<div><div style={{ marginBottom: 10 }}>{payroll[1].Hours}</div>{'$' + payroll[1].Paid.toFixed(2)}</div>) :
                                  payroll[1].Contracts && Object.values(payroll[1].Contracts).map(obj => obj.map(innerObj => { return (<div>{innerObj.Hours}</div>) }))
                                }
                                </td>
                                <td>{
                                  payroll[1].Contracts && Object.values(payroll[1].Contracts).map(obj => obj.map(innerObj => { return (<div>${innerObj.Rate}</div>) }))
                                }</td>
                                <td>{payroll[1].Contracts && Object.keys(payroll[1].Contracts).map(obj => { return (<div>{obj}</div>) })}</td>
                                <td>{payroll[1].Email}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>

                      <div className="row" style={{ marginBottom: -35 }}>
                        <div className="col-md">
                          <ul className="pyroll" style={{ justifyContent: 'flex-end' }}>
                            <li style={{ marginTop: -15, marginLeft: 5, marginRight: 15, textAlign: 'right' }}>Payroll Date</li>
                            <li>
                              <InputDate
                                onChange={value => this.onInput("PayrollDate", value)}
                                invalidFeedback={this.state.formErrors.PayrollDate}
                              />
                            </li>
                          </ul>
                        </div>
                        <a className="greenBtn" style={{ marginRight: 15, height: 55, backgroundColor: '#AAAAAA', paddingLeft: 25, paddingRight: 25 }} onClick={() => { console.log("print") }}>Print</a>
                        <a className="greenBtn" style={{ marginRight: 15, height: 55 }} onClick={() => this.onSubmit()}>Process</a>
                      </div>
                    </div>

                  </div>
                  {this.props.timesheetList.isLoading &&
                    <div className="loader"><FontAwesomeIcon icon={faSpinner} spin /></div>
                  }
                </div>
              )}
          </Modal.Body>
          {this.props.timesheetList.sendToPayrollResponse === "Success" ?
            this.closeModalCallback()
            : this.props.timesheetList.sendToPayrollResponse !== "" ? <div className="status error">Error in sending payroll!</div> : ''
          }
        </Modal>
      </React.Fragment>
    )
  }
}
SendToPayroll.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    memberInfo: state.member.memberInfo[0],
    timesheetList: state.timesheetList,
    addPayrollData: state.timesheetList.addPayrollData,

  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (data) => dispatch(closeModal()),
    setModalName: (data) => dispatch(setModalName(data)),
    setPayrollFormData: (key, value) => dispatch(timesheetActions.setPayrollFormData(key, value)),
    sendToPayroll: (data) => dispatch(timesheetActions.sendToPayroll(data)),
    fetchPayrolls: (data) => dispatch(timesheetActions.fetchPayrolls(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SendToPayroll);