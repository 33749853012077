import React, { Component, useMemo } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import InputTextArea from "../../../../components/ui/form/InputTextArea";
import Input from "../../../../components/ui/form/Input";
import InputDropdown from "../../../../components/ui/form/InputDropdown";
import InputDate from "../../../../components/ui/form/InputDate";
import InputHours from "../../../../components/ui/form/InputHours";
import InputMultiselect from "../../../../components/ui/form/InputMultiselect";

import { availableStatus } from "../../config/Constants";
import { API } from 'aws-amplify';

import moment from 'moment'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class EditContract extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        Name: '',
        StartDate: '',
        EndDate: '',
        Hours: '',
        Status: '',
      },
      ContactsDropdown: [],
      Details: ''
    }
    API.get('1', `/clients/dropdown`,
    ).then(responseJson => {
      this.setState({ ClientsDropdown: responseJson.Output.map(obj => { return ({ title: obj.Name, value: obj.ItemId }) }) });
    });
    setTimeout(() => {
      API.get('1', `/contacts/dropdown?ClientId=${this.props.contract.ClientId}`,
      ).then(responseJson => {
        this.setState({ ContactsDropdown: responseJson.Output.map(obj => { return ({ title: obj.Name, value: obj.ItemId }) }) });
      });
    }, 2000);

    API.get('1', `/admins`,).then(responseJson => {
      this.setState({ AdminsDropdown: responseJson.Output.map(obj => { return ({ title: `${obj.FirstName} ${obj.LastName}`, value: obj.ItemId }) }) });
    });

    setTimeout(() => {
      this.setState({ Details: this.props.contract.Details, Type: this.props.contract.Type, PM: this.props.contract.PM, CTO: this.props.contract.CTO });
    }, 2000);
  }

  clearFormErrors = async () => {
    const emptyErrors = {
      Name: '',
      StartDate: '',
      EndDate: '',
      Hours: '',
      Status: '',
      ClientId: '',
      MemberId: '',
    };
    await this.setState({
      formErrors: emptyErrors,
    });
  }

  setError = (key, message) => {
    let formErrors = this.state.formErrors;
    formErrors[key] = message;
    this.setState({
      formErrors: formErrors,
      isValid: false,
    });
  }

  validateData = () => {
    const data = this.props.addContractData;
    return this.clearFormErrors().then(() => {
      let hasErrors = false;

      if (typeof data.Name !== 'undefined' && data.Name === '') {
        this.setError('Name', 'Please provide Contract Name');
        hasErrors = true;
      }
      if (typeof data.StartDate !== 'undefined' && data.StartDate === '') {
        this.setError('StartDate', 'Please provide Start Date');
        hasErrors = true;
      }

      if (typeof data.Status !== 'undefined' && data.Status === '') {
        this.setError('Status', 'Please select Status');
        hasErrors = true;
      }
      if (typeof data.Details !== 'undefined' && data.Details === '') {
        this.setError('Details', 'Please insert Details');
        hasErrors = true;
      }
      return hasErrors;
    });
  }

  static propTypes = {
    modalVisible: PropTypes.bool,
    formErrors: PropTypes.object,
  };

  saveContract = () => {
    this.validateData().then(hasErrors => {
      if (!hasErrors) {
        if (this.state.DefaultTo) {
          //this.state.DefaultTo = this.state.DefaultTo.map(obj => obj.value).join(',');
          this.onChange("DefaultTo", "[" + this.state.DefaultTo.map(obj => `"${obj.value}"`).join(',') + "]");
        }
        this.props.onEditContract().then(response => {
          this.props.closeModal();
        });
      }
    });
  }

  onChange = (key, value) => {
    this.props.onContractEditInput(key, value);
    if (key === 'ClientId') {
      API.get('1', `/contacts/dropdown?ClientId=${value}`,
      ).then(responseJson => {
        this.setState({ ContactsDropdown: responseJson.Output.map(obj => { return ({ title: obj.Name, value: obj.ItemId }) }) });
      });
    }
  }

  render() {
    const { contract } = this.props;
    const { formErrors } = this.state;
    return (
      <Modal animation={false} size="xl" className="detailed_time_sheet_modal" show={this.props.modalVisible} onHide={() => {
        this.clearFormErrors();
        this.props.closeModal();
      }} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Contract</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            <div className="col-md-12">
              <Input
                label="Contract Name"
                placeholder="Contract Name"
                onChange={value => this.onChange("Name", value)}
                value={contract.Name}
                invalidFeedback={formErrors.Name}
              />

              <ReactQuill
                style={{ height: 150, marginBottom: 50 }}
                modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['link'], ['clean']] }}
                value={this.state.Details}
                onChange={value => { this.setState({ Details: value }); this.onChange("Details", value); }}
              />

              <div className="row">
                <div className="col-md-3">
                  <InputDropdown
                    label="Status"
                    placeholder="Select Status"
                    onChange={value => this.onChange("Status", value)}
                    options={availableStatus}
                    invalidFeedback={formErrors.Status}
                    selected={contract.Status}
                  />

                </div>
                <div className="col-md-3">
                  <InputDropdown
                    label="Client"
                    placeholder="Select Client"
                    onChange={value => this.onChange("ClientId", value)}
                    options={this.state.ClientsDropdown}
                    selected={contract.ClientId}
                  />
                </div>
                <div className="col-md-3">
                  <InputDropdown
                    label="Client Supervisor"
                    placeholder="Select Contact"
                    onChange={value => this.onChange("PrimaryContact", value)}
                    options={this.state.ContactsDropdown}
                    selected={contract.PrimaryContact}
                  />
                </div>
                <div className="col-md-3">
                  <InputDropdown
                    label="Type"
                    placeholder="Select Type"
                    onChange={value => { this.onChange("Type", value); this.setState({ Type: value }); }}
                    options={[{ title: 'Fixed Project', value: 'Fixed Project' }, { title: 'Augmentation', value: 'Augmentation' }]}
                    selected={contract.Type}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  <InputDropdown
                    label="Project Manager"
                    placeholder="Select Admin"
                    onChange={value => { this.onChange("PM", value); this.setState({ PM: value }); }}
                    options={this.state.AdminsDropdown}
                    selected={contract.PM}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="PM Fee (%)"
                    placeholder="0.00"
                    onChange={value => this.onChange("PMFee", value)}
                    value={this.state.PM ? contract.PMFee : '0.00'}
                    number={true}
                    disabled={!this.state.PM}
                  />
                </div>
                <div className="col-md-3">
                  <InputDropdown
                    label="Chief Technology Officer"
                    placeholder="Select Admin"
                    onChange={value => { this.onChange("CTO", value); this.setState({ CTO: value }); }}
                    options={this.state.AdminsDropdown}
                    selected={contract.CTO}
                  />
                </div>
                <div className="col-md-3">
                  <Input
                    label="CTO Fee (%)"
                    placeholder="0.00"
                    onChange={value => this.onChange("CTOFee", value)}
                    value={this.state.CTO ? contract.CTOFee : '0.00'}
                    number={true}
                    disabled={!this.state.CTO}
                  />
                </div>
              </div>


              <div className="row">
                <div className="col-md-3">
                  <InputDate
                    label="Start Date"
                    onChange={value => this.onChange("StartDate", value)}
                    value={moment(contract.StartDate).format('YYYY-MM-DD')}
                    invalidFeedback={formErrors.StartDate}
                  />
                </div>
                <div className="col-md-3">
                  <InputDate
                    label="End Date"
                    onChange={value => this.onChange("EndDate", value)}
                    value={moment(contract.EndDate).format('YYYY-MM-DD')}
                  />
                </div>
                <div className="col-md-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                  <InputHours
                    label="Hours"
                    value={contract.Hours}
                    onChange={value => this.onChange("Hours", value)}
                  />
                </div>
                <div className="col-md-3" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }}>
                  {this.state.Type === 'Augmentation' &&
                    <InputHours
                      label="People"
                      value={contract.People + ':00:00'}
                      onChange={value => this.onChange("People", value)}
                    />
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <InputMultiselect
                    label="Default To"
                    onChange={value => { this.onChange("DefaultTo", value); this.setState({ DefaultTo: value }); }}
                    options={this.state.ContactsDropdown}
                    selected={contract.DefaultTo && JSON.parse(contract.DefaultTo).map(obj => { return ({ value: obj, label: this.state.ContactsDropdown.filter(innerObj => innerObj.value === obj).length > 0 && this.state.ContactsDropdown.filter(innerObj => innerObj.value === obj)[0].title }) })}
                  />
                </div>
                <div className="col-md-3">
                  <InputDropdown
                    label="Default From"
                    placeholder="Select Admin"
                    onChange={value => { this.onChange("DefaultFrom", value); this.setState({ DefaultFrom: value }); }}
                    options={this.state.AdminsDropdown}
                    selected={contract.DefaultFrom}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <a className="greenBtn modalButton" onClick={() => this.saveContract()} >Save</a>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditContract;
