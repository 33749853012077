import React, { Component } from "react";
import { connect } from "react-redux";
import * as messagingActions from "../actions";

import Nav from "../../../components/Navigation/Nav";
import TopNav from "../../../components/TopNav/TopNav";

import SearchBar from "../components/SearchBar";
import ContactList from "../components/ContactList";
import MessageList from "../components/MessageList";
import MessageForm from "../components/MessageForm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import "../styles/messagingStyles.scss";

export class Messaging extends Component {
  constructor() {
    super();
  }

  componentDidMount() {
    const ItemId = this.props.match.params.ItemId;
    if (ItemId && typeof ItemId !== 'undefined' && ItemId !== '') {
      this.props.fetchConversation(ItemId);
    }
    this.props.fetchMessageThreads();
    this.props.fetchAvailableContacts();
  }

  componentWillReceiveProps (newprops) {
    const ItemId =  newprops.match.params.ItemId;
    if (ItemId && typeof ItemId !== 'undefined' && ItemId !== '' && ItemId !== this.props.ItemId) {
      this.props.fetchConversation(ItemId);
    } else {
      if (newprops.ItemId !== '') {
        // this.props.clearConversation();
      }
    }
  }

  onMessageSend = async (message, contacts) => {
    this.props.sendBulkMessage(message, contacts);
  }

  onConversation = async (message) => {
    // this.props.sendBulkMessage(message, contacts);
    const data = {
      ReceiverId: this.props.ItemId,
      Message: message,
    }
    this.props.sendMessageConversation(data);
  }
  
  onSelectThread = (messageId) => {
    // this.props.fetchConversation(messageId);
  }

  render() {
    const routerMsgID = this.props.match.params.ItemId;
    return (
      <React.Fragment>
        <TopNav title="Messaging" />
        <Nav />
        <div class="container add_contract_container">
          <div className="row Messaging">
            <div className="col-md-6 Messaging__Left">
              <SearchBar placeholder="Search messages..." />
              <div className="Messaging__List">
                <ContactList 
                  data={null} 
                  threads={this.props.threads}
                  onSelectThread={this.onSelectThread}
                  ItemId={this.props.ItemId}
                />
              </div>
            </div>
            <div className="col-md-6 Messaging__Right">
              {/* <MessageList data={MessageData} /> */}
              {(this.props.ItemId !== '' && routerMsgID) ? 
                <MessageList
                  data={this.props.conversation}
                  onMessageSend={this.onConversation}
                /> :
                <MessageForm 
                  contacts={this.props.availableContacts}
                  onMessageSend={this.onMessageSend}
                />
              }
              
            </div>
          </div>
        </div>
        {this.props.isLoading && 
          <div className="loader"><FontAwesomeIcon icon={faSpinner} spin/></div>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.messaging.isLoading,
  threads: state.messaging.threads,
  availableContacts: state.messaging.availableContacts,
  ItemId: state.messaging.ItemId,
  conversation: state.messaging.conversation,
});

const mapDispatchToProps = dispatch => ({
  fetchMessageThreads: () => dispatch(messagingActions.fetchMessageThreads()),
  fetchAvailableContacts: () => dispatch(messagingActions.fetchAvailableContacts()),
  sendMessage: (data) => dispatch(messagingActions.sendMessage(data)),
  sendMessageConversation: (data) => dispatch(messagingActions.sendMessageConversation(data)),
  sendBulkMessage: (message, contacts) => dispatch(messagingActions.sendBulkMessage(message, contacts)),
  fetchConversation: (messageId) => dispatch(messagingActions.fetchConversation(messageId)),
  clearConversation: (messageId) => dispatch(messagingActions.clearConversation()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Messaging);
