import React, { Component } from 'react';
import '../styles/OpportunityStyles.scss';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { connect } from 'react-redux';
import * as opportunityActions from '../actions/index';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from 'react-moment';
import { Link } from "react-router-dom";

import { API } from 'aws-amplify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

class OpportunityList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], page: 1, totalSize: 1, sizePerPage: 25,
            JobTitle: ' ',
            Open: true,
            Closed: false,
        }
    }

    componentDidMount() {
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, JobTitle, Open, Closed }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }

        let filterString = '';
        if (JobTitle === undefined) { JobTitle = this.state.JobTitle } if (JobTitle !== ' ') { filterString += `&JobTitle=${JobTitle}` }
        if (Open === undefined) { Open = this.state.Open } if (Open) { filterString += `&Open=${Open}`; }
        if (Closed === undefined) { Closed = this.state.Closed } if (Closed) { filterString += `&Closed=${Closed}`; }

        API.get('1', `/opportunities?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}${filterString}`).then(responseJson => {
            this.setState(() => ({
                page: page, data: responseJson.Output, sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder, totalSize: responseJson.RecordsTotal,
                JobTitle: JobTitle,
                Open: Open,
                Closed: Closed
            }));
            window.scrollTo(0, 0);
        });
    }

    render() {
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <ul className="search_boxnew single-search">
                            <li className="search-box ">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search by Job Title" onChange={(e) => { this.handleTableChange(null, { JobTitle: e.currentTarget.value ? e.currentTarget.value : ' ' }) }} />
                                    <div className="input-group-append">
                                        <button className="btn" type="submit">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 SearchIcon" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <ul className="checkoptionBox">
                        <li style={{marginRight: 40}}> <label className="container1">
                            <input type="checkbox" className="opportunity_status" onChange={() => { this.handleTableChange(null, { Open: !this.state.Open }); }} data-status="Open" checked={this.state.Open} />
                            <span className="checkmark"></span> Open</label></li>
                        <li style={{marginRight: 40}}> <label className="container1"> <input type="checkbox" className="opportunity_status" data-status="Active" onChange={() => { this.handleTableChange(null, { Closed: !this.state.Closed }); }} />
                            <span className="checkmark"></span> Closed </label></li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <hr className="line" />
                <BootstrapTable
                    remote
                    keyField="ItemId"
                    filter={filterFactory()}
                    pagination={paginationFactory({ page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
                    classes='time_sheet_table'
                    bordered={false}
                    data={this.state.data}
                    page={this.state.page}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                    columns={[
                        {
                            dataField: 'Title',
                            text: 'Job Title',
                            sort: true,
                            formatter: (cell, row) => { return (<div><b><Link className="contractLink" to={"opportunities/" + row.ItemId}>{cell}</Link></b></div>); },
                            headerStyle: { width: '25%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'Type',
                            text: 'Type',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '7%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Hours',
                            text: 'Hours',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '6%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Compensation',
                            text: 'Compensation',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Location',
                            text: 'Location',
                            sort: false,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '6%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'StartDate',
                            text: 'Start Date',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell !== '0000-00-00' ? <Moment format="MM/DD/YYYY">{cell}</Moment> : 'Open'}</div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Status',
                            text: 'Status',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '6%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'DateCreated',
                            text: 'Posted On',
                            sort: true,
                            formatter: (cell) => { return (<div><Moment format="MM/DD/YYYY">{cell}</Moment></div>); },
                            headerStyle: { width: '7%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Days',
                            text: '# of Days',
                            sort: false,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '6%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Applicants',
                            text: '# of Applicants',
                            sort: false,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '6%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                    ]}
                    defaultSorted={[{
                        dataField: 'Title',
                        order: 'asc'
                    }]}
                />
            </div>
        </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        myState: state,
        opportunities: state.opportunityList.opportunities,
        filteredOpportunities: state.opportunityList.filterOpportunities,
        opportunityList: state.opportunityList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchOpportunities: () => dispatch(opportunityActions.fetchOpportunities()),
        filterOpportunities: (data) => dispatch(opportunityActions.filterOpportunities(data)),
        sortOpportunities: (data) => dispatch(opportunityActions.sortOpportunities(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityList);