import { 
    ENABLE_OPPORTUNITY_LOADER, DISABLE_OPPORTUNITY_LOADER,
    FETCH_OPPORTUNITIES, SET_OPPORTUNITY,
    FILTER_OPPORTUNITIES,SORT_OPPORTUNITIES,
    UPDATE_OPPORTUNITIES,SET_OPPORTUNITIES,
    SET_OPPORTUNITY_FORM_DATA,CLEAR_OPPORTUNITY_FORM_DATA,SUBMIT_OPPORTUNITY,
    SET_RESPONSE,GET_OPPORTUNITY,OPPORTUNITY_DETAIL,
    REMOVE_RESPONSE
  } from './types';

export const enableLoader = () => ({
    type: ENABLE_OPPORTUNITY_LOADER,
});
export const disableLoader = () => ({
    type: DISABLE_OPPORTUNITY_LOADER,
});
export const fetchOpportunities = () => ({
    type: FETCH_OPPORTUNITIES
});

export const setOpportunities = (data) => ({
    type: SET_OPPORTUNITY,
    data,
});
export const filterOpportunities = (data) => ({
    type: FILTER_OPPORTUNITIES,
    data,
});
export const RemoveOpportunityResponse = () => ({
    type: REMOVE_RESPONSE
});

export const updateOpportunities = (data) => ({
    type: UPDATE_OPPORTUNITIES,
    data,
}); 
export const sortOpportunities = (data) => ({
    type: SORT_OPPORTUNITIES,
    data,
});
export const setOpportunityFormData = (key, value) => ({
    type: SET_OPPORTUNITY_FORM_DATA,
    key,
    value
});
export const clearOpportunityFormData = (key, value) => ({
    type: CLEAR_OPPORTUNITY_FORM_DATA,
    key,
    value
});
export const addOpportunity = (data) => ({
    type: SUBMIT_OPPORTUNITY,
    data,
});
export const setResponse = (data) => ({
    type: SET_RESPONSE,
    data,
  });
export const getOpportunityDetails = (data) => ({
    type: GET_OPPORTUNITY,
    data,
})
export const setOpportunityDetails = (data) => ({
    type: OPPORTUNITY_DETAIL,
    data,
})
