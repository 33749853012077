import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap"
import InputTextArea from "../../../../components/ui/form/InputTextArea";
import Input from "../../../../components/ui/form/Input";
import InputFile from "../../../../components/ui/form/InputFile";
import { Dropdown } from "react-bootstrap";
import { Label } from "aws-amplify-react";
import API from "@aws-amplify/api";

export class ProfileForm extends Component {
  static propTypes = {};

  constructor () {
    super();
    this.state = {
      formErrors: {
        Location: '',
        HourlyRate: '',
        Phone: '',
        Portfolio: '',
        LinkedIn: '',
        PrimaryTitle: '',
        Description: '',
        Resume: '',
        ProfilePicture: '',
      }
      , 
      EmploymentType: ' ', ItemId: ' ',
    }
  }
  setEmploymentType = async () => {
    const { member } = this.props;
    await API.put("1", `/members`, { body: { ItemId: member.ItemId, EmploymentType: member.EmploymentType} }).then(
      (responseJson) => {
        member.EmploymentType = this.state.EmploymentType;
        this.setState({ render: true});     
      }
    );
  };  

  onChange = (key, value) => {
    this.props.onProfileMetaInput(key,value);
  }

  onResumeUpload = (file) => {
    this.props.onProfileMetaInput('Resume',file.FileID);
  }

  onProfilePictureUpload = (file) => {
    this.props.onProfileMetaInput('ProfilePicture', file.FileID);
  }

  onProfileSubmit = () => {
    const Meta = this.props.memberMeta;
    const { member } = this.props;

    if (Meta.Location === '' || Meta.Location === null) {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          Location: 'Please enter location'
        }
      })
    } else {
      this.setState({
        formErrors: {
          ...this.state.formErrors,
          Location: ''
        }
      }, () => {
        this.props.onProfileSubmit(this.state).then(response => {
          this.props.handleModalClose();
        });
      })
    }
  }

  render() {
    const { formErrors } = this.state;
    const Meta = this.props.memberMeta;
    const { member } = this.props;

    const Location = (Meta && typeof Meta.Location !== 'undefined' && Meta.Location !== '') ? Meta.Location: '';
    const HourlyRate = (Meta && typeof Meta.HourlyRate !== 'undefined' && Meta.HourlyRate !== '') ? Meta.HourlyRate : '';
    const Mobile = (Meta && typeof Meta.Mobile !== 'undefined' && Meta.Mobile !== '') ? Meta.Mobile: '';
    const Portfolio = (Meta && typeof Meta.Portfolio !== 'undefined' && Meta.Portfolio !== '') ? Meta.Portfolio: '';
    const LinkedIn = (Meta && typeof Meta.LinkedIn !== 'undefined' && Meta.LinkedIn !== '') ? Meta.LinkedIn : '';
    const Resume = (Meta && typeof Meta.Resume !== 'undefined' && Meta.Resume !== '') ? Meta.Resume: '';
    // const ProfilePicture = (Meta && typeof Meta.ProfilePicture !== 'undefined' && Meta.ProfilePicture !== '') ? Meta.ProfilePicture: '';
    const PrimaryTitle = (Meta && typeof Meta.PrimaryTitle !== 'undefined' && Meta.PrimaryTitle !== '') ? Meta.PrimaryTitle: '';
    const Description = (Meta && typeof Meta.Description !== 'undefined' && Meta.Description !== '') ? Meta.Description: '';
    // const EmploymentType = (this.props.member.EmploymentType && typeof this.props.member.EmploymentType !== 'undefined' && this.props.member.EmploymentType !== '') ? this.props.member.EmploymentType: '';
    const EmploymentType = this.props.member.EmploymentType;
    const SSN = this.props.member.SSN;

    return (
      <Modal animation={false} size="lg" className="detailed_time_sheet_modal" show={this.props.modalEnabled} onHide={() => this.props.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              {/* <Label>Employment Type @ TECKpert</Label>
              <td>
                <select className="form-control"
                style={{ width: "100%", minWidth: "100%" }}
                value={this.props.EmploymentType}
                onChange={(e) => { this.setState({EmploymentType: e.target.value}) }}>
                  <option style={{ opacity: "40%" }} default>Not Specified</option>
                  <option>Not Employed</option>
                  <option>W2</option>
                  <option>1099</option>
                </select>
              </td> */}
              <Input
                label="Location"
                placeholder="Change Location"
                onChange={value => this.onChange("Location", value)}
                value={Location}
                invalidFeedback={formErrors.Location}
              />
              <Input
                label="Hourly Rate"
                placeholder="Change Hourly Rate"
                onChange={value => this.onChange("HourlyRate", value)}
                value={HourlyRate}
                invalidFeedback={formErrors.HourlyRate}
              />
              <Input
                label="Phone"
                placeholder="Change Phone"
                onChange={value => this.onChange("Mobile", value)}
                value={Mobile}
                invalidFeedback={formErrors.Phone}
              />
              <Input
                label="Portfolio"
                placeholder="Change Portfolio"
                onChange={value => this.onChange("Portfolio", value)}
                value={Portfolio}
                invalidFeedback={formErrors.Portfolio}
              />
              <Input
                label="LinkedIn"
                placeholder="Change LinkedIn"
                onChange={value => this.onChange("LinkedIn", value)}
                value={LinkedIn}
                invalidFeedback={formErrors.LinkedIn}
              />

              {/* <InputFile 
                label="Upload Resume"
                onChange={this.onResumeUpload}
                invalidFeedback={this.state.error}
              /> */}
              <Input
                label="Resume"
                placeholder="Set your Resume"
                onChange={value => this.onChange("Resume", value)}
                value={Resume}
                invalidFeedback={formErrors.Resume}
              />

              <InputFile 
                label="Upload Profile Picture"
                onChange={this.onProfilePictureUpload}
                invalidFeedback={this.state.error}
              />

              <Input
                label="Primary Title"
                placeholder="Change Primary Title"
                onChange={value => this.onChange("PrimaryTitle", value)}
                value={PrimaryTitle}
                invalidFeedback={formErrors.PrimaryTitle}
              />

              <InputTextArea
                label="Description"
                placeholder="Change Description"
                onChange={value => this.onChange("Description", value)}
                value={Description}
                invalidFeedback={formErrors.Description}
                rows="3"
              />
              <Input
                label="SSN"
                placeholder="Change SSN"
                onChange={value => this.onChange("SSN", value)}
                value={SSN}
                invalidFeedback={formErrors.SSN}
              />
              <a className="greenBtn modalButton" onClick={() => this.onProfileSubmit()} >
                Save
              </a>
            </div>
          </Modal.Body>
        </Modal>
    );
  }
}

export default ProfileForm;
