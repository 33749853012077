import React, { Component } from "react";
import AccordionCard from "../../../../components/ui/AccordionCard";
import { Modal } from "react-bootstrap";
import Input from "../../../../components/ui/form/Input";
import InputDate from "../../../../components/ui/form/InputDate";
import InputTextArea from "../../../../components/ui/form/InputTextArea";
import InputDropdown from "../../../../components/ui/form/InputDropdown";
import Empty from '../../../../components/ui/Empty'
import Moment from 'react-moment';
import { API, Auth } from 'aws-amplify';

export class References extends Component {

    constructor() {
        super();
        this.state = {
            emailModal: false,
            modalEnabled: false,
            isEdit: false,
            referenceDetail: {
                End: '',
                Role: '',
                Start: '',
                Detail: '',
                Company: '',
            },
            referenceIndex: 0,
            formErrors: {
                End: '',
                Role: '',
                Start: '',
                Detail: '',
                Company: '',
            }
        }
    }

    getReferences = props => {
        const { member } = props;
        return typeof member.Meta !== "undefined" &&
            typeof member.Meta.References !== "undefined" &&
            member.Meta.References !== ""
            ? member.Meta.References
            : [];
    };

    getModalTitle = () => {
        return 'Add Reference';
    }

    handleModalClose = () => {
        this.setState({
            modalEnabled: false,
            emailModal: false,
            isEdit: false,
            referenceDetail: {
                End: '',
                Role: '',
                Start: '',
                Detail: '',
                Company: '',
            },
            formErrors: {
                End: '',
                Role: '',
                Start: '',
                Detail: '',
                Company: '',
            }
        })
    }

    onChange = (key, value) => {
        this.setState({
            referenceDetail: {
                ...this.state.referenceDetail,
                [key]: value
            }
        });
    }

    onSubmit = () => {
        let references = [];
        if (!this.state.isEdit) {
            references = [].concat(this.getReferences(this.props), this.state.referenceDetail);
        } else {
            references = this.getReferences(this.props);
            references[this.state.referenceIndex] = this.state.referenceDetail;
        }
        this.props.onSubmit(references).then(resposne => {
            this.handleModalClose();
        });
    }

    onEditReference = (item, index) => {
        this.setState({
            isEdit: true,
            referenceDetail: item,
            referenceIndex: index,
            modalEnabled: true,
        })
    }
    handleModalOpen = () => {
    this.setState({
      modalEnabled: true
    });
  };


    onDeleteReference = (index) => {
        if (window.confirm('Are you sure you wish to delete this Reference?')) {
            let references = this.getReferences(this.props);
            references.splice(index, 1);
            this.props.onSubmit(references);
        }
    }
    componentWillReceiveProps(newProps) {
    this.setState({memberName:`${newProps.member.FirstName} ${newProps.member.LastName}`});
    this.setState({Subject:`Request for ${this.state.memberName}`});
    this.setState({Body: `<p>Hello</p> <p>My name is ${this.state.firstName} ${this.state.lastName} and I am contacting you regarding ${this.state.memberName} who has listed you as a reference.</p><p>We are considering ${this.state.memberName} for a position at TECKpert and would like to speak with you.</p><p>Could you let me know a good time I can call you&#63;</p><p>Thank You,</p><p>${this.state.firstName} ${this.state.lastName}</p>`});
    const { Meta } = newProps.member;
    const OldMeta = this.props.member.Meta;
    const ProfilePicture = Meta && typeof Meta.ProfilePicture !== "undefined" && Meta.ProfilePicture !== "" ? Meta.ProfilePicture : "";
    const OldProfilePicture = OldMeta && typeof OldMeta.ProfilePicture !== "undefined" && OldMeta.ProfilePicture !== "" ? OldMeta.ProfilePicture : "";

    API.get('1', `/admins?OrderBy=LastName&SortOrder=ASC&ItemsPerPage=100&Page=0`).then(responseJson => {
      let data = [];
      let adminsData = {};
      responseJson.Output.map(item => {
        data.push({
          title: `${item.FirstName} ${item.LastName} (${item.Email})`,
          value: item.ItemId,
          firstName: item.FirstName
        });
        adminsData[item.ItemId] = item.FirstName + ' ' + item.LastName;
      });
      this.setState(() => ({ admins: data, adminsData: adminsData }));
    });

    let userData = Auth.currentSession().then(response => {
        this.setState({ Admin: response.idToken.payload["custom:itemId"]});
        this.setState({ firstName: response.idToken.payload["custom:firstName"]});
        this.setState({ lastName: response.idToken.payload["custom:lastName"]});
        this.setState({ userEmail: response.idToken.payload["email"]});        
    });
    
  }

    onSendEmail = () => {
    let Subject = this.state.Subject
    let Body =  this.state.Body;
   
    API.post('1', `/emailReferences`, {
      body: {
        ToEmail: this.state.toEmail,
        FromEmail: this.state.userEmail,
        FromName: `${this.state.firstName} ${this.state.lastName}`,
        Subject: Subject,
        Message: Body
      }
    }).then(responseJson => {
      this.setState({ 
        emailModal: false, 
        Subject: Subject, 
        Message: Body, 
        Position: null });
    });
  }

    render() {
        const references = this.getReferences(this.props);

        return (
            <div>
                <AccordionCard title="References" actionhtml={
                    <span className="add-sign" onClick={() => {
                        this.setState({ modalEnabled: true });
                    }} >+</span>
                } >
                    {references.length > 0 ? (
                        <div className="Education__list">
                            {references.map((item, index) => {
                                return (
                                    <div
                                        className="Education__list__item"
                                        key={`profile-reference-${index}`}
                                    >
                                        <span className="Education__list__item__year">
                                            <div className="Education__list__item__actions">
                                                <a onClick={() => { this.onEditReference(item, index) }} >Edit</a>
                                                <a onClick={() => this.onDeleteReference(index)}>Delete</a>
                                                <a onClick={() => {this.setState({ emailModal: true, toEmail: item.Email })}} >Send Email</a>
                                                
                                            </div>
                                        </span>
                  |
                                        <div className="Education__list__item__school">
                                            {item.Name}
                                            <p>{item.Relationship}<br />
                                                {item.Email ? <a href={'mailto:' + item.Email}>{item.Email}</a> : ''}<br />
                                                {item.Phone ? <a href={'tel:' + item.Phone}>{item.Phone}</a> : ''}</p>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (

                            <Empty message="No reference found" />
                        )}


                </AccordionCard>
                <Modal animation={false} size="lg" className="detailed_time_sheet_modal" show={this.state.emailModal} onHide={() => this.handleModalClose()} centered
                  isVisible={this.state.emailModal}
                  title={`Send Email `}
                  onSubmit={this.onSendEmail}
                  onClose={() => {
                    this.setState({ emailModal: false })
                  }}
                > <Modal.Header closeButton>
                        <Modal.Title>Send Email</Modal.Title>
                    </Modal.Header>
                  <div className="container send_to_client_container">
                    <div className="clients_appBox from-for">
                      <div className="row">
                        <div className="col-md">
                          <div className="form-group">
                            <label class="form-label">From</label>
                            <InputDropdown
                              placeholder="Select Admin"
                              onChange={value => {
                                setTimeout(() => {
                                  this.formatMessage();
                                }, 0);
                                this.setState({ Admin: value });
                              }}
                              options={this.state.admins}
                              selected={this.state.Admin}
                            />
                          </div>
                        </div>
                        
                        {!['preScreen', '', undefined].includes(this.state.Type) &&
                          <div className="col-md">
                            <div className="form-group">
                              <label class="form-label">Position</label>
                              <InputDropdown
                                placeholder="Select Position"
                                onChange={value => {
                                  setTimeout(() => {
                                    this.formatMessage();
                                  }, 0);
                                  this.setState({ Position: value });
                                }}
                                options={this.state.positions}
                              />
                            </div>
                          </div>
                        }
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">Subject</label>
                             <input
                              type="text"
                              className="form-control"
                              onChange={(event) => this.setState({ Subject: event.currentTarget.value })}
                              value={this.state.Subject}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label className="form-label">Body</label>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  onChange={(event) => this.setState({ Body: event.currentTarget.value })}
                                  value={this.state.Body}
                                  rows={15}
                                />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="modal-footer"><button type="button" class="btn btn-secondary" onClick={() => {this.setState({ emailModal: false })}}>Cancel</button><button type="submit" class="btn btn-primary" onClick={() => this.onSendEmail()}>Submit</button></div>
                  </div>
                </Modal>
                <Modal animation={false} size="lg" className="detailed_time_sheet_modal" show={this.state.modalEnabled} onHide={() => this.handleModalClose()} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>{this.getModalTitle()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="container">

                            <Input
                                label="Name"
                                placeholder="Enter Name"
                                onChange={value => this.onChange("Name", value)}
                                value={this.state.referenceDetail.Name}
                                invalidFeedback={''}
                            />

                            <Input
                                label="Relationship"
                                placeholder="Enter Relationship"
                                onChange={value => this.onChange("Relationship", value)}
                                value={this.state.referenceDetail.Relationship}
                                invalidFeedback={''}
                            />

                            <Input
                                label="Email"
                                placeholder="Enter Email"
                                onChange={value => this.onChange("Email", value)}
                                value={this.state.referenceDetail.Email}
                                invalidFeedback={''}
                            />

                            <Input
                                label="Phone"
                                placeholder="Enter Phone"
                                onChange={value => this.onChange("Phone", value)}
                                value={this.state.referenceDetail.Phone}
                                invalidFeedback={''}
                            />

                            <a className="greenBtn modalButton" onClick={() => this.onSubmit()} >
                                Save
                            </a>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default References;
