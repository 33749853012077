import React, { Component } from "react";
import { bindActionCreators } from "redux";
import "../styles/OpportunityStyles.scss";
import Nav from "../../../components/Navigation/Nav";
import { connect } from "react-redux";
import TopNav from "../../../components/TopNav/TopNav";
import * as opportunityActions from "../actions/index";
import CustomModal from "../../../components/Modals/Modal";
import { openModal, setModalName } from "../../../actions/uiActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Info } from "../components/view/Info";
import { InterestedPeople } from "../components/view/InterestedPeople";
import { Link } from "react-router-dom";
import {
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faAngleDown,
  faSpinner,
  faFilePdf,
} from "@fortawesome/free-solid-svg-icons";
import CreatePDF from "../components/view/CreatePDF";

class OpportunityDetail extends Component {
  constructor(props) {
    super(props);

    this.onOpportunityEdit = this.onOpportunityEdit.bind(this);
  }
  componentDidMount() {
    const ItemId = this.props.match.params.ItemId;
    this.props.getOpportunityDetails(ItemId);
  }
  onSetRates = () => {
    this.props.openModal();
    this.props.setModalName("OpportunitySetRates");
  };
  onOpportunityEdit = () => {
    this.props.openModal();
    this.props.setModalName("OpportunityEdit");
  };
  render() {
    console.log(this.props.opportunityList.opportunityDetails);
    return (
      <div>
        <TopNav title='Opportunity Details' />
        <Nav />
        <div class='container'>
          <div class='row'>
            <div class='Opportunity_details_box'>
              <div class='row'>
                <div class='col-md-9 col-lg-10'></div>
                <div class='col-md-3 col-lg-2'>
                  <ul class='editinglist'>
                    <li>
                      <a onClick={this.onOpportunityEdit} class='active'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24'
                          height='24'
                          viewBox='0 0 24 24'>
                          <path d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z' />
                          <path d='M0 0h24v24H0z' fill='none' />
                        </svg>
                      </a>
                    </li>
                    <li>
                      <FontAwesomeIcon
                        style={{ color: "#6fbe47", cursor: "pointer" }}
                        icon={faFilePdf}
                        onClick={() => CreatePDF(this.props.opportunityList.opportunityDetails[0])}
                      />
                    </li>
                    <li>
                      <Link to={"/opportunities/add"} className='OpportunityDetailBtn'>
                        <svg
                          aria-hidden='true'
                          focusable='false'
                          data-prefix='fas'
                          data-icon='copy'
                          class='svg-inline--fa fa-copy fa-w-16 CopyIcon'
                          role='img'
                          xmlns='http://www.w3.org/2000/svg'
                          viewBox='0 0 512 512'>
                          <path
                            xmlns='http://www.w3.org/2000/svg'
                            d='M395.25,0h-306c-28.05,0-51,22.95-51,51v357h51V51h306V0z M471.75,102h-280.5c-28.05,0-51,22.95-51,51v357    c0,28.05,22.95,51,51,51h280.5c28.05,0,51-22.95,51-51V153C522.75,124.95,499.8,102,471.75,102z M471.75,510h-280.5V153h280.5V510z'
                          />
                        </svg>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <Info
                onsetRates={this.onSetRates}
                opportunity={this.props.opportunityList.opportunityDetails}
              />
              <InterestedPeople opportunity={this.props.opportunityList.opportunityDetails} />
              {this.props.opportunityList.isLoading && (
                <div className='loader'>
                  <FontAwesomeIcon icon={faSpinner} spin />
                </div>
              )}
            </div>
          </div>
        </div>
        <CustomModal />
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    myState: state,
    opportunities: state.opportunityList.opportunities,
    opportunityList: state.opportunityList,
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOpportunityDetails: (data) => dispatch(opportunityActions.getOpportunityDetails(data)),
    openModal: () => dispatch(openModal()),
    setModalName: (data) => dispatch(setModalName(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityDetail);
