export const ClientConstants = {
    "ClientsPerPage": 25,
    "FirstNameValidationMsg" : "Please enter the First Name",
    "LastNameValidationMsg" : "Please enter the Last Name",
    "EmailValidationMsg" : "Please enter a valid email",
    "CompanyValidationMsg"  : "Please enter the Company",
    "LocationValidationMsg" : "Please enter a Location",
    "PhoneValidationMsg"    : "Please enter a Phone",
    "EmptyNotesMsg" : "There are no notes for this contract",
    "EmptyWorkersMsg" : "There are no Workers assigned to this contract",
    "EmptyDocumentsMsg" : "There are no Documents assigned to this contract",
    "EmptyJobOrdersMsg" : "There are no Job Orders assigned to this contract",
    "EmptyHistoryMsg" : "There is no History",    
}