import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faMoneyBill,
  faPhone,
  faLink,
  faFileAlt,
  faEnvelope,
  faExternalLinkAlt,
  faUser
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import * as storageUtils from '../../../../helperFunctions/storageUtils'
import API from "@aws-amplify/api";

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export class Info extends Component {
  static propTypes = {};

  constructor() {
    super()

    this.state={
      Overtime: null
    }
  }

  handleOpenResume = (Resume) => {
    storageUtils.getStorageFile(Resume, true);
  }

  // setOvertime = async (e) => {
  //   let isChecked = e.target.checked;
  //   let isNotChecked = e.target.notChecked;
  //   const { member } = this.props;
  //   await API.put("1", `/members`, { body: { ItemId: member.ItemId, Overtime: member.Overtime} }).then(
  //     (responseJson) => {
  //       this.setState({Overtime: !this.state.Overtime})
  //       member.Overtime = this.state.Overtime;
  //       this.setState({ render: true});     
  //     }
  //   );
  // };  

  render() {
    const { member } = this.props;
    const { Meta } = this.props.member;

    const EmploymentType = (member && typeof member.EmploymentType !== 'undefined' && member.EmploymentType !== '') ? member.EmploymentType : '';
    const Location = (Meta && typeof Meta.Location !== 'undefined' && Meta.Location !== '') ? Meta.Location : '';
    const HourlyRate = (Meta && typeof Meta.HourlyRate !== 'undefined' && Meta.HourlyRate !== '') ? Meta.HourlyRate : '';
    const Mobile = (Meta && typeof Meta.Mobile !== 'undefined' && Meta.Mobile !== '') ? Meta.Mobile : '';
    const Email = (member && typeof member.Email !== 'undefined' && member.Email !== '') ? member.Email : '';

    const Portfolio = (Meta && typeof Meta.Portfolio !== 'undefined' && Meta.Portfolio !== '') ? Meta.Portfolio : '';
    const LinkedIn = (Meta && typeof Meta.LinkedIn !== 'undefined' && Meta.LinkedIn !== '') ? Meta.LinkedIn : '';
    const Resume = (member.Resume && typeof member.Resume !== 'undefined' && member.Resume !== '') ? member.Resume : (Meta && typeof Meta.Resume !== 'undefined' && Meta.Resume !== '') ? Meta.Resume : '';
    const Vetted = (member && typeof member.Vetted !== 'undefined' && member.Vetted !== '') ? member.Vetted : '';
    // const Overtime = (member && typeof member.Overtime !== 'undefined' && member.Overtime !== '') ? member.Overtime : '';


    return (
      <ul className="infoContainer">
        {/* <li>
          <FontAwesomeIcon className="icon2" icon={faUser} />
          <p className="info custom-info">
          Employment Type @  TECKpert
            <span style={{textTransform: 'capitalize', float: "left", display: "inline", width: "30%"}}>{EmploymentType ? `${EmploymentType}` : `Not set`}</span>
          </p>
          <span style={{float: "right", width: "29%",  display: "inline", marginLeft: "10%"}}>
            <label>
              <input type="checkbox" checked={ member.Overtime }
              />
                &nbsp;Overtime
            </label>
          </span>
        </li> */}
        <li className="location">
          <FontAwesomeIcon className="icon2" icon={faMapMarkedAlt} />
          <p className="info info-location">
            Location
            <span>{Location ? `${Location}` : `Not set`}</span>
          </p>
        </li>
        <li className="HourlyRate">
          <FontAwesomeIcon className="icon2 " icon={faMoneyBill} />
          <p className="info">
            Hourly Rate
            <span>{HourlyRate ? `$${HourlyRate}` : `Not set`}</span>
          </p>
        </li>
        <li className="Phone">
          <FontAwesomeIcon className="icon2 " icon={faPhone} />
          <p className="info">
            Phone
            <span>{Mobile ? <a href={`tel:${Mobile}`}>{formatPhoneNumber(Mobile)}</a> : `Not set`}</span>
          </p>
        </li>
        <li className="Email">
          <FontAwesomeIcon className="icon2 " icon={faEnvelope} />
          <p className="info">
            Email
            <span><a target="_blank" href={`mailto:${Email}`}>{Email}</a></span>
          </p>
        </li>
        <li className="portfolio">
          <FontAwesomeIcon className="icon2" icon={faLink} />
          <p className="info">
            Portfolio
            <span> {Portfolio && Portfolio !== '' ? (<a href={Portfolio} target="_blank">{Portfolio} <FontAwesomeIcon icon={faExternalLinkAlt} /></a>) : 'Not set'}</span>
          </p>
        </li>
        <li className="portfolio">
          <FontAwesomeIcon className="icon2 " icon={faLinkedin} />
          <p className="info">
            LinkedIn
            <span> {LinkedIn && LinkedIn !== '' ? (<a href={LinkedIn} target="_blank">Open LinkedIn Profile <FontAwesomeIcon icon={faExternalLinkAlt} /></a>) : 'Not set'}</span>
          </p>
        </li>

        <li className="portfolio">
          <FontAwesomeIcon className="icon2 " icon={faFileAlt} />
          <p className="info">
            Resume
            <span> {Resume && Resume !== '' ? (<a onClick={() => { this.handleOpenResume(Resume); }} target="_blank">Download Resume <FontAwesomeIcon icon={faExternalLinkAlt} /></a>) : 'Not set'}</span>
          </p>
        </li>

        <li className="portfolio">
          <FontAwesomeIcon className="icon2 " icon={faFileAlt} />
          <p className="info">
            Vetted
            <span> {Vetted && Vetted !== '0000-00-00' ? moment(Vetted.split('T')[0]).format("MMMM Do, YYYY") : 'Not set'}</span>
          </p>
        </li>

      </ul>
    );
  }
}

export default Info;
