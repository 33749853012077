import picPlaceholder from '../assets/picPlaceholder.png';


/**
 * 
 * @param {*} responseJson 
 * @param {*} memberArray 
 * @description parses information to be more readable and pushes it to an array. 
 * @if statements check if the values are null.
 * Parse the response from API.get from members
 * traverse the array of members profiles 
 */
// 
export function populateMember(responseJson, memberArray = []) {
  for (let i = 0; i < responseJson.Output.length; i++) {
    let obtainedInfo = {
      Id: '',
      Name: '',
      Role: '',
      Status: '',
      Availability: 'Unknown',
      Location: '',
      RecentEducation: '',
      AllEducation: [],
      DateRegistered: '',
      profilePicture: sessionStorage.getItem(responseJson.Output[i].ItemId),
      HourlyRate: '',  //need to be added
      Mobile: '',
      Email: '',
      LinkedIn: '',
      Portfolio: '',
      Resume: '',
      Specializations: '',
      WeeklyAvailability: '',
      UnverifiedCertifications: '',
      VerifiedCertifications: '',
      LocationAvailability: '',
      Notes: ''
    }
    


    let currentMember = responseJson.Output[i];
    obtainedInfo.Id = currentMember.ItemId;

    // Assign to obtainedInfo if not null. If it's null, just leave it blank
    if (currentMember.FirstName) {
      obtainedInfo.Name += currentMember.FirstName;
      if (currentMember.LastName) { obtainedInfo.Name += " " + currentMember.LastName };
    }

    if (currentMember.PrimaryTitle) { obtainedInfo.Role = currentMember.PrimaryTitle };
    if (currentMember.AccountStatus) { obtainedInfo.Status = currentMember.AccountStatus.charAt(0).toUpperCase() + currentMember.AccountStatus.slice(1) };
    if (currentMember.DateCreated) {
      let myDate = new Date(responseJson.Output[i].DateCreated);
      obtainedInfo.DateRegistered = myDate.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
    }
    if (currentMember.Meta) {
      // let parsedMeta = JSON.parse(currentMember.Meta);
      let parsedMeta = currentMember.Meta;

      if (parsedMeta.Location) { obtainedInfo.Location = parsedMeta.Location }
      if (parsedMeta.LocationAvailability) { obtainedInfo.Availability = parsedMeta.LocationAvailability };

      // Dates of each education object
      let dates = [];

      // Obtain the most recent education date
      if (parsedMeta.Education) {
        (parsedMeta.Education).forEach(element => {
          if (element.End) {
            dates.push(Date.parse(element.End))
          }
          obtainedInfo.AllEducation.push(element.End.split('/')[2] + ' | ' + element.Degree + ' at ' + element.School)
        });
        let maxNumber = dates[0];
        dates.forEach(number => {
          if (number > maxNumber) {
            maxNumber = number;
          }
        })
        let recentIndex = dates.indexOf(maxNumber);
        // Create a string to display under Recent Education category
        let recentEdu = parsedMeta.Education[recentIndex];
        obtainedInfo.RecentEducation = recentEdu.End.split('-')[0] + ' | ' + recentEdu.Degree + ' at ' + recentEdu.School;
        
      }
      if (parsedMeta.HourlyRate) { obtainedInfo.HourlyRate = parsedMeta.HourlyRate };
      if (parsedMeta.Mobile) { obtainedInfo.Mobile = parsedMeta.Mobile };
      if (parsedMeta.Email) { obtainedInfo.Email = parsedMeta.Email };
      if (parsedMeta.LinkedIn) { obtainedInfo.LinkedIn = parsedMeta.LinkedIn };
      if (parsedMeta.Resume) { obtainedInfo.Resume = parsedMeta.Resume };
      if (parsedMeta.Portfolio) { obtainedInfo.Portfolio = parsedMeta.Portfolio };
      
      if (parsedMeta.Specializations) { obtainedInfo.Specializations = parsedMeta.Specializations };
      if (parsedMeta.WeeklyAvailability) { obtainedInfo.WeeklyAvailability = parsedMeta.WeeklyAvailability };
      if (parsedMeta.UnverifiedCertifications) { obtainedInfo.UnverifiedCertifications = parsedMeta.UnverifiedCertifications };
      if (parsedMeta.VerifiedCertifications) { obtainedInfo.VerifiedCertifications = parsedMeta.VerifiedCertifications };
      if (parsedMeta.EmploymentStatus) { obtainedInfo.EmploymentStatus = parsedMeta.EmploymentStatus };
      if (parsedMeta.LocationAvailability) { obtainedInfo.LocationAvailability = parsedMeta.LocationAvailability };
      
      obtainedInfo.profilePicture = (parsedMeta.ProfilePicture ? parsedMeta.ProfilePicture : '');

      // if (obtainedInfo.profilePicture === null) {
      //   if (!parsedMeta.ProfilePicture) {
      //     // obtainedInfo.profilePicture = picPlaceholder;
      //     obtainedInfo.profilePicture = '';
      //   }
      // } else {
      //   obtainedInfo.profilePicture = parsedMeta.ProfilePicture;
      // }

    }
    else {
      // obtainedInfo.profilePicture = picPlaceholder;
      obtainedInfo.profilePicture = '';
    }
    memberArray.push(obtainedInfo);
  }
  return memberArray;
}

// Parse the response from API.get from contracts
export function parseContracts(responseJson, contractArray) {
  for (let i = 0; i < responseJson.Output.length; i++) {
    let contractInfo = {
      contractName: '',
      length: '',
      startDate: '',
      status: '',
    };

    let currentContract = responseJson.Output[i];
    
    if (currentContract.Name) { contractInfo.contractName = currentContract.Name };

    if (currentContract.StartDate) {

      let myDate = new Date(currentContract.StartDate);
      contractInfo.startDate = myDate.toLocaleString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });

      if (currentContract.EndDate) {
        let end = new Date(responseJson.Output[i].EndDate);
        let start = new Date(responseJson.Output[i].StartDate)
        // Modified version of a function I got from StackOverflow
        let diff = Math.floor(end - start);
        let secs = Math.floor(diff / 1000);
        let mins = Math.floor(secs / 60);
        let hours = Math.floor(mins / 60);
        let days = Math.floor(hours / 24);
        let months = Math.floor(days / 31);
        let years = Math.floor(months / 12);
        months = Math.floor(months % 12);
        days = Math.floor(days % 31);
        hours = Math.floor(hours % 24);
        let message = "";
        if (years > 1) { message += years + " years " }
        else if (years === 1) { message += years + " year " }
        if (months > 0 || years > 0) {
          if (months === 1) { message += months + " month "; }
          else if (months > 1) { message += months + " months " }
        }
        if (days === 1) { message += days + " day " }
        else if (days > 1) { message += days + " days " }
        contractInfo.length = message;
      };

      if (currentContract.Status) { contractInfo.status = currentContract.Status };
      
      contractArray.push(contractInfo);
      return contractArray;
    }

  }
}
/**
 * 
 * @param {*} responseJson 
 */
export function parseMemberPrivate(responseJson, privateMemberArray ){
  for (let i = 0; i < responseJson.Output.length; i++) {
    let obtainedInfo = {
      Notes: [], 
      DateCreated: '',
      DateUpdated: '',
  }

 //parse private members 
  let MemberPrivate = responseJson.Output[0];
    if (MemberPrivate.Notes){
      JSON.parse( MemberPrivate.Notes)
      let obj = JSON.parse( MemberPrivate.Notes)

      let key = ''
      for ( key in obj){
        let value = obj[key]
        // let newObj = obtainedInfo.Notes
        obtainedInfo.Notes.push(value)
      }
    }

    privateMemberArray.push(obtainedInfo)


}
}