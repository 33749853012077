import React, { Component } from 'react';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import Moment from 'react-moment';
import { API } from 'aws-amplify';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import Modal from '../../../components/ui/Modal';
import InputDropdown from "../../../components/ui/form/InputDropdown";
import { Dropdown } from 'react-bootstrap';
import { Link } from "react-router-dom";

class ToolsResourcesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            data: [],
            totalSize: 1,
            sizePerPage: 25,
            editModal: false,
            editData: {}
        }
        this.handleTableChange = this.handleTableChange.bind(this);
    }
    async componentDidMount() { }
    RemoteTable = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
        <BootstrapTable
            remote
            keyField="ItemId"
            data={data}
            columns={[{
                dataField: 'Name',
                text: 'Name',
                sort: true,
                formatter: (cell) => { return (<div className="tableCenter">{cell}</div>); },
                headerFormatter: (column, colIndex, { sortElement }) => {
                    return (<div className="tableCenter"><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>)
                },
                headerStyle: { width: '10%' }
            }, {
                dataField: 'Type',
                text: 'Type',
                sort: true,
                formatter: (cell) => { return (<div className="tableCenter">{cell}</div>); },
                headerFormatter: (column, colIndex, { sortElement }) => {
                    return (<div className="tableCenter"><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>)
                },
                headerStyle: { width: '10%' }
            }, {
                dataField: 'Link',
                text: 'Link',
                sort: true,
                formatter: (cell) => { return (<a target="_blank" href={cell}>{cell}</a>); },
                headerFormatter: (column, colIndex, { sortElement }) => {
                    return (<div className="tableCenter"><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>)
                },
                headerStyle: { width: '10%' }
            }, {
                dataField: 'ItemId',
                text: '',
                sort: false,
                formatter: (cell, row) => { return (<div className="tableCenter"><a onClick={() => { this.setState({ editModal: true, editData: row }); }} href="#">Edit</a></div>); },
                headerFormatter: (column, colIndex, { sortElement }) => {
                    return (<div className="tableCenter"><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>)
                },
                headerStyle: { width: '5%' }
            }]}
            defaultSorted={[{
                dataField: 'Name',
                order: 'asc'
            }]}
            filter={filterFactory()}
            pagination={paginationFactory({ page, sizePerPage, totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
            onTableChange={onTableChange}
            classes='time_sheet_table'
            bordered={false}
        />
    );

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }

        let filterString = '';

        API.get('1', `/documents?Folder=TECKpert&OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}${filterString}`).then(responseJson => {
            let result = responseJson.Output;
            this.setState(() => ({
                page: page,
                data: result,
                sizePerPage: sizePerPage,
                sortField: sortField,
                sortOrder: sortOrder,
                totalSize: responseJson.RecordsTotal
            }));
            window.scrollTo(0, 0);
        });
    }

    render() {
        const { data, sizePerPage, page } = this.state;
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <div className="row" style={{ paddingLeft: 40, paddingTop: 25, paddingBottom: 0, fontSize: 24 }}>
                    <Link style={{ marginRight: 10, fontWeight: '800', textDecoration: 'none' }} to="/tools">{'←'}</Link>Manage TECKpert Resources
                    <a style={{ margin: 15, marginLeft: 'auto', marginBottom: 0, fontSize: 16 }} href="#" onClick={() => { this.setState({ editModal: true, editData: {} }) }}>Add Resource</a>
                </div>
                <hr className="line" />
            </div>
            <div className="container">
                <this.RemoteTable
                    data={data}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                />
            </div>

            <Modal
                isVisible={this.state.editModal}
                title={`${this.state.editData.ItemId ? 'Edit' : 'Add'} Resource`}
                onSubmit={async () => {
                    if (this.state.editData.ItemId) {
                        await API.put('1', `/documents`, { body: this.state.editData });
                    }
                    else {
                        await API.post('1', `/documents`, { body: { ...this.state.editData, Folder: 'TECKpert' } });
                    }
                    this.handleTableChange(null, {});
                    this.setState({ editModal: false, editData: {} });
                }}
                onClose={() => {
                    this.setState({ editModal: false, editData: {} });
                }}
                centered
            >
                <div className="row" style={{ marginBottom: 20 }}>
                    <div className="col-2" style={{ padding: 10 }}>
                        Name
                    </div>
                    <div className="col-10">
                        <input style={{ padding: 10, width: '100%' }} value={this.state.editData.Name} onChange={(event) => { this.setState({ editData: { ...this.state.editData, Name: event.currentTarget.value } }) }} />
                    </div>
                </div>
                <div className="row" style={{ marginBottom: 20 }}>
                    <div className="col-2" style={{ padding: 10 }}>
                        Type
                    </div>
                    <div className="col-10">
                        {/* <input style={{ padding: 10, width: '100%' }} value={this.state.editData.Type} onChange={(event) => { this.setState({ editData: { ...this.state.editData, Type: event.currentTarget.value } }) }} /> */}
                        <select style={{ padding: 10, width: '100%' }} value={this.state.editData.Type} onChange={(event) => { this.setState({ editData: { ...this.state.editData, Type: event.currentTarget.value } }); }}>
                            {['Onboarding', 'Something Else'].map(obj => <option value={obj}>{obj}</option>)}
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-2" style={{ padding: 10 }}>
                        Link
                    </div>
                    <div className="col-10">
                        <input style={{ padding: 10, width: '100%' }} value={this.state.editData.Link} onChange={(event) => { this.setState({ editData: { ...this.state.editData, Link: event.currentTarget.value } }) }} />
                    </div>
                </div>
                {this.state.editData.ItemId &&
                    <div className="row">
                        <a style={{ color: '#ff0000', marginLeft: 'auto', marginTop: 15, marginRight: 15 }} href="#" onClick={async () => {
                            if (window.confirm('Are you sure you wish to delete this resource?')) {
                                await API.del('1', `/documents?ItemId=${this.state.editData.ItemId}`);
                                this.handleTableChange(null, {});
                                this.setState({ editModal: false, editData: {} });
                            }
                        }}>Delete Resource</a>
                    </div>
                }
            </Modal>
        </React.Fragment >
        )
    }
}

export default ToolsResourcesList;