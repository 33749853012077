import * as types from "./types";

export const fetchMessageThreads = () => ({
  type: types.FETCH_MESSAGE_THREADS
});
export const setMessageThreads = data => ({
  type: types.SET_MESSAGE_THREADS,
  data
});

export const fetchAvailableContacts = () => ({
  type: types.FETCH_AVAILABLE_CONTACTS
});
export const setAvailableContacts = data => ({
  type: types.SET_AVAILABLE_CONTACTS,
  data
});

export const sendBulkMessage = (message, contacts) => ({
  type: types.SEND_BULK_MESSAGE,
  message,
  contacts
});

export const sendMessage = data => ({ type: types.SEND_MESSAGE, data });
export const sendMessageConversation = data => ({ type: types.SEND_MESSAGE_CONVERSATION, data });

// export const sendPrivateMessage = data => ({ type: types.SEND_PRIVATE_MESSAGE, data });

export const clearConversation = () => ({type: types.CLEAR_CONVERSATION});

export const fetchConversation = ItemId => ({ type: types.FETCH_CONVERSATION, ItemId });
export const setConversation = data => ({ type: types.SET_CONVERSATION, data });

export const enableLoader = () => ({ type: types.ENABLE_LOADER });
export const disbleLoader = () => ({ type: types.DISABLE_LOADER });
