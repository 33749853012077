import { API, Storage } from "aws-amplify";

export function fetchJobTitles() {
  return API.get("1", `/jobtitles/dropdown`).then(responseJson => {
    return responseJson;
  });
}

export function fetchMembers(params) {
  let query = `?Page=${params.Page}&ItemsPerPage=${params.ItemsPerPage}`;
  if (params && typeof params.orderBy !== 'undefined' && params.orderBy !== '') {
    query += `&OrderBy=${params.orderBy}`
  }
  if (params && typeof params.Search !== 'undefined' && params.Search !== '') {
    query += `&Search=${params.Search}`
  }
  if (params && typeof params.filters !== 'undefined' && typeof params.filters !== '' && params.filters.length > 0) {
    params.filters.map(item => {
      if (item.key !== '' && item.value !== '') {
        query += `&${item.key}=${item.value}`
      }
    });
  }
  // Search
  return API.get("1", `/members${query}`).then(responseJson => {
    return responseJson;
  });
}

export function updateProfileMeta(ItemId, Meta) {
  const data = {
    body: {
      ItemId: ItemId,
      SSN: JSON.parse(Meta).SSN,
      Meta: Meta,
    }
  };
  return API.put("1", `/members`, data).then(responseJson => {
    return responseJson;
  });
}

export function fetchMember(ItemId) {
  return API.get("1", `/members?ItemId=${ItemId}`).then(responseJson => {
    return responseJson;
  });
}
export function addMemberNote(ItemId, addNote) {
  return API.put('1', `/members`, { body: { ItemId: ItemId, addNote: addNote } }).then(responseJson => {
    return responseJson;
  });
}
export function editMemberNote(ItemId, editNoteId, editNote) {
  return API.put('1', `/members`, { body: { ItemId: ItemId, editNote: editNote, editNoteId: editNoteId } }).then(responseJson => {
    return responseJson;
  });
}
export function deleteMemberNote(ItemId, deleteNote) {
  return API.put('1', `/members`, { body: { ItemId, ItemId, deleteNote, deleteNote } }).then(responseJson => {
    return responseJson;
  });
}
export function addMemberDocument(ItemId, data) {
  return API.post('1',
    `/documents`,
    {
      body: {
        Key: data.addDocumentFile,
        Type: data.addDocumentType,
        Folder: 'Profile',
        Name: data.addDocument,
        MemberId: ItemId
      }
    }
  ).then(responseJson => {
    return responseJson;
  });
}
export function editMemberDocument(ItemId, data) {
  return API.put('1',
    `/documents`, {
    body: {
      ItemId: data.editDocument,
      Key: data.editDocumentFile,
      Type: data.editDocumentType
    }
  }
  ).then(responseJson => {
    return responseJson;
  });
}
export function deleteMemberDocument(ItemId, data) {
  const deleteDocument = data.deleteDocument;
  return API.del('1',
    `/documents?ItemId=${deleteDocument}`
  ).then(responseJson => {
    return responseJson;
  });
}