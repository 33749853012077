import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from 'react-moment';
import { OpportunityConstants } from '../../config/Constants';
import Empty from '../../../../components/ui/Empty';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCheck, faQuestion, faTimes, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { API } from 'aws-amplify';
import AppModal from "../../../../components/ui/Modal";
import TabsNotes from "../../../Members/components/profile/TabsNotes";


export class InterestedPeople extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ItemId: 0,
            ScreeningStatus: [], 
            data: [],
            Notes: [],
            notesModal: false
        }
    }

    componentDidMount() {
        API.get("1", `/members`).then(
            (responseJson) => {
              let data = [];
              responseJson.Output.map((item) => {
                data.push({
                  key: item.ItemId,
                  ScreeningStatus: item.ScreeningStatus,
                  Notes: item.Notes
                });
              });
              this.setState(() => ({ data: data }));
            }
          ); 
    }
    static propTypes = {
        opportunity: PropTypes.object
    };

    Interested = async (type, ItemId) => {
       let response = await API.put('1', `/applicants`, {body:{ItemId:ItemId, Interested:type}}) 
    }

    render() {     
        const { opportunity } = this.props;
        const data = this.state.data;        
        let Workers = [];
        if (opportunity.length > 0) {
            if (typeof opportunity[0].Workers !== "undefined" && opportunity[0].Workers !== null && opportunity[0].Workers.length > 0) {
                Workers = opportunity[0].Workers;
            } 
        }
        return (
            <div>
                <h3 class="headingblack">Interested People</h3>
                {(typeof Workers !== 'undefined' && Array.isArray(Workers) && Workers.length > 0) ?
                    <table class="table mt-4">
                        <thead>
                            <tr>
                                <th><FontAwesomeIcon style={{ color: '#cccccc' }} icon={faStar} /></th>
                                <th>Name</th>
                                <th>Date Interested</th>
                                <th>Location</th>
                                <th>Links</th>
                                <th>Interested?</th>
                                <th>Status</th>
                                <th>Notes</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Workers.map((Worker) => {
                                return (
                                    <tr key={Worker.ItemId}>                             
                                        <td><FontAwesomeIcon style={{ color: Worker.Starred !== '0' ? '#6FBE47' : '#cccccc' }} icon={faStar} /></td>
                                        <td><Link to={{ pathname: `/workers/${Worker.ItemId}` }}>{Worker.Name}</Link></td>
                                        <td><Moment format="MM/DD/YYYY">{Worker.DateInterested}</Moment></td>
                                        <td>{Worker.Location}</td>
                                        <td>{Worker.LinkedIn ? <a target="_blank" href={Worker.LinkedIn}>LinkedIn</a> : <span>LinkedIn</span>} | {Worker.Portfolio ? <a target="_blank" href={Worker.Portfolio}>Portfolio</a> : <span>Portfolio</span>}</td>
                                        <td>
                                            <div className="interested-buttons">
                                                <button onClick={ () => {
                                                    if (Worker.Interested == "yes") {
                                                    Worker.Interested = null
                                                   } else {
                                                    Worker.Interested = "yes"
                                                   } 
                                                    this.Interested(Worker.Interested, Worker.ApplicantId);
                                                    this.setState({render:true});
                                                    }} className="btn check" style={{ backgroundColor: Worker.Interested == 'yes' ? '#6fbe47' : '#fff' }}>
                                                    <FontAwesomeIcon  icon={faCheck} />
                                                </button>
                                                <button onClick={ () => {
                                                    if (Worker.Interested == "maybe") {
                                                    Worker.Interested = null
                                                   } else {
                                                    Worker.Interested = "maybe"
                                                   } 
                                                    this.Interested(Worker.Interested, Worker.ApplicantId);
                                                    this.setState({render:true});
                                                    }} className="btn questionmark" style={{ backgroundColor: Worker.Interested == 'maybe' ? '#e1e1d0' : '#fff' }}>
                                                    <FontAwesomeIcon  icon={faQuestion} />
                                                </button>
                                                <button onClick={ () => {
                                                    if (Worker.Interested == "no") {
                                                    Worker.Interested = null
                                                   } else {
                                                    Worker.Interested = "no"
                                                   } 
                                                    this.Interested(Worker.Interested, Worker.ApplicantId);
                                                    this.setState({render:true});
                                                    }} className="btn times" style={{ backgroundColor: Worker.Interested == 'no' ? '#b30000' : '#fff' }}>
                                                    <FontAwesomeIcon icon={faTimes} />
                                                </button>
                                            </div>
                                        </td>
                                        <td>  
                                            {data.map(item => (
                                                item.key === Worker.ItemId ? <span>{item.ScreeningStatus}</span> : " "                                            
                                            ))
                                            }                            
                                        </td>
                                        <td>  
                                            <a href='javascript:void(0)'
                                            onClick={() => {
                                                this.setState({ notesModal: !this.state.notesModal, ItemId: Worker.ItemId });
                                            }}>
                                                {data.map(item => (
                                                    item.key === Worker.ItemId ? 
                                                    (item.Notes && item.Notes.length !== 0 ? 
                                                    <FontAwesomeIcon style={{ cursor:"pointer", color: "#6FBE47" }} icon={faStickyNote} /> 
                                                    :
                                                    <FontAwesomeIcon style={{ cursor:"pointer", color: "gray" }} icon={faStickyNote} />) 
                                                    : " "                                            
                                                ))
                                                }   
                                            </a>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    : <Empty message="No interested people found" />
                }
                <AppModal
                isVisible={this.state.notesModal}
                onClose={() => {
                    this.setState({ notesModal: false });
                }}>
                    <div>
                        <div style={{marginTop: "38px"}}></div>
                        {
                            data.map((item, id) => (
                            item.key === this.state.ItemId ? 
                                (item.Notes && item.Notes.length !== 0 ? 
                                 <TabsNotes member={this.state.data[id]}></TabsNotes>
                                : <div className="col-md-12"><div style={{ height: 40 }} /><Empty message="No notes found !" /></div>)
                            : " "                                            
                            ))
                        }
                        <div style={{height: "42px", backgroundColor: "white", position: "relative", top: "72px", left: "15px"}}></div>
                    </div>             
                </AppModal>
            </div>
        );
    }
}
export default InterestedPeople;