import React, { Component } from "react";
import InputTextArea from "../../../components/ui/form/InputTextArea";
import ReactTags from "react-tag-autocomplete";

export class MessageForm extends Component {
  constructor() {
    super();
    this.state = {
      message: "",
      contacts: []
    };
  }

  handleDelete = i => {
    const contacts = this.state.contacts.slice(0);
    contacts.splice(i, 1);
    this.setState({ contacts });
  };

  handleAdd = contact => {
    this.setState({
      contacts: [].concat(this.state.contacts, contact)
    });
  };

  onMessageSend = () => {
    const message = this.state.message;
    const contacts = this.state.contacts;
    this.props.onMessageSend(message, contacts);
  };

  render() {
    let contacts = [];
    if (this.props.contacts.length > 0) {
      this.props.contacts.map(item => {
        contacts.push({
          id: item.ItemId,
          name: `${item.FirstName} ${item.LastName}`
        });
      });
    }

    return (
      <div className="Message_New">
        <ReactTags
          tags={this.state.contacts}
          suggestions={contacts}
          placeholder="Select Contacts"
          className="form-control"
          allowNew={false}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAdd}
        />

        <InputTextArea
          placeholder="Write a Message"
          value={this.state.editNote}
          onChange={text => {
            this.setState({
              message: text
            });
          }}
          rows="10"
        />

        <ul className="buttonBlock">
          <li>
            <a className="greenBtn" onClick={this.onMessageSend}>
              Send
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default MessageForm;
