import React, { Component } from "react";
import PropTypes from "prop-types";

export class InputHours extends Component {

  constructor (props) {
    super(props);
    this.state = {
      hours: (typeof props.value !== "undefined" && props.value !== '') ? (props.value.split(':')[0]) : 0,
      minutes: 0,
      seconds: 0
    }
  }

  emitInput = () => {
    const time = `${this.state.hours}:${this.state.minutes}:${this.state.seconds}`;
    this.props.onChange(time);
  }

  onMinChange = (event) => {
    this.setState({
      minutes: event.currentTarget.value,
    }, () => {
      this.emitInput();
    });
  }

  onHoursChange = (event) => {
    this.setState({
      hours: event.currentTarget.value,
    }, () => {
      this.emitInput();
    });
  }

  render() {
    let inputClass = "form-control";
    if (this.props.validFeedback !== "") inputClass += " is-valid";
    if (this.props.invalidFeedback !== "") inputClass += " is-invalid";

    return (
      <div className="form-group">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon3">{this.props.label}</span>
          </div>
          <input
            type="number"
            className={inputClass}
            id="hours"
            onChange={event => this.onHoursChange(event)}
            placeholder="Hours"
            value={this.state.hours}
          />
          
        </div>
        {this.props.invalidFeedback !== "" && (
          <div class="invalid-feedback">{this.props.invalidFeedback}</div>
        )}
        {this.props.validFeedback !== "" && (
          <div class="valid-feedback">{this.props.validFeedback}</div>
        )}
      </div>
    );
  }
}

InputHours.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  validFeedback: PropTypes.string,
  invalidFeedback: PropTypes.string,
  value: PropTypes.string,
};

InputHours.defaultProps = {
  label: "",
  placeholder: "",
  validFeedback: "",
  invalidFeedback: "",
  value: '',
};

export default InputHours;
