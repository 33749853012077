import React, { Fragment } from "react";
import NavigationButton from "../NavButton/NavButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";

export default class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: this.getCookie('expanded') === 'true' ? true : false
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.getCookie('expanded') !== 'true') {
      document.querySelector('.masterContainer').style.marginLeft = '56px';
      document.querySelector('.teckpertFooter').style.marginLeft = '56px';
      document.querySelector('.sidenavlogo').style.opacity = '0';
      document.querySelector('.teckpertFooter').style.width = 'calc(100% - 56px)';
    }
  }

  handleClick() {
    this.setState(prevState => {
      document.querySelector('.masterContainer').style.marginLeft = prevState.isToggleOn ? '56px' : '240px';
      document.querySelector('.teckpertFooter').style.marginLeft = prevState.isToggleOn ? '56px' : '240px';
      document.querySelector('.sidenavlogo').style.opacity = prevState.isToggleOn ? '0' : '1';
      document.querySelector('.teckpertFooter').style.width = prevState.isToggleOn ? 'calc(100% - 56px)' : 'calc(100% - 240px)';
      this.setCookie('expanded', !prevState.isToggleOn, 365);
      return { isToggleOn: !prevState.isToggleOn };
    });
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }


  render() {
    return (
      <Fragment>
        <div className="navButton " onClick={this.handleClick}>
          <span className="nav-button__icon ">
            {!this.state.isToggleOn ? (
              <FontAwesomeIcon className="oneIcon "
                style={{ background: "#6FBE47", color: "white", padding: "15px" }}
                icon={faBars}
              />
            ) : (
                <FontAwesomeIcon
                  className="secondIcon"
                  style={{ background: "#6FBE47", color: "white", padding: "10px" }}
                  icon={faTimes}
                />
              )}
            <div>
              <NavigationButton
                toggleSidebar={this.handleClick.bind(this)}
                expanded={this.state.isToggleOn}
              />
            </div>
          </span>
        </div>
      </Fragment>
    );
  }
}
