import React, { Component } from 'react';
import '../styles/TimeSheetStyles.scss';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { Modal, Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import * as timesheetActions from '../actions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomModal from '../../../components/Modals/Modal';
import { openModal, setModalName } from '../../../actions/uiActions';
import Moment from 'react-moment';
import { faFile, faTimes, faCalendar } from "@fortawesome/free-solid-svg-icons";

import { API } from 'aws-amplify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "../styles/DateRangeCustom.css"

import InputAutocomplete from "../../../components/ui/form/InputAutocomplete";

import { Link } from "react-router-dom";

class InvoicesAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], page: 1, totalSize: 1, sizePerPage: 25,
            MemberId: ' ',
            Status: ' ',
            Contract: ' ',
            FromDate: ' ',
            ToDate: ' ',
            Duration: ' ',
            Contracts: [],
            ContractName: '',
            TotalHours: 0,
            MembersDropdown: [],
            InvoiceModalData: { Workers: [], PM: {}, CTO: {} },
            QuickbookCompanies: []
        }
    }

    componentDidMount() {
        API.get('1', `/contracts/dropdown`).then(responseJson => {
            this.setState({ Contracts: responseJson.Output });
        });
        API.get('1', `/members/dropdown?Timesheets=1`,
        ).then(responseJson => {
            let parsedData = [];
            responseJson.Output.map(obj => {
                parsedData.push({ title: `${obj.Name} ${obj.Email ? '(' + obj.Email + ')' : ''}`, value: obj.ItemId })
            })
            this.setState({ MembersDropdown: parsedData });
        });
        API.get('1', `/quickbookcompanies/dropdown`).then(responseJson => {
            this.setState({ QuickbookCompanies: responseJson.Output });
        });
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, MemberId, Status, FromDate, ToDate, Contract }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }

        let filterString = '';
        if (!MemberId) { MemberId = this.state.MemberId } if (MemberId !== ' ') { filterString += `&MemberId=${MemberId}` }
        if (!Status) { Status = this.state.Status } if (Status !== ' ') { filterString += `&Status=${Status}`; }
        if (!FromDate) { FromDate = this.state.FromDate } if (FromDate !== ' ') { filterString += `&FromDate=${FromDate}`; }
        if (!ToDate) { ToDate = this.state.ToDate } if (ToDate !== ' ') { filterString += `&ToDate=${ToDate}`; }
        if (!Contract) { Contract = this.state.Contract } if (Contract !== ' ') { filterString += `&ContractId=${Contract}`; }

        API.get('1', `/timesheets?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}${filterString}`).then(responseJson => {
            this.setState(() => ({
                page: page, data: responseJson.Output, sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder, totalSize: responseJson.RecordsTotal,
                MemberId: MemberId,
                Status: Status,
                FromDate: FromDate,
                ToDate: ToDate,
                Contract: Contract,
                TotalHours: responseJson.Output.length > 0 ? responseJson.Output[0].TotalHours : 0
            }));
            window.scrollTo(0, 0);
        });
    }

    render() {
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <div className="row" style={{ paddingLeft: 40, paddingTop: 25, paddingBottom: 0, fontSize: 24 }}>
                    <Link style={{ marginRight: 10, fontWeight: '800', textDecoration: 'none' }} to="/invoices">{'←'}</Link>Add Timesheets to Invoice
                    <a className="btn btn-success sendPayroll" style={{ marginLeft: 'auto' }} href="#" onClick={async () => {
                        console.log(this.props.selectedTimeSheet);
                        //we need to validate that all of the selected timesheets have the same contractid. if so, we continue.
                        let validatedTimesheets = true;
                        for (let i = 0; i < this.props.selectedTimeSheet.length; i++) {
                            if (this.props.selectedTimeSheet[i].ContractId !== this.props.selectedTimeSheet[0].ContractId) {
                                validatedTimesheets = false;
                                alert("You must select timesheets with matching contracts.");
                            }
                        }
                        if (validatedTimesheets && this.props.selectedTimeSheet.length > 0) {
                            let WorkersArray = [];
                            let InvoiceModalSelectedTimesheetsArray = [];
                            let WorkersTotal = 0;
                            for (let i = 0; i < this.props.selectedTimeSheet.length; i++) {
                                WorkersArray.push({ Description: `${this.props.selectedTimeSheet[i].Member}, ${this.props.selectedTimeSheet[i].Title} - ${this.props.selectedTimeSheet[i].Period}`, Quantity: this.props.selectedTimeSheet[i].Hours, Rate: this.props.selectedTimeSheet[i].BillableRate });
                                InvoiceModalSelectedTimesheetsArray.push(this.props.selectedTimeSheet[i].ItemId);
                                WorkersTotal += this.props.selectedTimeSheet[i].Hours * this.props.selectedTimeSheet[i].BillableRate;
                            }
                            let responseContracts = await API.get('1', `/contracts?ItemId=${this.props.selectedTimeSheet[0].ContractId}`);
                            this.setState({
                                InvoiceModal: true,
                                InvoiceModalSelectedTimesheets: InvoiceModalSelectedTimesheetsArray,
                                InvoiceModalData: {
                                    Workers: WorkersArray,
                                    WorkersTotal: WorkersTotal,
                                    ContractId: this.props.selectedTimeSheet[0].ContractId,
                                    PM: {
                                        Description: `Project Management service - ${responseContracts.Output[0].PMFee ? responseContracts.Output[0].PMFee : 0}% of billed hours`,
                                        Percentage: responseContracts.Output[0].PMFee ? responseContracts.Output[0].PMFee : 0
                                    },
                                    CTO: {
                                        Description: `Chief Technology Officer service - ${responseContracts.Output[0].CTOFee ? responseContracts.Output[0].CTOFee : 0}% of billed hours`,
                                        Percentage: responseContracts.Output[0].CTOFee ? responseContracts.Output[0].CTOFee : 0
                                    }
                                }
                            });
                            function compareStrings(aString, bString) {
                                let a = aString.split(" ");
                                let b = bString.split(" ");
                                let found = 0;
                                for (let i = 0; i < a.length; i++) {
                                    for (let j = 0; j < b.length; j++) {
                                        if (b[j].toLowerCase() === a[i].toLowerCase()) found++;
                                    }
                                }
                                return found;
                            }

                            //match the quickbooksCompanyId with the contract name
                            let quickbooksCompanyFilter = this.state.QuickbookCompanies.filter(obj => compareStrings(obj.title, responseContracts.Output[0].Name));
                            console.log(quickbooksCompanyFilter);
                            if (quickbooksCompanyFilter.length > 0) {
                                this.setState({ InvoiceModalData: { ...this.state.InvoiceModalData, QuickbooksCompanyId: quickbooksCompanyFilter[0].value, QuickbooksCompanyName: quickbooksCompanyFilter[0].title } });
                            }
                            console.log(this.state.InvoiceModalData);
                        }
                    }}>Create Invoice</a>
                </div>
                <hr className="line" />
            </div>
            <div className="container" style={{ marginTop: -30 }}>
                <div className="row">
                    <div className="col-md-12">
                        <ul className="search_boxnew">
                            <li className="search-box">
                                {/* <input type="text" className="form-control" placeholder="Search Contract or Worker Name" onChange={(e) => { this.handleTableChange(null, { MemberId: e.currentTarget.value ? e.currentTarget.value : ' ' }) }} /> */}
                                <InputAutocomplete
                                    style={{ width: 450, marginTop: 15 }}
                                    placeholder="Worker"
                                    onChange={(value) => { this.handleTableChange(null, { MemberId: value ? value : ' ' }) }}
                                    options={this.state.MembersDropdown}
                                />
                            </li>
                        </ul>
                        <ul className="search_boxnew search_filters">
                            <li style={{ flex: '0 0 25%' }}>
                                <Dropdown className="filterToggle">
                                    <Dropdown.Toggle>{this.state.Status !== ' ' ? this.state.Status : 'Select Status'}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => { this.handleTableChange(null, { Status: ' ' }) }}>Select Status</Dropdown.Item>
                                        {['Pending', 'Sent for Approval', 'Approved', 'Payroll in Progress', 'Paid'].map(obj => {
                                            return (<Dropdown.Item onClick={() => { this.handleTableChange(null, { Status: obj }); }}>{obj}</Dropdown.Item>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li style={{ flex: '0 0 25%' }}>
                                <Dropdown className="filterToggle">
                                    <Dropdown.Toggle>{this.state.Contract !== ' ' ? this.state.ContractName : 'Select Contract'}</Dropdown.Toggle>
                                    <Dropdown.Menu style={{ maxHeight: 400, overflowY: 'auto' }}>
                                        <Dropdown.Item onClick={() => { this.handleTableChange(null, { Contract: ' ' }) }}>Select Contract</Dropdown.Item>
                                        {this.state.Contracts.map(obj => {
                                            return (<Dropdown.Item onClick={() => { this.handleTableChange(null, { Contract: obj.ItemId }); this.setState({ ContractName: obj.Name }); }}>{obj.Name}</Dropdown.Item>)
                                        })}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li style={{ flex: '0 0 25%' }}>
                                <Dropdown className="filterToggle">
                                    <Dropdown.Toggle>{this.state.Duration !== ' ' ? this.state.Duration : 'Select Duration'}</Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => {
                                            this.handleTableChange(null, { FromDate: ' ', ToDate: ' ' });
                                            this.setState({ Duration: ' ' });
                                        }}>Select Duration</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let ToDate = this.lastWeekformatDate(today);
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 13));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last Week' });
                                        }}>Last Week</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let ToDate = this.lastWeekformatDate(today);
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), today.getDate() - 20));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last 2 Weeks' });
                                        }}>Last 2 Weeks</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), 1));
                                            let ToDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth() + 1, 0));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'This Month' });
                                        }}>This Month</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth() - 1, 1));
                                            let ToDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), 0));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last Month' });
                                        }}>Last Month</Dropdown.Item>
                                        <Dropdown.Item onClick={() => {
                                            let today = new Date();
                                            let FromDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth() - 2, 1));
                                            let ToDate = this.lastWeekformatDate(new Date(today.getFullYear(), today.getMonth(), 0));
                                            this.handleTableChange(null, { FromDate: FromDate, ToDate: ToDate })
                                            this.setState({ Duration: 'Last 2 Months' });
                                        }}>Last 2 Months</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </li>
                            <li style={{ flex: '0 0 25%' }}>
                                {this.state.FromDate !== ' ' && this.state.ToDate !== ' ' ? (
                                    <DateRangePicker onApply={this.onDaterange} >
                                        <button className="rangeButton"><Moment utc format="MM/DD/YYYY">{this.state.FromDate}</Moment><div style={{ marginLeft: 10, marginRight: 10 }}>-</div><Moment utc format="MM/DD/YYYY">{this.state.ToDate}</Moment></button>
                                        <div className="Search__iconCloseDatePicker" onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({ FromDate: ' ', ToDate: ' ' });
                                            this.handleTableChange(null, { FromDate: ' ', ToDate: ' ' });
                                            return false;
                                        }}
                                        ><FontAwesomeIcon className="closeIcon" icon={faTimes} /></div>
                                    </DateRangePicker>
                                ) : (
                                        <DateRangePicker onApply={(event, picker) => {
                                            this.setState({ FromDate: picker.startDate.format("YYYY-MM-DD"), ToDate: picker.endDate.format("YYYY-MM-DD") });
                                            this.handleTableChange(null, { FromDate: picker.startDate.format("YYYY-MM-DD"), ToDate: picker.endDate.format("YYYY-MM-DD") });
                                        }}>
                                            <button id="dateRangePickerStyle" className="rangeButton">Select Date Range <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faCalendar} /></button>
                                        </DateRangePicker>
                                    )}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="container">
                <hr className="line" />
                <BootstrapTable
                    remote
                    keyField="ItemId"
                    filter={filterFactory()}
                    pagination={paginationFactory({ page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
                    classes='time_sheet_table'
                    bordered={false}
                    data={this.state.data}
                    page={this.state.page}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                    columns={[
                        {
                            dataField: 'Member',
                            text: 'Worker',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '30%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'PeriodStart',
                            text: 'Period',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{row.Period}</div>); },
                            headerStyle: { width: '30%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Hours',
                            text: 'Hours',
                            sort: false,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Status',
                            text: 'Status',
                            sort: true,
                            formatter: (cell, row) => {
                                return (<div>{cell === 'Paid' ? new Date() < new Date(row.PayrollDate) ? 'Payroll In Progress' : 'Paid' : cell}</div>);
                            },
                            headerStyle: { width: '20%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'ItemId',
                            text: 'Details',
                            sort: false,
                            formatter: (cell, row) => {
                                return (<div><a href="javascript:void(0)" onClick={() => {
                                    setTimeout(() => {
                                        this.props.setSelectedTimeSheet({ "selectedTimeSheet": this.props.selectedTimeSheet });
                                        this.props.getDetailedTimeSheet({ "itemId": cell });
                                        this.props.openModal();
                                        this.props.setModalName('DetailedTimeSheet');
                                        this.props.selectedTimeSheet.unshift(row);
                                    }, 1);
                                }}><FontAwesomeIcon className="icon2 timesheetDetail" icon={faFile} /></a></div>);
                            },
                            headerFormatter: (column) => { return (<div>{column.text}</div>) },
                            headerStyle: { width: '10%' }
                        }]}
                    selectRow={{
                        mode: 'checkbox',
                        clickToSelect: true,
                        onSelect: (row, isSelect) => {
                            if (isSelect) { this.props.selectedTimeSheet.unshift(row); }
                            else { this.props.selectedTimeSheet.splice(this.props.selectedTimeSheet.findIndex(item => item.ItemId === row.ItemId), 1); }
                            this.props.setSelectedTimeSheet({ "selectedTimeSheet": this.props.selectedTimeSheet });
                        },
                        onSelectAll: (isSelect, rows) => {
                            if (isSelect) { rows.forEach(obj => { this.props.selectedTimeSheet.unshift(obj); }); }
                            else { rows.forEach(obj => { this.props.selectedTimeSheet.splice(this.props.selectedTimeSheet.findIndex(item => item.ItemId === obj.ItemId), 1); }); }
                            this.props.setSelectedTimeSheet({ "selectedTimeSheet": this.props.selectedTimeSheet });
                        },
                    }}
                    defaultSorted={[{
                        dataField: 'PeriodStart',
                        order: 'desc'
                    }]}
                />
            </div>
            <CustomModal />
            <Modal animation={false} size="xl" show={this.state.InvoiceModal} onHide={() => this.setState({ InvoiceModal: false })} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Create Invoice</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <div style={{ margin: 20 }}>
                            Select Company from Quickbooks
                            </div>
                        <InputAutocomplete
                            style={{ width: 450, marginTop: 15 }}
                            placeholder="Company"
                            onChange={(value) => { this.setState({ InvoiceModalData: { ...this.state.InvoiceModalData, QuickbooksCompanyId: value } }); }}
                            options={this.state.QuickbookCompanies}
                            selected={this.state.InvoiceModalData.QuickbooksCompanyName}
                        />
                    </div>
                    <table className="table mt-4 time_sheet_table">
                        <thead>
                            <tr>
                                <th style={{ width: '10%' }}>#</th>
                                <th style={{ width: '50%' }}>Description</th>
                                <th style={{ width: '10%' }}>Quantity</th>
                                <th style={{ width: '10%' }}>Rate</th>
                                <th style={{ width: '10%' }}>Percentage</th>
                                <th style={{ width: '10%' }}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.InvoiceModalData.Workers.map((obj, index) =>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>
                                        <input style={{ padding: 10, width: '100%' }} value={obj.Description} onChange={(event) => { this.setState({ InvoiceModalData: { ...this.state.InvoiceModalData, Worker: { ...obj, Description: event.currentTarget.value } } }); }} />
                                    </td>
                                    <td>
                                        <input style={{ padding: 10, width: 80 }} value={obj.Quantity} onChange={(event) => { this.setState({ InvoiceModalData: { ...this.state.InvoiceModalData, Worker: { ...obj, Quantity: event.currentTarget.value } } }); }} type="number" />
                                    </td>
                                    <td>
                                        <input style={{ padding: 10, width: 80 }} value={obj.Rate} onChange={(event) => { this.setState({ InvoiceModalData: { ...this.state.InvoiceModalData, Worker: { ...obj, Rate: event.currentTarget.value } } }); }} type="number" />
                                    </td>
                                    <td>
                                        N/A
                                </td>
                                    <td>
                                        ${obj.Quantity * obj.Rate}
                                    </td>
                                </tr>
                            )}
                            {this.state.InvoiceModalData.PM.Percentage > 0 &&
                                <tr>
                                    <td>{this.state.InvoiceModalData.Workers.length + 1}</td>
                                    <td>
                                        <input style={{ padding: 10, width: '100%' }} value={this.state.InvoiceModalData.PM.Description} onChange={(event) => { this.setState({ InvoiceModalData: { ...this.state.InvoiceModalData, PM: { ...this.state.InvoiceModalData.PM, Description: event.currentTarget.value } } }); }} />
                                    </td>
                                    <td>
                                        1
                                </td>
                                    <td>
                                        <input style={{ padding: 10, width: 80 }} value={((this.state.InvoiceModalData.PM.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)).toFixed(2)} type="number" readonly />
                                    </td>
                                    <td>
                                        {this.state.InvoiceModalData.PM.Percentage}%
                                </td>
                                    <td>
                                        ${((this.state.InvoiceModalData.PM.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)).toFixed(2)}
                                    </td>
                                </tr>
                            }
                            {this.state.InvoiceModalData.CTO.Percentage > 0 &&
                                <tr>
                                    <td>{this.state.InvoiceModalData.Workers.length + 2}</td>
                                    <td>
                                        <input style={{ padding: 10, width: '100%' }} value={this.state.InvoiceModalData.CTO.Description} onChange={(event) => { this.setState({ InvoiceModalData: { ...this.state.InvoiceModalData, CTO: { ...this.state.InvoiceModalData.CTO, Description: event.currentTarget.value } } }); }} />
                                    </td>
                                    <td>
                                        1
                                </td>
                                    <td>
                                        <input value={((this.state.InvoiceModalData.CTO.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)).toFixed(2)} style={{ padding: 10, width: 80 }} type="number" readonly />
                                    </td>
                                    <td>
                                        {this.state.InvoiceModalData.CTO.Percentage}%
                                </td>
                                    <td>
                                        ${((this.state.InvoiceModalData.CTO.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)).toFixed(2)}
                                    </td>
                                </tr>
                            }
                            <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>Total</td>
                                <td>${((this.state.InvoiceModalData.WorkersTotal) + ((this.state.InvoiceModalData.PM.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)) + ((this.state.InvoiceModalData.CTO.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal))).toFixed(2)} </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row" style={{ justifyContent: 'center', marginTop: 20 }}>
                        <a className="greenBtn" style={{ marginRight: 15, height: 55, backgroundColor: '#AAAAAA', paddingLeft: 25, paddingRight: 25 }} onClick={() => this.setState({ InvoiceModal: false, InvoiceModalData: { Workers: [], PM: {}, CTO: {} } })}>Cancel</a>
                        <a className="greenBtn" style={{ height: 55 }} onClick={async () => {
                            let responseInvoices = await API.post('1', `/invoices`, {
                                body: {
                                    Workers: JSON.stringify(this.state.InvoiceModalData.Workers),
                                    PMDescription: this.state.InvoiceModalData.PM.Description,
                                    PMFee: ((this.state.InvoiceModalData.PM.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)),
                                    PMPercentage: this.state.InvoiceModalData.PM.Percentage,
                                    CTODescription: this.state.InvoiceModalData.CTO.Description,
                                    CTOFee: ((this.state.InvoiceModalData.CTO.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)),
                                    CTOPercentage: this.state.InvoiceModalData.CTO.Percentage,
                                    ContractId: this.state.InvoiceModalData.ContractId,
                                    Amount: ((this.state.InvoiceModalData.WorkersTotal) + ((this.state.InvoiceModalData.PM.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal)) + ((this.state.InvoiceModalData.CTO.Percentage / 100) * (this.state.InvoiceModalData.WorkersTotal))).toFixed(2),
                                    QuickbooksCompanyId: this.state.InvoiceModalData.QuickbooksCompanyId
                                }
                            });
                            this.setState({ InvoiceModal: false, InvoiceModalData: { Workers: [], PM: {}, CTO: {} } });
                            this.props.setSelectedTimeSheet({ "selectedTimeSheet": [] });
                            this.props.history.push('/invoices');
                        }}>Create</a>
                    </div>
                </Modal.Body>
            </Modal>
        </React.Fragment>
        )
    }

    lastWeekformatDate(dateObj) {
        var dateObjMonth = dateObj.getMonth() + 1;
        var dateObjDay = dateObj.getDate();
        var dateObjYear = dateObj.getFullYear();
        var dateFormatted = ("0000" + dateObjYear.toString()).slice(-4) + "-" + ("00" + dateObjMonth.toString()).slice(-2) + "-" + ("00" + dateObjDay.toString()).slice(-2);
        return dateFormatted;
    }
}

function mapStateToProps(state) {
    return {
        myState: state,
        timeSheets: state.timesheetList.timeSheets,
        modalState: state.uiState.modalOpen,
        modalName: state.uiState.modalName,
        selectedTimeSheet: state.timesheetList.selectedTimeSheet,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setSelectedTimeSheet: (data) => dispatch(timesheetActions.setSelectedTimeSheet(data)),
        getDetailedTimeSheet: (data) => dispatch(timesheetActions.getDetailedTimeSheet(data)),
        setSelectedPayroll: (data) => dispatch(timesheetActions.setSelectedPayroll(data)),

        openModal: () => dispatch(openModal()),
        setModalName: (data) => dispatch(setModalName(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesAdd);