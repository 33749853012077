import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  FETCH_MEMBERS,
  FETCH_MEMBER,
  UPDATE_PROFILE_META,
  FETCH_JOB_TITLES,
  ADD_MEMBER_NOTE,
  EDIT_MEMBER_NOTE,
  DELETE_MEMBER_NOTE,
  ADD_MEMBER_DOCUMENT,
  EDIT_MEMBER_DOCUMENT,
  DELETE_MEMBER_DOCUMENT,
  SUBMIT_MEMBER_CONTRACTS,
} from "../actions/types";
import * as membersActions from '../actions';


import * as membersApis from '../api';
import * as apiParser from '../../../helperFunctions/APIparsers';
import * as contractUtils from '../../Contracts/utils/helper';
import * as contractApis from "../../Contracts/api";


export default function* memberSagas() {
  yield takeEvery(FETCH_MEMBERS, fetchMembers);
  yield takeEvery(FETCH_MEMBER, fetchMember);
  yield takeEvery(UPDATE_PROFILE_META, updateProfileMeta);
  yield takeEvery(FETCH_JOB_TITLES, fetchJobTitles);

  yield takeEvery(ADD_MEMBER_NOTE, addMemberNote);
  yield takeEvery(EDIT_MEMBER_NOTE, editMemberNote);
  yield takeEvery(DELETE_MEMBER_NOTE, deleteMemberNote);

  yield takeEvery(ADD_MEMBER_DOCUMENT, addMemberDocument);
  yield takeEvery(EDIT_MEMBER_DOCUMENT, editMemberDocument);
  yield takeEvery(DELETE_MEMBER_DOCUMENT, deleteMemberDocument);

  yield takeEvery(SUBMIT_MEMBER_CONTRACTS, submitMemberContract);

}

function* submitMemberContract(action) {
  yield put(membersActions.enableLoader());
  const dataParsed = yield call(contractUtils.parseObjectToQueryStringData, action.data);
  const response = yield call(contractApis.postContracts, dataParsed);
  let data = yield call(membersApis.fetchMember, action.data.MemberId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(membersActions.setMember(data.Output[0]));
  } else {
    yield put(membersActions.setMember({}));
  }
  yield put(membersActions.setContractResponse(data.Message));
  yield put(membersActions.disableLoader());
}

function* addMemberDocument(action) {
  yield put(membersActions.enableLoader());
  yield call(membersApis.addMemberDocument, action.ItemId, action.data);
  let data = yield call(membersApis.fetchMember, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(membersActions.setMember(data.Output[0]));
  } else {
    yield put(membersActions.setMember({}));
  }
  yield put(membersActions.disableLoader());
}
function* editMemberDocument(action) {
  yield put(membersActions.enableLoader());
  yield call(membersApis.editMemberDocument, action.ItemId, action.data);
  let data = yield call(membersApis.fetchMember, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(membersActions.setMember(data.Output[0]));
  } else {
    yield put(membersActions.setMember({}));
  }
  yield put(membersActions.disableLoader());
}
function* deleteMemberDocument(action) {
  yield put(membersActions.enableLoader());
  yield call(membersApis.deleteMemberDocument, action.ItemId, action.data);
  let data = yield call(membersApis.fetchMember, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(membersActions.setMember(data.Output[0]));
  } else {
    yield put(membersActions.setMember({}));
  }
  yield put(membersActions.disableLoader());
}

function* addMemberNote(action) {
  yield put(membersActions.enableLoader());
  yield call(membersApis.addMemberNote, action.ItemId, action.addNote);
  let data = yield call(membersApis.fetchMember, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(membersActions.setMember(data.Output[0]));
  } else {
    yield put(membersActions.setMember({}));
  }
  yield put(membersActions.disableLoader());
}
function* editMemberNote(action) {
  yield put(membersActions.enableLoader());
  yield call(membersApis.editMemberNote, action.ItemId, action.editNoteId, action.editNote);
  let data = yield call(membersApis.fetchMember, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(membersActions.setMember(data.Output[0]));
  } else {
    yield put(membersActions.setMember({}));
  }
  yield put(membersActions.disableLoader());
}
function* deleteMemberNote(action) {
  yield put(membersActions.enableLoader());
  yield call(membersApis.deleteMemberNote, action.ItemId, action.deleteNote);
  let data = yield call(membersApis.fetchMember, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(membersActions.setMember(data.Output[0]));
  } else {
    yield put(membersActions.setMember({}));
  }
  yield put(membersActions.disableLoader());
}

function* fetchJobTitles(action) {
  yield put(membersActions.enableLoader());
  const data = yield call(membersApis.fetchJobTitles);
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    yield put(membersActions.setJobTitles(data.Output));
  } else {
    yield put(membersActions.setJobTitles({}));
  }
  yield put(membersActions.disableLoader());
}

function* updateProfileMeta(action) {
  yield put(membersActions.enableLoader());
  // console.log('action.meta : ', action.meta);
  const Meta = JSON.stringify(action.meta);
  const data = yield call(membersApis.updateProfileMeta, action.ItemId, Meta);
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    const memberData = yield call(membersApis.fetchMember, action.ItemId);
    if (typeof memberData.Message !== 'undefined' && memberData.Message === "Success") {
      yield put(membersActions.setMember(memberData.Output[0]));
    } else {
      yield put(membersActions.setMember({}));
    }
  }
  yield put(membersActions.disableLoader());
}

function* fetchMember(action) {
  yield put(membersActions.enableLoader());
  const data = yield call(membersApis.fetchMember, action.ItemId);
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    yield put(membersActions.setMember(data.Output[0]));
  } else {
    yield put(membersActions.setMember({}));
  }
  yield put(membersActions.disableLoader());
}

function* fetchMembers(action) {
  yield put(membersActions.enableLoader());
  let params = action.params;
  let data = yield call(membersApis.fetchMembers, params);
  if (typeof data.Message !== 'undefined' && data.Message === "Success") {
    let parsedData = yield call(apiParser.populateMember, data);
    yield put(membersActions.setMembers(parsedData, data.Records, data.RecordsTotal));
  } else {
    yield put(membersActions.setMembers([]));
  }
  yield put(membersActions.disableLoader());
}
