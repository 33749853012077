import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import AccordionCard from "../../../../components/ui/AccordionCard";
import ReactTags from 'react-tag-autocomplete';
import Empty from '../../../../components/ui/Empty';

export class Categories extends Component {

  constructor() {
    super();
    this.state = {
      modalEnabled: false,
      tags: [],
    };
  }

  static propTypes = {
    categories: PropTypes.object
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.tags).then(resposne => {
      this.setState({ modalEnabled: false });
    });
  }

  handleModalClose = () => {
    this.setState({
      modalEnabled: false
    });
  };

  handleAdd = (tag) => {
    const tags = [].concat(this.state.tags, tag.name);
    this.setState({ tags });
  }

  handleRemove = (i) => {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  componentWillReceiveProps(newprops) {
    this.setState({
      tags: this.getCategories(newprops),
    });
  }

  getCategories = (props) => {
    const { categories } = props;
    return categories ? categories : [];
  }

  render() {
    const modalData = [];
    this.state.tags.map((item, index) => {
      modalData.push({
        id: index,
        name: item,
      });
    });

    const category = this.getCategories(this.props);

    return (
      <div>
        <AccordionCard
          title="Categories"
          actionhtml={
            <span
              className="add-sign"
              onClick={() => {
                this.setState({ modalEnabled: true });
              }}
            >
              +
            </span>
          }
        >
          {category.length > 0 ? (
            <div className="Specializations__list">
              <ul className="skill_box">
                {category.map((item, index) => {
                  return (
                    <li
                      key={`profile-category-${index}`}
                      className="Specializations__list__item"
                    ><a href="#">
                        {item}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )
            : <Empty message="No categories found" />
          }
        </AccordionCard>
        <Modal
          size="lg"
          className="specializations_modal"
          show={this.state.modalEnabled}
          onHide={() => this.handleModalClose()}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Manage categories</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <ReactTags
                tags={modalData}
                allowNew={true}
                suggestions={[]}
                handleDelete={this.handleRemove}
                handleAddition={this.handleAdd}
                placeholder='Add new category'
              />
              <a
                className="greenBtn modalButton"
                onClick={() => this.handleSubmit()}
              >
                Save
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Categories;
