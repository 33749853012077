// LOADER ACTIONS
export const ENABLE_LOADER = "ENABLE_LOADER";
export const DISABLE_LOADER = "DISABLE_LOADER";

// MEMBERS LIST DATA ACTIONS
export const FETCH_MEMBERS = "FETCH_MEMBERS";
export const SET_MEMBERS = "SET_MEMBERS";
export const UPDATE_MEMBERS = "UPDATE_MEMBERS";
export const CLEAR_MEMBER = "CLEAR_MEMBER";

// MEMBERS LIST DATA ACTIONS
export const FETCH_MEMBER = "FETCH_MEMBER";
export const SET_MEMBER = "SET_MEMBER";

export const SET_MEMBER_META = "SET_MEMBER_META";
export const UPDATE_PROFILE_META = "UPDATE_PROFILE_META";

export const FETCH_JOB_TITLES = "FETCH_JOB_TITLES";
export const SET_JOB_TITLES = "SET_JOB_TITLES";

export const ADD_MEMBER_NOTE = "ADD_MEMBER_NOTE";
export const EDIT_MEMBER_NOTE = "EDIT_MEMBER_NOTE";
export const DELETE_MEMBER_NOTE = "DELETE_MEMBER_NOTE";


export const ADD_MEMBER_DOCUMENT = "ADD_MEMBER_DOCUMENT";
export const EDIT_MEMBER_DOCUMENT = "EDIT_MEMBER_DOCUMENT";
export const DELETE_MEMBER_DOCUMENT = "DELETE_MEMBER_DOCUMENT";

export const SUBMIT_MEMBER_CONTRACTS = "SUBMIT_MEMBER_CONTRACTS";
export const SET_CONTRACT_RESPONSE = "SET_CONTRACT_RESPONSE";
export const CLEAR_CONTRACT_RESPONSE = "CLEAR_CONTRACT_RESPONSE";
