import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, openModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddContractForm from "../../modules/Contracts/components/addContractForm";
import * as contractActions from "../../modules/Contracts/actions";
import * as clientActions from "../../modules/Clients/actions";

import Input from "../../components/ui/form/Input";
import InputDropdown from "../../components/ui/form/InputDropdown";
import InputDate from "../../components/ui/form/InputDate";
import InputHours from "../../components/ui/form/InputHours";
import InputTextArea from "../../components/ui/form/InputTextArea";

import { availableStatus } from "../../modules/Contracts/config/Constants";
class AddContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ClientId : '',
      
      formErrors: {
        Name: '',
        StartDate: '',
        EndDate: '',
        Hours: '',
        Status: '',
        ClientId: '',
        MemberId: '',
      },
      isValid: false,
      clients: [],
    }
    this.handleModalClose.bind(this);
  }
  componentDidMount() {
    this.props.fetchContacts(this.props.clientList.clientDetails[0].ItemId);
    this.props.fetchWorkers();
    this.props.setContractFormData("ClientId", this.props.clientList.clientDetails[0].ItemId);
    this.props.setContractFormData("Status", "Draft");
    
    setTimeout(() => {
      var isModalFound = document.getElementsByClassName('modal show');
      if(isModalFound.length > 1){
        document.getElementsByClassName('modal show')[0].style.display="none";
      }
    }, 1000);
  }

  onCancel = () => {
    this.props.setModalName(null);
    this.props.closeModal();
    // this.context.router.goBack();
  }
  closeModal(){
    this.props.setModalName(null);
    this.props.closeModal();
  }
  closeModalCallback(){
    this.props.setModalName(null);
    this.props.closeModal();
  }
  handleModalClose() {
    this.props.setModalName(null);
    this.props.closeModal();
  }
  
  parseClientData = () => {
  }
  parseWorkersData = () => {
    let data = [];
    console.log(this.props.workers);
    if(this.props.workers.length > 0){

      this.props.workers.map((item) => {
        console.log(item);
        data.push({
          title: item.Name,
          value: item.ItemId,
        });
      });
    }
    return data;
  }

  parseContactsData = () => {
    let data = [];
    if (this.props.contacts.length > 0) {
      this.props.contacts.map(item => {
        data.push({
          title: `${item.FirstName} ${item.LastName}`,
          value: item.ItemId,
        });
      })
    }
    return data;
  }
  
  fetchContacts = (ItemId) => {
    this.props.fetchContacts(ItemId);
  }

  onSubmit = () => {
      console.log(101);
    this.validateData().then(hasErrors => {
      // console.log(hasErrors);
      // if (!hasErrors) {
        this.props.addClientContract(this.props.addContractData);
        this.props.setModalName(null);
        this.props.closeModal();
      // }
    });
  }

  setError = (key, message) => {
    let formErrors = this.state.formErrors;
    formErrors[key] = message; 
    this.setState({
      formErrors: formErrors,
      isValid: false,
    });
  }

  clearFormErrors =  async () => {
    const emptyErrors = {
      Name: '',
      StartDate: '',
      EndDate: '',
      Hours: '',
      Status: '',
      ClientId: '',
      MemberId: '',
    };
    await this.setState({
      formErrors: emptyErrors,
    });
  }

  validateData = () => {
    const data = this.props.addContractData;
    return this.clearFormErrors().then(() => {
      let hasErrors = false;

      if (typeof data.Name === 'undefined' || data.Name === '') {
          
        this.setError('Name', 'Please provide Contract Name');
        hasErrors = true;
      }
      if (typeof data.StartDate === 'undefined' || data.StartDate === '') {
        
        this.setError('StartDate', 'Please provide Start Date');
        hasErrors = true;
      }
      if (typeof data.EndDate === 'undefined' || data.EndDate === '') {
        
        this.setError('EndDate', 'Please provide End Date');
        hasErrors = true;
      }
      if (typeof data.Hours === 'undefined' || data.Hours === '') {
        
        this.setError('Hours', 'Please provide Hours');
        hasErrors = true;
      }
      if (typeof data.Status === 'undefined' || data.Status === '') {
        
        this.setError('Status', 'Please select Status');
        hasErrors = true;
      }
      if (typeof data.MemberId === 'undefined' || data.MemberId === '') {
        
        this.setError('MemberId', 'Please select Worker');
        hasErrors = true;
      }
      
      if (typeof data.PrimaryContact === 'undefined' || data.PrimaryContact === '') {
        
        this.setError('PrimaryContact', 'Please insert Contact');
        hasErrors = true;
      }
      if (typeof data.Details === 'undefined' || data.Details === '') {
        
        this.setError('Details', 'Please insert Details');
        hasErrors = true;
      }
      return hasErrors;
    });
  }

  onCancel = () => {
    // this.context.router.goBack();
  }

  onInput = (key, value) => {
    if (key === 'ClientId') {
        this.fetchContacts(value);
    }
    this.props.setContractFormData(key, value);
  }
  //
  render() {
    return (
      <React.Fragment>
        <Modal animation={false} size="lg" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="add_contact">
                <div className="row">
                <div className="col-md-12">
                    <div className="row">
                    <div className="col-md-8">
                        <div className="row">
                        <div className="col-md-12">
                            <Input
                            label="Contract Name"
                            placeholder="Contract Name"
                            onChange={value => this.onInput("Name", value)}
                            validFeedback=""
                            invalidFeedback={this.state.formErrors.Name}
                            />
                        </div>
                        </div>
                        <div className="row">
                        <div className="col-md">
                            <label class="form-label">Client Name</label>
                            <select class="form-control" disabled>
                                <option value={this.props.clientList.clientDetails[0].ItemId}>{this.props.clientList.clientDetails[0].Name}</option>
                            </select>
                        </div>
                        <div className="col-md">
                            <InputDropdown
                            label="Contact"
                            placeholder="Please select Contact"
                            onChange={value => this.onInput("PrimaryContact", value)}
                            options={this.parseContactsData()}
                            invalidFeedback={this.state.formErrors.PrimaryContact}
                            />
                        </div>
                        </div>
                        <div className="row">
                        </div>
                        <div className="row">
                        <div className="col-md-12">
                            <InputTextArea
                            label="Details"
                            placeholder="Contract Details"
                            onChange={value => this.onInput("Details", value)}
                            validFeedback=""
                            invalidFeedback={this.state.formErrors.Details}
                            />
                        </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="row">
                        <div className="col-md-12">
                            <InputDropdown
                            label="Status"
                            placeholder="Select Status"
                            onChange={value => this.onInput("Status", value)}
                            options={availableStatus}
                            invalidFeedback={this.state.formErrors.Status}
                            selected="Draft"
                            />
                        </div>
                        </div>

                        <div className="row">
                        <div className="col-md-12">
                            <InputDate
                            label="Start Date"
                            onChange={value => this.onInput("StartDate", value)}
                            invalidFeedback={this.state.formErrors.StartDate}
                            />
                        </div>
                        </div>

                        <div className="row">
                        <div className="col-md-12">
                            <InputDate
                            label="End Date"
                            onChange={value => this.onInput("EndDate", value)}
                            invalidFeedback={this.state.formErrors.EndDate}
                            />
                        </div>
                        </div>

                        <div className="row">
                        <div className="col-md-12">
                            {/* <InputHours
                            label="Hours"
                            onChange={value => this.onChange("Hours", value)}
                            invalidFeedback={formErrors.Hours}
                            /> */}

                            <InputHours
                            label="Hours"
                            onChange={value => this.onInput("Hours", value)}
                            />
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
          </Button>
            <Button variant="primary" onClick={() => this.onSubmit()}>
              Save Changes
          </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )

  }
}
AddContract.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
  getContracts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    memberContracts: state.contract.memberContracts,
    addContractData: state.contract.addContractData,
    clients: state.clientList.clients,
    contacts: state.contract.contacts,
    workers: state.contract.workers,
    contractList : state.contractList,
    clientList: state.clientList,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: (data) => dispatch(closeModal()),
        openModal: () => dispatch(openModal()),
        setModalName: (data) => dispatch(setModalName(data)),
        setContractFormData: (key, value) => dispatch(contractActions.setContractFormData(key, value)),
        fetchClients: () => dispatch(clientActions.fetchClients()),
        fetchWorkers: () => dispatch(contractActions.fetchWorkers()),
        fetchContacts: (ItemId) => dispatch(contractActions.fetchContacts(ItemId)),
        addClientContract: (data) => dispatch(clientActions.addClientContract(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddContract);