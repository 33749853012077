import React, { Component } from "react";

import { Link } from "react-router-dom";

import Input from "../../../components/ui/form/Input";
import InputDropdown from "../../../components/ui/form/InputDropdown";
import InputDate from "../../../components/ui/form/InputDate";
import InputHours from "../../../components/ui/form/InputHours";
import InputTextArea from "../../../components/ui/form/InputTextArea";

import { availableStatus } from "../config/Constants";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class addContractForm extends Component {
  state = {
    Type: 'Fixed Project'
  };

  onChange(key, value) {
    if (key === 'ClientId') {
      this.props.fetchContacts(value);
    }
    this.props.onInput(key, value);
  }

  render() {
    const { formErrors, clients, contacts, workers, addContractData } = this.props;
    let defaultworker = "";
    if (typeof this.props.defaultWorker !== "undefined") {
      defaultworker = this.props.defaultWorker;
    }
    return (
      <div className="add_contact">
        <div className="default_margin" style={{ height: 80 }} />
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-12">
                    <Input
                      label="Contract Name"
                      placeholder="Contract Name"
                      onChange={value => this.onChange("Name", value)}
                      validFeedback=""
                      invalidFeedback={formErrors.Name}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <InputDropdown
                      label="Client Name"
                      placeholder="Please select Client"
                      onChange={value => this.onChange("ClientId", value)}
                      options={clients}
                      invalidFeedback={formErrors.ClientId}
                    />
                  </div>
                  <div className="col-md">
                    <InputDropdown
                      label="Contact"
                      placeholder="Please select Contact"
                      onChange={value => this.onChange("PrimaryContact", value)}
                      options={contacts}

                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <ReactQuill
                      style={{ height: 150, marginBottom: 50 }}
                      modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['link'], ['clean']] }}
                      onChange={value => { this.onChange("Details", value); }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="row">
                  <div className="col-md-12">
                    <InputDropdown
                      label="Status"
                      placeholder="Select Status"
                      onChange={value => this.onChange("Status", value)}
                      options={availableStatus}
                      invalidFeedback={formErrors.Status}
                      selected={"Draft"}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <InputDate
                      label="Start Date"
                      onChange={value => this.onChange("StartDate", value)}
                      invalidFeedback={formErrors.StartDate}
                    />
                  </div>
                  <div className="col-md-6">
                    <InputDate
                      label="End Date"
                      onChange={value => this.onChange("EndDate", value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <InputDropdown
                      label="Type"
                      placeholder="Select Type"
                      onChange={value => { this.onChange("Type", value); this.setState({ Type: value }); }}
                      options={[{ title: 'Fixed Project', value: 'Fixed Project' }, { title: 'Augmentation', value: 'Augmentation' }]}
                      selected={"Fixed Project"}
                    />
                  </div>
                </div>

                {this.state.Type === 'Fixed Project' ?
                  <div className="row">
                    <div className="col-md-12">
                      <InputHours
                        label="Total Hours"
                        onChange={value => this.onChange("Hours", value)}
                      />
                    </div>
                  </div>
                  :
                  <div className="row">
                    <div className="col-md-6">
                      <InputHours
                        label="Estimated Hours"
                        onChange={value => this.onChange("Hours", value)}
                      />
                    </div>
                    <div className="col-md-6">
                      <InputHours
                        label="Number of People"
                        onChange={value => this.onChange("People", value)}
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <ul className="buttonBlock">
                  <li>
                    <a
                      className="greenBtn"
                      onClick={() => this.props.onSubmit()}
                    >
                      Save
                    </a>
                  </li>
                  <li>
                    <Link to="/contracts" className="greenBtn">Cancel  </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default addContractForm;
