// LOADER ACTIONS
export const ENABLE_OPPORTUNITY_LOADER = 'ENABLE_OPPORTUNITY_LOADER';
export const DISABLE_OPPORTUNITY_LOADER = 'DISABLE_OPPORTUNITY_LOADER';
export const FETCH_OPPORTUNITIES = 'FETCH_OPPORTUNITIES';
export const SET_OPPORTUNITY = 'SET_OPPORTUNITY';
export const FILTER_OPPORTUNITIES = 'FILTER_OPPORTUNITIES';
export const SORT_OPPORTUNITIES = 'SORT_OPPORTUNITIES';
export const UPDATE_OPPORTUNITIES = 'UPDATE_OPPORTUNITIES';
export const SET_OPPORTUNITIES = 'SET_OPPORTUNITIES';
export const SUBMIT_OPPORTUNITY = 'SUBMIT_OPPORTUNITY';
export const SET_OPPORTUNITY_FORM_DATA = 'SET_OPPORTUNITY_FORM_DATA';
export const CLEAR_OPPORTUNITY_FORM_DATA = 'CLEAR_OPPORTUNITY_FORM_DATA';
export const SET_RESPONSE = 'SET_RESPONSE';
export const GET_OPPORTUNITY = 'GET_OPPORTUNITY';
export const OPPORTUNITY_DETAIL = 'OPPORTUNITY_DETAIL';
export const REMOVE_RESPONSE = 'REMOVE_RESPONSE';

