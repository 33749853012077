export const GET_MEMBER_CONTRACTS = 'GET_MEMBER_CONTRACTS';
export const GET_MEMBER = 'GET_MEMBER';
export const CLEAR_MEMBER = 'CLEAR_MEMBER';
export const GET_ADMIN_BASICS = 'GET_ADMIN_BASICS';
export const SET_MODAL_TRUE = 'SET_MODAL_TRUE';
export const SET_MODAL_FALSE = 'SET_MODAL_FALSE';
export const SET_MODAL_NAME = 'SET_MODAL_NAME';
export const SET_USER_ATTRIBUTES = 'SET_USER_ATTRIBUTES';
export const ADD_NOTE = 'ADD_NOTE';
export const DELETE_NOTE= 'DELETE_NOTE';
export const GET_NOTE= 'GET_NOTE';
export const UPDATE_NOTE= 'UPDATE_NOTE';
export const GET_TIMESHEETS = 'GET_TIMESHEETS';
export const FILTER_TIMESHEETS = 'FILTER_TIMESHEETS';