import { SET_USER_ATTRIBUTES} from '../actions/actionTypes';

const initialState = {
  userState: [],
};

export default function(state = initialState, action) {
  
  switch (action.type) {
    case SET_USER_ATTRIBUTES:
      return {
        userState: action.payload,
      };
    default:
      return state;
  }
}
