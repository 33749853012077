import { API, Storage } from 'aws-amplify';

export function fetchTimeSheets() {
    return API.get('1', '/timesheets').then(responseJson => {
        return responseJson;
    });
}
export function fetchContacts(data) {
    return API.get('1', '/contacts?ClientId=' + data).then(responseJson => {
        return responseJson;
    });
}
export function filterTimeSheetsByDate(fromDate, toDate) {
    return API.get('1', '/timesheets?fromDate=' + fromDate + '&toDate=' + toDate).then(responseJson => {
        return responseJson;
    });
}

export function sendToClient(data) {
    return API.post('1', `/timesheets/sendtoclient`, { body: data }).then(responseJson => {
        return responseJson;
    });
}
export function sendToPayroll(data) {
    return API.post('1', `/payrolls?${data}`).then(responseJson => {
        return responseJson;
    });
}
export function editPayrollDate(data) {
    //console.log("we hit the function!");console.log(data);return {Message: "Success", Output: []};
    return API.put('1', `/payrolls`, { body: data }).then(responseJson => {
        return responseJson;
    });
}
export function getDetailedTimeSheets(itemId) {
    return API.get('1', '/timesheets?ItemId=' + itemId).then(responseJson => {
        return responseJson;
    });
}
export function approveTimesheet(TimeSheets) {
    return API.put('1', '/approve', { body: { Timesheets: TimeSheets } }).then(responseJson => {
        return responseJson;
    });
}
export function fetchTimeSheetsForApproval(TimeSheets) {
    return API.get('2', `/approve?Timesheets=${TimeSheets}`).then(responseJson => {
        return responseJson;
    });
}

