import React, { Component } from "react";
import PropTypes from "prop-types";
import AccordionCard from "../../../../components/ui/AccordionCard";
import EmptyData from './EmptyData';
import { MemberConstants } from '../../config/Constants';
import Moment from 'react-moment';
import Empty from '../../../../components/ui/Empty';
import { Link } from "react-router-dom";

export class Contracts extends Component {
  static propTypes = {
    member: PropTypes.object
  };

  static defaultProps = {
    member: {

    }
  }
  addContract = () => {
    this.props.onAddContract();
  }
  state = {
    Draft: false,
    Building: false,
    InProgress: true,
    Paused: false,
    Ended: false
  };

  render() {
    const { member } = this.props;
    let Contracts = [];
    if (typeof member.Contracts !== "undefined" && member.Contracts !== null && member.Contracts.length > 0) {
      Contracts = member.Contracts;
      for (var i = 0; i < Contracts.length; i++) {
        let StartDate = new Date(Contracts[i].StartDate);
        let EndDate = new Date(Contracts[i].EndDate);
        // To calculate the time difference of two dates 
        let DifferenceInTime = EndDate.getTime() - StartDate.getTime();
        // To calculate the no. of days between two dates 
        let DifferenceInMonths = DifferenceInTime / (1000 * 3600 * 24 * 30);
        Contracts[i].contractLength = Math.round(DifferenceInMonths);
      }
    }
    return (
      <AccordionCard title="Contracts" actionhtml={
        <div>
          <div className="col-md-7" style={{ marginTop: -80, marginLeft: 120, marginBottom: -60 }}>
            <ul className="checkoptionBox">
              <li style={{ marginRight: -20 }}> <label className="container1">
                <input type="checkbox" className="contract_status" onChange={() => { this.setState({ Draft: !this.state.Draft }); }} data-status="Draft" />
                <span className="checkmark"></span> Draft</label></li>
              <li> <label className="container1"> <input type="checkbox" className="contract_status" data-status="Building" onChange={() => { this.setState({ Building: !this.state.Building }); }} />
                <span className="checkmark"></span> Building </label></li>
              <li style={{ marginRight: 20 }}> <label className="container1"> <input type="checkbox" className="contract_status" data-status="In Progress" onChange={() => { this.setState({ InProgress: !this.state.InProgress }); }} defaultChecked />
                <span className="checkmark"></span> In Progress </label></li>
              <li style={{ marginRight: -10 }}> <label className="container1">	<input type="checkbox" className="contract_status" data-status="Paused" onChange={() => { this.setState({ Paused: !this.state.Paused }); }} /> <span className="checkmark">
              </span> Paused </label></li>
              <li> <label className="container1"> <input type="checkbox" className="contract_status" data-status="Ended" onChange={() => { this.setState({ Ended: !this.state.Ended }); }} />
                <span className="checkmark"></span> Ended </label></li>
            </ul>
          </div>
          <span className="add-sign" onClick={this.addContract} >+</span>
        </div>
      } >
        {(typeof Contracts !== 'undefined' && Array.isArray(Contracts) && Contracts.length > 0) ?
          <table className="table mt-4">
            <thead>
              <tr>
                <th> Contract Name </th>
                <th>Length </th>
                <th>Start Date  </th>
                <th>  Status </th>
                <th> Job Order(s)  </th>
                <th> Onboarding  </th>
              </tr>
            </thead>
            <tbody>

              {Contracts.map((Contract) => {
                if ((Contract.Status === 'Draft' && this.state.Draft) || (Contract.Status === 'Building' && this.state.Building) || (Contract.Status === 'In Progress' && this.state.InProgress) || (Contract.Status === 'Paused' && this.state.Paused) || (Contract.Status === 'Ended' && this.state.Ended))
                  return (
                    <tr>
                      <td> <Link to={"/contracts/" + Contract.ItemId}>{Contract.Name}</Link> </td>
                      <td> {Contract.contractLength} Months </td>
                      <td> <Moment format="MM/DD/YYYY">{Contract.StartDate}</Moment> </td>
                      <td>  {Contract.Status} </td>
                      <td>  {(Contract.JobOrders === 1) ? '✓' : ''} </td>
                      <td>  </td>
                    </tr>
                  )
              })}

            </tbody>
          </table>
          : <Empty message="No contracts found" />
        }
      </AccordionCard>
    );
  }
}

export default Contracts;
