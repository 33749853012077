import React, { Component } from "react";
import Moment from "react-moment";
import AccordionCard from "../../../../components/ui/AccordionCard";
import Empty from "../../../../components/ui/Empty";
import { API } from "aws-amplify";

export class Activities extends Component {
  constructor() {
    super();
    this.state = {
      activities: [],
    };
  }

  componentDidMount() {
    API.get("1", `/activity?MemberId=${this.props.memberId}`).then((responseJson) => {
      console.log(responseJson);
      this.setState(() => ({
        activities: responseJson.Output,
      }));
    });
  }

  render() {
    return (
      <AccordionCard title='Activities'>
        {this.state.activities.length > 0 ? (
          <div className='Activities__list'>
            {this.state.activities.map((item, index) => {
              return (
                <div className='Activities__list__item' key={`profile-Activities-${index}`}>
                  <div style={{ fontSize: 16, fontWeight: "bold" }}>{item.Message}</div>
                  <div style={{ fontSize: 14, color: "gray" }}>
                    Date Created: <Moment format='MM/DD/YYYY'>{item.DateCreated}</Moment>
                  </div>
                  <div style={{ fontSize: 14, color: "gray" }}>
                    Date Updated: <Moment format='MM/DD/YYYY'>{item.DateUpdated}</Moment>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <Empty message='No Activities found' />
        )}
      </AccordionCard>
    );
  }
}

export default Activities;
