import React, { Component } from "react";
import Moment from 'react-moment';
import AccordionCard from "../../../../components/ui/AccordionCard";
import Empty from '../../../../components/ui/Empty';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { API } from 'aws-amplify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import { Modal, Button } from "react-bootstrap"

export class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [], page: 1, totalSize: 1, sizePerPage: 25,
      timesheetDetail: {}
    };
  }
  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }
    API.get('1', `/timesheets?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}&ContractId=${this.props.contractId}`).then(responseJson => {
      this.setState(() => ({
        page: page, data: responseJson.Output, sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder, totalSize: responseJson.RecordsTotal
      }));
    });
  }
  render() {
    return (
      <AccordionCard title="History" >
        <BootstrapTable
          remote
          keyField="ItemId"
          filter={filterFactory()}
          pagination={paginationFactory({ page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
          classes='time_sheet_table'
          bordered={false}
          data={this.state.data}
          page={this.state.page}
          sizePerPage={this.state.sizePerPage}
          totalSize={this.state.totalSize}
          onTableChange={this.handleTableChange}
          columns={[
            {
              dataField: 'Member',
              text: 'Worker',
              sort: true,
              formatter: (cell) => { return (<div>{cell}</div>); },
              headerStyle: { width: '15%' },
              headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
            }, {
              dataField: 'PeriodStart',
              text: 'Period',
              sort: true,
              formatter: (cell, row) => { return (<div>{row.Period}</div>); },
              headerStyle: { width: '15%' },
              headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
            }, {
              dataField: 'Hours',
              text: 'Hours',
              sort: false,
              formatter: (cell) => { return (<div>{cell}</div>); },
              headerStyle: { width: '8%' },
              headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn">{column.text}</a>{sortElement}</div>) },
            }, {
              dataField: 'Status',
              text: 'Status',
              sort: true,
              formatter: (cell, row) => {
                return (<div>{cell === 'Paid' ? new Date() < new Date(row.PayrollDate) ? 'Payroll In Progress' : 'Paid' : cell}</div>);
              },
              headerStyle: { width: '8%' },
              headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
            }, {
              dataField: 'PayrollDate',
              text: 'Payroll',
              sort: true,
              formatter: (cell, row) => {
                return (<div>{(cell !== null) &&
                  <a href="javascript:void(0)" onClick={() => {
                    this.props.setSelectedPayroll({ selectedPayroll: { ItemId: row.PayrollId, PayrollDate: cell } });
                    this.props.openModal();
                    this.props.setModalName('EditPayrollDate');
                  }
                  }>
                    <Moment utc format="MM/DD/YYYY">{cell}</Moment></a>}</div>);
              },
              headerStyle: { width: '8%' },
              headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
            }, {
              dataField: 'ItemId',
              text: 'Details',
              sort: false,
              formatter: (cell, row) => {
                return (<div><a href="javascript:void(0)" onClick={() => {
                  // API.get('1', `/timesheets?ItemId=${cell}`).then(responseJson => {this.setState({ modal: true, timesheetDetail: responseJson.Output[0] });});
                  this.setState({ modal: true, timesheetDetail: row });
                }}><FontAwesomeIcon className="icon2 timesheetDetail" icon={faFile} /></a></div>);
              },
              headerFormatter: (column) => { return (<div>{column.text}</div>) },
              headerStyle: { width: '8%' }
            }]}
          defaultSorted={[{
            dataField: 'PeriodStart',
            order: 'desc'
          }]}
        />
        <Modal animation={false} size="xl" className="detailed_time_sheet_modal" show={this.state.modal} onHide={() => { this.setState({ modal: false }) }} centered>
          <Modal.Header closeButton>
            <Modal.Title> Timesheet Period: From <Moment format="MM/DD/YYYY">{this.state.timesheetDetail.PeriodStart}</Moment> to <Moment format="MM/DD/YYYY">{this.state.timesheetDetail.PeriodEnd}</Moment> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <ul className="profildetail">
                <li>
                  <div className="userprofil">
                    {(this.state.profilePic === "") ? "" :
                      <img className="contactImg" src={this.state.profilePic} />
                    }
                  </div>
                </li>
                <li className="username">
                  <h3> {this.state.timesheetDetail.Member} </h3>
                  <h4> {this.state.timesheetDetail.PrimaryTitle} </h4>
                  <h5> Status: {this.state.timesheetDetail.Status} </h5>
                </li>
              </ul>
              <table className="table mt-4 time_sheet_table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Hours</th>
                    <th>Rate</th>
                    <th>Category</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.timesheetDetail.TimesheetDays && this.state.timesheetDetail.TimesheetDays.map((timesheetday) => {
                    return (
                      <tr>
                        <td> <Moment format="MM/DD/YYYY">{timesheetday.Date}</Moment> </td>
                        <td> {timesheetday.Hours} </td>
                        <td> ${this.state.timesheetDetail.HourlyRate} </td>
                        <td> {timesheetday.Category === 'undefined' ? 'Uncategorized' : timesheetday.Category} </td>
                        <td> {timesheetday.Details} </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </AccordionCard >
    );
  }
}

export default History;
