import React, { Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faFighterJet,
  faClock
} from "@fortawesome/free-solid-svg-icons";
const Preferences = props => {

  return (
    <Fragment>
      <div className="preferenceInfo">
        <div className="grid grid--1">
          <ul>
            <li>
              <FontAwesomeIcon icon={faBriefcase} className="Icon3" />
              <p className="pInfo">
                System Status
                <span>{props.systemStatus}</span>
              </p>
            </li>
            <li>
              <FontAwesomeIcon icon={faClock} className="Icon3" />
              <p className="pInfo">
                Weekly availability is
                <span>{props.WeeklyAvailability}</span>
              </p>
            </li>
          </ul>
        </div>
        <div className="grid grid--2">
          <ul>
            <li>
              <FontAwesomeIcon icon={faBriefcase} className="Icon3" />
              <p className="pInfo ">
                Current employment status is
                <span>{props.EmploymentStatus}</span>
              </p>
            </li>
            <li>
              <FontAwesomeIcon icon={faFighterJet} className="Icon3" />
              <p className="pInfo">
                Work Location will be
                <span>{props.LocationAvailability}</span>
              </p>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  );
};

export default Preferences;
