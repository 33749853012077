import React, { Component } from "react";
import Nav from "../../../components/Navigation/Nav";
import TopNav from "../../../components/TopNav/TopNav";
import CustomModal from "../../../components/Modals/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from "react-moment";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { API } from "aws-amplify";
import { PDFDownloadLink, Document, Page, Text, View } from "@react-pdf/renderer";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import moment from "moment";
import { Link } from "react-router-dom";

const RemoteTable = ({ data, page, sizePerPage, onTableChange, totalSize }) => (
  <BootstrapTable
    remote
    keyField='ItemId'
    data={data}
    columns={[
      {
        dataField: "PayrollDate",
        text: "Date",
        sort: true,
        formatter: (cell) => {
          return (
            <div className='tableCenter'>
              <Moment utc format='MM/DD/YYYY'>
                {cell}
              </Moment>
            </div>
          );
        },
        headerFormatter: (column, colIndex, { sortElement }) => {
          return (
            <div className='tableCenter'>
              <a className='sortcolumn' href='#'>
                {column.text}
              </a>
              {sortElement}
            </div>
          );
        },
        headerStyle: { width: "25%" },
      },
      {
        dataField: "Hours",
        text: "Total Hours",
        sort: true,
        formatter: (cell) => {
          return <div className='tableCenter'>{cell.slice(0, -3)}</div>;
        },
        headerFormatter: (column, colIndex, { sortElement }) => {
          return (
            <div className='tableCenter'>
              <a className='sortcolumn' href='#'>
                {column.text}
              </a>
              {sortElement}
            </div>
          );
        },
        headerStyle: { width: "25%" },
      },
      {
        dataField: "Paid",
        text: "Cash Required",
        sort: true,
        formatter: (cell) => {
          return <div className='tableCenter'>${cell}</div>;
        },
        headerFormatter: (column, colIndex, { sortElement }) => {
          return (
            <div className='tableCenter'>
              <a className='sortcolumn' href='#'>
                {column.text}
              </a>
              {sortElement}
            </div>
          );
        },
        headerStyle: { width: "25%" },
      },
      {
        dataField: "Summary",
        text: "PDF",
        sort: false,
        formatter: (cell, row) => {
          let summary = JSON.parse(cell);
          let info = summary.Info;
          delete summary.Info;
          let PayrollDate = moment(row.PayrollDate).utc().format("MM/DD/YYYY").toString();
          return (
            <div className='tableCenter'>
              <PDFDownloadLink
                document={
                  <Document>
                    <Page
                      style={{
                        fontSize: 10,
                        color: "#212529",
                        display: "flex",
                        alignItems: "center",
                        margin: 25,
                        marginLeft: 0,
                        marginRight: 0,
                      }}>
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginBottom: 25,
                          marginLeft: 0,
                          marginRight: 0,
                        }}>
                        <Text>TECKpert - Payroll Report - {PayrollDate}</Text>
                      </View>

                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 1,
                          marginBottom: 1,
                          padding: 5,
                          backgroundColor: "#f2f2f2",
                          borderWidth: 1,
                          borderColor: "#dee2e6",
                        }}>
                        <View style={{ width: "20%" }}>
                          <Text>Worker</Text>
                        </View>
                        <View style={{ width: "8%" }}>
                          <Text>Hours</Text>
                        </View>
                        <View style={{ width: "8%" }}>
                          <Text>Rate</Text>
                        </View>
                        <View style={{ width: "14%" }}>
                          <Text>Period</Text>
                        </View>
                        <View style={{ width: "20%" }}>
                          <Text>Client</Text>
                        </View>
                        <View style={{ width: "20%" }}>
                          <Text>Email Address</Text>
                        </View>
                      </View>

                      {Object.entries(summary).map(([key, value]) => {
                        return (
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              marginTop: 1,
                              marginBottom: 1,
                              padding: 5,
                              borderWidth: 1,
                              borderColor: "#dee2e6",
                            }}>
                            <View style={{ width: "20%" }}>
                              <Text>{key}</Text>
                            </View>
                            <View style={{ width: "8%" }}>
                              <Text>{value.Hours}</Text>
                            </View>
                            <View style={{ width: "8%" }}>
                              <Text>${value.Rate}</Text>
                            </View>
                            <View style={{ width: "14%" }}>
                              {value.Period &&
                                value.Period.split(",").map((obj) => {
                                  return <Text>{obj}</Text>;
                                })}
                            </View>
                            <View style={{ width: "20%" }}>
                              {value.Client &&
                                value.Client.split(",").map((obj) => {
                                  return <Text>{obj}</Text>;
                                })}
                            </View>
                            <View style={{ width: "20%" }}>
                              <Text>{value.Email}</Text>
                            </View>
                          </View>
                        );
                      })}

                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: 25,
                          marginBottom: 5,
                        }}>
                        <View style={{ width: "20%" }}>
                          <Text>Total Hours</Text>
                        </View>
                        <View style={{ width: "8%" }}>
                          <Text>{info.Hours}</Text>
                        </View>
                      </View>
                      <View style={{ display: "flex", flexDirection: "row", marginTop: 5 }}>
                        <View style={{ width: "20%" }}>
                          <Text>Cash Required</Text>
                        </View>
                        <View style={{ width: "8%" }}>
                          <Text>${info.Paid}</Text>
                        </View>
                      </View>
                    </Page>
                  </Document>
                }
                fileName={row.ItemId + ".pdf"}>
                {({ blob, url, loading, error }) => (
                  <div>
                    Download PDF <FontAwesomeIcon className='icon2 timesheetDetail' icon={faFile} />
                  </div>
                )}
              </PDFDownloadLink>
            </div>
          );
        },
        headerFormatter: (column) => {
          return <div className='tableCenter'>{column.text}</div>;
        },
        headerStyle: { width: "25%" },
      },
    ]}
    defaultSorted={[
      {
        dataField: "PayrollDate",
        order: "desc",
      },
    ]}
    filter={filterFactory()}
    pagination={paginationFactory({
      page,
      sizePerPage,
      totalSize,
      hideSizePerPage: true,
      alwaysShowAllBtns: true,
      withFirstAndLast: false,
    })}
    onTableChange={onTableChange}
    classes='time_sheet_table'
    bordered={false}
  />
);

class ToolsPayrollsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      data: [],
      totalSize: 1,
      sizePerPage: 25,
    };
    this.handleTableChange = this.handleTableChange.bind(this);
  }
  componentDidMount() {
    console.log("tools did mount");
  }
  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, PayrollDate }) => {
    API.get(
      "1",
      `/payrolls?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${
        page - 1
      }`
    ).then((responseJson) => {
      let result = responseJson.Output;
      this.setState(() => ({
        page: page,
        data: result,
        sizePerPage: sizePerPage,
        sortField: sortField,
        sortOrder: sortOrder,
        totalSize: responseJson.RecordsTotal,
      }));
    });
  };

  render() {
    const { data, sizePerPage, page } = this.state;
    return (
      <React.Fragment>
        <TopNav />
        <Nav />
        <div className='container'>
          <div
            className='row'
            style={{ paddingLeft: 40, paddingTop: 25, paddingBottom: 15, fontSize: 24 }}>
            <Link
              style={{ marginRight: 10, fontWeight: "800", textDecoration: "none" }}
              to='/tools'>
              {"←"}
            </Link>
            Payrolls Log
          </div>
          <hr className='line' />
        </div>
        <div className='container'>
          <RemoteTable
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={this.state.totalSize}
            onTableChange={this.handleTableChange}
          />
        </div>
        <CustomModal />
      </React.Fragment>
    );
  }
}

export default ToolsPayrollsList;
