import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as clientActions from '../../modules/Clients/actions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { closeModal, openModal, setModalName } from '../../actions/uiActions';
import { faSpinner} from "@fortawesome/free-solid-svg-icons";
import AddContactForm from "../../modules/Clients/components/addContactForm";
class ContactAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        Name: '',
        StartDate: '',
        EndDate: '',
        Hours: '',
        Status: '',
        ClientId: '',
        MemberId: '',
      },
      isValid: false,
    }
  }
  componentDidMount() {
    if (typeof this.props.clientList.contactDetail.ItemId !== "undefined") {
      this.props.setContactFormData("ItemId", this.props.clientList.contactDetail.ItemId);
      this.props.setContactFormData("FirstName", this.props.clientList.contactDetail.FirstName);
      this.props.setContactFormData("LastName", this.props.clientList.contactDetail.LastName);
      this.props.setContactFormData("Email", this.props.clientList.contactDetail.Email);
      this.props.setContactFormData("Title", this.props.clientList.contactDetail.Title);
      this.props.setContactFormData("Phone", this.props.clientList.contactDetail.Phone);
      this.props.setContactFormData("Fax", this.props.clientList.contactDetail.Fax);
      this.props.setContactFormData("primarycontact", false);
      setTimeout(() => {
        var isModalFound = document.getElementsByClassName('modal show');
        if (isModalFound.length > 1) {
          document.getElementsByClassName('modal show')[0].style.display = "none";
        }
      }, 1000);
    }
  }
  onDelete = () => {
    this.props.deleteContact(this.props.addContactData);
  }
  onSubmit = () => {
    this.validateData().then(hasErrors => {
      if (!hasErrors) {
        this.props.addContactData.ClientId = this.props.clientList.clientDetails[0].ItemId;

        if (typeof this.props.clientList.contactDetail.ItemId === "undefined") {
          delete this.props.addContactData.ItemId;
        }

        this.props.addContact(this.props.addContactData);
      }
    });
  }

  setError = (key, message) => {
    let formErrors = this.state.formErrors;
    formErrors[key] = message;
    this.setState({
      formErrors: formErrors,
      isValid: false,
    });
  }

  clearFormErrors = async () => {
    const emptyErrors = {
      FirstName: '',
      LastName: '',
      Email: '',
      Title: '',
      Phone: '',
      ClientId: '',
    }
    await this.setState({
      formErrors: emptyErrors,
    });
  }
  validateData = () => {
    const data = this.props.addContactData;
    return this.clearFormErrors().then(() => {
      let hasErrors = false;
      var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      if (typeof data.FirstName === 'undefined' || data.FirstName === '') {
        this.setError('FirstName', 'Please provide First Name');
        hasErrors = true;
      }
      if (typeof data.LastName === 'undefined' || data.LastName === '') {
        this.setError('LastName', 'Please provide Last Name');
        hasErrors = true;
      }
      if (typeof data.Email === 'undefined' || data.Email === '' || !filter.test(data.Email)) {
        this.setError('Email', 'Please provide valid Email');
        hasErrors = true;
      }
      if (typeof data.Title === 'undefined' || data.Title === '') {
        this.setError('Title', 'Please provide Title');
        hasErrors = true;
      }
      if (typeof data.Phone === 'undefined' || data.Phone === '') {
        this.setError('Phone', 'Please select Phone');
        hasErrors = true;
      }
      return hasErrors;
    });

  }
  onCancel = () => {
    this.props.setModalName(null);
    this.props.closeModal();
    this.props.clearContactDetail();
    // this.context.router.goBack();
  }
  closeModal() {
    this.props.clearContactDetail();
    this.props.setModalName(null);
    this.props.closeModal();
  }
  closeModalCallback() {
    this.props.getClientContacts(this.props.clientList.clientDetails[0].ItemId);
    this.props.clearContactDetail();
    this.props.setModalName(null);
    this.props.closeModal();
  }
  onInput = (key, value) => {
    this.props.setContactFormData(key, value);
    //this.validateData();
  }
  handleModalClose() {
    this.props.clearContactDetail();
    this.props.setModalName(null);
    this.props.closeModal();
  }
  render() {
    return (
      <React.Fragment>

        <Modal animation={false} size="lg" className="detailed_time_sheet_modal" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>
              {(typeof this.props.clientList.contactDetail.ItemId !== "undefined") ? 'Update Contact' : 'Add Contact'}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="container add_contact_container">
              <AddContactForm
                onSubmit={this.onSubmit}
                onDelete={this.onDelete}
                onCancel={this.onCancel}
                onInput={this.onInput}
                contactDetail={this.props.clientList.contactDetail}
                formErrors={this.state.formErrors} />
              {this.props.clientList.isLoading &&
                <div className="loader"><FontAwesomeIcon icon={faSpinner} spin /></div>
              }
              {this.props.clientList.addClientResponse === "Success" ?
                this.closeModalCallback()
                : this.props.clientList.addClientResponse !== "" ? <div className="status error">Error in creating Contact!</div> : ''
              }

            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment >
    )
  }
}
ContactAdd.propTypes = {
  closeModal: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
};
function mapStateToProps(state) {
  return {
    myState: state,
    addContactData: state.clientList.addContactData,
    clientList: state.clientList,
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setContactFormData: (key, value) => dispatch(clientActions.setContactFormData(key, value)),
    addContact: (data) => dispatch(clientActions.addContact(data)),
    deleteContact: (data) => dispatch(clientActions.deleteContact(data)),
    clearContactDetail: (data) => dispatch(clientActions.clearContactDetail(data)),
    getClientContacts: (data) => dispatch(clientActions.getClientContacts(data)),
    closeModal: (data) => dispatch(closeModal()),
    openModal: () => dispatch(openModal()),
    setModalName: (data) => dispatch(setModalName(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactAdd);