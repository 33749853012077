import React from "react";
import { FontAwesomeIcon } from "../../../node_modules/@fortawesome/react-fontawesome";
import { API } from 'aws-amplify';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'bootstrap/dist/js/bootstrap.js';
import icon from '../../assets/Picture1.png';
import 'bootstrap/dist/js/bootstrap.bundle';
import './TopNavStyle.scss';
import {
    faBell,
    faComments,
} from "../../../node_modules/@fortawesome/free-solid-svg-icons";
import { setUserAttributes } from '../../actions/adminActions';
import CustomModal from '../Modals/Modal';
import { openModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import queryString from 'query-string'

class TopNav extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NavbarText: '',
            SendToClient: false,
            displayModal: true,
        };
        this.approveTimesheets = this.approveTimesheets.bind(this);
    }
    approveTimesheets() {
        if (this.props.timesheetList.selectedTimeSheet.length > 0) {
            let data = '';
            this.props.timesheetList.selectedTimeSheet.forEach(obj => {
                data += obj.ItemId + ',';
            });
            data = data.slice(0, -1);
            console.log(data);
            let contact = queryString.parse(window.location.search).Contact;
            API.put('2', '/approve', { body: { Contact: contact, Timesheets: data } }).then(responseJson => {
                console.log(responseJson);
            });
            this.setState({ modalEnabled: true });
        }
    }

    componentDidMount() {
        let currentRoute = window.location.pathname;
        switch (currentRoute) {
            default:
                this.setState({ NavbarText: '' });
        }
    }

    render() {
        return (
            <React.Fragment >
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <ul className="navbar-nav mr-auto">
                        <li className="nav-item ml-lg-5 ml-sm-0">
                            <div className="circle profile-box"><img src={icon} className="teckpert-logo" alt=""></img></div>
                        </li>
                        <li className="nav-item">
                            <a className="navbar-brand top-nav-name" href="# ">Timesheets</a>
                        </li>
                    </ul>
                    <div className="sendPayrollBtn" style={{ marginRight: 20 }} >
                        {this.props.timesheetList.selectedTimeSheet.length > 0 ?
                            <a className="btn btn-success sendToClient" href="#" onClick={this.approveTimesheets}>Approve</a>
                            :
                            <a className="btn btn-success sendToClient greyed" href="#">Approve</a>
                        }
                    </div>
                </nav>
                <Modal animation={false} size="md" className="detailed_time_sheet_modal" show={this.state.modalEnabled} onHide={() => { window.location.reload(); }} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Success</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        The selected timesheets are approved.
          </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => { window.location.reload(); }}>
                            OK
          </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

TopNav.propTypes = {
    setUserAttributes: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        myState: state,
        timesheetList: state.timesheetList,
    }
}
export default connect(mapStateToProps, { setUserAttributes, openModal, setModalName })(TopNav)