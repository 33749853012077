import React, { Component } from "react";
import PropTypes from "prop-types";
import {Contacts} from './Contacts';
export class Info extends Component {
    constructor () {
        super();
        this.state = {
            profilePics: []
        }
    }
  static propTypes = {
    client: PropTypes.object
  };

  openContact = (contact) =>{
    this.props.openContact(contact);
    }
  render() {
    const { client,contacts } = this.props;
    let FirstName = "";
    let LastName = "";
    let CompanyName = "";
    let IndustryType = "";
    let Website = "";
    let Locations = "";
    let Phone = "";
    let Email = "";
    let Fax = "";
    let primaryContact = "";
    let Contracts = [];
    if(client.length > 0){
        FirstName = (client[0].FirstName !== "undefined") ? client[0].FirstName : "";
        LastName = (client[0].LastName !== "undefined") ? client[0].LastName : "";
        CompanyName = client[0].Name;
        IndustryType = client[0].IndustryType;
        Website = client[0].Website;
        Locations = client[0].Location;
        Phone = client[0].Phone;
        Email = client[0].Email;
        Fax = client[0].Fax;
        if(typeof client[0].PrimaryContact !== "undefined" && client[0].PrimaryContact !== null && client[0].PrimaryContact !== ""){
            primaryContact = client[0].PrimaryContact;
        }
        
        if(client[0].Contracts !== null && client[0].Contracts.length > 0){
            Contracts = client[0].Contracts;
        }
        for(var i=0;i<Contracts.length;i++){
            let StartDate = new Date(Contracts[i].StartDate);
            let EndDate = new Date(Contracts[i].EndDate);
            // To calculate the time difference of two dates 
            let DifferenceInTime = EndDate.getTime() - StartDate.getTime(); 
            // To calculate the no. of days between two dates 
            let DifferenceInMonths = DifferenceInTime / (1000 * 3600 * 24 * 30); 
            Contracts[i].contractLength = Math.round(DifferenceInMonths);
        }
    }
    return (
        <div class="tab-content">
       
        <div class="modelBoxnew infobox pt-5">  
            <div class="row">
                <div class="col-md-8">
                    <div class="hadingnewblock"> 
                        <h3>{CompanyName}</h3>
                        <h4>{IndustryType} </h4>
                        <h5>{Website} </h5>  
                    </div>
                    <Contacts primaryContact={primaryContact} contacts={contacts}  openContact={this.openContact} profilePic={this.props.contactProfile}/>
                </div> 
                <div class="col-md-4">
                    <div class="row contactlocation"> 
                        <div class="col-md-2"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="icon_green"><path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>  </div>
                        <div class="col-md-10">  
                            <span class="spadress">  
                                <b>  Location </b> 
                                <p>  {Locations} </p>
                            </span>
                        </div> 
                    </div>
                    <div class="row contactlocation"> 
                        <div class="col-md-2"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="icon_green"><path fill="none" d="M0 0h24v24H0z"/><path d="M20.01 15.38c-1.23 0-2.42-.2-3.53-.56-.35-.12-.74-.03-1.01.24l-1.57 1.97c-2.83-1.35-5.48-3.9-6.89-6.83l1.95-1.66c.27-.28.35-.67.24-1.02-.37-1.11-.56-2.3-.56-3.53 0-.54-.45-.99-.99-.99H4.19C3.65 3 3 3.24 3 3.99 3 13.28 10.73 21 20.01 21c.71 0 .99-.63.99-1.18v-3.45c0-.54-.45-.99-.99-.99z"/></svg>  </div>
                        <div class="col-md-10">  
                            <span class="spadress">  
                                <b>  Phone </b>   
                                <p> {Phone} <br/> <b> Fax </b> {Fax}   </p>
                            </span>
                        </div> 
                    </div>
                    <div class="row contactlocation"> 
                        <div class="col-md-2"> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="icon_green"><path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"/><path d="M0 0h24v24H0z" fill="none"/></svg>  </div>
                        <div class="col-md-10">  
                            <span class="spadress">     
                                <b>  Email </b> 
                                <p> {Email}  </p>
                            </span>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
  }
}

export default Info;
