import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import AccordionCard from "../../../../components/ui/AccordionCard";
import ReactTags from 'react-tag-autocomplete';

import { specializationOptions } from '../../config/index';
import Empty from '../../../../components/ui/Empty';

export class Specialization extends Component {

  constructor() {
    super();
    this.state = {
      modalEnabled: false,
      tags: [],
    };
  }

  static propTypes = {
    member: PropTypes.object
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.tags).then(resposne => {
      this.setState({modalEnabled: false});
    });
  }

  handleModalClose = () => {
    this.setState({
      modalEnabled: false
    });
  };

  handleAdd = (tag) => {
    const tags = [].concat(this.state.tags, tag.name);
    this.setState({ tags });
  }

  handleRemove = (i) => {
    const tags = this.state.tags.slice(0);
    tags.splice(i, 1);
    this.setState({ tags });
  }

  componentWillReceiveProps (newprops) {
    this.setState({
      tags: this.getSpecialidsations(newprops),
    });
  }

  getSpecialidsations = (props) => {
    const { member } = props;
    return (typeof member.Meta !== "undefined" && typeof member.Meta.Specializations !== "undefined" && member.Meta.Specializations !== "" ) ? member.Meta.Specializations : [];
  }

  render() {
    const modalData = [];
    this.state.tags.map((item, index) => {
      modalData.push({
        id: index,
        name: item,
      });
    });
    
    const specialization = this.getSpecialidsations(this.props);

    return (
      <div>
        <AccordionCard
          title="Specialization"
          actionhtml={
            <span
              className="add-sign"
              onClick={() => {
                this.setState({ modalEnabled: true });
              }}
            >
              +
            </span>
          }
        >
          {specialization.length > 0 ? (
            <div className="Specializations__list">
              <ul className="skill_box">
              {specialization.map((item, index) => {
                return (
                  <li 
                    key={`profile-specialization-${index}`}
                    className="Specializations__list__item"
                  ><a href="#">
                    {item}
                    </a>
                  </li>
                );
              })}
              </ul>
            </div>
          )
          :<Empty message="No specialization found" />
          }
        </AccordionCard>
        <Modal
          size="lg"
          className="specialization_modal"
          show={this.state.modalEnabled}
          onHide={() => this.handleModalClose()}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Manage specialization</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container">

              <ReactTags
                tags={modalData}
                allowNew={true}
                suggestions={specializationOptions}
                handleDelete={this.handleRemove}
                handleAddition={this.handleAdd} 
                placeholder='Add new specialization'
              />

              <a
                className="greenBtn modalButton"
                onClick={() => this.handleSubmit()}
              >
                Save
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Specialization;
