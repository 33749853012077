import React, { Component } from "react";
import InputTextArea from "../../../../components/ui/form/InputTextArea";
import Input from "../../../../components/ui/form/Input";

export class InterviewForm extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      message: ""
    };
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  render() {
    return (
      <div>
        <Input
          label="Title"
          placeholder="Enter title"
          onChange={value => this.onChange("HourlyRate", value)}
          value={this.state.title}
          invalidFeedback=""
        />

        <InputTextArea
          label="Message"
          placeholder="Enter Message"
          onChange={value => this.onChange("message", value)}
          value={this.state.message}
          invalidFeedback=""
          rows={6}
        />
      </div>
    );
  }
}

export default InterviewForm;
