import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from 'react-select';

export class InputMultiselect extends Component {
    state = {
        selectedOption: []
    }

    componentDidMount() {
        if (this.props.selected) {
            this.setState({ selectedOption: this.props.selected });
        }
    }

    onChange = selectedOption => {
        this.setState({ selectedOption: selectedOption });
        this.props.onChange(selectedOption);
    }

    render() {

        let inputClass = 'form-control';
        if (this.props.validFeedback !== '') inputClass += ' is-valid';
        if (this.props.invalidFeedback !== '') inputClass += ' is-invalid';
        return (
            <div className="form-group">
                {this.props.label !== "" && (
                    <label className="form-label" style={this.props.labelStyle}>{this.props.label}</label>
                )}

                <Select
                    styles={{ control: (provided, state) => ({ ...provided, minHeight: 50, borderRadius: 0, paddingTop: 8, paddingBottom: 8 }) }}
                    placeholder="Select contacts"
                    value={this.state.selectedOption}
                    onChange={this.onChange}
                    options={this.props.options.map((item, index) => { return ({ value: item.value, label: item.title }) })}
                    isMulti={true}
                />
                {this.props.invalidFeedback !== '' && <div class="invalid-feedback">{this.props.invalidFeedback}</div>}
                {this.props.validFeedback !== '' && <div class="valid-feedback">{this.props.validFeedback}</div>}

            </div>
        );
    }
}

InputMultiselect.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.array,
    selected: PropTypes.array,
    labelStyle: PropTypes.object
};

InputMultiselect.defaultProps = {
    label: '',
    placeholder: '',
    options: [],
    validFeedback: '',
    invalidFeedback: '',
    selected: [],
}

export default InputMultiselect;
