import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import InputDate from "../../components/ui/form/InputDate";
import * as timesheetActions from '../../modules/Timesheet/actions/index';
import './ModalStyle.scss';

class EditPayrollDate extends Component {
  constructor(props) {
    super(props);
    this.state = {}
    this.handleModalClose.bind(this);
  }
  componentDidMount() {
    console.log(this.props);
  }

  handleModalClose() {
    this.props.setModalName(null);
    this.props.closeModal();
  }

  onSubmit = () => {
    this.props.editPayrollDate(this.props.editPayrollData);
  }

  render() {
    return (
      <React.Fragment>
        <Modal animation={false} size="sm" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Payroll Date</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container send_to_client_container">
              <div class="row" style={{ justifyContent: 'center' }}>
                <InputDate
                  value={this.props.editPayrollData.PayrollDate}
                  onChange={value => {this.props.editPayrollData.PayrollDate=value;}}
                />
              </div>
              <div class="row" style={{ justifyContent: 'center', marginTop: 20 }}>
                <a className="greenBtn" style={{ marginRight: 15, height: 55, backgroundColor: '#AAAAAA', paddingLeft: 25, paddingRight: 25 }} onClick={() => this.handleModalClose()}>Cancel</a>
                <a className="greenBtn" style={{ height: 55 }} onClick={() => this.onSubmit()}>Set</a>
              </div>
            </div>
          </Modal.Body>
          {this.props.timesheetList.sendToPayrollResponse === "Success" ?
            this.handleModalClose()
            : this.props.timesheetList.sendToPayrollResponse !== "" ? <div className="status error">Error in changing payroll date</div> : ''
          }
        </Modal>
      </React.Fragment>
    )
  }
}
EditPayrollDate.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    editPayrollData: state.timesheetList.selectedPayroll,
    timesheetList: state.timesheetList
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (data) => dispatch(closeModal()),
    setModalName: (data) => dispatch(setModalName(data)),
    editPayrollDate: (data) => dispatch(timesheetActions.editPayrollDate(data)),
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditPayrollDate);