import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
//import contractReducer from './contractReducer';
import memberReducer from './memberReducer';
import uiReducer from './uiReducer';
import adminReducer from './adminReducer';
import NoteReducer from './NoteReducer';
// import timeSheetReducer from './timeSheetsReducer';
import timeSheetReducer from '../modules/Timesheet/reducers';


import members from '../modules/Members/reducers/index';
import contractReducer from '../modules/Contracts/reducers';
import clientReducer from '../modules/Clients/reducers';
import opportunityReducer from '../modules/Opportunities/reducers';
import MessagingReducer from '../modules/Messaging/reducers';

const reducers = (history) => combineReducers({
    adminState: adminReducer,
    uiState: uiReducer,
    contract: contractReducer,
    member: memberReducer,
    note: NoteReducer,
    timesheetList: timeSheetReducer,
    contractList: contractReducer,
    clientList : clientReducer,
    members: members,
    opportunityList : opportunityReducer,
    messaging: MessagingReducer,
    router: connectRouter(history),
});

export default reducers;