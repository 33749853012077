import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import '../styles/ContractStyles.scss';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { Table, Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as contractActions from '../actions';
import { ContractConstants } from '../config/Constants';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Moment from 'react-moment';
import { faFile } from "@fortawesome/free-solid-svg-icons";

import { API } from 'aws-amplify';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';
import * as storageUtils from '../../../helperFunctions/storageUtils'

class ContractList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [], page: 1, totalSize: 1, sizePerPage: 25,
            ContractsOrClients: ' ',
            Draft: false,
            Building: false,
            InProgress: true,
            Paused: false,
            Ended: false,
        }
    }
    componentDidMount() {
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder, ContractsOrClients, Draft, Building, InProgress, Paused, Ended }) => {
        if (!page && !sizePerPage && !sortField && !sortOrder) { page = this.state.page; sizePerPage = this.state.sizePerPage; sortField = this.state.sortField; sortOrder = this.state.sortOrder; }

        let filterString = '';
        if (ContractsOrClients === undefined) { ContractsOrClients = this.state.ContractsOrClients } if (ContractsOrClients !== ' ') { filterString += `&ContractsOrClients=${ContractsOrClients}` }
        if (Draft === undefined) { Draft = this.state.Draft } if (Draft) { filterString += `&Draft=${Draft}`; }
        if (Building === undefined) { Building = this.state.Building } if (Building) { filterString += `&Building=${Building}`; }
        if (InProgress === undefined) { InProgress = this.state.InProgress } if (InProgress) { filterString += `&InProgress=${InProgress}`; }
        if (Paused === undefined) { Paused = this.state.Paused } if (Paused) { filterString += `&Paused=${Paused}`; }
        if (Ended === undefined) { Ended = this.state.Ended } if (Ended) { filterString += `&Ended=${Ended}`; }

        API.get('1', `/contracts?OrderBy=${sortField}&SortOrder=${sortOrder}&ItemsPerPage=${sizePerPage}&Page=${page - 1}${filterString}`).then(responseJson => {
            this.setState(() => ({
                page: page, data: responseJson.Output, sizePerPage: sizePerPage, sortField: sortField, sortOrder: sortOrder, totalSize: responseJson.RecordsTotal,
                ContractsOrClients: ContractsOrClients,
                Draft: Draft,
                Building: Building,
                InProgress: InProgress,
                Paused: Paused,
                Ended: Ended
            }));
            window.scrollTo(0, 0);
        });
    }

    render() {
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <div className="row">
                    <div className="col-md-5">
                        <ul className="search_boxnew single-search">
                            <li className="search-box ">
                                <div className="input-group">
                                    <input type="text" className="form-control" placeholder="Search Contract or Client Name" onChange={(e) => { this.handleTableChange(null, { ContractsOrClients: e.currentTarget.value ? e.currentTarget.value : ' ' }) }} />
                                    <div className="input-group-append">
                                        <button className="btn" type="submit">
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="svg-inline--fa fa-search fa-w-16 SearchIcon" role="img"
                                                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                            </li>


                        </ul>
                    </div>
                    <ul className="checkoptionBox">
                        <li style={{ marginRight: 40 }}> <label className="container1">
                            <input type="checkbox" className="contract_status" onChange={() => { this.handleTableChange(null, { Draft: !this.state.Draft }); }} data-status="Draft" />
                            <span className="checkmark"></span> Draft</label></li>
                        <li style={{ marginRight: 40 }}> <label className="container1"> <input type="checkbox" className="contract_status" data-status="Building" onChange={() => { this.handleTableChange(null, { Building: !this.state.Building }); }} />
                            <span className="checkmark"></span> Building </label></li>
                        <li style={{ marginRight: 40 }}> <label className="container1" style={{width:117}}> <input type="checkbox" className="contract_status" data-status="In Progress" onChange={() => { this.handleTableChange(null, { InProgress: !this.state.InProgress }); }} defaultChecked />
                            <span className="checkmark"></span> In Progress </label></li>
                        <li style={{ marginRight: 40 }}> <label className="container1">	<input type="checkbox" className="contract_status" data-status="Paused" onChange={() => { this.handleTableChange(null, { Paused: !this.state.Paused }); }} /> <span className="checkmark">
                        </span> Paused </label></li>
                        <li style={{ marginRight: 40 }}> <label className="container1"> <input type="checkbox" className="contract_status" data-status="Ended" onChange={() => { this.handleTableChange(null, { Ended: !this.state.Ended }); }} />
                            <span className="checkmark"></span> Ended </label></li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <hr className="line" />
                <BootstrapTable
                    remote
                    keyField="ItemId"
                    filter={filterFactory()}
                    pagination={paginationFactory({ page: this.state.page, sizePerPage: this.state.sizePerPage, totalSize: this.state.totalSize, hideSizePerPage: true, alwaysShowAllBtns: true, withFirstAndLast: false })}
                    classes='time_sheet_table'
                    bordered={false}
                    data={this.state.data}
                    page={this.state.page}
                    sizePerPage={this.state.sizePerPage}
                    totalSize={this.state.totalSize}
                    onTableChange={this.handleTableChange}
                    columns={[
                        {
                            dataField: 'Name',
                            text: 'Contract Name',
                            sort: true,
                            formatter: (cell, row) => { return (<div><b><Link className="contractLink" to={"contracts/" + row.ItemId}>{cell}</Link></b></div>); },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                        {
                            dataField: 'ClientName',
                            text: 'Client',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '15%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Length',
                            text: 'Length',
                            sort: true,
                            formatter: (cell, row) => { return (<div>{storageUtils.getDateDifference(row.StartDate, row.EndDate)}</div>); },
                            headerStyle: { width: '7%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'StartDate',
                            text: 'Start Date',
                            sort: true,
                            formatter: (cell, row) => { return (<div><Moment format="MM/DD/YYYY">{cell}</Moment></div>); },
                            headerStyle: { width: '7%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Status',
                            text: 'Status',
                            sort: false,
                            formatter: (cell) => { return (<div>{cell}</div>); },
                            headerStyle: { width: '7%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'MSA',
                            text: 'MSA',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell === 1 ? '✓' : ''}</div>); },
                            headerStyle: { width: '4%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'WorkOrder',
                            text: 'Work Order',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell === 1 ? '✓' : ''}</div>); },
                            headerStyle: { width: '7%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'Workers',
                            text: '# Workers',
                            sort: true,
                            formatter: (cell) => { return (<div><b>{cell}</b></div>); },
                            headerStyle: { width: '7%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'JobOrder',
                            text: 'Job Order',
                            sort: true,
                            formatter: (cell) => { return (<div>{cell === 1 ? '✓' : ''}</div>); },
                            headerStyle: { width: '7%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        }, {
                            dataField: 'PrimaryContactName',
                            text: 'Contact',
                            sort: true,
                            formatter: (cell) => { return (<div><b>{cell}</b></div>); },
                            headerStyle: { width: '10%' },
                            headerFormatter: (column, colIndex, { sortElement }) => { return (<div><a className="sortcolumn" href="#">{column.text}</a>{sortElement}</div>) },
                        },
                    ]}
                    defaultSorted={[{
                        dataField: 'Name',
                        order: 'asc'
                    }]}
                />
            </div>

        </React.Fragment >
        )
    }
}
function mapStateToProps(state) {

    return {
        myState: state,
        contracts: state.contractList.contracts,
        filteredContracts: state.contractList.filterContracts,
        contractsList: state.contractList,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchContracts: () => dispatch(contractActions.fetchContracts()),
        filterContracts: (data) => dispatch(contractActions.filterContracts(data)),
        sortContracts: (data) => dispatch(contractActions.sortContracts(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractList);