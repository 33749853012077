import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import "./Style/search.scss";
// import SearchBar from "./SearchBar";

export class SearchBar extends Component {
  static propTypes = {
    handleSearch: PropTypes.func,
    Search: PropTypes.string,
  };

  onChange = (event) => {
    this.props.handleSearch(event.currentTarget.value);
  }

  onClearSearch = () => {
    let searchBox = this.refs.searchBox;
    searchBox.value = '';
    this.props.clearSearch();
  }

  render() {
    return (
      <Fragment>
        <div className="Search">
          <input
            ref="searchBox"
            type=""
            className="Search__SearchBar"
            placeholder={this.props.placeholder}
            onChange={(event) => this.onChange(event)}
          ></input>
          {this.props.Search === '' ?
            <div className="Search__iconSearch">
              <FontAwesomeIcon className="SearchIcon" icon={faSearch} />
            </div> : 
            <div className="Search__iconClose" onClick={() => this.onClearSearch()} >
              <FontAwesomeIcon className="closeIcon" icon={faTimes} />
            </div>
          }
          {/* <DropDowns {...this.props} /> */}
        </div>
      </Fragment>
    );
  }
}

export default SearchBar;
