import React from "react";
import "./App.css";
import { Auth } from "aws-amplify";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import SearchPage from "./modules/Dashboard/containers/SearchPage";
import Clients from "./modules/Clients/containers/ClientList";
import AddClient from "./modules/Clients/containers/ClientAdd";
import ViewClient from "./modules/Clients/containers/ClientView";
import Opportunities from "./modules/Opportunities/containers/OpportunityList";
import OpportunityAdd from "./modules/Opportunities/containers/OpportunityAdd";
import OpportunityDetail from "./modules/Opportunities/containers/OpportunityDetail";
import Contracts from "./modules/Contracts/containers/ContractList";
import ContractAdd from "./modules/Contracts/containers/ContractAdd";
import ContractDetail from "./modules/Contracts/containers/ContractDetail";
import TimeSheetList from "./modules/Timesheet/containers/TimesheetList";
import InvoicesList from "./modules/Invoices/containers/InvoicesList";
import InvoicesAdd from "./modules/Timesheet/containers/InvoicesAdd";
import InvoicesDetail from "./modules/Invoices/containers/InvoicesDetail";
import ToolsList from "./modules/Tools/containers/ToolsList";
import ToolsPayrollsList from "./modules/Tools/containers/ToolsPayrollsList";
import ToolsEmailsList from "./modules/Tools/containers/ToolsEmailsList";
import ToolsResourcesList from "./modules/Tools/containers/ToolsResourcesList";
import Messaging from './modules/Messaging/containers/Messaging'
import AnalyticsList from "./modules/Analytics/containers/AnalyticsList";
import AdminsList from "./modules/Admins/containers/AdminsList";
import ApproveTimeSheet from "./modules/Timesheet/containers/ApproveTimeSheets";
import MemberDetails from "./modules/Members/containers/MemberDetails";
import CoursesList from "./modules/Courses/containers/CoursesList";
import CoursesAdd from "./modules/Courses/containers/CoursesAdd";
import CoursesDetail from "./modules/Courses/containers/CoursesDetail";
import Footer from "./components/ui/Footer";

import MemberNotes from "./modules/Members/containers/MemberNotes";

//These only seem important for styling
import Search from "./components/Search/SearchBar";
import MemberProfile from "./components/Pages/Profile/MemberProfile";

//Members-only components
import TimeEntry from "./modules/TimeEntry/containers/TimeEntry";
import TimesheetMembersList from "./modules/Timesheet/containers/TimesheetMembersList";

class App extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    Auth.currentAuthenticatedUser({}).then(user => {
      if (Date.now() / 1000 > user.signInUserSession.accessToken.payload.exp) {
        Auth.signOut({ global: true })
          .then(() => {
            console.log("Session Expired");
          })
          .catch(err => console.log(err));
      }
    });
  }

  render() {
    if (this.props.authState === "signedIn") {
      if (['admin', 'finance', 'sales', 'recruiter', 'marketing'].includes(localStorage.getItem('userType'))) {
        return (
          <div>
            <div className="masterContainer">
              <Router>
                <Route exact path="/" component={SearchPage} />
                <Route exact path="/workers/:ItemId" component={MemberDetails} />
                <Route exact path="/workers/notes/:ItemId" component={MemberNotes} />
                <Route exact path="/clients" component={Clients} />
                <Switch>
                  <Route exact path="/clients/add" component={AddClient} />
                  <Route path="/clients/:ItemId" component={ViewClient} />
                </Switch>
                <Route exact path="/opportunities" component={Opportunities} />
                <Switch>
                  <Route exact path="/opportunities/add" component={OpportunityAdd} />
                  <Route exact path="/opportunities/:ItemId" component={OpportunityDetail} />
                </Switch>
                {['admin', 'finance', 'sales'].includes(localStorage.getItem('userType')) &&
                  <Route exact path="/contracts" component={Contracts} />
                }
                {['admin', 'finance', 'sales'].includes(localStorage.getItem('userType')) &&
                  <Switch>
                    <Route exact path="/contracts/add" component={ContractAdd} />
                    <Route exact path="/contracts/:ItemId" component={ContractDetail} />
                  </Switch>
                }
                {['admin', 'finance'].includes(localStorage.getItem('userType')) &&
                  <Route exact path="/timesheets" component={TimeSheetList} />
                }
                {['admin', 'finance'].includes(localStorage.getItem('userType')) &&
                  <Route exact path="/invoices" component={InvoicesList} />
                }
                {['admin', 'finance'].includes(localStorage.getItem('userType')) &&
                  <Switch>
                    <Route exact path="/invoices/add" component={InvoicesAdd} />
                    <Route exact path="/invoices/:ItemId" component={InvoicesDetail} />
                  </Switch>
                }
                {['admin', 'finance'].includes(localStorage.getItem('userType')) &&
                  <Route exact path="/tools" component={ToolsList} />
                }
                {['admin', 'finance'].includes(localStorage.getItem('userType')) &&
                  <Switch>
                    <Route exact path="/tools/payrolls" component={ToolsPayrollsList} />
                    <Route exact path="/tools/emails" component={ToolsEmailsList} />
                    <Route exact path="/tools/resources" component={ToolsResourcesList} />
                  </Switch>
                }
                <Route exact path="/messaging" component={Messaging} />
                <Route exact path="/messaging/:ItemId" component={Messaging} />
                {['admin', 'finance'].includes(localStorage.getItem('userType')) &&
                  <Route exact path="/analytics" component={AnalyticsList} />
                }
                {['admin'].includes(localStorage.getItem('userType')) &&
                  <Route exact path="/admins" component={AdminsList} />
                }
                <Route exact path="/courses" component={CoursesList} />
                <Switch>
                  <Route exact path="/courses/add" component={CoursesAdd} />
                  <Route exact path="/courses/:ItemId" component={CoursesDetail} />
                </Switch>
              </Router>
            </div>
            <Footer />
          </div>
        );
      }
      else {
        return (
          <div>
            <div className="masterContainer">
              <Router>
                <Route exact path="/" component={TimeEntry} />
                <Route exact path="/timesheets" component={TimesheetMembersList} />
              </Router>
            </div>
            <Footer />
          </div>
        );
      }
    } else {
      return (
        <Router>
          <Route exact path="/timesheets/approve" component={ApproveTimeSheet} />
        </Router>
      );
    }
  }
}

export default App;
