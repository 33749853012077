import React, { Component } from "react";
import { connect } from "react-redux";
import '../styles/OpportunityStyles.scss';
import Nav from "../../../components/Navigation/Nav";
import TopNav from "../../../components/TopNav/TopNav";
// import { Table, Modal, Button, ButtonToolbar } from "react-bootstrap";

import * as opportunityActions from '../actions/index';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { ContractConstants } from "../config/Constants";
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";

import AddOpportunityForm from "../components/AddOpportunityForm";

export class OpportunityAdd extends Component {

  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        Title: '',
      Description: '',
      StartDate: '',
      Location: '',
      Type: '',
      Hours: '',
      Compensation: '',
      },
      isValid: false,
    }
  }

  componentDidMount() {
    
  }

  onSubmit = () => {
    this.validateData().then(hasErrors => {
      if (!hasErrors) {
        this.props.addOpportunity(this.props.addOpportunityData);
      }
    });
  }

  setError = (key, message) => {
    let formErrors = this.state.formErrors;
    formErrors[key] = message; 
    this.setState({
      formErrors: formErrors,
      isValid: false,
    });
  }

  clearFormErrors =  async () => {
    const emptyErrors = {
      Title: '',
      Description: '',
      StartDate: '',
      Location: '',
      Type: '',
      Hours: '',
      Compensation: '',
    };
    await this.setState({
      formErrors: emptyErrors,
    });
  }

  validateData = () => {
    const data = this.props.addOpportunityData;
    return this.clearFormErrors().then(() => {
      let hasErrors = false;
      console.log(data);
      console.log(typeof data.Title);
      if (typeof data.Title === 'undefined' || data.Title === '') {
        this.setError('Title', 'Please provide Job Title');
        hasErrors = true;
      }
      if (typeof data.Description === 'undefined' || data.Description === '') {
        this.setError('Description', 'Please provide Details');
        hasErrors = true;
      }
      if (typeof data.StartDate === 'undefined' || data.StartDate === '') {
        this.setError('StartDate', 'Please provide Start Date');
        hasErrors = true;
      }
      if (typeof data.Location === 'undefined' || data.Location === '') {
        this.setError('Location', 'Please provide Location');
        hasErrors = true;
      }
      if (typeof data.Type === 'undefined' || data.Type === '') {
        this.setError('Type', 'Please select Type');
        hasErrors = true;
      }
      if (typeof data.Hours === 'undefined' || data.Hours === '') {
        this.setError('Hours', 'Please provide Hours');
        hasErrors = true;
      }
      
      if (typeof data.Compensation === 'undefined' || data.Compensation === '') {
        this.setError('Compensation', 'Please provide Compensation');
        hasErrors = true;
      }
      return hasErrors;
    });
  }

  onCancel = () => {
    
  }

  onInput = (key, value) => {
    this.props.setOpportunityFormData(key, value);
  }

  render() {
    
    return (
      <React.Fragment>
        <TopNav title="Add Opportunity" />
        <Nav />
        <section className="lighgray">
          <div className="container">
            <div class="container add_opportunity_container">
              <AddOpportunityForm
                onSubmit={this.onSubmit}
                onCancel={this.onCancel}
                onInput={this.onInput}
                formErrors={this.state.formErrors}
              />
              {this.props.opportunityList.isLoading && 
                  <div className="loader"><FontAwesomeIcon icon={faSpinner} spin/></div>
              }
              {this.props.opportunityList.addOpportunityResponse === "Success" ?  
                <Redirect to="/opportunities" />
              : this.props.opportunityList.addOpportunityResponse !== "" ? <div className="status error">Error in creating opportunity!</div> : ''  
              }
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
opportunityList: state.opportunityList,
  addOpportunityData: state.opportunityList.addOpportunityData
});

const mapDispatchToProps = dispatch => {
  return {
    setOpportunityFormData: (key, value) => dispatch(opportunityActions.setOpportunityFormData(key, value)),
    addOpportunity: (data) => dispatch(opportunityActions.addOpportunity(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpportunityAdd);
