import { 
  ENABLE_LOADER, DISABLE_LOADER,
  FETCH_MEMBERS, SET_MEMBERS, UPDATE_MEMBERS,
  FETCH_MEMBER, SET_MEMBER,
  SET_MEMBER_META,
  UPDATE_PROFILE_META,
  FETCH_JOB_TITLES, SET_JOB_TITLES,
  ADD_MEMBER_NOTE, 
  EDIT_MEMBER_NOTE, 
  DELETE_MEMBER_NOTE,
  ADD_MEMBER_DOCUMENT,
  EDIT_MEMBER_DOCUMENT,
  DELETE_MEMBER_DOCUMENT,
  SUBMIT_MEMBER_CONTRACTS,
  CLEAR_MEMBER,
  SET_CONTRACT_RESPONSE,
  CLEAR_CONTRACT_RESPONSE,
} from './types';

export const fetchJobTitles = () => ({
  type: FETCH_JOB_TITLES,
});

export const setJobTitles = (data) => ({
  type: SET_JOB_TITLES,
  data,
});

export const updateProfileMeta = (ItemId, meta) => ({
  type: UPDATE_PROFILE_META,
  ItemId: ItemId,
  meta: meta,
})

export const setMemeberMeta = (key, value) => ({
  type: SET_MEMBER_META,
  key,
  value,
});

// MEMBER DETAILS ACTIONS : START
export const clearMemeber = () => ({
  type: CLEAR_MEMBER,
});
export const fetchMember = (ItemId) => ({
  type: FETCH_MEMBER,
  ItemId,
});
export const setMember = (data) => ({
  type: SET_MEMBER,
  data,
});
// MEMBER DETAILS ACTIONS : END

export const enableLoader = () => ({
  type: ENABLE_LOADER,
});

export const disableLoader = () => ({
  type: DISABLE_LOADER,
});

export const fetchMembers = (params = {}) => ({
  type: FETCH_MEMBERS,
  params,
});

export const setMembers = (members, noRecords, totalRecords) => ({
  type: SET_MEMBERS,
  members,
  noRecords,
  totalRecords,
});

export const updateMembers = (members) => ({
  type: UPDATE_MEMBERS,
  members,
});

export const addMemberNote = (ItemId, addNote) => ({
  type: ADD_MEMBER_NOTE,
  ItemId,
  addNote,
});
export const editMemberNote = (ItemId, editNoteId, editNote) => ({
  type: EDIT_MEMBER_NOTE,
  ItemId,
  editNoteId,
  editNote,
});
export const deleteMemberNote = (ItemId, deleteNote) => ({
  type: DELETE_MEMBER_NOTE,
  ItemId,
  deleteNote,
});

export const addMemberDocument = (ItemId, data) => ({
  type: ADD_MEMBER_DOCUMENT,
  ItemId,
  data,
});
export const editMemberDocument = (ItemId, data) => ({
  type: EDIT_MEMBER_DOCUMENT,
  ItemId,
  data,
});
export const deleteMemberDocument = (ItemId, data) => ({
  type: DELETE_MEMBER_DOCUMENT,
  ItemId,
  data,
});
 // ADD CONTRACT - ACTION
 export const addMemberContract = (data) => ({
  type: SUBMIT_MEMBER_CONTRACTS,
  data,
});
export const setContractResponse = (data) => ({
  type: SET_CONTRACT_RESPONSE,
  data,
});

export const clearContractResponse = () => ({
  type: CLEAR_CONTRACT_RESPONSE,
});