import React from 'react'
import "./Style/profileStyle.scss"
export default class Profile extends React.Component {
  constructor(props) {
    super(props);
    console.log(this.profileMember);
  }
  render() {
    return (
      <div className="profile">
        <div className="Categories">
          <span className="Name">{this.props.profileMember.Name}</span>
          <span className="Role">{this.props.profileMember.Role}</span>
          <span className="DateRegiestered"> Registered on {this.props.profileMember.DateRegistered}</span>
        </div>
      </div>
    )
  }
}

