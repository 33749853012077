import React, { Component } from "react";
import { connect } from "react-redux";
import "../styles/MemberStyles.scss";
import * as memberActions from "../actions";
import * as messageActions from "../../Messaging/actions";
import Nav from "../../../components/Navigation/Nav";
import TopNav from "../../../components/TopNav/TopNav";
import { Table, Modal, Button, ButtonToolbar } from "react-bootstrap";
import ProfileHeader from "../components/profile/Header";
import ProfileTabs from "../components/profile/Tabs";

import Education from "../components/profile/Education";
import Activities from "../components/profile/Activities";
import Work from "../components/profile/Work";
import References from "../components/profile/References";
import Specialization from "../components/profile/Specialization";
import Certifications from "../components/profile/Certifications";
import Documents from "../components/profile/Documents";
import Contracts from "../components/profile/Contracts";
import History from "../components/profile/History";
import CustomModal from "../../../components/Modals/Modal";
import { openModal, setModalName } from "../../../actions/uiActions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ContactAdd from "../../../components/Modals/ContactAdd";

export class MemberDetails extends Component {
  componentDidMount() {
    const ItemId = this.props.match.params.ItemId;
    this.props.clearMemeber();
    this.props.fetchMember(ItemId);
  }

  onProfileMetaInput = async (key, value) => {
    await this.props.setMemeberMeta(key, value);
  };

  refreshMember = async () => {
    this.props.fetchMember(this.props.match.params.ItemId);
  };

  updateSpecializations = (data) => {
    return this.onProfileMetaInput("Specializations", data).then((res) => {
      return this.onProfileSubmit();
    });
  };

  updateWorks = (data) => {
    return this.onProfileMetaInput("Work", data).then((res) => {
      return this.onProfileSubmit();
    });
  };

  updateReferences = (data) => {
    return this.onProfileMetaInput("References", data).then((res) => {
      return this.onProfileSubmit();
    });
  };

  updateEducation = (data) => {
    return this.onProfileMetaInput("Education", data).then((res) => {
      return this.onProfileSubmit();
    });
  };

  updateActivities = (data) => {
    return this.onProfileMetaInput("Activities", data).then((res) => {
      return this.onProfileSubmit();
    });
  };
  updateLastContacted = (data) => {
    return this.onProfileMetaInput("LastContacted", data).then((res) => {
      return this.onProfileSubmit();
    });
  };

  updateCertifications = (VerifiedCertifications, UnverifiedCertifications) => {
    this.onProfileMetaInput("VerifiedCertifications", VerifiedCertifications);
    return this.onProfileMetaInput("UnverifiedCertifications", UnverifiedCertifications).then(
      (res) => {
        return this.onProfileSubmit();
      }
    );
  };

  onAddNote = (note) => {
    let ItemId = this.props.match.params.ItemId;
    this.props.addMemberNote(ItemId, note);
  };
  onEditNote = (noteId, note) => {
    let ItemId = this.props.match.params.ItemId;
    this.props.editMemberNote(ItemId, noteId, note);
  };
  onDeleteNote = (noteId) => {
    let ItemId = this.props.match.params.ItemId;
    this.props.deleteMemberNote(ItemId, noteId);
  };

  /* MANAGE Member DOCUMENTS : START */
  onDocumentAdd = (data) => {
    let ItemId = this.props.match.params.ItemId;
    this.props.addMemberDocument(ItemId, data);
  };
  onDocumentEdit = (data) => {
    let ItemId = this.props.match.params.ItemId;
    this.props.editMemberDocument(ItemId, data);
  };
  onDocumentDelete = (data) => {
    let ItemId = this.props.match.params.ItemId;
    this.props.deleteMemberDocument(ItemId, data);
  };

  onProfileSubmit = async () => {
    return new Promise((resolve, reject) => {
      this.props.updateProfileMeta(this.props.member.ItemId, this.props.memberMeta);
      resolve(true);
    });
  };

  onContractAdd = (data) => {
    this.props.openModal();
    this.props.setModalName("AddPeopleContract");
  };

  render() {
    return (
      <React.Fragment>
        <TopNav title='Search People / Profile' />
        <Nav />
        <div className='member_details_block'>
          <ProfileHeader
            member={this.props.member}
            memberMeta={this.props.memberMeta}
            onProfileMetaInput={this.onProfileMetaInput}
            onProfileSubmit={this.onProfileSubmit}
            sendPrivateMessage={this.props.sendPrivateMessage}
            refreshMember={this.refreshMember}
          />
          <div className='container'>
            <ProfileTabs
              onAddNote={this.onAddNote}
              onEditNote={this.onEditNote}
              onDeleteNote={this.onDeleteNote}
              updateCertifications={this.updateCertifications}
              member={this.props.member}
            />
            <div id='accordion' class='accordion_block'>
              <Education member={this.props.member} onSubmit={this.updateEducation} />
              <Work member={this.props.member} onSubmit={this.updateWorks} />
              <Specialization member={this.props.member} onSubmit={this.updateSpecializations} />
              <References member={this.props.member} onSubmit={this.updateReferences} />
              <Certifications member={this.props.member} onSubmit={this.updateCertifications} />
              <Documents
                onDocumentAdd={this.onDocumentAdd}
                onDocumentEdit={this.onDocumentEdit}
                onDocumentDelete={this.onDocumentDelete}
                member={this.props.member}
              />
              <Contracts member={this.props.member} onAddContract={this.onContractAdd} />
              <History memberId={this.props.match.params.ItemId} />
              <Activities memberId={this.props.match.params.ItemId} />{" "}
            </div>
          </div>
          {this.props.isLoading && (
            <div className='loader'>
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>
          )}
        </div>
        <CustomModal />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  member: state.members.member,
  memberMeta: state.members.memberMeta,
  isLoading: state.members.isLoading,
  modalState: state.uiState.modalOpen,
  modalName: state.uiState.modalName,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMember: (ItemId) => dispatch(memberActions.fetchMember(ItemId)),
    clearMemeber: () => dispatch(memberActions.clearMemeber()),
    setMemeberMeta: (key, value) => dispatch(memberActions.setMemeberMeta(key, value)),
    updateProfileMeta: (ItemId, meta) => dispatch(memberActions.updateProfileMeta(ItemId, meta)),

    addMemberNote: (ItemId, addNote) => dispatch(memberActions.addMemberNote(ItemId, addNote)),
    editMemberNote: (ItemId, editNoteId, editNote) =>
      dispatch(memberActions.editMemberNote(ItemId, editNoteId, editNote)),
    deleteMemberNote: (ItemId, deleteNote) =>
      dispatch(memberActions.deleteMemberNote(ItemId, deleteNote)),

    addMemberDocument: (ItemId, data) => dispatch(memberActions.addMemberDocument(ItemId, data)),
    editMemberDocument: (ItemId, data) => dispatch(memberActions.editMemberDocument(ItemId, data)),
    deleteMemberDocument: (ItemId, data) =>
      dispatch(memberActions.deleteMemberDocument(ItemId, data)),

    openModal: () => dispatch(openModal()),
    setModalName: (data) => dispatch(setModalName(data)),

    sendPrivateMessage: (data) => dispatch(messageActions.sendMessage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MemberDetails);
