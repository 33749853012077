import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import {Modal, Button} from "react-bootstrap"
import { closeModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


class EditWork extends Component {
    constructor(props) {
        super(props);
        this.handleModalClose.bind(this);
    }

    handleModalClose() {
      this.props.setModalName(null);
      this.props.closeModal();
    }


//
    render() {
        return (
            <React.Fragment>
      <Modal show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>EditWork</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.handleModalClose()}>
            Close
          </Button>
          <Button variant="primary">
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
            </React.Fragment>
        )

    }
}
EditWork.propTypes = {
    closeModal: PropTypes.func.isRequired,
    setModalName: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        modalState: state.uiState.modalOpen,
        modalName: state.uiState.modalName,
    }
}
export default connect(mapStateToProps, { closeModal, setModalName })(EditWork);