export function parseEducation(education) {
    let parsedEducation = {
        Year: '',
        Degree: '',
        Institution: '',
    }
    // Get Year
    parsedEducation.Year = education.substring(0, 4);

    // Get Degree
    parsedEducation.Degree = education.substring(7, education.indexOf("at")-1);

    // Get Institution
    parsedEducation.Institution = education.substring(education.indexOf("at")+2, education.length);

    return parsedEducation;
}

export function parseContractDocument(contract) {


}