import { 
  SET_MEMBERS, UPDATE_MEMBERS,
  ENABLE_LOADER, DISABLE_LOADER,
  SET_MEMBER, SET_MEMBER_META,
  SET_JOB_TITLES, CLEAR_MEMBER, 
  SET_CONTRACT_RESPONSE,
  CLEAR_CONTRACT_RESPONSE,
} from "../actions/types";

const initialState = {
  members: [],
  isLoading: false,
  member: {},
  memberMeta: {},
  noRecords: 0,
  totalRecords: 0,
  jobTitles: [],
  addContractResponse : '',
};

export default function(state = initialState, action) {
  switch (action.type) {

    case SET_JOB_TITLES:
      return {
        ...state,
        jobTitles: action.data,
      }
    
    case SET_MEMBER_META:
      return {
        ...state,
        memberMeta: {
          ...state.memberMeta,
          [action.key]: action.value,
        }
      };
    case SET_CONTRACT_RESPONSE:
    return {
      ...state,
      addContractResponse : action.data,
    };
    case CLEAR_CONTRACT_RESPONSE:
    return {
      ...state,
      addContractResponse : '',
    };
    case CLEAR_MEMBER: {
      return {
        ...state,
        member: {},
        memberMeta: {},
      }
    }
    
    case SET_MEMBER:
      return {
        ...state,
        member: action.data,
        memberMeta: action.data.Meta,
        addContractResponse : '',
      };

    case ENABLE_LOADER:
      return {
        ...state,
        isLoading: true,
      };

    case DISABLE_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    
    case SET_MEMBERS:
      return {
        ...state,
        members: action.members,
        noRecords: action.noRecords,
        totalRecords: action.totalRecords,
        addContractResponse : '',
      };

    case UPDATE_MEMBERS:
      return {
        ...state
      };

    default:
      return state;
  }
}
