export default function listOfIndustryTypes() {
    return [
        {
            value: "Accounting"
        },
        {
            value: "Airlines/Aviation"
        },
        {
            value: "Alternative Dispute Resolution"
        },
        {
            value: "Alternative Medicine"
        },
        {
            value: "Animation"
        },
        {
            value: "Apparel & Fashion"
        },
        {
            value: "Architecture & Planning"
        },
        {
            value: "Arts and Crafts"
        },
        {
            value: "Automotive"
        },
        {
            value: "Aviation & Aerospace"
        },
        {
            value: "Banking"
        },
        {
            value: "Biotechnology"
        },
        {
            value: "Broadcast Media"
        },
        {
            value: "Building Materials"
        },
        {
            value: "Business Supplies and Equipment"
        },
        {
            value: "Cannabis"
        },
        {
            value: "Capital Markets"
        },
        {
            value: "Chemicals"
        },
        {
            value: "Civic & Social Organization"
        },
        {
            value: "Civil Engineering"
        },
        {
            value: "Commercial Real Estate"
        },
        {
            value: "Computer & Network Security"
        },
        {
            value: "Computer Games"
        },
        {
            value: "Computer Hardware"
        },
        {
            value: "Computer Networking"
        },
        {
            value: "Computer Software"
        },
        {
            value: "Internet"
        },
        {
            value: "Construction"
        },
        {
            value: "Consumer Electronics"
        },
        {
            value: "Consumer Goods"
        },
        {
            value: "Consumer Services"
        },
        {
            value: "Cosmetics"
        },
        {
            value: "Dairy"
        },
        {
            value: "Defense & Space"
        },
        {
            value: "Design"
        },
        {
            value: "Education Management"
        },
        {
            value: "E-Learning"
        },
        {
            value: "Electrical/Electronic Manufacturing"
        },
        {
            value: "Entertainment"
        },
        {
            value: "Environmental Services"
        },
        {
            value: "Events Services"
        },
        {
            value: "Executive Office"
        },
        {
            value: "Facilities Services"
        },
        {
            value: "Farming"
        },
        {
            value: "Financial Services"
        },
        {
            value: "Fine Art"
        },
        {
            value: "Fishery"
        },
        {
            value: "Food & Beverages"
        },
        {
            value: "Food Production"
        },
        {
            value: "Fund-Raising"
        },
        {
            value: "Furniture"
        },
        {
            value: "Gambling & Casinos"
        },
        {
            value: "Glass"
        },
        {
            value: "Government Administration"
        },
        {
            value: "Government Relations"
        },
        {
            value: "Graphic Design"
        },
        {
            value: "Health"
        },
        {
            value: "Higher Education"
        },
        {
            value: "Hospital & Health Care"
        },
        {
            value: "Hospitality"
        },
        {
            value: "Human Resources"
        },
        {
            value: "Import and Export"
        },
        {
            value: "Individual & Family Services"
        },
        {
            value: "Industrial Automation"
        },
        {
            value: "Information Services"
        },
        {
            value: "Information Technology and Services"
        },
        {
            value: "Insurance"
        },
        {
            value: "International Affairs"
        },
        {
            value: "International Trade and Development"
        },
        {
            value: "Investment Banking"
        },
        {
            value: "Investment Management"
        },
        {
            value: "Judiciary"
        },
        {
            value: "Law Enforcement"
        },
        {
            value: "Law Practice"
        },
        {
            value: "Legal Services"
        },
        {
            value: "Legislative Office"
        },
        {
            value: "Leisure"
        },
        {
            value: "Libraries"
        },
        {
            value: "Logistics and Supply Chain"
        },
        {
            value: "Luxury Goods & Jewelry"
        },
        {
            value: "Machinery"
        },
        {
            value: "Management Consulting"
        },
        {
            value: "Maritime"
        },
        {
            value: "Market Research"
        },
        {
            value: "Marketing and Advertising"
        },
        {
            value: "Mechanical or Industrial Engineering"
        },
        {
            value: "Media Production"
        },
        {
            value: "Medical Devices"
        },
        {
            value: "Medical Practice"
        },
        {
            value: "Mental Health Care"
        },
        {
            value: "Military"
        },
        {
            value: "Mining & Metals"
        },
        {
            value: "Motion Pictures and Film"
        },
        {
            value: "Museums and Institutions"
        },
        {
            value: "Music"
        },
        {
            value: "Nanotechnology"
        },
        {
            value: "Newspapers"
        },
        {
            value: "Nonprofit Organization Management"
        },
        {
            value: "Oil & Energy"
        },
        {
            value: "Online Media"
        },
        {
            value: "Outsourcing/Offshoring"
        },
        {
            value: "Package/Freight Delivery"
        },
        {
            value: "Packaging and Containers"
        },
        {
            value: "Paper & Forest Products"
        },
        {
            value: "Performing Arts"
        },
        {
            value: "Pharmaceuticals"
        },
        {
            value: "Philanthropy"
        },
        {
            value: "Photography"
        },
        {
            value: "Plastics"
        },
        {
            value: "Political Organization"
        },
        {
            value: "Primary/Secondary Education"
        },
        {
            value: "Printing"
        },
        {
            value: "Professional Training & Coaching"
        },
        {
            value: "Program Development"
        },
        {
            value: "Public Policy"
        },
        {
            value: "Public Relations and Communications"
        },
        {
            value: "Public Safety"
        },
        {
            value: "Publishing"
        },
        {
            value: "Railroad Manufacture"
        },
        {
            value: "Ranching"
        },
        {
            value: "Real Estate"
        },
        {
            value: "Recreational Facilities and Services"
        },
        {
            value: "Religious Institutions"
        },
        {
            value: "Renewables & Environment"
        },
        {
            value: "Research"
        },
        {
            value: "Restaurants"
        },
        {
            value: "Retail"
        },
        {
            value: "Security and Investigations"
        },
        {
            value: "Semiconductors"
        },
        {
            value: "Shipbuilding"
        },
        {
            value: "Sporting Goods"
        },
        {
            value: "Sports"
        },
        {
            value: "Staffing and Recruiting"
        },
        {
            value: "Supermarkets"
        },
        {
            value: "Telecommunications"
        },
        {
            value: "Textiles"
        },
        {
            value: "Think Tanks"
        },
        {
            value: "Tobacco"
        },
        {
            value: "Translation and Localization"
        },
        {
            value: "Transportation/Trucking/Railroad"
        },
        {
            value: "Utilities"
        },
        {
            value: "Venture Capital & Private Equity"
        }
    ]
}