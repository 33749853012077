import React, { Component } from "react";
import PropTypes from "prop-types";
import EmptyData from './EmptyData';
import { ContractConstants } from '../../config/Constants';
import Moment from 'react-moment';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export class Notes extends Component {

  static propTypes = {
    notes: PropTypes.array
  };

  constructor() {
    super();
    this.state = {
      newNote: '',
      isEdit: false,
      noteId: 0,
      editNote: '',
    }
  }

  addNewNote = () => {
    this.props.onAddNote(this.state.newNote);
    this.setState({
      newNote: '',
    });
  }

  enableEditor = (noteId, editNote) => {
    this.setState({
      noteId: noteId,
      editNote: editNote,
      isEdit: true,
    })
  }

  cancelEdit = () => {
    this.setState({
      noteId: 0,
      editNote: '',
      isEdit: false,
    });
  }

  saveNote = () => {
    this.props.onEditNote(this.state.noteId, this.state.editNote);
    this.cancelEdit();
  }

  onDeleteNote = (ItemId) => {
    this.props.onDeleteNote(ItemId);
  }

  render() {
    const { notes } = this.props;
    return (
      <div className="notesBlock">
        <h4 className="noteHading"> Notes</h4>
        <div className="row">
          {(typeof notes !== 'undefined' && Array.isArray(notes) && notes.length > 0) ?
            <div className="col-md-8">
              {notes.map(item => {
                return (
                  <div className='noteItem' key={item.ItemId} >
                    <p className="noteTime"><Moment format="MM/DD/YYYY hh:mm a">{item.DateCreated}</Moment>
                      <span onClick={() => this.enableEditor(item.ItemId, item.Note)} >Edit</span>
                      <span onClick={() => {
                        if (window.confirm('Are you sure you wish to delete this note?')) this.onDeleteNote(item.ItemId)
                      }}>Delete</span>
                    </p>
                    {(this.state.isEdit && this.state.noteId === item.ItemId) ?
                      <div className="noteEditor">
                        <ReactQuill
                          style={{ height: 150, marginBottom: 50 }}
                          modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['link'], ['clean']] }}
                          value={this.state.editNote}
                          onChange={value => { this.setState({ editNote: value }); }}
                        />
                        <div className="buttons">
                          <a className="greenBtn" onClick={() => this.saveNote()} >Save</a>
                          <a className="greenBtn" onClick={() => this.cancelEdit()} >Cancel</a>
                        </div>
                      </div> :
                      <div className="noteText" dangerouslySetInnerHTML={{ __html: item.Note }}></div>
                    }
                  </div>
                )
              })}
            </div>
            : <div className="col-md-8"><EmptyData msg={ContractConstants.EmptyNotesMsg} /></div>
          }

          <div className="col-md-4">

            <ReactQuill
              style={{ height: 200, marginBottom: 50 }}
              modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['link'], ['clean']] }}
              value={this.state.newNote}
              onChange={value => { this.setState({ newNote: value }); }}
            />
            <a className="greenBtn mt-4 mb-4 width20" onClick={() => this.addNewNote()} >
              Add
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Notes;
