import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from 'react-moment';
import EmptyData from './EmptyData';
import { ClientConstants } from '../../config/Constants';
import Empty from '../../../../components/ui/Empty';
export class History extends Component {
  
  static propTypes = {
    client: PropTypes.object
  };

  static defaultProps = {
    client: {

    }
  }
  render() {
    const { client } = this.props;
    let history = [];
    if(client.length > 0){
        if(Array.isArray(client[0].History) && client[0].History.length > 0){
            history = client[0].History;
        }
    }
    return (
        <div class="card">
            <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapsefour" aria-expanded="false" aria-controls="four">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="icon_green mr-1">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z"/>
                    </svg>
                    History
                </button>
                </h5>
            </div>
            <div id="collapsefour" class="collapse" aria-labelledby="headingThree" data-parent="#accordion">
                <div class="card-body">
                {(typeof history !== 'undefined' && Array.isArray(history) && history.length > 0) ?
                <table class="table mt-4">
                    <thead>
                        <tr>
                            <th>Detail </th>
                            <th> Date  </th>
                        </tr>
                    </thead>
                        <tbody>
                        
                            {history.map((item, index) => {
                                return (
                                <tr key={`History-${item.ItemId}-${index}`}>
                                    <td>{item.Message}</td>
                                    <td><Moment format="MM/DD/YYYY hh:mm a">{item.DateCreated}</Moment></td>
                                </tr>
                                )
                            })}
                        </tbody>
                </table>
                : <Empty message="No history found" />
                }
                </div>
            </div>
        </div>
    );
}
}

export default History;