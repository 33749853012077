import {
  ENABLE_CONTRACT_LOADER,
  DISABLE_CONTRACT_LOADER,
  FETCH_CONTRACTS,
  SET_CONTRACT,
  UPDATE_CONTRACT,
  FILTER_CONTRACT,
  SORT_CONTRACT,
  SET_CONTRACT_FORM_DATA, CLEAR_CONTRACT_FORM_DATA,
  FETCH_CONTACTS,
  SET_CONTACTS,
  SUBMIT_CONTRACTS, EDIT_CONTRACT, 
  FETCH_CONTRACTS_DETAILS,
  SET_CONTRACT_DETAILS,
  SET_CONTRACT_CLIENT, SET_CONTRACT_CONTACT,
  FETCH_WORKERS,
  SET_WORKERS,
  SET_RESPONSE,
  ADD_CONTRACT_NOTE, EDIT_CONTRACT_NOTE, DELETE_CONTRACT_NOTE, UPDATE_CATEGORIES,
  ADD_CONTRACT_JOBORDER, EDIT_CONTRACT_JOBORDER, DELETE_CONTRACT_JOBORDER,
  ADD_CONTRACT_DOCUMENT, EDIT_CONTRACT_DOCUMENT, DELETE_CONTRACT_DOCUMENT,
} from "./types";

/* CONTRACT : MANAGE DOCUMENT ACTIONS : START */
export const addContractDocument = (ItemId, data) => ({
  type: ADD_CONTRACT_DOCUMENT,
  ItemId,
  data,
});
export const editContractDocument = (ItemId, data) => ({
  type: EDIT_CONTRACT_DOCUMENT,
  ItemId,
  data,
});
export const deleteContractDocument = (ItemId, data) => ({
  type: DELETE_CONTRACT_DOCUMENT,
  ItemId,
  data,
});
/* CONTRACT : MANAGE DOCUMENT ACTIONS : END */

/* CONTRACT : MANAGE JOB ORDER ACTIONS : START */
export const addContractJobOrder = (Itemid, data) => ({
  type: ADD_CONTRACT_JOBORDER,
  ItemId: Itemid,
  data: data,
});
export const editContractJobOrder = (Itemid, data) => ({
  type: EDIT_CONTRACT_JOBORDER,
  ItemId: Itemid,
  data: data,
});
export const deleteContractJobOrder = (Itemid, jobOrderId) => ({
  type: DELETE_CONTRACT_JOBORDER,
  ItemId: Itemid,
  deleteJobOrder: jobOrderId,
});
/* CONTRACT : MANAGE JOB ORDER ACTIONS : END */

/* CONTRACT : MANAGE NOTES ACTIONS : START */
export const addContractNote = (ItemId, addNote) => ({
  type: ADD_CONTRACT_NOTE,
  ItemId,
  addNote,
});
export const editContractNote = (ItemId, editNoteId, editNote) => ({
  type: EDIT_CONTRACT_NOTE,
  ItemId,
  editNoteId,
  editNote,
});
export const deleteContractNote = (ItemId, deleteNote) => ({
  type: DELETE_CONTRACT_NOTE,
  ItemId,
  deleteNote,
});
/* CONTRACT : MANAGE NOTES ACTIONS : END */
export const updateCategories = (ItemId, data) => ({
  type: UPDATE_CATEGORIES,
  ItemId,
  data,
});

export const fetchContractDetails = (ItemId) => ({
  type: FETCH_CONTRACTS_DETAILS,
  ItemId
});

export const setContractDetails = (data) => ({
  type: SET_CONTRACT_DETAILS,
  data,
});
export const setContractClient = (data) => ({
  type: SET_CONTRACT_CLIENT,
  data,
});
export const setContractContact = (data) => ({
  type: SET_CONTRACT_CONTACT,
  data,
});

// ADD CONTRACT - ACTION
export const addContract = (data) => ({
  type: SUBMIT_CONTRACTS,
  data,
});

// EDIT CONTRACT - ACTION
export const editContract = (ItemId, data) => ({
  type: EDIT_CONTRACT,
  ItemId,
  data,
});

// FETCH CONTRACTS - ACTION
export const fetchContacts = (ItemId) => ({
  type: FETCH_CONTACTS,
  ItemId,
});
export const fetchWorkers = () => ({
  type: FETCH_WORKERS
});

export const setWorkers = (data) => ({
  type: SET_WORKERS,
  data,
});
export const setResponse = (data) => ({
  type: SET_RESPONSE,
  data,
});

export const setContacts = (data) => ({
  type: SET_CONTACTS,
  data,
});

export const setContractFormData = (key, value) => ({
  type: SET_CONTRACT_FORM_DATA,
  key,
  value
});
export const clearContractFormData = () => ({
  type: CLEAR_CONTRACT_FORM_DATA
});


export const enableLoader = () => ({
  type: ENABLE_CONTRACT_LOADER
});

export const disableLoader = () => ({
  type: DISABLE_CONTRACT_LOADER
});

export const fetchContracts = () => ({
  type: FETCH_CONTRACTS
});

export const setContracts = data => ({
  type: SET_CONTRACT,
  data
});

export const filterContracts = data => ({
  type: FILTER_CONTRACT,
  data
});

export const sortContracts = data => ({
  type: SORT_CONTRACT,
  data
});

export const updateContracts = data => ({
  type: UPDATE_CONTRACT,
  data
});
