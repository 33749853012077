import React, { Component } from "react";
import PropTypes from "prop-types";

export class InputTextArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: typeof props.value !== "undefined" ? props.value : ""
    };
  }

  onChange(event) {
    this.setState({ value: event.currentTarget.value }, () => {
      this.props.onChange(this.state.value);
    });
  }

  componentWillReceiveProps(nextProps) {
    // let inputTextArea = this.refs.inputTextArea;
    // inputTextArea.value = nextProps.value;
  }

  render() {
    let inputClass = "form-control";
    if (this.props.validFeedback !== "") inputClass += " is-valid";
    if (this.props.invalidFeedback !== "") inputClass += " is-invalid";
    
    // let inputTextArea = this.refs.inputTextArea;
    // if(typeof inputTextArea !== 'undefined') {
    //   inputTextArea.value = this.props.value;
    // }

    return (
      <div className="form-group">
        {this.props.label && (
          <label className="form-label">{this.props.label}</label>
        )}
        <textarea
          type="text"
          className={inputClass}
          ref="inputTextArea"
          onChange={event => this.onChange(event)}
          placeholder={this.props.placeholder}
          defaultValue={this.state.value}
          rows={this.props.rows}
        />
        {this.props.invalidFeedback !== "" && (
          <div class="invalid-feedback">{this.props.invalidFeedback}</div>
        )}
        {this.props.validFeedback !== "" && (
          <div class="valid-feedback">{this.props.validFeedback}</div>
        )}
      </div>
    );
  }
}

InputTextArea.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  validFeedback: PropTypes.string,
  invalidFeedback: PropTypes.string,
  value: PropTypes.string,
  rows: PropTypes.any
};

InputTextArea.defaultProps = {
  label: "",
  placeholder: "",
  validFeedback: "",
  invalidFeedback: "",
  value: "",
  rows: "2"
};

export default InputTextArea;
