export const ContractConstants = {
  ContractsPerPage: 25,
  EmptyNotesMsg : "There are no notes for this contract",
  EmptyWorkersMsg : "There are no workers assigned to this contract",
  EmptyDocumentsMsg : "There are no documents for this contract",
  EmptyJobOrdersMsg : "There are no Job Orders assigned to this contract",
};

export const availableStatus = [
  { key: "Draft", title: "Draft" },
  { key: "Building", title: "Building" },
  { key: "In Progress", title: "In Progress" },
  { key: "Paused", title: "Paused" },
  { key: "Ended", title: "Ended" },  
];
