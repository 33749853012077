
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkedAlt,
  faMoneyBill,
  faPhone,
  faLink,
  faFileAlt, 
  
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import React, { Component, Fragment } from 'react'
import './Style/infoStyles.scss'

export default class Info extends Component {
    render() {
        return (
        <Fragment>
        <ul className="infoContainer">
        <li className="location">
          <FontAwesomeIcon className="icon2" icon={faMapMarkedAlt} />
          <p className="info info-location">
            Location
            <span>{this.props.profileMember.Location}</span>
          </p>
        </li>
        <li className="HourlyRate">
          <FontAwesomeIcon className="icon2 " icon={faMoneyBill} />
          <p className="info">
            Hourly Rate
            <span>{this.props.profileMember.HourlyRate}</span>
          </p>
        </li>
        <li className="Phone">
          <FontAwesomeIcon className="icon2 " icon={faPhone} />
          <p className="info">
            Phone
            <span>{this.props.profileMember.Mobile}</span>
          </p>
        </li>
          <li className="portfolio">
            <FontAwesomeIcon className="icon2" icon={faLink} />
            <p className="info"> <span>Portfolio</span></p>
          </li>
          <li className="linkedin">
            <FontAwesomeIcon className="icon2 " icon={faLinkedin} />
            <p className="info"> <span>Linkedin</span></p>
          </li>
          <li className="Resume">
            <FontAwesomeIcon className="icon2 " icon={faFileAlt} />
            <p className="info"> <span>Resume</span></p>
          </li>
        </ul>

        </Fragment>
        )
    }
}




