import React, { Component, Fragment } from "react";

import "./styles/navStyle.scss";
import Toggle from "./Toggle/toggle";
let toggled = false;
//pass in state
export default class NavigationItems extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.toggleSidebar = this.toggleSidebar.bind(this);
  }

  //function toggle sidebar collapse and expand
  toggleSidebar() {
    this.setState({ expanded: !this.state.expanded });
  }

  render() {
    return (
      //pass in state
      <Fragment>
        <Toggle
          toggleSidebar={this.toggleSidebar.bind(this)}
        />
      </Fragment>
    );
  }
}

export { toggled };
