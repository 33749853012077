import React, { } from "react";
import "../styles/navStyle.scss";

import NavContent from "../NavContent/NavContent";
import logo from '../../../assets/logoSmall.png';

export default class NavigationButton extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="logoImg">
          <img src={logo} className="sidenavlogo" alt="" />
        </div>
        <div
          className={
            this.props.expanded ? ("sidebar sidebar--expanded") : "sidebar"
          }
          onClick={this.props.toggleSidebar} >
          <NavContent />
        </div>
      </React.Fragment>
    );
  }
}