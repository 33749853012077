export const OpportunityConstants = {
  OpportunitiesPerPage: 25,
  ResponsibilityEmptyMsg: "There is no responsibilities listed yet!",
  RequirementEmptyMsg: "Requirement is not specified yet",
  SpecializationEmptyMsg: "No skills specified yet",
};
export const availableStatus = [
  { key: "Open", title: "Open" },
  { key: "Closed", title: "Closed" },
];
export const availableType = [
  { key: "Part-Time", title: "Part-Time" },
  { key: "Full-Time", title: "Full-Time" },
  { key: "Freelance", title: "Freelance" },
];
export const availableCompensation = [{ key: "Based on experience", title: "Based on experience" }];
export const availableLocation = [
  { key: "Remote", title: "Remote" },
  { key: "On site", title: "On site" },
];
