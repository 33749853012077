import React, { Component } from 'react';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import CustomModal from '../../../components/Modals/Modal';
import { Link } from "react-router-dom";

class ToolsList extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        console.log("tools did mount");
    }

    render() {
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container" style={{ marginTop: 20 }}>
                <Link className="btn btn-success sendPayroll" style={{ height: 50, width: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }} to="/tools/payrolls">Payrolls Log</Link>
                <p />
                <Link className="btn btn-success sendPayroll" style={{ height: 50, width: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }} to="/tools/emails">Email Messages Log</Link>
                <p />
                <Link className="btn btn-success sendPayroll" style={{ height: 50, width: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }} to="/tools/resources">Manage TECKpert Resources</Link>
            </div>
            <CustomModal />
        </React.Fragment >
        )
    }
}

export default ToolsList;