import { createStore, applyMiddleware, compose } from 'redux';
import { refreshToken} from './helperFunctions/authFunction';
// import thunk from 'redux-thunk';
import createSagaMiddleware from "redux-saga";
import { createLogger } from 'redux-logger';

import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import thunk from 'redux-thunk'
import sagas from './sagas'
import rootReducer from './reducers/rootReducer';

export const history = createBrowserHistory()

const initialState = {};

const logger = createLogger({
    level: 'info',
    collapsed: true
});

const sagaMiddleware = createSagaMiddleware();

let middlewares;
if (process.env.NODE_ENV === 'development') {
    middlewares = [sagaMiddleware, logger, thunk, routerMiddleware(history)];    
} else {
    middlewares = [sagaMiddleware, thunk, routerMiddleware(history)];
}

const store = createStore(
    rootReducer(history),
    initialState,
    compose(
        applyMiddleware(refreshToken, ...middlewares)
    )
);
sagaMiddleware.run(sagas);

export default store;
