import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  FETCH_CONTRACTS,
  UPDATE_CONTRACT,
  FILTER_CONTRACT,
  SORT_CONTRACT,
  FETCH_CONTACTS,
  SUBMIT_CONTRACTS, EDIT_CONTRACT,
  FETCH_CONTRACTS_DETAILS,
  FETCH_WORKERS,
  ADD_CONTRACT_NOTE, EDIT_CONTRACT_NOTE, DELETE_CONTRACT_NOTE, UPDATE_CATEGORIES,
  ADD_CONTRACT_JOBORDER, EDIT_CONTRACT_JOBORDER, DELETE_CONTRACT_JOBORDER,
  ADD_CONTRACT_DOCUMENT, EDIT_CONTRACT_DOCUMENT, DELETE_CONTRACT_DOCUMENT,
} from "../actions/types";
import * as contractActions from "../actions";
import * as contractUtils from '../utils/helper'
import * as contractApis from "../api";
import * as clientApis from "../../././Clients/api";
import * as dataParser from "../../../helperFunctions/DataParser";

export default function* timesheetSagas() {
  yield takeEvery(FETCH_CONTRACTS, fetchContracts);
  yield takeEvery(FILTER_CONTRACT, filterContracts);
  yield takeEvery(FETCH_CONTACTS, filterContacts);
  yield takeEvery(SUBMIT_CONTRACTS, submitContract);
  yield takeEvery(EDIT_CONTRACT, editContract);
  yield takeEvery(FETCH_CONTRACTS_DETAILS, fetchContractDetails);
  yield takeEvery(SORT_CONTRACT, sortContract);
  yield takeEvery(FETCH_WORKERS, fetchWorkers);

  yield takeEvery(ADD_CONTRACT_NOTE, addContractNote);
  yield takeEvery(EDIT_CONTRACT_NOTE, editContractNote);
  yield takeEvery(DELETE_CONTRACT_NOTE, deleteContractNOte);
  yield takeEvery(UPDATE_CATEGORIES, updateCategories);

  yield takeEvery(ADD_CONTRACT_JOBORDER, addContractJobOrder);
  yield takeEvery(EDIT_CONTRACT_JOBORDER, editContractJobOrder);
  yield takeEvery(DELETE_CONTRACT_JOBORDER, deleteContractJobOrder);

  yield takeEvery(ADD_CONTRACT_DOCUMENT, addContractDocument);
  yield takeEvery(EDIT_CONTRACT_DOCUMENT, editContractDocument);
  yield takeEvery(DELETE_CONTRACT_DOCUMENT, deleteContractDocument);
}

/* CONTRACT - MANAGE DOCUMENT SAGAS : START */
function* addContractDocument(action) {
  yield put(contractActions.enableLoader());
  yield call(contractApis.addContractDocument, action.ItemId, action.data);
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  yield put(contractActions.disableLoader());
}
function* editContractDocument(action) {
  yield put(contractActions.enableLoader());
  yield call(contractApis.editContractDocument, action.ItemId, action.data);
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  yield put(contractActions.disableLoader());
}
function* deleteContractDocument(action) {
  yield put(contractActions.enableLoader());
  yield call(contractApis.deleteContractDocument, action.ItemId, action.data);
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  yield put(contractActions.disableLoader());
}
/* CONTRACT - MANAGE DOCUMENT SAGAS : END */

/* CONTRACT - MANAGE JOB ORDER SAGAS : START */
function* addContractJobOrder(action) {
  yield put(contractActions.enableLoader());
  yield call(contractApis.addContractJobOrder, action.ItemId, action.data);
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  yield put(contractActions.disableLoader());
}
function* editContractJobOrder(action) {
  yield put(contractActions.enableLoader());
  yield call(contractApis.editContractJobOrder, action.ItemId, action.data);
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  yield put(contractActions.disableLoader());
}
function* deleteContractJobOrder(action) {
  yield put(contractActions.enableLoader());
  yield call(contractApis.deleteContractJobOrder, action.ItemId, action.deleteJobOrder);
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  yield put(contractActions.disableLoader());
}
/* CONTRACT - MANAGE JOB ORDER SAGAS : END */

/* CONTRACT - MANAGE NOTES SAGAS : START */
function* addContractNote(action) {
  yield put(contractActions.enableLoader());
  yield call(contractApis.addContractNote, action.ItemId, action.addNote);
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  yield put(contractActions.disableLoader());
}
function* editContractNote(action) {
  yield put(contractActions.enableLoader());
  yield call(contractApis.editContractNote, action.ItemId, action.editNoteId, action.editNote);
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  yield put(contractActions.disableLoader());
}
function* deleteContractNOte(action) {
  yield put(contractActions.enableLoader());
  yield call(contractApis.deleteContractNote, action.ItemId, action.deleteNote);
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  yield put(contractActions.disableLoader());
}
/* CONTRACT - MANAGE NOTES SAGAS : END */
function* updateCategories(action) {
  yield put(contractActions.enableLoader());
  yield call(contractApis.updateCategories, action.ItemId, action.data);
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  yield put(contractActions.disableLoader());
}

function* fetchContractDetails(action) {
  yield put(contractActions.enableLoader());
  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    let contractData = data.Output[0];

    const ClientId = contractData.ClientId;
    const clientData = yield call(clientApis.getClient, ClientId);
    if (typeof clientData.Message !== "undefined" && clientData.Message === "Success") {
      yield put(contractActions.setContractClient(clientData.Output[0]));
    }
    const ContactId = contractData.PrimaryContact;
    const contactData = yield call(contractApis.fetchContacts, ContactId);
    if (typeof contactData.Message !== "undefined" && contactData.Message === "Success") {
      yield put(contractActions.setContractContact(contactData.Output[0]));
    }
    yield put(contractActions.setContractDetails(contractData));
  }
  yield put(contractActions.disableLoader());
}

function* submitContract(action) {
  yield put(contractActions.enableLoader());
  const response = yield call(contractApis.postContracts, action.data);
  if (typeof response.Message !== "undefined" && response.Message === "Success") {
    // console.log('response : ', response);
  }
  yield put(contractActions.setResponse(response.Message));
  yield put(contractActions.clearContractFormData());
  yield put(contractActions.setResponse(""));
  yield put(contractActions.disableLoader());
}

function* editContract(action) {
  yield put(contractActions.enableLoader());
  const response = yield call(contractApis.editContract, action.ItemId, action.data);

  let data = yield call(contractApis.fetchContractDetails, action.ItemId);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContractDetails(data.Output[0]));
  }
  // yield put(contractActions.setResponse(response.Message));
  yield put(contractActions.clearContractFormData());
  yield put(contractActions.disableLoader());
}

function* fetchWorkers(action) {
  yield put(contractActions.enableLoader());
  let data = yield call(contractApis.fetchWorkersList);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setWorkers(data.Output));
  }
  yield put(contractActions.disableLoader());
}

function* sortContract(action) {
  let parsedData = yield call(dataParser.sortData, action.data.sortby, action.data.sortDir, action.data.data);
  var filteredData = { "filterData": parsedData, "TotalData": action.data.data };
  yield put(contractActions.updateContracts(filteredData));
}

function* filterContacts(action) {
  yield put(contractActions.enableLoader());
  let data = yield call(contractApis.fetchContactsList);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    let filtereddata = yield call(contractUtils.filterContactByClientId, data.Output, action.ItemId);
    yield put(contractActions.setContacts(filtereddata));
  }
  yield put(contractActions.disableLoader());
}

function* fetchContracts() {
  yield put(contractActions.enableLoader());
  let data = yield call(contractApis.fetchContracts);
  if (typeof data.Message !== "undefined" && data.Message === "Success") {
    yield put(contractActions.setContracts(data.Output));
  }
  yield put(contractActions.disableLoader());
}
function* filterContracts(action) {
  //let data = yield call(timeSheetApis.fetchTimeSheets);
  yield put(contractActions.enableLoader());
  let parsedData = [];
  let filteredData = [];
  if (action.data.value.length === 0) {
    parsedData = action.data.data;
  } else {
    parsedData = yield call(
      dataParser.filterContracts,
      action.data.key,
      action.data.value,
      action.data.data
    );
  }
  console.log('parsedData : ', parsedData);
  filteredData = { filterData: parsedData, TotalData: action.data.data };
  console.log(filteredData);
  yield put(contractActions.disableLoader());
  yield put(contractActions.updateContracts(filteredData));
}
