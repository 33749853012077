
import { SET_MODAL_TRUE, SET_MODAL_FALSE, SET_MODAL_NAME} from './actionTypes';

/**
 * Open modal 
 */
export const openModal = () => dispatch => {

    dispatch({
        type: SET_MODAL_TRUE,
        payload: true
      })

};
/**
 * closes modal 
 */
export const closeModal = () => dispatch => {
 
    dispatch({
        type: SET_MODAL_FALSE,
        payload: false
      })

};
/**
 * edit modal 
 */
export const setModalName = modal => dispatch => {
    dispatch({
      type: SET_MODAL_NAME,
      payload: modal
    })
}

