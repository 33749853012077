import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import '../styles/ClientStyles.scss';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { Table, Modal, Button, ButtonToolbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import * as clientActions from '../actions/index';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientConstants } from '../config/Constants';
import {
    faSpinner,
    faPhoneAlt,
    faEnvelope

} from "@fortawesome/free-solid-svg-icons";
import { Redirect } from 'react-router';
import { Link } from "react-router-dom";
import listOfStates from "../../../helperFunctions/listOfStates";
import listOfIndustryTypes from '../../../helperFunctions/listOfIndustryTypes';

class ClientAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "Company": "",
            "Address": "",
            "City": "",
            "State": "",
            "Zip": "",
            "IndustryType": "",
            "Website": "",
            "Phone": "",
            "Fax": "",
            "Email": "",
            "firstNameValidation": "",
            "lastNameValidation": "",
            "companyValidation": "",
            "addressValidation": "",
            "cityValidation": "",
            "stateValidation": "",
            "zipValidation": "",
            "emailValidation": "",
            "phoneValidation": ""
        }
        this.updateInput = this.updateInput.bind(this);
        this.addClient = this.addClient.bind(this);

    }
    componentDidMount() {

    }
    updateInput(event) {
        var currentVal = event.currentTarget.value;
        var currentInput = event.currentTarget.getAttribute('data-input');
        this.setState({ [currentInput]: currentVal });
        if (this.state.Company !== "") {
            this.setState({ "companyValidation": "" });
        }
        if (this.state.Address !== "") {
            this.setState({ "addressValidation": "" });
        }
        if (this.state.Phone !== "") {
            this.setState({ "phoneValidation": "" });
        }
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (this.state.Email !== "" && filter.test(this.state.Email)) {
            this.setState({ "emailValidation": "" });
        }

    }
    addClient() {
        if (this.state.Company === "") {
            this.setState({ "companyValidation": "is-invalid" });
        }
        if (this.state.Address === "") {
            this.setState({ "addressValidation": "is-invalid" });
        }
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (this.state.Email === "" || !filter.test(this.state.Email)) {
            this.setState({ "emailValidation": "is-invalid" });
        }
        if (this.state.Phone === "") {
            this.setState({ "phoneValidation": "is-invalid" });
        }
        if (this.state.Company !== "" && this.state.Address !== "" && this.state.Phone !== "" && this.state.Email !== "") {
            this.setState({ "companyValidation": "" });
            this.setState({ "AddressValidation": "" });
            this.setState({ "emailValidation": "" });
            this.setState({ "phoneValidation": "" });
            this.props.addClient(this.state);
        }
    }
    render() {

        return (<React.Fragment>
            <TopNav title="Add Client" />
            <Nav />
            <section className="lighgray">
                <div className="container">
                    <div className="add_contact">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Company Name</label>
                                                    <input type="text" onChange={this.updateInput} data-input="Company" className={"form-control " + this.state.companyValidation} placeholder="Company Name" />
                                                    <div class="invalid-feedback">
                                                        {ClientConstants.CompanyValidationMsg}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-5">
                                                <div className="form-group">
                                                    <label className="form-label">Address</label>
                                                    <input type="text" onChange={this.updateInput} data-input="Address" className={"form-control " + this.state.addressValidation} placeholder="Address" />
                                                    <div class="invalid-feedback">
                                                        {ClientConstants.AddressValidationMsg}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label className="form-label">City</label>
                                                    <input type="text" onChange={this.updateInput} data-input="City" className={"form-control " + this.state.cityValidation} placeholder="City" />
                                                    <div class="invalid-feedback">
                                                        {ClientConstants.CityValidationMsg}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="form-label">State</label>
                                                    <select type="text" onChange={this.updateInput} data-input="State" className={"form-control " + this.state.stateValidation} placeholder="State">
                                                        {listOfStates().map(obj => (<option value={obj.value}>{obj.value}</option>))}
                                                    </select>
                                                    <div class="invalid-feedback">
                                                        {ClientConstants.StateValidationMsg}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="form-label">Zip</label>
                                                    <input type="text" onChange={this.updateInput} data-input="Zip" className={"form-control " + this.state.zipValidation} placeholder="Zip" />
                                                    <div class="invalid-feedback">
                                                        {ClientConstants.ZipValidationMsg}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md">
                                                <div className="form-group">
                                                    <label className="form-label">Industry Type</label>
                                                    <select type="text" onChange={this.updateInput} data-input="IndustryType" className="form-control " placeholder="Industry Type">
                                                        {listOfIndustryTypes().map(obj => (<option value={obj.value}>{obj.value}</option>))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md">
                                                <div className="form-group">
                                                    <label className="form-label">Website</label>
                                                    <input type="text" onChange={this.updateInput} data-input="Website" className="form-control" placeholder="Website" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 pl-4 list_form">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"><FontAwesomeIcon className="svg-inline--fa fa-call fa-w-16 callIcon" icon={faPhoneAlt} /> Phone</label>
                                                    <input type="text" onChange={this.updateInput} data-input="Phone" className={"form-control " + this.state.phoneValidation} placeholder="Phone" />
                                                    <div class="invalid-feedback">
                                                        {ClientConstants.PhoneValidationMsg}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"> <FontAwesomeIcon className="svg-inline--fa fa-call fa-w-16 callIcon" icon={faPhoneAlt} />Fax</label>
                                                    <input type="text" onChange={this.updateInput} data-input="Fax" className="form-control" placeholder="Fax" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label"> <FontAwesomeIcon className="svg-inline--fa fa-call fa-w-16 callIcon" icon={faEnvelope} /> Email</label>
                                                    <input type="text" onChange={this.updateInput} data-input="Email" className={"form-control " + this.state.emailValidation} placeholder="Email" />

                                                    <div class="invalid-feedback">
                                                        {ClientConstants.EmailValidationMsg}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <ul className="buttonBlock">
                                            <li> <a className="greenBtn" onClick={this.addClient} >Add </a>
                                            </li>
                                            <li> <Link to="/clients" className="greenBtn">Cancel  </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.props.clientList.isLoading &&
                            <div className="loader"><FontAwesomeIcon icon={faSpinner} spin /></div>
                        }
                        {this.props.clientList.addClientResponse === "Success" ?
                            <Redirect to="/clients" />
                            : this.props.clientList.addClientResponse !== "" ? <div className="status error">Error in creating client!</div> : ''
                        }

                    </div>
                </div>
            </section>
        </React.Fragment >
        )
    }
}
function mapStateToProps(state) {
    return {
        myState: state,
        clientList: state.clientList
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addClient: (data) => dispatch(clientActions.addClient(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAdd);