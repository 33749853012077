import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  FETCH_TIMESHEETS,
  UPDATE_TIMESHEET,
  FILTER_TIMESHEET,
  SORT_TIMESHEET,
  SET_SELECTED_TIMESHEET,
  GET_DETAILED_TIMESHEET,
  SEND_TO_CLIENT,
  FETCH_ALL_CONTACTS,
  SEND_TO_PAYROLL,
  FETCH_PAYROLLS,
  APPROVE_TIMESHEETS,
  TIMESHEET_FOR_APPROVAL,
  EDIT_PAYROLL_DATE,
} from "../actions/types";
import * as timesheetActions from '../actions'

import * as timeSheetApis from '../api'
import * as dataParser from '../../../helperFunctions/DataParser'

export default function* timesheetSagas() {
  yield takeEvery(FETCH_TIMESHEETS, fetchTimesheets);
  yield takeEvery(FILTER_TIMESHEET, filterTimesheets);
  yield takeEvery(SORT_TIMESHEET, sortTimesheets);
  yield takeEvery(GET_DETAILED_TIMESHEET, getDetailedTimeSheet);
  yield takeEvery(SEND_TO_CLIENT, sendToClient);
  yield takeEvery(FETCH_ALL_CONTACTS, fetchAllContacts);
  yield takeEvery(SEND_TO_PAYROLL, sendToPayroll);
  yield takeEvery(FETCH_PAYROLLS, fetchPayrolls);
  yield takeEvery(APPROVE_TIMESHEETS, approveTimesheet);
  yield takeEvery(TIMESHEET_FOR_APPROVAL, fetchTimesheetsForApproval);
  yield takeEvery(EDIT_PAYROLL_DATE, editPayrollDate);

}

function* editPayrollDate(action) {
  yield put(timesheetActions.enableLoader());
  let data = yield call(timeSheetApis.editPayrollDate, action.data);

  let data2 = yield call(timeSheetApis.fetchTimeSheets);
  if (typeof data2.Message!=='undefined' && data2.Message==="Success") {
    yield put(timesheetActions.setTimesheet(data2.Output));
  }

  yield put(timesheetActions.setPayrollResponse(data.Message));
  yield put(timesheetActions.disableLoader());
}

function* fetchTimesheetsForApproval(action) {
  yield put(timesheetActions.enableLoader());
  let data = yield call(timeSheetApis.fetchTimeSheetsForApproval, action.data);
  if (typeof data.Message!=="undefined" && data.Message==="Success") {
    yield put(timesheetActions.setTimeSheetforApprove(data.Output));
  }
  yield put(timesheetActions.disableLoader());
}

function* approveTimesheet(action) {

  yield put(timesheetActions.enableLoader());
  let data = yield call(timeSheetApis.approveTimesheet, action.data);
  if (typeof data.Message!=="undefined" && data.Message==="Success") {
    yield put(timesheetActions.setApproveResponse(data.Message));
  }
  yield put(timesheetActions.disableLoader());
}

function* fetchPayrolls(action) {
  yield put(timesheetActions.enableLoader());
  let data = yield call(timeSheetApis.sendToPayroll, action.data);
  if (typeof data.Message!=="undefined" && data.Message==="Success") {
    yield put(timesheetActions.setPayrolls(data.Output));
  }
  yield put(timesheetActions.disableLoader());
}

function* fetchAllContacts(action) {
  yield put(timesheetActions.enableLoader());
  let data = yield call(timeSheetApis.fetchContacts, action.data);
  if (typeof data.Message!=='undefined' && data.Message==="Success") {
    yield put(timesheetActions.setAllContacts(data.Output));
  }
  yield put(timesheetActions.disableLoader());
}

function* sendToPayroll(action) {
  yield put(timesheetActions.enableLoader());
  const dataParsed = yield call(dataParser.parseObjectToQueryStringData, action.data);
  let data = yield call(timeSheetApis.sendToPayroll, dataParsed + "&submit=true");
  if (typeof data.Message!=="undefined" && data.Message==="Success") {
    let response = yield call(timeSheetApis.fetchTimeSheets);
    if (typeof response.Message!=='undefined' && response.Message==="Success") {
      yield put(timesheetActions.setTimesheet(response.Output));
    }
  }
  yield put(timesheetActions.setPayrollResponse(data.Message));
  yield put(timesheetActions.disableLoader());
}

function* sendToClient(action) {
  yield put(timesheetActions.enableLoader());
  let data = yield call(timeSheetApis.sendToClient, action.data);
  if (typeof data.Message!=="undefined" && data.Message==="Success") {
    let response = yield call(timeSheetApis.fetchTimeSheets);
    if (typeof response.Message!=='undefined' && response.Message==="Success") {
      yield put(timesheetActions.setTimesheet(response.Output));
    }
  }
  yield put(timesheetActions.setResponse(data.Message));
  yield put(timesheetActions.disableLoader());
}

function* fetchTimesheets() {
  yield put(timesheetActions.enableLoader());
  let data = yield call(timeSheetApis.fetchTimeSheets);
  if (typeof data.Message!=='undefined' && data.Message==="Success") {
    yield put(timesheetActions.setTimesheet(data.Output));
  }
  yield put(timesheetActions.disableLoader());
}
function* filterTimesheets(action) {
  //let data = yield call(timeSheetApis.fetchTimeSheets);
  yield put(timesheetActions.enableLoader());
  let parsedData = [];
  let filteredData = [];
  if (action.data.key==="date") {
    if (action.data.value.fromDate==="") {
      yield put(timesheetActions.enableLoader());
      parsedData = yield call(timeSheetApis.fetchTimeSheets);
      yield put(timesheetActions.disableLoader());
    } else {
      parsedData = yield call(timeSheetApis.filterTimeSheetsByDate, action.data.value.fromDate, action.data.value.toDate);
    }
    filteredData = { "filterData": parsedData.Output, "TotalData": parsedData.Output }
  } else {
    if (action.data.value==="Status" || action.data.value==="Select Contract") {
      parsedData = action.data.data;
    } else {

      parsedData = yield call(dataParser.filterData, action.data.key, action.data.value, action.data.data);
    }

    filteredData = { "filterData": parsedData, "TotalData": action.data.data }
  }
  yield put(timesheetActions.disableLoader());

  yield put(timesheetActions.updateTimesheet(filteredData));
}
function* sortTimesheets(action) {
  let parsedData = yield call(dataParser.sortData, action.data.sortby, action.data.sortDir, action.data.data);
  var filteredData = { "filterData": parsedData, "TotalData": action.data.data }
  yield put(timesheetActions.updateTimesheet(filteredData));
}
function* getDetailedTimeSheet(action) {
  let detailedTimeSheet = {};
  yield put(timesheetActions.enableLoader());
  yield put(timesheetActions.deleteDetailedTimeSheet());
  detailedTimeSheet = yield call(timeSheetApis.getDetailedTimeSheets, action.data.itemId);
  yield put(timesheetActions.disableLoader());
  yield put(timesheetActions.setDetailedTimeSheet(detailedTimeSheet.Output));
}