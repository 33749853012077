import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as contractActions from "../actions";

import Nav from "../../../components/Navigation/Nav";
import TopNav from "../../../components/TopNav/TopNav";

import Info from '../components/view/Info';
import Categories from '../components/view/Categories';
import Notes from '../components/view/Notes';
import Documents from '../components/view/Documents';
import JobOrders from '../components/view/JobOrders';
import Workers from '../components/view/Workers';
import History from '../components/view/History';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner

} from "@fortawesome/free-solid-svg-icons";
export class ContractDetail extends Component {

  constructor() {
    super();
    this.state = {
      fetchedOnce: false,
    }
  }

  componentDidMount() {
    const ItemId = this.props.match.params.ItemId;
    this.props.fetchContractDetails(ItemId);
  }

  // componentWillReceiveProps (newprops) {
  //   if(typeof newprops.contractDetails.ItemId !== 'undefined' && newprops.contractDetails.ItemId !== '' && !this.state.fetchedOnce) {
  //     const ClientId = newprops.contractDetails.ClientId;
  //     const ContactId = newprops.contractDetails.PrimaryContact;
  //     this.props.fetchContractClientContactDetails(ClientId, ContactId);
  //   }
  // }

  /* MANAGE CONTRACT CATEGORIES : START */

  updateCategories = (data) => {
    //do stuff to update categories
    let contractId = this.props.contractDetails.ItemId;
    return new Promise((resolve, reject) => {
      this.props.updateCategories(contractId, data);
      resolve(true);
    });
  }
  /* MANAGE CONTRACT NOTES : END */

  /* MANAGE CONTRACT NOTES : START */
  onAddNote = (note) => {
    let contractId = this.props.contractDetails.ItemId;
    this.props.addContractNote(contractId, note);
  }
  onEditNote = (noteId, note) => {
    let contractId = this.props.contractDetails.ItemId;
    this.props.editContractNote(contractId, noteId, note);
  }
  onDeleteNote = (noteId) => {
    let contractId = this.props.contractDetails.ItemId;
    this.props.deleteContractNote(contractId, noteId);
  }
  /* MANAGE CONTRACT NOTES : END */

  /* MANAGE CONTRACT JOB ORDERS : START */
  onJobOrderAdd = (data) => {
    let contractId = this.props.contractDetails.ItemId;
    this.props.addContractJobOrder(contractId, data);
  }
  onJobOrderEdit = (data) => {
    let contractId = this.props.contractDetails.ItemId;
    this.props.editContractJobOrder(contractId, data);
  }
  onJobOrderDelete = (ItemId) => {
    let contractId = this.props.contractDetails.ItemId;
    this.props.deleteContractJobOrder(contractId, ItemId);
  }
  /* MANAGE CONTRACT JOB ORDERS : END */

  /* MANAGE CONTRACT DOCUMENTS : START */
  onDocumentAdd = (data) => {
    let contractId = this.props.contractDetails.ItemId;
    this.props.addContractDocument(contractId, data);
  }
  onDocumentEdit = (data) => {
    let contractId = this.props.contractDetails.ItemId;
    this.props.editContractDocument(contractId, data);
  }
  onDocumentDelete = (data) => {
    let contractId = this.props.contractDetails.ItemId;
    this.props.deleteContractDocument(contractId, data);
  }
  /* MANAGE CONTRACT DOCUMENTS : END */

  onContractEditInput = (key, value) => {
    this.props.setContractFormData(key, value);
  }

  onEditContract = async () => {
    let contractId = this.props.contractDetails.ItemId;
    return new Promise((resolve, reject) => {
      this.props.editContract(contractId, this.props.addContractData);
      resolve(true);
    });

  }

  render() {

    return (
      <React.Fragment>
        <TopNav title="Contract Details" />
        <Nav />
        <div className="container">
          <div className="report_issues contract_details_block">
            {/* <h4 className="mt-5 mb-5 text-center"> Contract Details </h4> */}
            <Info
              contract={this.props.contractDetails}
              onContractEditInput={this.onContractEditInput}
              onEditContract={this.onEditContract}
              addContractData={this.props.addContractData}
              contractClient={this.props.contractClient}
              contractContact={this.props.contractContact}
            />

            <Notes
              notes={this.props.contractDetails.Notes}
              onAddNote={this.onAddNote}
              onEditNote={this.onEditNote}
              onDeleteNote={this.onDeleteNote}
            />

            <div id="accordion" className="accordion_block">
              <Categories
                categories={this.props.contractDetails.Categories}
                //categories={['Test1','Test2']}
                onSubmit={this.updateCategories}
              />
              <JobOrders
                contract={this.props.contractDetails}
                worker={this.props.contractDetails.Workers}
                onJobOrderAdd={this.onJobOrderAdd}
                onJobOrderEdit={this.onJobOrderEdit}
                onJobOrderDelete={this.onJobOrderDelete}
              />
              <Documents
                documents={this.props.contractDetails.Documents}
                onDocumentAdd={this.onDocumentAdd}
                onDocumentEdit={this.onDocumentEdit}
                onDocumentDelete={this.onDocumentDelete}
              />
              <History contractId={this.props.match.params.ItemId} />
            </div>
            {this.props.contractsList.isLoading &&
              <div className="loader"><FontAwesomeIcon icon={faSpinner} spin /></div>
            }
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  contractDetails: state.contract.contractDetails,
  contractsList: state.contractList,
  addContractData: state.contract.addContractData,
  contractClient: state.contract.contractClient,
  contractContact: state.contract.contractContact,
})

const mapDispatchToProps = dispatch => {
  return {
    editContract: (ItemId, data) => dispatch(contractActions.editContract(ItemId, data)),
    fetchContractDetails: (ItemId) => dispatch(contractActions.fetchContractDetails(ItemId)),
    setContractFormData: (key, value) => dispatch(contractActions.setContractFormData(key, value)),

    addContractNote: (ItemId, addNote) => dispatch(contractActions.addContractNote(ItemId, addNote)),
    editContractNote: (ItemId, editNoteId, editNote) => dispatch(contractActions.editContractNote(ItemId, editNoteId, editNote)),
    deleteContractNote: (ItemId, deleteNote) => dispatch(contractActions.deleteContractNote(ItemId, deleteNote)),

    updateCategories: (ItemId, data) => dispatch(contractActions.updateCategories(ItemId, data)),

    addContractJobOrder: (ItemId, text) => dispatch(contractActions.addContractJobOrder(ItemId, text)),
    editContractJobOrder: (ItemId, jobOrderId, text) => dispatch(contractActions.editContractJobOrder(ItemId, jobOrderId, text)),
    deleteContractJobOrder: (ItemId, jobOrderId) => dispatch(contractActions.deleteContractJobOrder(ItemId, jobOrderId)),

    addContractDocument: (ItemId, data) => dispatch(contractActions.addContractDocument(ItemId, data)),
    editContractDocument: (ItemId, data) => dispatch(contractActions.editContractDocument(ItemId, data)),
    deleteContractDocument: (ItemId, data) => dispatch(contractActions.deleteContractDocument(ItemId, data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractDetail)
