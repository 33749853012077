import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


class EditCertifications extends Component {
  constructor(props) {
    super(props);
    this.handleModalClose.bind(this);
  }

  handleModalClose() {
    this.props.setModalName(null);
    this.props.closeModal();
  }


  //
  render() {
    return (
      <React.Fragment>
        <Modal show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Edit Certifications</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group controlId="exampleForm.ControlSelect2">
                  <Form.Label>Unverified Certifications</Form.Label>
                  <Form.Control as="select" multiple>
                      {this.props.memberInfo.UnverifiedCertifications.map((unverified) => {
                        return (
                          <option>{unverified}</option>
                        )
                      })}
                    </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
          </Button>
            <Button variant="primary">
              Save Changes
          </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )

  }
}
EditCertifications.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    memberInfo: state.member.memberInfo[0]
  }
}
export default connect(mapStateToProps, { closeModal, setModalName })(EditCertifications);