import React, { Component } from 'react';
import Nav from '../../../components/Navigation/Nav';
import TopNav from '../../../components/TopNav/TopNav';
import { API } from 'aws-amplify';

import InputDropdown from "../../../components/ui/form/InputDropdown";
import InputDate from "../../../components/ui/form/InputDate";
import InputHoursAndMinutes from "../../../components/ui/form/InputHoursAndMinutes";
import InputTextArea from "../../../components/ui/form/InputTextArea";

class TimeEntry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Contract: '',
            Date: new Date().toISOString().split('T')[0],
            Hours: '04:00:00',
            Description: '',
            Contracts: []
        }
    }
    componentDidMount() {
        console.log("TimeEntry did mount");
        API.get('1', `/contracts?ActiveOnly=1`).then(responseJson => {
            this.setState({
                Contracts: responseJson.Output.map(obj => {
                    return ({ value: obj.ItemId, title: obj.Name, categories: obj.Categories })
                })
            });
        });
    }

    render() {
        return (<React.Fragment>
            <TopNav />
            <Nav />
            <div className="container">
                <div style={{ padding: 25, maxWidth: 1000 }}>
                    <div className="row">
                        <div className="col-md">
                            <InputDropdown
                                label="Contract"
                                placeholder="Please select a Contract"
                                onChange={value => this.setState({ Contract: value, Categories: this.state.Contracts.find(obj => obj.value === value).categories.map(obj => { return ({ value: obj, title: obj }) }) })}
                                value={this.state.Contract}
                                options={this.state.Contracts}
                            />
                        </div>
                        <div className="col-md">
                            <InputDropdown
                                label="Category"
                                placeholder="Please select a Category"
                                onChange={value => this.setState({ Category: value })}
                                value={this.state.Category}
                                options={this.state.Categories}
                            />
                        </div>
                        <div className="col-md-2">
                            <InputDate
                                label="Date"
                                onChange={value => this.setState({ Date: value })}
                                value={this.state.Date}
                            />
                        </div>
                        <div className="col-md-3" style={{ alignSelf: 'flex-end' }}>
                            <InputHoursAndMinutes
                                label="Hours"
                                onChange={value => this.setState({ Hours: value })}
                                value={this.state.Hours}
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            <InputTextArea
                                label="Description"
                                placeholder="Description"
                                onChange={value => this.setState({ Description: value })}
                                rows="6"
                                value={this.state.Description}
                            />
                        </div>
                    </div>

                    <div className="row" style={{ justifyContent: 'flex-end', padding: 17 }}>
                        <a className="greenBtn" onClick={() => {
                            API.post('1', '/timesheetdays', { 'body': { 'ContractId': this.state.Contract, 'Category': this.state.Category, 'Date': this.state.Date, 'Hours': this.state.Hours, 'Details': this.state.Description, 'Status': 'complete' } }).then(responseJson => {
                                console.log(responseJson);
                                alert("Time entry submitted!");
                                this.props.history.push('/timesheets');
                            });
                        }}>Submit</a>
                    </div>
                </div>
            </div>
        </React.Fragment >
        )
    }
}

export default TimeEntry;