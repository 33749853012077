import { GET_MEMBER, CLEAR_MEMBER} from '../actions/actionTypes';

const initialState = {
  memberInfo: {},
  memberState: '',
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_MEMBER:
      return {
        ...state,
        memberInfo: action.payload,
      };
    case CLEAR_MEMBER:
      return initialState;
    default:
      return state;
  }
}
