import React, { Component } from "react";
import Moment from "react-moment";
import OnboardingForm from "./OnboardingForm";
import { API, Storage } from "aws-amplify";
import { Link } from "react-router-dom";

export class Profile extends Component {
  componentDidMount() {
    console.log(API.get("1",`/members`))

    API.get("1", `/members?ItemId=${this.props.member.ItemId}`)
    .then((responseJson) => {
      this.setState({ Members: responseJson.Output }, () => {
        this.setState({ LastContacted: this.state.Members[0].LastContacted } );
      });
    })
    API.get("1", `/emails`).then(
      (responseJson) => {
        let data = [];
        responseJson.Output.map((item) => {
          data.push({
            key: item.ItemId,
            DateCreated: item.DateCreated,
            MemberIds: item.MemberIds[0]
          });
        });
        this.setState(() => ({ data: data }));
      }
    ); 
  }
  constructor() {
    super();
    this.state = {
      onboardingModal: false,
      onboardingData: {},
      LastContacted: 0,
      data: [],
      MemberIds: 0,
      ItemId: 0,
    };
  }

  render() {
    let { member } = this.props;
    const { Meta } = this.props.member;

    const updateState = (obj) => {
      this.setState(obj);
      this.props.refreshMember();
    };

    let role = "";
    if (
      member.Meta &&
      typeof member.Meta.PrimaryTitle !== "undefined" &&
      member.Meta.PrimaryTitle !== ""
    ) {
      role = member.Meta.PrimaryTitle;
    } else {
      if (member.Meta && typeof member.Meta.PrimaryRole !== "undefined") {
        role = member.Meta.PrimaryRole.label;
      }
    }

    const completedTask = () => {
      let count = [];
      let el = (
        <div
          style={{ background: "#6FBE47", width: 35, height: 6, borderRadius: 3, marginRight: 7 }}
        />
      );
      if (member.DriversLicense == 1) count.push(el);
      if (member.ThreeReferences == 1) count.push(el);
      if (member.BackgroundCheck == 1) count.push(el);
      if (member.SSN != null) count.push(el);
      if (member.SignNDA == 1) count.push(el);
      if (member.SignCSA == 1) count.push(el);
      if (member.Enroll == 1) count.push(el);
      while (count.length != 7)
        count.push(
          <div
            style={{ background: "#c7c7c7", width: 35, height: 6, borderRadius: 3, marginRight: 7 }}
          />
        );
      return count;
    };

    const Description =
      Meta && typeof Meta.Description !== "undefined" && Meta.Description !== ""
        ? Meta.Description
        : "";
    return (
      <div className='profile'>
        <div className='Categories'>
          <span className='Name'>{`${member.FirstName} ${member.LastName}`}</span>
          <span className='Role'>{role}</span>
          <span className='DateRegiestered'>
            Registered on&nbsp;<Moment format='MMMM DD, YYYY'>{member.DateCreated}</Moment>
          </span>
          <span className='DateRegiestered'>
            Last Contacted on&nbsp;
            <Link target="_blank" to="/tools/emails">
              { member.LastContacted === "NULL" ? "N/A" : <Moment format='MMMM DD, YYYY'>{ member.LastContacted }</Moment> }
            </Link>
          </span>  
        </div>
        <p>{Description}</p>
        <div style={{ marginLeft: 127 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <b>
              Onboarding Checklist{" "}
              <span
                style={{
                  cursor: "pointer",
                  color: "#6fbe47",
                  fontStyle: "italic",
                  display: "inline",
                }}
                onClick={() => this.setState({ onboardingModal: !this.state.onboardingModal })}
                onClick={async () => {
                  //let responseJobOrders = await API.get('1', `/joborders?ItemId=${item.ItemId}`);
                  let responseDocuments = await API.get(
                    "1",
                    `/documents?Type=Drivers License&MemberId=${this.props.member.ItemId}`
                  );
                  let responseMembers = await API.get(
                    "1",
                    `/members?ItemId=${this.props.member.ItemId}`
                  );
                  console.log(responseMembers);
                  let ThreeReferences = false;
                  if (responseMembers.Output[0].Meta.References) {
                    if (responseMembers.Output[0].Meta.References.length >= 3) {
                      ThreeReferences = true;
                    }
                  }
                  this.setState({
                    onboardingModal: true,
                    onboardingData: {
                      ItemId: this.props.member.ItemId,
                      // EmploymentType: responseMembers.Output[0].EmploymentType,
                      DriversLicense: responseDocuments.Output.length > 0 ? true : false,
                      ThreeReferences: ThreeReferences,
                      SSN: responseMembers.Output[0].SSN,
                      SignNDA: responseMembers.Output[0].SignNDA,
                      NDALink: "https://app.hellosign.com/s/Am8LEDKf",
                      SignCSA: responseMembers.Output[0].SignCSA,
                      CSALink: "https://app.hellosign.com/s/W2gPVOPJ",
                      SignJobOrder: responseMembers.Output[0].SignJobOrder,
                      JobOrderLink: responseMembers.Output[0].JobOrderLink,
                      Enroll: responseMembers.Output[0].Enroll,
                      EnrollLink: responseMembers.Output[0].EnrollLink,
                    },
                  });
                }}>
                Edit
              </span>
            </b>
            <button
              onClick={() => {
                this.props.member.ItemId &&
                  API.put("1", `/members`, {
                    body: {
                      ItemId: this.props.member.ItemId,
                      Onboarding: member.Onboarding ? 0 : 1,
                    },
                  }).then((response) => {
                    console.log(response);
                    this.props.refreshMember();
                  });
              }}
              class='btn btn-primary btn-sm'
              style={{ marginRight: 15 }}>
              {member.Onboarding ? "Start" : "Stop"}
            </button>
          </div>

          <OnboardingForm
            updateState={updateState}
            onboardingModal={this.state.onboardingModal}
            onboardingData={this.state.onboardingData}
          />
          <table>
            <tbody>
              <tr>
                <td style={{ paddingRight: 20 }}>Driver's License</td>
                <td>
                  <span>{member.DriversLicense ? "Yes" : "No"}</span>
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: 20 }}>3 References</td>
                <td>
                  <span>{member.ThreeReferences ? "Yes" : "No"}</span>
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: 20 }}>Background Check</td>
                <td>
                  <span>{member.BackgroundCheck ? "Yes" : "No"}</span>
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: 20 }}>Social Security Number</td>
                <td>
                  <span>{member.SSN}</span>
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: 20 }}>Signed NDA</td>
                <td>
                  <span>{member.SignNDA ? "Yes" : "No"}</span>
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: 20 }}>Signed CSA</td>
                <td>
                  <span>{member.SignCSA ? "Yes" : "No"}</span>
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: 20 }}>Enrolled in Payroll</td>
                <td>
                  <span>{member.Enroll ? "Yes" : "No"}</span>
                </td>
              </tr>
              <tr>
                <td style={{ paddingRight: 20, paddingTop: 10, display: "flex" }}>
                  {completedTask()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Profile;
