import React, { Component } from "react";
import { connect } from 'react-redux'
import { Container, Row } from "react-bootstrap";
import { faHome, faUsers, faUser, faBriefcase, faFileContract, faCalendar, faTools, faComments, faChartArea, faSchool, faKey, faFileInvoiceDollar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../styles/navStyle.scss";
import { Link, NavLink } from "react-router-dom";

class NavContent extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    console.log(this.props.currentRoute);
  }

  render() {
    let currentRoute = window.location.pathname;
    console.log('currentRoute : ', currentRoute);

    if (localStorage.getItem('userType') !== 'member') {
      return (
        <React.Fragment>
          <div className={this.props.expanded}>
            <Container className="Container " style={{ width: "240px" }}>
              {['admin', 'finance', 'sales', 'recruiter', 'marketing'].includes(localStorage.getItem('userType')) &&
                <Row className={`side-nav__item ${currentRoute === '/' || currentRoute.startsWith('/workers') ? 'active' : ''}`} >
                  <NavLink
                    activeClassName="active"
                    to={{ pathname: "/" }}
                    className="side-nav__link"
                  >
                    <svg className="side-nav__icon">
                      <FontAwesomeIcon icon={faUsers} />
                    </svg>
                    <span className="side-nav__category">People</span>
                  </NavLink>
                </Row>
              }
              {['admin', 'finance', 'sales', 'recruiter', 'marketing'].includes(localStorage.getItem('userType')) &&
                <Row className={`side-nav__item ${currentRoute.startsWith('/clients') ? 'active' : ''}`}>
                  <NavLink
                    activeClassName="active"
                    to={{ pathname: "/clients" }}
                    className="side-nav__link"
                  >
                    <svg className="side-nav__icon">
                      <FontAwesomeIcon icon={faUser} />{" "}
                    </svg>
                    <span className="side-nav__category">Clients</span>
                  </NavLink>
                </Row>
              }
              {['admin', 'finance', 'sales', 'recruiter', 'marketing'].includes(localStorage.getItem('userType')) &&
                <Row className={`side-nav__item ${currentRoute.startsWith('/opportunities') ? 'active' : ''}`}>
                  <NavLink
                    activeClassName="active"
                    to={{ pathname: "/opportunities" }}
                    className="side-nav__link"
                  >
                    <svg className="side-nav__icon">
                      <FontAwesomeIcon icon={faBriefcase} />{" "}
                    </svg>
                    <span className="side-nav__category">Opportunities</span>
                  </NavLink>
                </Row>
              }
              {['admin', 'finance', 'sales'].includes(localStorage.getItem('userType')) &&
                <Row className={`side-nav__item ${currentRoute.startsWith('/contracts') ? 'active' : ''}`}>
                  <NavLink
                    activeClassName="active"
                    to={{ pathname: "/contracts" }}
                    className="side-nav__link"
                  >
                    <svg className="side-nav__icon">
                      <FontAwesomeIcon icon={faFileContract} />
                    </svg>
                    <span className="side-nav__category">Contracts</span>
                  </NavLink>
                </Row>
              }
              {['admin', 'finance'].includes(localStorage.getItem('userType')) &&
                <Row className={`side-nav__item ${currentRoute.startsWith('/timesheets') ? 'active' : ''}`}>
                  <NavLink
                    activeClassName="active"
                    to={{ pathname: "/timesheets" }}
                    className="side-nav__link"
                  >
                    <svg className="side-nav__icon">
                      <FontAwesomeIcon icon={faCalendar} />
                    </svg>
                    <span className="side-nav__category">Timesheets</span>
                  </NavLink>
                </Row>
              }
              {['admin', 'finance'].includes(localStorage.getItem('userType')) &&
                <Row className={`side-nav__item ${currentRoute.startsWith('/invoices') ? 'active' : ''}`}>
                  <NavLink
                    activeClassName="active"
                    to={{ pathname: "/invoices" }}
                    className="side-nav__link"
                  >
                    <svg className="side-nav__icon">
                      <FontAwesomeIcon icon={faFileInvoiceDollar} />
                    </svg>
                    <span className="side-nav__category">Invoices</span>
                  </NavLink>
                </Row>
              }
              {['admin', 'finance'].includes(localStorage.getItem('userType')) &&
                <Row className={`side-nav__item ${currentRoute.startsWith('/tools') ? 'active' : ''}`}>
                  <NavLink
                    activeClassName="active"
                    to={{ pathname: "/tools" }}
                    className="side-nav__link"
                  >
                    <svg className="side-nav__icon">
                      <FontAwesomeIcon icon={faTools} />
                    </svg>
                    <span className="side-nav__category">Tools</span>
                  </NavLink>
                </Row>
              }
              {['admin', 'finance'].includes(localStorage.getItem('userType')) &&
                <Row className={`side-nav__item ${currentRoute.startsWith('/analytics') ? 'active' : ''}`}>
                  <NavLink
                    activeClassName="active"
                    to={{ pathname: "/analytics" }}
                    className="side-nav__link"
                  >
                    <svg className="side-nav__icon">
                      <FontAwesomeIcon icon={faChartArea} />
                    </svg>
                    <span className="side-nav__category">Analytics</span>
                  </NavLink>
                </Row>
              }
              {['admin'].includes(localStorage.getItem('userType')) &&
                <Row className={`side-nav__item ${currentRoute.startsWith('/admins') ? 'active' : ''}`}>
                  <NavLink
                    activeClassName="active"
                    to={{ pathname: "/admins" }}
                    className="side-nav__link"
                  >
                    <svg className="side-nav__icon">
                      <FontAwesomeIcon icon={faKey} />
                    </svg>
                    <span className="side-nav__category">Users</span>
                  </NavLink>
                </Row>
              }
              {['admin', 'finance', 'sales', 'recruiter', 'marketing'].includes(localStorage.getItem('userType')) &&
                <Row className={`side-nav__item ${currentRoute.startsWith('/courses') ? 'active' : ''}`}>
                  <NavLink
                    activeClassName="active"
                    to={{ pathname: "/courses" }}
                    className="side-nav__link"
                  >
                    <svg className="side-nav__icon">
                      <FontAwesomeIcon icon={faSchool} />
                    </svg>
                    <span className="side-nav__category">Courses</span>
                  </NavLink>
                </Row>
              }
            </Container>
          </div>
        </React.Fragment>
        // </Nav>
      );
    }
    else {
      return (
        <React.Fragment>
          <div className={this.props.expanded}>
            <Container className="Container " style={{ width: "240px" }}>
              <Row className={`side-nav__item ${currentRoute === '/' ? 'active' : ''}`} >
                <NavLink
                  activeClassName="active"
                  to={{ pathname: "/" }}
                  className="side-nav__link"
                >
                  <svg className="side-nav__icon">
                    <FontAwesomeIcon icon={faBriefcase} />
                  </svg>
                  <span className="side-nav__category">Time Entry</span>
                </NavLink>
              </Row>
              <Row className={`side-nav__item ${currentRoute.startsWith('/timesheets') ? 'active' : ''}`}>
                <NavLink
                  activeClassName="active"
                  to={{ pathname: "/timesheets" }}
                  className="side-nav__link"
                >
                  <svg className="side-nav__icon">
                    <FontAwesomeIcon icon={faCalendar} />
                  </svg>
                  <span className="side-nav__category">Timesheets</span>
                </NavLink>
              </Row>
            </Container>
          </div>
        </React.Fragment>
        // </Nav>
      );
    }
  }
}

const mapStateToProps = ({ router }) => ({
  currentRoute: router.location.pathname,
  router: router
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(NavContent);
