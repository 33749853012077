import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import EmptyData from "./EmptyData";
import Moment from "react-moment";
import InputFile from "../../../../components/ui/form/InputFile";
import { MemberConstants } from "../../config/Constants";

import AccordionCard from "../../../../components/ui/AccordionCard";

import * as storageUtils from "../../../../helperFunctions/storageUtils";
import Empty from "../../../../components/ui/Empty";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

export class Documents extends Component {
  static propTypes = {
    member: PropTypes.object,
  };

  static defaultProps = {
    member: {},
  };
  constructor() {
    super();
    this.state = {
      modalEnabled: false,
      ItemId: 0,
      file: {},
    };
  }

  onFileUpload = (file) => {
    this.setState({ file: file });
  };

  onChangeType = (type) => {
    this.setState({ type: type });
  };

  getModalTitle = () => {
    if (this.state.ItemId !== 0 && this.state.ItemId !== "") {
      return "Edit Document";
    } else {
      return "Add New Document";
    }
  };

  handleModalClose = () => {
    this.setState({
      modalEnabled: false,
      ItemId: 0,
      file: {},
    });
  };

  onDocumentSubmit = () => {
    let name = localStorage.getItem("name");
    if (this.state.ItemId !== 0 && this.state.ItemId !== "") {
      const data = {
        editDocumentId: this.state.ItemId,
        editDocument: this.state.file.FileInput.name,
        editDocumentFile: this.state.file.FileID,
        editDocumentCreator: name,
        editDocumentType: this.state.type,
      };
      console.log("document edit!");
      console.log(data);
      this.props.onDocumentEdit(data);
    } else {
      const data = {
        addDocument: this.state.file.FileInput.name,
        addDocumentFile: this.state.file.FileID,
        addDocumentCreator: name,
        addDocumentType: this.state.type,
      };
      this.props.onDocumentAdd(data);
    }
    this.handleModalClose();
  };

  onEditPress = (item) => {
    console.log(item);
    const file = {
      FileInput: {
        name: item.Name,
      },
      FileID: item.Key,
      DocType: item.Type,
    };
    this.setState({
      ItemId: item.ItemId,
      modalEnabled: true,
      file: file,
    });
  };

  onDelete = (item) => {
    if (window.confirm("Are you sure you wish to delete this Document?")) {
      const data = {
        deleteDocument: item.ItemId,
      };
      this.props.onDocumentDelete(data);
    }
  };

  render() {
    const { member } = this.props;
    var documents = member.Documents;

    return (
      <div>
        <AccordionCard
          title='Documents'
          actionhtml={
            <span
              className='add-sign'
              onClick={() => {
                this.setState({ modalEnabled: true });
              }}>
              +
            </span>
          }>
          {typeof documents !== "undefined" && Array.isArray(documents) && documents.length > 0 ? (
            <table className='table mt-4 contractDocuments'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Uploaded </th>
                  <th>Creator</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {documents.map((item, index) => {
                  if (item === null || item === "") return "";
                  return (
                    <tr key={`Document-${item.ItemId}-${index}`}>
                      <td>
                        <a onClick={() => storageUtils.getStorageFile(item.Key, true)}>
                          {item.Name} <FontAwesomeIcon icon={faExternalLinkAlt} />
                        </a>
                      </td>
                      <td>{item.Type}</td>
                      <td>
                        <Moment format='MM/DD/YYYY hh:mm a'>{item.DateCreated}</Moment>
                      </td>
                      <td>{item.Creator}</td>
                      <td className='actionsCell'>
                        <div className='actionsWrapper'>
                          <span onClick={() => this.onEditPress(item)}>Edit</span>
                          <span onClick={() => this.onDelete(item)}>Delete</span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <Empty message='No documents found' />
          )}
        </AccordionCard>
        <Modal
          animation={false}
          size='lg'
          className='detailed_time_sheet_modal'
          show={this.state.modalEnabled}
          onHide={() => this.handleModalClose()}
          centered>
          <Modal.Header closeButton>
            <Modal.Title>{this.getModalTitle()}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='container'>
              <InputFile
                options={[
                  { title: "Background Check", value: "Background Check" },
                  {
                    title: "College Transcript",
                    value: "College Transcript",
                  },
                  {
                    title: "Contractor Employment Agreement",
                    value: "Contractor Employment Agreement",
                  },
                  { title: "Drivers License", value: "Drivers License" },
                  { title: "I9", value: "I9" },
                  { title: "Interview Results", value: "Interview Results" },
                  { title: "List of References", value: "List of References" },
                  { title: "NDA", value: "NDA" },
                  { title: "Passport", value: "Passport" },
                  { title: "Prescreen Report", value: "Prescreen Report" },
                  { title: "Reference Check Report", value: "Reference Check Report" },
                  { title: "Resume", value: "Resume" },
                  { title: "Skills Matrix", value: "Skills Matrix" },
                  { title: "SSN Card", value: "SSN Card" },
                  {
                    title: "Test Results",
                    value: "Test Results",
                  },
                  { title: "W9", value: "W9" },
                  { title: "Other", value: "Other" },
                ]}
                label='Upload Document'
                onChange={this.onFileUpload}
                onChangeType={this.onChangeType}
              />
              <a className='greenBtn modalButton' onClick={() => this.onDocumentSubmit()}>
                Save
              </a>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Documents;
