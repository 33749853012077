import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from 'react-moment';
import Empty from '../../../../../components/ui/Empty'

export class NotesView extends Component {

  static propTypes = {
    member: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.state = {

    }
  }

  render() {
    const { member } = this.props;
    var notes = member.Notes;

    return (
     <div>
       <h3>Notes View</h3>
       <div className="notesBlock">
        <div className="row">
          {(typeof notes !== 'undefined' && Array.isArray(notes) && notes.length > 0) ?
            <div className="col-md-12">
              {notes.map(item => {
                return (
                  <div className='noteItem' key={item.ItemId} >
                    <p className="noteTime"><Moment format="MM/DD/YYYY hh:mm a">{item.DateCreated}</Moment></p>
                    <p>Added by: {item.Creator} </p>
                    {(this.state.noteId !== item.ItemId) &&                     
                      <div className="noteText" dangerouslySetInnerHTML={{ __html: item.Note }}></div>
                    }
                  </div>
                )
              })}
            </div>
            : <div className="col-md-12"><div style={{ height: 40 }} /><Empty message="No notes found !" /></div>
          }
        </div>
      </div>
    </div>
    );
  }
}

export default NotesView;