import React, { Component } from "react";
import AccordionCard from "../../../../components/ui/AccordionCard";
import { Modal } from "react-bootstrap";
import Input from "../../../../components/ui/form/Input";
import InputDate from "../../../../components/ui/form/InputDate";
import InputTextArea from "../../../../components/ui/form/InputTextArea";
import Empty from '../../../../components/ui/Empty'
import Moment from 'react-moment';

export class Work extends Component {
  
  constructor () {
    super();
    this.state = {
      modalEnabled: false,
      isEdit: false,
      workDetail: {
        End: '',
        Role: '',
        Start: '',
        Detail: '',
        Company: '',
      },
      workIndex: 0,
      formErrors: {
        End: '',
        Role: '',
        Start: '',
        Detail: '',
        Company: '',
      }
    }
  }

  getWorks = props => {
    const { member } = props;
    return typeof member.Meta !== "undefined" &&
      typeof member.Meta.Work !== "undefined" &&
      member.Meta.Work !== ""
      ? member.Meta.Work
      : [];
  };

  getModalTitle = () => {
    return 'Add Work';
  }

  handleModalClose = () => {
    this.setState({
      modalEnabled: false,
      isEdit: false,
      workDetail: {
        End: '',
        Role: '',
        Start: '',
        Detail: '',
        Company: '',
      },
      formErrors: {
        End: '',
        Role: '',
        Start: '',
        Detail: '',
        Company: '',
      }
    })
  }

  onChange = (key, value) => {
    this.setState({
      workDetail: {
        ...this.state.workDetail,
        [key]: value
      }
    });
  }

  onSubmit = () => {
    let works = [];
    if(!this.state.isEdit) {
      works = [].concat(this.getWorks(this.props), this.state.workDetail);
    } else {
      works = this.getWorks(this.props);
      works[this.state.workIndex] = this.state.workDetail;
    }
    this.props.onSubmit(works).then(resposne => {
      this.handleModalClose();
    });
  }

  onEditWork = (item, index) => {
    this.setState({
      isEdit: true,
      workDetail: item,
      workIndex: index,
      modalEnabled: true,
    })
  }

  onDeleteWork = (index) => {
    if (window.confirm('Are you sure you wish to delete this Work?')) {
      let works = this.getWorks(this.props);
      works.splice(index, 1);
      this.props.onSubmit(works);
    }
  }

  render() {
    const works = this.getWorks(this.props);
    return (
      <div>
      <AccordionCard title="Work" actionhtml={
        <span className="add-sign" onClick={() => {
          this.setState({modalEnabled: true});
        }} >+</span>
      } >
        {works.length > 0 ? (
          <div className="Education__list">
            {works.map((item, index) => {
              return (
                <div
                  className="Education__list__item"
                  key={`profile-work-${index}`}
                >
                  <span className="Education__list__item__year">
                    <Moment format="MM/DD/YYYY">{item.End}</Moment>
                    <div className="Education__list__item__actions">
                      <a onClick={() => { this.onEditWork(item, index) }} >Edit</a>
                      <a onClick={() => this.onDeleteWork(index) }>Delete</a>
                    </div>
                  </span>
                  |
                  <div className="Education__list__item__school">
                    {item.Role} at {item.Company !== "" ? item.Company : "-"}
                    <p>{item.Detail}</p>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (

          <Empty message="No work found" />
        )}

        
      </AccordionCard>
      <Modal animation={false} size="lg" className="detailed_time_sheet_modal" show={this.state.modalEnabled} onHide={() => this.handleModalClose()} centered>
        <Modal.Header closeButton>
          <Modal.Title>{this.getModalTitle()}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container">
            
            <Input
              label="Role"
              placeholder="Enter Role"
              onChange={value => this.onChange("Role", value)}
              value={this.state.workDetail.Role}
              invalidFeedback={''}
            />

            <Input
              label="Company"
              placeholder="Enter Company"
              onChange={value => this.onChange("Company", value)}
              value={this.state.workDetail.Company}
              invalidFeedback={''}
            />

            <InputDate
              label="Start Date"
              placeholder="Select start date"
              onChange={value => this.onChange("Start", value)}
              value={this.state.workDetail.Start}
              invalidFeedback={''}
            />
            
            <InputDate
              label="End Date"
              placeholder="Select End date"
              onChange={value => this.onChange("End", value)}
              value={this.state.workDetail.End}
              invalidFeedback={''}
            />

            <InputTextArea
              label="Details"
              placeholder="Enter Details"
              onChange={value => this.onChange("Detail", value)}
              value={this.state.workDetail.Detail}
              invalidFeedback={''}
              rows="3"
            />

            <a className="greenBtn modalButton" onClick={() => this.onSubmit()} >
              Save
            </a>
          </div>
        </Modal.Body>
      </Modal>
      </div>
    );
  }
}

export default Work;
