import React, { Component } from "react";

import Input from "../../../components/ui/form/Input";
import InputFile from '../../../components/ui/form/InputFile';
export class addContactForm extends Component {
    state = {
        checked: false
    };
    componentDidMount() {
        if (this.props.contactDetail.PrimaryContact==='true') {
            var primarycontact = document.getElementsByClassName("primarycontact");
            for (var i = 0; i < primarycontact.length; i++) {
                primarycontact[i].checked = true;
            }
            setTimeout(()=>{ this.setState({checked: true}); this.props.onInput("primarycontact", true); }, 10);
        }
    }

    onChange(key, value) {
        if (key === "primarycontact") {
            this.props.onInput("primarycontact", !this.state.checked);
            this.setState({checked: !this.state.checked});
        } else {
            this.props.onInput(key, value);
        }

    }
    checkboxCall(event) {

    }
    onFileUpload = (file) => {
        console.log(file);
        if (typeof file.FileID !== "undefined" && file.FileID !== "") {
            this.props.onInput("ProfilePicture", file.FileID);
        }
    }
    render() {

        const { formErrors, contactDetail } = this.props;
        let FirstName = "";
        let LastName = "";
        let Title = "";
        let Phone = "";
        let Fax = "";
        let Email = "";
        if (typeof contactDetail.FirstName !== "undefined") {
            FirstName = contactDetail.FirstName;
        }
        if (typeof contactDetail.LastName !== "undefined") {
            LastName = contactDetail.LastName;
        }
        if (typeof contactDetail.Title !== "undefined") {
            Title = contactDetail.Title;
        }
        if (typeof contactDetail.Phone !== "undefined") {
            Phone = contactDetail.Phone;
        }
        if (typeof contactDetail.Fax !== "undefined") {
            Fax = contactDetail.Fax;
        }
        if (typeof contactDetail.Email !== "undefined") {
            Email = contactDetail.Email;
        }
        return (
            <div class="add_contact">
                <div className="container">

                    <div className="row">
                        <div className="col-md-12">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <div class="form-group">
                                <Input
                                    label="First Name"
                                    placeholder="First Name"
                                    onChange={value => this.onChange("FirstName", value)}
                                    validFeedback=""
                                    value={FirstName}
                                    invalidFeedback={formErrors.FirstName}
                                />
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="form-group">
                                <Input
                                    label="Last Name"
                                    placeholder="Last Name"
                                    onChange={value => this.onChange("LastName", value)}
                                    validFeedback=""
                                    value={LastName}
                                    invalidFeedback={formErrors.LastName}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <Input
                                    label="Title"
                                    placeholder="Title"
                                    onChange={value => this.onChange("Title", value)}
                                    validFeedback=""
                                    value={Title}
                                    invalidFeedback={formErrors.Title}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <div class="form-group">
                                <Input
                                    label="Phone"
                                    placeholder="Phone"
                                    onChange={value => this.onChange("Phone", value)}
                                    validFeedback=""
                                    value={Phone}
                                    invalidFeedback={formErrors.Phone}
                                />
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="form-group">
                                <Input
                                    label="Fax"
                                    placeholder="Fax"
                                    onChange={value => this.onChange("Fax", value)}
                                    validFeedback=""
                                    value={Fax}
                                    invalidFeedback={formErrors.Fax}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md">
                            <div class="form-group">
                                <Input
                                    label="Email"
                                    placeholder="Email"
                                    onChange={value => this.onChange("Email", value)}
                                    validFeedback=""
                                    value={Email}
                                    invalidFeedback={formErrors.Email}
                                />
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="form-group">
                                <InputFile
                                    label="Upload Picture"
                                    onChange={this.onFileUpload}
                                />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <label class="container1 checkbox_1">
                                <input className="primarycontact" type="checkbox" onChange={value => this.onChange("primarycontact", value)} /><span class="checkmark"></span>Primary Contact</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <ul class="buttonBlock" style={{ float: 'left' }}>
                                <li> <a href="#" style={{color:'#FF0000',lineHeight:'50px'}} onClick={() => this.props.onDelete()}>Delete Contact</a></li>
                            </ul>
                            <ul class="buttonBlock" style={{ float: 'right', marginRight: -15 }}>
                                <li> <a href="#" class="greenBtn" style={{backgroundColor:'#AAAAAA'}} onClick={() => this.props.onCancel()}>Cancel</a></li>
                                <li>
                                    <a href="#" className="greenBtn" onClick={() => this.props.onSubmit()}>
                                        {(typeof contactDetail.FirstName !== "undefined") ? "Update" : "Add"}
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default addContactForm;
