import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button } from "react-bootstrap"
import { closeModal, openModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Input from "../../components/ui/form/Input";
import InputDropdown from "../../components/ui/form/InputDropdown";
import InputDateOpportunity from "../../components/ui/form/InputDateOpportunity";

import { availableStatus } from "../../modules/Opportunities/config/Constants";
import { availableType } from "../../modules/Opportunities/config/Constants";
import { availableLocation } from "../../modules/Opportunities/config/Constants";
import ReactTags from 'react-tag-autocomplete';
import * as opportunityActions from '../../modules/Opportunities/actions/index';
import { faSpinner, faClock, faMapMarkerAlt, faMoneyBillAlt, faHourglassStart } from "@fortawesome/free-solid-svg-icons";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import InputAutocomplete from "../../components/ui/form/InputAutocomplete";

class OpportunityEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formErrors: {
        Title: '',
        Description: '',
        StartDate: '',
        Location: '',
        Type: '',
        Hours: '',
        Compensation: '',
      },
      isValid: false,
      skills: [],
      responsibilities: [],
      requirements: [],
      MembersDropdown: []
    }
    this.handleAddSkill = this.handleAddSkill.bind(this);
    this.handleRemoveSkill = this.handleRemoveSkill.bind(this);
    this.handleAddResponsibilities = this.handleAddResponsibilities.bind(this);
    this.handleRemoveResponsibilities = this.handleRemoveResponsibilities.bind(this);
    this.handleAddRequirements = this.handleAddRequirements.bind(this);
    this.handleRemoveRequirements = this.handleRemoveRequirements.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.validateData = this.validateData.bind(this);

  }
  onChange(key, value) {
    this.props.setOpportunityFormData(key, value);
  }

  componentDidMount() {

    this.props.setOpportunityFormData("ItemId", this.props.opportunityList.opportunityDetails[0].ItemId);
    this.props.setOpportunityFormData("Title", this.props.opportunityList.opportunityDetails[0].Title);
    this.props.setOpportunityFormData("Description", this.props.opportunityList.opportunityDetails[0].Description);
    this.props.setOpportunityFormData("Status", this.props.opportunityList.opportunityDetails[0].Status);
    this.setState({ Status: this.props.opportunityList.opportunityDetails[0].Status });
    this.props.setOpportunityFormData("Specializations", this.props.opportunityList.opportunityDetails[0].Specializations);
    this.props.setOpportunityFormData("Responsibilities", this.props.opportunityList.opportunityDetails[0].Responsibilities);
    this.props.setOpportunityFormData("Requirements", this.props.opportunityList.opportunityDetails[0].Requirements);
    this.props.setOpportunityFormData("Location", this.props.opportunityList.opportunityDetails[0].Location);
    this.props.setOpportunityFormData("Compensation", this.props.opportunityList.opportunityDetails[0].Compensation);
    this.props.setOpportunityFormData("Hours", this.props.opportunityList.opportunityDetails[0].Hours);
    this.props.setOpportunityFormData("HoursType", this.props.opportunityList.opportunityDetails[0].HoursType);
    this.props.setOpportunityFormData("Length", this.props.opportunityList.opportunityDetails[0].Length);

    this.props.setOpportunityFormData("Type", this.props.opportunityList.opportunityDetails[0].Type);
    let StartDate = this.props.opportunityList.opportunityDetails[0].StartDate.split('T');
    this.props.setOpportunityFormData("StartDate", StartDate[0]);
    this.setState({ skills: this.props.opportunityList.opportunityDetails[0].Specializations });
    this.setState({ responsibilities: this.props.opportunityList.opportunityDetails[0].Responsibilities });
    this.setState({ requirements: this.props.opportunityList.opportunityDetails[0].Requirements });
    setTimeout(() => {
      var isModalFound = document.getElementsByClassName('modal show');
      if (isModalFound.length > 1) {
        document.getElementsByClassName('modal show')[0].style.display = "none";
      }
    }, 1000);

    //set subject and message for potentially closing an opportunity
    this.props.setOpportunityFormData("Subject", `The ${this.props.opportunityList.opportunityDetails[0].Title} position is closed, thank you for your interest`);
    this.props.setOpportunityFormData("Body",
      `Thank you for your interest in the ${this.props.opportunityList.opportunityDetails[0].Title} position.

We appreciate you taking the time and effort put into the process.

The pool of applicants was competitive. After careful consideration another candidate has been selected.

We are continuing to post short and long term opportunities through our platform. We hope you will continue to express interest for opportunities posted.

You may consider these other postings by logging in to the mobile app:
[listOfOpportunities]

Sincerely,
The TECKpert Team`
    );

    //load the members dropdown
    let parsedData = [];
    this.props.opportunityList.opportunityDetails[0].Workers.map(obj => {
      parsedData.push({ title: `${obj.Name} ${obj.Email ? '(' + obj.Email + ')' : ''}`, value: obj.ItemId })
    })
    this.setState({ MembersDropdown: parsedData });
  }
  handleAddSkill = (tag) => {
    const skills = [].concat(this.state.skills, tag.name);
    this.setState({ skills });
  }

  handleRemoveSkill = (i) => {
    const skills = this.state.skills.slice(0);
    skills.splice(i, 1);
    this.setState({ skills });
  }
  handleAddResponsibilities = (tag) => {
    const responsibilities = [].concat(this.state.responsibilities, tag.name);
    this.setState({ responsibilities });
  }

  handleRemoveResponsibilities = (i) => {
    const responsibilities = this.state.responsibilities.slice(0);
    responsibilities.splice(i, 1);
    this.setState({ responsibilities });
  }
  handleAddRequirements = (tag) => {
    const requirements = [].concat(this.state.requirements, tag.name);
    this.setState({ requirements });
  }

  handleRemoveRequirements = (i) => {
    const requirements = this.state.requirements.slice(0);
    requirements.splice(i, 1);
    this.setState({ requirements });
  }
  setError = (key, message) => {
    let formErrors = this.state.formErrors;
    formErrors[key] = message;
    this.setState({
      formErrors: formErrors,
      isValid: false,
    });
  }
  clearFormErrors = async () => {
    const emptyErrors = {
      Title: '',
      Description: '',
      StartDate: '',
      Location: '',
      Type: '',
      Hours: '',
      Compensation: '',
    };
    await this.setState({
      formErrors: emptyErrors,
    });
  }

  validateData = () => {
    const data = this.props.addOpportunityData;
    return this.clearFormErrors().then(() => {
      let hasErrors = false;
      console.log(data);
      console.log(typeof data.Title);
      if (typeof data.Title === 'undefined' || data.Title === '') {
        this.setError('Title', 'Please provide Job Title');
        hasErrors = true;
      }
      if (typeof data.Description === 'undefined' || data.Description === '') {
        this.setError('Description', 'Please provide Details');
        hasErrors = true;
      }
      if (typeof data.StartDate === 'undefined' || data.StartDate === '') {
        this.setError('StartDate', 'Please provide Start Date');
        hasErrors = true;
      }
      if (typeof data.Location === 'undefined' || data.Location === '') {
        this.setError('Location', 'Please provide Location');
        hasErrors = true;
      }
      if (typeof data.Type === 'undefined' || data.Type === '') {
        this.setError('Type', 'Please select Type');
        hasErrors = true;
      }
      if (typeof data.Hours === 'undefined' || data.Hours === '') {
        this.setError('Hours', 'Please provide Hours');
        hasErrors = true;
      }

      if (typeof data.Compensation === 'undefined' || data.Compensation === '') {
        this.setError('Compensation', 'Please provide Compensation');
        hasErrors = true;
      }
      return hasErrors;
    });
  }
  onSubmit() {
    this.props.setOpportunityFormData("Specializations", this.state.skills);
    this.props.setOpportunityFormData("Responsibilities", this.state.responsibilities);
    this.props.setOpportunityFormData("Requirements", this.state.requirements);
    this.validateData().then(hasErrors => {
      if (!hasErrors) {
        this.props.addOpportunity(this.props.addOpportunityData);
      }
    });
  }
  handleModalClose() {
    this.props.RemoveOpportunityResponse();
    this.props.setModalName(null);
    this.props.closeModal();
  }
  closeModal() {
    this.props.RemoveOpportunityResponse();
    console.log("Modal Closed")
    this.props.setModalName(null);
    this.props.closeModal();
  }
  closeModalCallback() {
    this.props.RemoveOpportunityResponse()
    this.props.setModalName(null);
    this.props.closeModal();
  }
  //
  render() {
    const { formErrors } = this.props;
    const skills = [];
    const responsibilities = [];
    const requirements = [];
    this.state.skills.map((item, index) => {
      skills.push({
        id: index,
        name: item,
      });
    });
    this.state.responsibilities.map((item, index) => {
      responsibilities.push({
        id: index,
        name: item,
      });
    });
    this.state.requirements.map((item, index) => {
      requirements.push({
        id: index,
        name: item,
      });
    });
    return (
      <div>
        <Modal className="opportunity_modal" size="xl" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Update Opportunity</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="container add_opportunity_container">
              <div className="add_opportunity">
                <div className="row">
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-10">
                        <div className="row">
                          <div className="col-md-12">
                            <Input
                              label="Job Title"
                              placeholder="Job Title"
                              onChange={value => this.onChange("Title", value)}
                              validFeedback=""
                              invalidFeedback={this.state.formErrors.Title}
                              value={this.props.addOpportunityData.Title}
                            />
                          </div>
                        </div>
                        <div className="row test">
                          <div className="col-md">
                            <InputDropdown
                              label="Type"
                              placeholder="Select Type"
                              onChange={value => this.onChange("Type", value)}
                              options={availableType}
                              invalidFeedback={this.state.formErrors.Type}
                              selected={this.props.addOpportunityData.Type}
                            />
                          </div>
                          <div className="col-md">
                            <InputDropdown
                              label="Status"
                              placeholder="Select Status"
                              onChange={value => { this.onChange("Status", value); this.setState({ Status: value }); }}
                              options={availableStatus}
                              invalidFeedback={this.state.formErrors.Status}
                              selected={this.props.addOpportunityData.Status}
                            />
                          </div>
                        </div>

                        {this.state.Status === 'Open' ?
                          <div>
                            <div className="row">
                              <div className="col-md-12">
                                <ReactQuill
                                  style={{ height: 250, marginBottom: 50 }}
                                  modules={{ toolbar: [['bold', 'italic', 'underline'], [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['link'], ['clean']] }}
                                  value={this.props.addOpportunityData.Description || ''}
                                  onChange={value => this.onChange("Description", value)}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <label className="form-label">Skills/Specializations</label>
                                <ReactTags
                                  tags={skills}
                                  className="form-control"
                                  allowNew={true}
                                  handleDelete={this.handleRemoveSkill}
                                  handleAddition={this.handleAddSkill}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <label className="form-label">Responsibilities</label>
                                <ReactTags
                                  tags={responsibilities}
                                  className="form-control"
                                  allowNew={true}
                                  handleDelete={this.handleRemoveResponsibilities}
                                  handleAddition={this.handleAddResponsibilities}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <label className="form-label">Requirements</label>

                                <ReactTags
                                  tags={requirements}
                                  className="form-control"
                                  allowNew={true}
                                  handleDelete={this.handleRemoveRequirements}
                                  handleAddition={this.handleAddRequirements}
                                />
                              </div>
                            </div>
                          </div>
                          : <div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <InputAutocomplete
                                    label="Selected Applicant (excluded from closed opportunity notice)"
                                    placeholder="Worker"
                                    onChange={value => this.onChange("SelectedApplicant", value)}
                                    options={this.state.MembersDropdown}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="form-label">Subject</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(event) => { this.onChange("Subject", event.currentTarget.value); }}
                                    value={this.props.addOpportunityData.Subject}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label className="form-label">Body</label>
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    onChange={(event) => { this.onChange("Body", event.currentTarget.value); }}
                                    value={this.props.addOpportunityData.Body}
                                    rows={10}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>}
                      </div>

                      {this.state.Status === 'Open' &&
                        <div className="col-md-2">
                          <div className="row">
                            <div className="col-md-12">
                              <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faMapMarkerAlt} /> Location</label>
                              <InputDropdown
                                placeholder="Select Location"
                                onChange={value => this.onChange("Location", value)}
                                options={availableLocation}
                                invalidFeedback={this.state.formErrors.Location}
                                selected={this.props.addOpportunityData.Location}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faMoneyBillAlt} /> Compensation</label>
                              <Input
                                onChange={value => this.onChange("Compensation", value)}
                                invalidFeedback={this.state.formErrors.Compensation}
                                value={this.props.addOpportunityData.Compensation}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faClock} />Term</label>
                              <InputDropdown
                                onChange={value => this.onChange("HoursType", value)}
                                options={[{ title: 'Hours per week', value: 'Hours per week' }, { title: 'Hours per month', value: 'Hours per month' }, { title: 'Fixed delvierable', value: 'Fixed delvierable' }]}
                                selected={this.props.addOpportunityData.HoursType}
                              />
                              <Input
                                onChange={value => this.onChange("Hours", value)}
                                validFeedback=""
                                invalidFeedback={this.state.formErrors.Hours}
                                value={this.props.addOpportunityData.Hours}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faHourglassStart} /> Start Date</label>
                              <InputDateOpportunity
                                onChange={value => this.onChange("StartDate", value)}
                                invalidFeedback={this.state.formErrors.StartDate}
                                value={this.props.addOpportunityData.StartDate}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <label className="form-label"><FontAwesomeIcon className="fa_icons" icon={faMoneyBillAlt} /> Length</label>
                              <Input
                                onChange={value => this.onChange("Length", value)}
                                value={this.props.addOpportunityData.Length}
                              />
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                {this.props.opportunityList.isLoading &&
                  <div className="loader"><FontAwesomeIcon icon={faSpinner} spin /></div>
                }
                {this.props.opportunityList.addOpportunityResponse === "Success" ?
                  this.closeModalCallback()
                  : this.props.opportunityList.addOpportunityResponse !== "" ? <div className="status error">Error in creating opportunity!</div> : ''
                }
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {this.state.Status === 'Open' ?
              <ul className="checkoptionBox" style={{ marginTop: 20, marginRight: 20 }}>
                <li>
                  <label className="container1">
                    <input type="checkbox" onChange={() => { this.onChange("SendPush", this.props.addOpportunityData.SendPush ? false : true); }} data-status="Open" />
                    <span className="checkmark"></span> Send Push Notification</label>
                </li>
                <li>
                  <label className="container1">
                    <input type="checkbox" onChange={() => { this.onChange("SendEmail", this.props.addOpportunityData.SendEmail ? false : true); }} data-status="Open" />
                    <span className="checkmark"></span> Send Email Notification</label>
                </li>
                <li>
                  <label className="container1">
                    <input type="checkbox" onChange={() => { this.onChange("SendMatch", this.props.addOpportunityData.SendMatch ? false : true); }} data-status="Open" />
                    <span className="checkmark"></span> Only Send To People With Matching Specializations</label>
                </li>
              </ul>
              :
              <ul className="checkoptionBox" style={{ marginTop: 20, marginRight: 20 }}>
                <li>
                  <label className="container1">
                    <input type="checkbox" onChange={() => { this.onChange("SendClosedEmail", this.props.addOpportunityData.SendMatch ? false : true); }} data-status="Open" />
                    <span className="checkmark"></span> Send Closed Opportunity Email to {this.props.opportunityList.opportunityDetails[0].Workers.length - (![undefined, null].includes(this.props.addOpportunityData.SelectedApplicant) ? 1 : 0)} Applicants</label>
                </li>
              </ul>
            }
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
          </Button>
            <Button onClick={this.onSubmit} variant="primary">
              Save Changes
          </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )

  }
}
OpportunityEdit.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
  getContracts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    opportunities: state.opportunityList.opportunities,
    opportunityList: state.opportunityList,
    addOpportunityData: state.opportunityList.addOpportunityData
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: (data) => dispatch(closeModal()),
    openModal: () => dispatch(openModal()),
    setModalName: (data) => dispatch(setModalName(data)),
    setOpportunityFormData: (key, value) => dispatch(opportunityActions.setOpportunityFormData(key, value)),
    addOpportunity: (data) => dispatch(opportunityActions.addOpportunity(data)),
    RemoveOpportunityResponse: () => dispatch(opportunityActions.RemoveOpportunityResponse())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(OpportunityEdit);