import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, openModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ClientConstants } from '../../modules/Clients/config/Constants';
import * as clientActions from '../../modules/Clients/actions';
import {
    faSpinner,
    faPhoneAlt,
    faEnvelope

} from "@fortawesome/free-solid-svg-icons";
import listOfStates from "../../helperFunctions/listOfStates";
import listOfIndustryTypes from '../../helperFunctions/listOfIndustryTypes';

class ClientEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            "Name": "",
            "Address": "",
            "City": "",
            "State": "",
            "Zip": "",
            "IndustryType": "",
            "Website": "",
            "Phone": "",
            "Fax": "",
            "Email": "",
            "ItemId": "",
            "companyValidation": "",
            "addressValidation": "",
            "cityValidation": "",
            "stateValidation": "",
            "zipValidation": "",
            "emailValidation": "",
            "phoneValidation": ""
        }
        this.updateInput = this.updateInput.bind(this);
        this.addClient = this.addClient.bind(this);
        this.handleModalClose.bind(this);
    }
    componentDidMount() {
        this.setState({ "ItemId": this.props.clientList.clientDetails[0].ItemId });
        this.setState({ "Name": this.props.clientList.clientDetails[0].Name });
        this.setState({ "Address": this.props.clientList.clientDetails[0].Address });
        this.setState({ "City": this.props.clientList.clientDetails[0].City });
        this.setState({ "State": this.props.clientList.clientDetails[0].State });
        this.setState({ "Zip": this.props.clientList.clientDetails[0].Zip });
        this.setState({ "IndustryType": this.props.clientList.clientDetails[0].IndustryType });
        this.setState({ "Website": this.props.clientList.clientDetails[0].Website });
        this.setState({ "Phone": this.props.clientList.clientDetails[0].Phone });
        this.setState({ "Fax": this.props.clientList.clientDetails[0].Fax });
        this.setState({ "Email": this.props.clientList.clientDetails[0].Email });
        setTimeout(() => {
            var isModalFound = document.getElementsByClassName('modal show');
            if (isModalFound.length > 1) {
                document.getElementsByClassName('modal show')[0].style.display = "none";
            }
        }, 1000);
    }
    handleModalClose() {
        this.props.setModalName(null);
        this.props.closeModal();
    }
    closeModal() {
        this.props.setModalName(null);
        this.props.closeModal();
    }
    closeModalCallback() {
        this.props.setModalName(null);
        this.props.closeModal();
    }
    updateInput(event) {
        var currentVal = event.currentTarget.value;
        var currentInput = event.currentTarget.getAttribute('data-input');
        this.setState({ [currentInput]: currentVal });
        if (this.state.Company !== "") {
            this.setState({ "companyValidation": "" });
        }
        if (this.state.Address !== "") {
            this.setState({ "addressValidation": "" });
        }
        if (this.state.Phone !== "") {
            this.setState({ "phoneValidation": "" });
        }
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (this.state.Email !== "" && filter.test(this.state.Email)) {
            this.setState({ "emailValidation": "" });
        }

    }
    addClient() {
        if (this.state.Company === "") {
            this.setState({ "companyValidation": "is-invalid" });
        }
        if (this.state.Address === "") {
            this.setState({ "addressValidation": "is-invalid" });
        }
        var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        if (this.state.Email === "" || !filter.test(this.state.Email)) {
            this.setState({ "emailValidation": "is-invalid" });
        }
        if (this.state.Phone === "") {
            this.setState({ "phoneValidation": "is-invalid" });
        }
        if (this.state.Company !== "" && this.state.Address !== "" && this.state.Phone !== "" && this.state.Email !== "") {
            this.setState({ "companyValidation": "" });
            this.setState({ "addressValidation": "" });
            this.setState({ "emailValidation": "" });
            this.setState({ "phoneValidation": "" });
            this.props.updateClient(this.state);

        }


    }
    //
    render() {

        return (
            <React.Fragment>
                <Modal animation={false} size="xl" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Update Client</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="add_contact">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Company Name</label>
                                                <input type="text" value={this.state.Name} onChange={this.updateInput} data-input="Name" className={"form-control " + this.state.companyValidation} placeholder="  Lorem ipsum " />
                                                <div class="invalid-feedback">
                                                    {ClientConstants.CompanyValidationMsg}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-5">
                                            <div className="form-group">
                                                <label className="form-label">Address</label>
                                                <input type="text" value={this.state.Address} onChange={this.updateInput} data-input="Address" className={"form-control " + this.state.addressValidation} placeholder="Address" />
                                                <div class="invalid-feedback">
                                                    {ClientConstants.AddressValidationMsg}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="form-label">City</label>
                                                <input type="text" value={this.state.City} onChange={this.updateInput} data-input="City" className={"form-control " + this.state.cityValidation} placeholder="City" />
                                                <div class="invalid-feedback">
                                                    {ClientConstants.CityValidationMsg}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label">State</label>
                                                <select type="text" value={this.state.State} onChange={this.updateInput} data-input="State" className={"form-control " + this.state.stateValidation} placeholder="State">
                                                    {listOfStates().map(obj => (<option value={obj.value}>{obj.value}</option>))}
                                                </select>
                                                <div class="invalid-feedback">
                                                    {ClientConstants.StateValidationMsg}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="form-label">Zip</label>
                                                <input type="text" value={this.state.Zip} onChange={this.updateInput} data-input="Zip" className={"form-control " + this.state.zipValidation} placeholder="Zip" />
                                                <div class="invalid-feedback">
                                                    {ClientConstants.ZipValidationMsg}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label className="form-label">Industry Type</label>
                                                <select type="text" value={this.state.IndustryType} onChange={this.updateInput} data-input="IndustryType" className="form-control " placeholder="Industry Type">
                                                    {listOfIndustryTypes().map(obj => (<option value={obj.value}>{obj.value}</option>))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md">
                                            <div className="form-group">
                                                <label className="form-label">Website</label>
                                                <input type="text" value={this.state.Website} onChange={this.updateInput} data-input="Website" className="form-control" placeholder="Lorem ipsum " />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 pl-4 list_form">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label"><FontAwesomeIcon className="svg-inline--fa fa-call fa-w-16 callIcon" icon={faPhoneAlt} /> Phone</label>
                                                <input type="text" value={this.state.Phone} onChange={this.updateInput} data-input="Phone" className={"form-control " + this.state.phoneValidation} placeholder="Lorem ipsum " />
                                                <div class="invalid-feedback">
                                                    {ClientConstants.PhoneValidationMsg}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label"> <FontAwesomeIcon className="svg-inline--fa fa-call fa-w-16 callIcon" icon={faPhoneAlt} />Fax</label>
                                                <input type="text" value={this.state.Fax} onChange={this.updateInput} data-input="Fax" className="form-control" placeholder="Lorem ipsum " />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label"> <FontAwesomeIcon className="svg-inline--fa fa-call fa-w-16 callIcon" icon={faEnvelope} /> Email</label>
                                                <input type="text" value={this.state.Email} onChange={this.updateInput} data-input="Email" className={"form-control " + this.state.emailValidation} placeholder="Lorem ipsum " />
                                                <div class="invalid-feedback">
                                                    {ClientConstants.EmailValidationMsg}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.props.clientList.isLoading &&
                                <div className="loader"><FontAwesomeIcon icon={faSpinner} spin /></div>
                            }
                            {this.props.clientList.addClientResponse === "Success" &&
                                this.closeModal()
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.handleModalClose()}>
                            Close
          </Button>
                        <Button onClick={this.addClient} variant="primary">
                            Save Changes
          </Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )

    }
}
ClientEdit.propTypes = {
    closeModal: PropTypes.func.isRequired,
    setModalName: PropTypes.func.isRequired,
    getContracts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        modalState: state.uiState.modalOpen,
        modalName: state.uiState.modalName,
        memberContracts: state.contract.memberContracts,
        clientList: state.clientList,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: (data) => dispatch(closeModal()),
        openModal: () => dispatch(openModal()),
        setModalName: (data) => dispatch(setModalName(data)),
        updateClient: (data) => dispatch(clientActions.updateClient(data)),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClientEdit);