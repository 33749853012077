import React, { Component } from "react";
import PropTypes from "prop-types";
import {
    Link
  } from "react-router-dom";

import Moment from 'react-moment';
import Empty from '../../../../components/ui/Empty';
export class Contracts extends Component {
  
  static propTypes = {
    client: PropTypes.object
  };

  static defaultProps = {
    client: {

    }
  }
  addContract = () => {
      this.props.onAddContract();
  }
  render() {
    const { client } = this.props;
    let Contracts = [];
    if(client.length > 0){
        if(client[0].Contracts !== null && client[0].Contracts.length > 0){
            Contracts = client[0].Contracts;
        }
        for(var i=0;i<Contracts.length;i++){
            let StartDate = new Date(Contracts[i].StartDate);
            let EndDate = new Date(Contracts[i].EndDate);
            // To calculate the time difference of two dates 
            let DifferenceInTime = EndDate.getTime() - StartDate.getTime(); 
            // To calculate the no. of days between two dates 
            let DifferenceInMonths = DifferenceInTime / (1000 * 3600 * 24 * 30); 
            Contracts[i].contractLength = Math.round(DifferenceInMonths);
        }
    }
    return (
        <div class="card">
            <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                <button class="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" class="icon_green mr-1">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M3 5H1v16c0 1.1.9 2 2 2h16v-2H3V5zm18-4H7c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3c0-1.1-.9-2-2-2zm0 16H7V3h14v14z"/>
                    </svg>
                    Contracts  
                </button>
                <a onClick={this.addContract}><span class="add-sign">+</span></a>
                </h5> 
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                {(typeof Contracts !== 'undefined' && Array.isArray(Contracts) && Contracts.length > 0) ?
                <table class="table mt-4">
                    <thead>
                        <tr>
                            <th> Contract Name </th>
                            <th>Length </th>
                            <th>Start Date  </th>
                            <th>  Status </th>
                            <th>  MSA </th>
                            <th> Job Order(s)  </th>
                        </tr>
                    </thead>
                    <tbody>
                        {Contracts.map((Contract) => {
                            return (
                                <tr>
                                    <td> <Link to={"/contracts/"+Contract.ItemId}>{Contract.Name}</Link> </td>
                                    <td> {Contract.contractLength} Months </td>
                                    <td> <Moment format="MM/DD/YYYY">{Contract.StartDate}</Moment> </td>
                                    <td>  {Contract.Status} </td>
                                    <td>  {(Contract.MSA === 1) ? '✓' : '' } </td>
                                    <td>  {(Contract.JobOrders === 1) ? '✓' : '' } </td>
                                </tr> 
                            )
                        })}
                    </tbody>
                </table>
                : <Empty message="No contracts found" />
                }
                </div>
            </div>
        </div>
    );
    }
}

export default Contracts;