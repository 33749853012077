export const filterContactByClientId = (data, ClientId) => {
  const filteredData = data.filter(item => {
    return item.ClientId === ClientId;
  });
  return filteredData;
}

export const parseObjectToQueryStringData = (data) => {
  return Object.keys(data).map(key => key + '=' + data[key]).join('&');
}
