import React, { Component } from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
//importing contacts from Clients contacts component
//import ContactImg from '../../../Clients/components/view/ContactImg';
import { getStorageFile } from "../../../../helperFunctions/storageUtils";

import * as storageUtils from "../../../../helperFunctions/storageUtils";
import EditContract from "../form/editContract";

export class Info extends Component {
  constructor() {
    super();
    this.state = {
      editContractModalVisible: false,
      ContactProfilePicture: "",
      PMProfilePicture: "",
      CTOProfilePicture: "",
    };
  }

  componentDidMount() {
    setTimeout(() => {
      ["ContactProfilePicture", "PMProfilePicture", "CTOProfilePicture"].forEach((obj) => {
        if (this.props.contract[obj]) {
          getStorageFile(this.props.contract[obj]).then((url) => {
            if (url && url !== "") {
              this.setState({ [obj]: url });
            }
          });
        }
      });
    }, 2000);
  }

  static propTypes = {
    contract: PropTypes.object,
  };

  static defaultProps = {
    contract: {},
  };

  render() {
    const { contract, contractClient } = this.props;

    let clientName = "";
    if (typeof contractClient.Name !== "undefined" && contractClient.Name !== "") {
      clientName = contractClient.Name;
    }

    return (
      <div>
        <div className='row'>
          <div className='col-md-9 col-lg-10'></div>
          <div className='col-md-3 col-lg-2'>
            <ul className='editinglist'>
              <li>
                <a
                  href='#'
                  className='active'
                  onClick={() => {
                    this.setState({ editContractModalVisible: true });
                  }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'>
                    <path d='M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z' />
                    <path d='M0 0h24v24H0z' fill='none' />
                  </svg>
                </a>
              </li>
              <li>
                <a
                  href='#'
                  onClick={() => {
                    window.print();
                  }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'>
                    <path d='M19 8H5c-1.66 0-3 1.34-3 3v6h4v4h12v-4h4v-6c0-1.66-1.34-3-3-3zm-3 11H8v-5h8v5zm3-7c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm-1-9H6v4h12V3z' />
                    <path d='M0 0h24v24H0z' fill='none' />
                  </svg>
                </a>
              </li>
              <li>
                <a href='#'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'>
                    <path fill='none' d='M0 0h24v24H0z' />
                    <path d='M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z' />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <ul className='profildetail boderbox' style={{ alignItems: "flex-start" }}>
          <li className='username'>
            <h3>{contract.Name}</h3>
            <h4>{clientName}</h4>
            <h5 className='mb-4'> Status: {contract.Status} </h5>

            <div
              class='descriptionText'
              dangerouslySetInnerHTML={{ __html: contract.Details }}></div>

            <div class='row'>
              {contract.PrimaryContact && (
                <div className={"profile-block supervisor"}>
                  <div class='userprofil'>
                    <div className='contactImgContainer'>
                      {this.state.ContactProfilePicture && (
                        <img className='contactImg' src={this.state.ContactProfilePicture} />
                      )}
                    </div>
                  </div>
                  <h4>{contract.ContactName} </h4>
                </div>
              )}

              {contract.PM && (
                <div className={"profile-block PM"}>
                  <div class='userprofil'>
                    <div className='contactImgContainer'>
                      {this.state.PMProfilePicture && (
                        <img className='contactImg' src={this.state.PMProfilePicture} />
                      )}
                    </div>
                    <span style={{ fontSize: 12 }}>
                      {contract.PMFee ? "Fee: " + contract.PMFee + "%" : ""}
                    </span>
                  </div>
                  <h4>{contract.PMName} </h4>
                </div>
              )}

              {contract.CTO && (
                <div className={"profile-block CTO"}>
                  <div class='userprofil'>
                    <div className='contactImgContainer'>
                      {this.state.CTOProfilePicture && (
                        <img className='contactImg' src={this.state.CTOProfilePicture} />
                      )}
                    </div>
                    <span style={{ fontSize: 12 }}>
                      {contract.CTOFee ? "Fee: " + contract.CTOFee + "%" : ""}
                    </span>
                  </div>
                  <h4>{contract.CTOName} </h4>
                </div>
              )}
            </div>
          </li>
          <li>
            <span className='spadress'>
              <b>Workers </b>
              <p>
                {typeof contract.Workers !== "undefined" && Array.isArray(contract.Workers)
                  ? contract.Workers.length
                  : 0}
              </p>
            </span>

            <span className='spadress'>
              <b>Length</b>
              <p>{storageUtils.getDateDifference(contract.StartDate, contract.EndDate)}</p>
            </span>

            <span className='spadress'>
              <b>Start Date</b>
              <p>
                <Moment format='MM/DD/YYYY'>{contract.StartDate}</Moment>
              </p>
            </span>

            <span className='spadress'>
              <b>End Date</b>
              <p>
                <Moment format='MM/DD/YYYY'>{contract.EndDate}</Moment>
              </p>
            </span>

            <span className='spadress'>
              <b>Hours</b>
              <p>{contract.Hours && contract.Hours.slice(0, -3)}</p>
            </span>

            <span className='spadress'>
              <b>Default To</b>
              <p>
                {contract.DefaultToNames
                  ? contract.DefaultToNames.split(",").map((obj) => <div>{obj}</div>)
                  : "None Set"}
              </p>
            </span>

            <span className='spadress'>
              <b>Default From</b>
              <p>{contract.DefaultFromName ? contract.DefaultFromName : "None Set"}</p>
            </span>
          </li>
        </ul>
        <EditContract
          {...this.props}
          modalVisible={this.state.editContractModalVisible}
          closeModal={() => {
            this.setState({ editContractModalVisible: false });
          }}
        />
      </div>
    );
  }
}

export default Info;
