import React, { Component, Fragment } from "react";
// import "./Style/profileStyle.scss";
import "./Style/HeaderBG.scss"
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserCircle,
  faPen,
  faPrint,
  faFilePdf
} from "@fortawesome/free-solid-svg-icons";
import Profile from './Profile/Profile';

import Info from './info/info';

export default class HeaderBG extends Component {
  
  render() {
    // {this.props.profileMember.Name}
    //
   
    return (
      <Fragment>
      <section className="background">
        <div className="main-head">
          <div className="gridItem1"></div>
          <div className="Header">
            <ul className = "HeaderUl">
              <li>
                <span>Email</span>
              </li>
              <li>
                <span>Message</span>
              </li>
              <li>
                <span>Setup interview</span>{" "}
              </li>

                <ul className="rightIcons">
                  <li>
                    <FontAwesomeIcon className="icon3" icon={faPen} />
                  </li>
                  <li>
                    <FontAwesomeIcon className="icon3" icon={faPrint} />
                  </li>
                  <li>
                    <FontAwesomeIcon className="icon3" icon={faFilePdf} />
                  </li>
                </ul>
            </ul>

          </div>
        </div>
        <div className="Main-Card">
          <div className="column column--1">
            <FontAwesomeIcon className="icon1" icon={faUserCircle} />
        
          </div>
          <div className="column column--2">
            <header></header>
            <Profile profileMember={this.props.profileMember} />
          </div>
          <div className="column column--3">
            <Info profileMember ={this.props.profileMember} />
          </div>
        </div>
      </section>

      <section>
      
      
      </section>
      </Fragment>
    );
  }
}
