export const certificationsOptions = [
  {i: "Salesforce.com Certified Advanced Develop", name: "Salesforce.com Certified Advanced Develop" },
  {i: "Zend PHP Certification", name: "Zend PHP Certification" },
  {i: "Oracle APEX Certification", name: "Oracle APEX Certification" },
  {i: "Google Developer", name: "Google Developer" },
  {i: "Microsoft Certified Solutions Developer (MCSD)", name: "Microsoft Certified Solutions Developer (MCSD)" },
  {i: "Salesforce.com Certified Developer", name: "Salesforce.com Certified Developer" },
  {i: "MongoDB Certified Developer", name: "MongoDB Certified Developer" },
  {i: "Red Hat Certified JBoss Developer", name: "Red Hat Certified JBoss Developer" },
];

export const specializationOptions = [
  {id: "HTML", name: 'HTML' },
  {id: "CSS", name: 'CSS' },
  {id: "C#", name: 'C#' },
  {id: ".NET", name: '.NET' },
  {id: "C", name: 'C' },
  {id: "C++", name: 'C++' },
  {id: "React Native", name: 'React Native' },
  {id: "React JS", name: 'React JS' },
  {id: "Angular JS", name: 'Angular JS' },
  {id: "Swift", name: 'Swift' },
  {id: "Objective C", name: 'Objective C' },
  {id: "jQuery", name: 'jQuery' },
  {id: "JavaScript", name: 'JavaScript' },
  {id: "TypeScript", name: 'TypeScript' },
  {id: "WordPress", name: 'WordPress' },
  {id: "PHP", name: 'PHP' },
  {id: "MySQL", name: 'MySQL' },
  {id: "Python", name: 'Python' },
  {id: "Ruby", name: 'Ruby' },
  {id: "Go", name: 'Go' },
  {id: "ASP", name: 'ASP' },
];
