import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Modal, Button, Form, Row, Col } from "react-bootstrap"
import { closeModal, openModal, setModalName } from '../../actions/uiActions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddContractForm from "../../modules/Contracts/components/addContractForm";
import * as contractActions from "../../modules/Contracts/actions";
import * as clientActions from "../../modules/Clients/actions";
import * as memberActions from "../../modules/Members/actions";

import Input from "../../components/ui/form/Input";
import InputDropdown from "../../components/ui/form/InputDropdown";
import InputDate from "../../components/ui/form/InputDate";
import InputHours from "../../components/ui/form/InputHours";
import InputTextArea from "../../components/ui/form/InputTextArea";
import { Redirect } from 'react-router';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSpinner,
} from "@fortawesome/free-solid-svg-icons";
import { availableStatus } from "../../modules/Contracts/config/Constants";
class AddPeopleContract extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            formErrors: {
            Name: '',
            StartDate: '',
            EndDate: '',
            Hours: '',
            Status: '',
            ClientId: '',
            MemberId: '',
            },
            isValid: false,
            clients: [],
        }
    }

    componentDidMount() {
    this.props.fetchClients();
    this.parseWorkersData();
    this.props.setContractFormData("MemberId", this.props.member.ItemId);
    this.props.setContractFormData("Status", "Draft");
    
    //this.props.fetchWorkers();
    }

    parseClientData = () => {
    let data = [];
    if(this.props.clients.length > 0){
        this.props.clients.map((item) => {
        data.push({
            title: item.Name,
            value: item.ItemId,
        });
        });
    }
    return data;
    }
    parseWorkersData = () => {
    let data = [];
    data.push({
        title: `${this.props.member.FirstName} ${this.props.member.LastName}`,
        value: this.props.member.ItemId,
    });
    return data;
    }

    parseContactsData = () => {
    let data = [];
    if (this.props.contacts.length > 0) {
        this.props.contacts.map(item => {
        data.push({
            title: `${item.FirstName} ${item.LastName}`,
            value: item.ItemId,
        });
        })
    }
    return data;
    }
    
    fetchContacts = (ItemId) => {
    console.log(ItemId);
    this.props.fetchContacts(ItemId);
    }

    onSubmit = () => {
    this.validateData().then(hasErrors => {
        if (!hasErrors) {
        this.props.addMemberContract(this.props.addContractData);
        }
    });
    }

    setError = (key, message) => {
    let formErrors = this.state.formErrors;
    formErrors[key] = message; 
    this.setState({
        formErrors: formErrors,
        isValid: false,
    });
    }

    clearFormErrors =  async () => {
    const emptyErrors = {
        Name: '',
        StartDate: '',
        EndDate: '',
        Hours: '',
        Status: '',
        ClientId: '',
        MemberId: '',
    };
    await this.setState({
        formErrors: emptyErrors,
    });
    }

    validateData = () => {
    const data = this.props.addContractData;
    return this.clearFormErrors().then(() => {
        let hasErrors = false;

        if (typeof data.Name === 'undefined' || data.Name === '') {
        this.setError('Name', 'Please provide Contract Name');
        hasErrors = true;
        }
        if (typeof data.StartDate === 'undefined' || data.StartDate === '') {
        this.setError('StartDate', 'Please provide Start Date');
        hasErrors = true;
        }
        if (typeof data.EndDate === 'undefined' || data.EndDate === '') {
        this.setError('EndDate', 'Please provide End Date');
        hasErrors = true;
        }
        if (typeof data.Hours === 'undefined' || data.Hours === '') {
        this.setError('Hours', 'Please provide Hours');
        hasErrors = true;
        }
        if (typeof data.Status === 'undefined' || data.Status === '') {
        this.setError('Status', 'Please select Status');
        hasErrors = true;
        }
        if (typeof data.MemberId === 'undefined' || data.MemberId === '') {
        this.setError('MemberId', 'Please select Worker');
        hasErrors = true;
        }
        
        if (typeof data.PrimaryContact === 'undefined' || data.PrimaryContact === '') {
        this.setError('PrimaryContact', 'Please insert Contact');
        hasErrors = true;
        }
        if (typeof data.Details === 'undefined' || data.Details === '') {
        this.setError('Details', 'Please insert Details');
        hasErrors = true;
        }
        
        if (typeof data.ClientId === 'undefined' || data.ClientId === '' || data.ClientId <= 0 ) {
        this.setError('ClientId', 'Please select Client');
        hasErrors = true;
        }
        return hasErrors;
    });
    }

    onCancel = () => {
        this.props.setModalName(null);
        this.props.closeModal();
        this.props.clearContractResponse();
        // this.context.router.goBack();
    }
    closeModal(){
        this.props.setModalName(null);
        this.props.closeModal();
        this.props.clearContractResponse();
    }
    closeModalCallback(){
        this.props.setModalName(null);
        this.props.closeModal();
        this.props.clearContractResponse();
    }
    handleModalClose() {
        this.props.setModalName(null);
        this.props.closeModal();
        this.props.clearContractResponse();
    }

    onInput = (key, value) => {
    this.props.setContractFormData(key, value);
    }
  //
  render() {
    return (
      <React.Fragment>
        <Modal animation={false} size="lg" show={this.props.modalState} onHide={() => this.handleModalClose()} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Contract</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <div class="container add_contract_container worker_contract">
          <AddContractForm
            onSubmit={this.onSubmit}
            onCancel={this.onCancel}
            onInput={this.onInput}
            formErrors={this.state.formErrors}
            clients={this.parseClientData()}
            contacts={this.parseContactsData()}
            workers={this.parseWorkersData()}
            defaultWorker = {this.props.member.ItemId}
            fetchContacts={this.fetchContacts}
          />
          {this.props.contractList.isLoading && 
              <div className="loader"><FontAwesomeIcon icon={faSpinner} spin/></div>
          }
           {this.props.isLoading && 
              <div className="loader"><FontAwesomeIcon icon={faSpinner} spin/></div>
          }
          {this.props.addContractResponse === "Success" ?  
            this.closeModal()
          : this.props.addContractResponse !== "" ? <div className="status error">Error in creating contract!</div> : ''  
          }
        </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.handleModalClose()}>
              Close
          </Button>
            <Button variant="primary" onClick={() => this.onSubmit()}>
              Save Changes
          </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    )

  }
}
AddPeopleContract.propTypes = {
  closeModal: PropTypes.func.isRequired,
  setModalName: PropTypes.func.isRequired,
  getContracts: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    modalState: state.uiState.modalOpen,
    modalName: state.uiState.modalName,
    memberContracts: state.contract.memberContracts,
    addContractData: state.contract.addContractData,
    clients: state.clientList.clients,
    contacts: state.contract.contacts,
    workers: state.contract.workers,
    contractList : state.contractList,
    clientList: state.clientList,
    member: state.members.member,
    isLoading: state.members.isLoading,
    addContractResponse: state.members.addContractResponse,
  }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeModal: (data) => dispatch(closeModal()),
        openModal: () => dispatch(openModal()),
        setModalName: (data) => dispatch(setModalName(data)),
        setContractFormData: (key, value) => dispatch(contractActions.setContractFormData(key, value)),
        fetchClients: () => dispatch(clientActions.fetchClients()),
        fetchWorkers: () => dispatch(contractActions.fetchWorkers()),
        fetchContacts: (ItemId) => dispatch(contractActions.fetchContacts(ItemId)),
        addMemberContract: (data) => dispatch(memberActions.addMemberContract(data)),
        clearContractResponse : () => dispatch(memberActions.clearContractResponse()),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(AddPeopleContract);