export const FETCH_MESSAGE_THREADS = "FETCH_MESSAGE_THREADS"
export const SET_MESSAGE_THREADS = "SET_MESSAGE_THREADS"

export const FETCH_AVAILABLE_CONTACTS = "FETCH_AVAILABLE_CONTACTS"
export const SET_AVAILABLE_CONTACTS = "SET_AVAILABLE_CONTACTS"

export const SEND_MESSAGE = "SEND_MESSAGE"
export const SEND_BULK_MESSAGE = "SEND_BULK_MESSAGE"

export const FETCH_CONVERSATION = "FETCH_CONVERSATION"
export const SET_CONVERSATION = "SET_CONVERSATION"
export const CLEAR_CONVERSATION = "CLEAR_CONVERSATION"

export const SEND_MESSAGE_CONVERSATION = "SEND_MESSAGE_CONVERSATION"
// export const SEND_PRIVATE_MESSAGE = "SEND_PRIVATE_MESSAGE"

export const ENABLE_LOADER = "ENABLE_LOADER"
export const DISABLE_LOADER = "DISABLE_LOADER"