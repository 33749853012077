import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faPen, faPrint, faFilePdf, faStar } from "@fortawesome/free-solid-svg-icons";

import Profile from "./Profile";
import Info from "./Info";
import ProfileForm from "../form/EditProfile";

import { getStorageFile } from "../../../../helperFunctions/storageUtils";
import Modal from "../../../../components/ui/Modal";

import MessageForm from "../form/MessageForm";
import InterviewForm from "../form/InterviewForm";
import { API, Auth } from "aws-amplify";
import Input from "../../../../components/ui/form/Input";
import InputDropdown from "../../../../components/ui/form/InputDropdown";
import InputTextArea from "../../../../components/ui/form/InputTextArea";
import InputDate from "../../../../components/ui/form/InputDate";
import moment from "moment";
import People from "./CreatePDF/People";

export class ProfileHeader extends Component {
  static propTypes = {};

  constructor() {
    super();
    this.state = {
      ItemId: 0,
      ProfilePictureURL: "",
      modalEnabled: false,
      messageModal: false,
      interviewModal: false,
      Subject: "",
      Body: "",
      Types: [
        { title: "Interview with Client", value: "interviewWithClient" },
        { title: "Invite to Skills Test", value: "InviteToSkillsTest" },
        { title: "Offer for Contract", value: "offerForContract" },
        { title: "Pre-Screen", value: "preScreen" },
        { title: "Screening Interview for Position", value: "interviewForPosition" },
      ],
      Positions: [],
      LastContacted: " "
    };
  }

  componentWillReceiveProps(newProps) {
    const { Meta } = newProps.member;
    const OldMeta = this.props.member.Meta;
    const ProfilePicture =
      Meta && typeof Meta.ProfilePicture !== "undefined" && Meta.ProfilePicture !== ""
        ? Meta.ProfilePicture
        : "";
    const OldProfilePicture =
      OldMeta && typeof OldMeta.ProfilePicture !== "undefined" && OldMeta.ProfilePicture !== ""
        ? OldMeta.ProfilePicture
        : "";
    if (ProfilePicture && ProfilePicture !== "" && ProfilePicture !== OldProfilePicture) {
      getStorageFile(ProfilePicture).then((url) => {
        if (url && url !== "") {
          this.setState({ ProfilePictureURL: url });
        }
      });
    }

    let userData = Auth.currentSession().then((response) => {
      this.setState({ Admin: response.idToken.payload["custom:itemId"] });
    });

    API.get(
      "1",
      `/applicants?OrderBy=DateCreated&SortOrder=ASC&ItemsPerPage=1&Page=0&MemberId=${this.props.member.ItemId}`
    ).then((responseJson) => {
      if (responseJson.Output.length > 0) {
        this.setState({ Position: responseJson.Output[0].OpportunityTitle }, () =>
          console.log(this.state.Positions)
        );
      }
    });

    API.get("1", `/admins?OrderBy=LastName&SortOrder=ASC&ItemsPerPage=100&Page=0`).then(
      (responseJson) => {
        let data = [];
        let adminsData = {};
        responseJson.Output.map((item) => {
          data.push({
            title: `${item.FirstName} ${item.LastName} (${item.Email})`,
            value: item.ItemId,
            firstName: item.FirstName,
          });
          adminsData[item.ItemId] = item.FirstName + " " + item.LastName;
        });
        this.setState(() => ({ admins: data, adminsData: adminsData }));
      }
    );

    API.get(
      "1",
      `/opportunities?OrderBy=Title&SortOrder=ASC&ItemsPerPage=100&Page=0&Open=true`
    ).then((responseJson) => {
      let data = [];
      responseJson.Output.map((item) => {
        data.push({
          title: item.Title,
          value: item.Title,
        });
      });
      this.setState(() => ({ positions: data }));
    });

    this.setState({ vettedDate: moment().format("YYYY-MM-DD") });
  }

  openModal = () => {
    this.setState({
      ItemId: this.props.member.ItemId,
      modalEnabled: true,
    });
  };

  handleModalClose = () => {
    this.setState({
      modalEnabled: false,
    });
  };

  onMessageSend = () => {
    let data = this.state.message;
    data.ReceiverId = this.props.member.UserId;
    this.props.sendPrivateMessage(data);
    this.setState({
      message: {
        Message: "",
      },
      messageModal: false,
    });
  };

  onSetupInterview = () => {
    let Subject = this.state.Subject;
    let Body = this.state.Body;
    if (!["preScreen", "", undefined].includes(this.state.Type)) {
      Subject = Subject.replace(/\[OPPORTUNITY NAME\]/g, this.state.Position);
      Body = Body.replace(/\[OPPORTUNITY NAME\]/g, this.state.Position).replace(
        /\[OPPORTUNITY\]/g,
        encodeURI(this.state.Position)
      );
    } 
    API.post("1", `/interview`, {
      body: {
        Admin: this.state.Admin,
        MemberIds: [this.props.member.ItemId],
        Type: this.state.Type,
        Subject: Subject,
        Body: Body,
      },
    }).then((responseJson) => {
      this.setState({
        interviewModal: false,
        Admin: null,
        Subject: null,
        Body: null,
        Type: null,
        MemberIds: null,
        Position: null,
      });
    });
  };

  onMessageChange = (key, value) => {
    let message = this.state.message;
    message[key] = value;
    this.setState({
      message: message,
    });
  };

  formatMessage = () => {
    let subject = "";
    let message = "";
    if (this.state.Type === "preScreen") {
      subject = `We'd like to get to know you`;
      message = `Welcome to TECKpert.\n\nMy name is ${
        this.state.adminsData[this.state.Admin]
      } and I'd like to setup a Zoom meeting to get to know you better.\n\nPlease click on the link below to find a time that works for you.\n\nhttps://calendly.com/tckage/pre-screen-platform?name=[NAME]&email=[EMAIL]\n\nI look forward to meeting you.\n\n- ${
        this.state.adminsData[this.state.Admin]
      }`;
    } else if (this.state.Type === "interviewForPosition") {
      subject = `We'd like to speak with you about the [OPPORTUNITY NAME] opportunity`;
      message = `Thank you for your interest in our [OPPORTUNITY NAME].\n\nWe are holding pre-screening interviews for this opportunity over the coming days.\n\nClick on the link below to select a time slot.\n\nhttps://calendly.com/tckage/pre-screen-position?name=[NAME]&email=[EMAIL]&a1=[OPPORTUNITY]\n\nPlease take some time to complete your profile on our app prior to the interview. I look forward to meeting with you.\n\n- ${
        this.state.adminsData[this.state.Admin]
      }`;
    } else if (this.state.Type === "InviteToSkillsTest") {
      subject = `Thank you for your interest in our [OPPORTUNITY NAME] position.`;
      message = `We are sending you an invite for a skills test from a service called TestDome.\n\nPlease be on the lookout for the invite and respond to the test invite.\n\nThe test must be completed within 3 days to be considered for the position. \n\nBest,\n- ${
        this.state.adminsData[this.state.Admin]
      }`;
    } else if (this.state.Type === "interviewWithClient") {
      subject = `Interview Request by our client for the [OPPORTUNITY NAME] opportunity`;
      message = `Our client has reviewed your profile and would like to setup an interview.\n\nClick on the link below to select a time slot.\n\nhttps://calendly.com/tckage/interview-client?name=[NAME]&email=[EMAIL]&a1=[OPPORTUNITY]\n\nWe look forward to meeting with you.\n\n- ${
        this.state.adminsData[this.state.Admin]
      }`;
    } else if (this.state.Type === "offerForContract") {
      subject = `Contract Offer for [OPPORTUNITY NAME]`;
      message = `Congratulations!\n\nOur client is ready for you to join the team!\n\nI have some details below for you to review:\n\n• Desired start date: mm/dd/yy\n•Rate of Pay: $xy\n• Hours per week: NN\n• Length of contract: NN\n\nPlease contact me at 555-555-5555 to review the next steps.\n\nBest,\n- ${
        this.state.adminsData[this.state.Admin]
      }`;
    }
    this.setState({ Subject: subject, Body: message });
  };

  render() {
    const { member } = this.props;
    return (
      <Fragment>
        <section className='background'>
          <div className='container'>
            <div className='main-head'>
              <div className='gridItem1'></div>
              <div className='ProfileHeader'>
                <div className='ProfileHeader__ctas'>
                  {/* <button className="btn btn-primary btn-sm" onClick={() => {
                    this.setState({ messageModal: true })
                  }} >Send Message</button> */}
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                      if (this.props.member.Vetted === "0000-00-00") {
                        this.setState({ vettedModal: true });
                      } else {
                        let result = window.confirm(
                          "Are you sure you want to unmark this worker as vetted?"
                        );
                        if (result) {
                          API.put("1", `/members`, {
                            body: { ItemId: this.props.member.ItemId, Vetted: null },
                          }).then((responseJson) => {
                            this.props.member.Vetted = "0000-00-00";
                            this.setState({ render: true });
                          });
                        }
                      }
                    }}>
                    {this.props.member.Vetted === "0000-00-00"
                      ? "Mark as Vetted"
                      : "Unmark as Vetted"}
                  </button>
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                      API.put("1", `/members`, {
                        body: {
                          ItemId: this.props.member.ItemId,
                          Flagged: !this.props.member.Flagged ? 1 : 0,
                        },
                      }).then((responseJson) => {
                        this.props.member.Flagged = !this.props.member.Flagged;
                        this.setState({ render: true });
                      });
                    }}>
                    {!this.props.member.Flagged ? "Mark as Flagged" : "Unmark as Flagged"}
                  </button>
                  <button
                    className='btn btn-primary btn-sm'
                    onClick={() => {
                      this.setState({ interviewModal: true });
                    }}>
                    Message
                  </button>
                  {this.props.member.AccountStatus === "active" ? (
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        let result = window.confirm(
                          "Are you sure you want to disable this worker?"
                        );
                        if (result) {
                          API.put("1", `/members`, {
                            body: { ItemId: this.props.member.ItemId, AccountStatus: "disabled" },
                          }).then((responseJson) => {
                            this.props.member.AccountStatus = "disabled";
                            this.setState({ render: true });
                          });
                        }
                      }}>
                      Disable Account
                    </button>
                  ) : (
                    <button
                      className='btn btn-primary btn-sm'
                      onClick={() => {
                        let result = window.confirm(
                          "Are you sure you want to re-enable this worker?"
                        );
                        if (result) {
                          API.put("1", `/members`, {
                            body: { ItemId: this.props.member.ItemId, AccountStatus: "active" },
                          }).then((responseJson) => {
                            this.props.member.AccountStatus = "active";
                            this.setState({ render: true });
                          });
                        }
                      }}>
                      Enable Account
                    </button>
                  )}
                </div>
                <div className='ProfileHeader__actions'>
                  <ul className='ProfileHeader__actions__list'>
                    <li onClick={this.openModal}>
                      <FontAwesomeIcon className='icon3' icon={faPen} />
                    </li>
                    <li
                      onClick={() => {
                        window.print();
                      }}>
                      <FontAwesomeIcon className='icon3' icon={faPrint} />
                    </li>
                    <li>
                      <People
                        member={this.props.member}
                        profilePic={this.state.ProfilePictureURL}
                      />
                    </li>
                    <li
                      onClick={() => {
                        this.props.member.Starred = !this.props.member.Starred;
                        API.put("1", `/members`, {
                          body: {
                            ItemId: this.props.member.ItemId,
                            Starred: this.props.member.Starred ? 1 : 0,
                          },
                        }).then((responseJson) => {
                          this.setState({});
                        });
                      }}>
                      <FontAwesomeIcon
                        className='icon3'
                        style={{
                          cursor: "pointer",
                          color: this.props.member.Starred ? "#6FBE47" : "#cccccc",
                        }}
                        icon={faStar}
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className='Main-Card'>
              <div className='column column--1'>
                {this.state.ProfilePictureURL !== "" ? (
                  <div className='memberProfilePicture'>
                    <div
                      style={{
                        backgroundImage: `url(${this.state.ProfilePictureURL})`,
                      }}
                      className='memberProfilePictureImage'></div>
                  </div>
                ) : (
                  <div className='memberProfilePicture'>
                    <FontAwesomeIcon style={{ width: 200 }} className='icon1' icon={faUserCircle} />
                  </div>
                )}
              </div>
              <div className='column column--2'>
                <header></header>
                <Profile {...this.props} />
              </div>
              <div className='column column--3'>
                <Info {...this.props} />
              </div>
            </div>
          </div>
        </section>
        <ProfileForm
          modalEnabled={this.state.modalEnabled}
          handleModalClose={this.handleModalClose}
          member={this.props.member}
          memberMeta={this.props.memberMeta}
          onProfileMetaInput={this.props.onProfileMetaInput}
          onProfileSubmit={this.props.onProfileSubmit}
        />
        <Modal
          isVisible={this.state.messageModal}
          title={`Send a message to ${member.FirstName} ${member.LastName}`}
          onSubmit={this.onMessageSend}
          onClose={() => {
            this.setState({ messageModal: false });
          }}
          buttonTitle='Send'>
          <MessageForm onMessageChange={this.onMessageChange} message={this.state.message} />
        </Modal>

        <Modal
          isVisible={this.state.interviewModal}
          title={`Send a message to ${member.FirstName} ${member.LastName}`}
          onSubmit={this.onSetupInterview}
          onClose={() => {
            this.setState({ interviewModal: false });
          }}>
          <div className='container send_to_client_container'>
            <div className='clients_appBox from-for'>
              <div className='row'>
                <div className='col-md'>
                  <div className='form-group'>
                    <label class='form-label'>From</label>
                    <InputDropdown
                      placeholder='Select Admin'
                      onChange={(value) => {
                        setTimeout(() => {
                          this.formatMessage();
                        }, 0);
                        this.setState({ Admin: value });
                      }}
                      selected={this.state.Admin}
                      options={this.state.admins}
                    />
                  </div>
                </div>
                <div className='col-md'>
                  <div className='form-group'>
                    <label class='form-label'>Type</label>
                    <InputDropdown
                      placeholder='Select Type'
                      onChange={(value) => {
                        setTimeout(() => {
                          this.formatMessage();
                        }, 0);
                        this.setState({ Type: value });
                      }}
                      options={this.state.Types}
                    />
                  </div>
                </div>
                {!["preScreen", "", undefined].includes(this.state.Type) && (
                  <div className='col-md'>
                    <div className='form-group'>
                      <label class='form-label'>Position</label>
                      <InputDropdown
                        placeholder='Select Position'
                        onChange={(value) => {
                          setTimeout(() => {
                            this.formatMessage();
                          }, 0);
                          this.setState({ Position: value });
                        }}
                        options={this.state.positions}
                        selected={this.state.Position}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label className='form-label'>Subject</label>
                    <input
                      type='text'
                      className='form-control'
                      onChange={(event) => this.setState({ Subject: event.currentTarget.value })}
                      value={this.state.Subject}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label className='form-label'>Body</label>
                    <textarea
                      type='text'
                      className='form-control'
                      onChange={(event) => this.setState({ Body: event.currentTarget.value })}
                      value={this.state.Body}
                      rows={15}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          isVisible={this.state.vettedModal}
          title={`Mark ${member.FirstName} ${member.LastName} as vetted`}
          onSubmit={() => {
            API.put("1", `/members`, {
              body: { ItemId: member.ItemId, Vetted: this.state.vettedDate },
            }).then((responseJson) => {
              this.props.member.Vetted = this.state.vettedDate;
              this.setState({ vettedModal: false });
            });
          }}
          onClose={() => {
            this.setState({ vettedModal: false });
          }}>
          <div class='row' style={{ justifyContent: "center" }}>
            <InputDate
              value={this.state.vettedDate}
              onChange={(value) => {
                this.state.vettedDate = value;
              }}
            />
          </div>
        </Modal>
      </Fragment>
    );
  }
}

export default ProfileHeader;
