import { SET_MODAL_TRUE, SET_MODAL_FALSE, SET_MODAL_NAME } from '../actions/actionTypes';

const initialState = {
    modalOpen: false,
    modalName: null
};

export default function (state = initialState, action) {
    switch (action.type) {
        case SET_MODAL_TRUE:
            return {
                ...state,
                modalOpen: action.payload,
            };
        case SET_MODAL_FALSE:
            return {
                ...state,
                modalOpen: action.payload,
            };
        case SET_MODAL_NAME:
            return {
                ...state,
                modalName: action.payload,
            };
        default:
            return state;
    }
}
